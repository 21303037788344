import { editorActive_atom, partnerID_atom } from "@/pages/Partner/Influencers/Eidtor/atom";
import { Button, ButtonGroup } from "@shopify/polaris";
import { useCallback, useMemo } from "react";

import { loadableAuthAtom } from "@/global/authAtom";
import { useBomLocation } from "@/hooks/useBomLocation";
import { useRecoilToggle } from "@/hooks/useRecoilToggle";
import { useAtom } from "jotai";
import { useRecoilState } from "recoil";

export const Operation = ({ partnerID, platformID }) => {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      LOADING: permission.state === "loading",
      ERROR: permission.state === "hasError",
      WRITE: permission.data?.["pmp/resources/discovery/write"],
      IMPORT: permission.data?.["pmp/resources/discovery/import"],
      EXPORT: permission.data?.["pmp/resources/discovery/export"],
      READ: permission.data?.["pmp/resources/discovery/read"],
    }),
    [permission]
  );
  const { origin } = useBomLocation();
  const { toggle } = useRecoilToggle(editorActive_atom);
  const [, setPartnerID] = useRecoilState(partnerID_atom);

  const navgateReport = useCallback(() => {
    if (!platformID) {
      window.open(`${origin}/home/${partnerID}/edit/report`);
    } else {
      window.open(`${origin}/home/platforms/${platformID}/report/${partnerID}`);
    }
  }, [partnerID, platformID, origin]);

  const navgateEdit = useCallback(() => {
    setPartnerID(partnerID);
    toggle();
  }, [toggle, partnerID, setPartnerID]);

  return (
    <ButtonGroup segmented>
      <Button plain onClick={navgateReport}>
        View
      </Button>
      {!(partnerID && access.WRITE) ? undefined : (
        <Button plain onClick={navgateEdit}>
          Edit
        </Button>
      )}
    </ButtonGroup>
  );
};
