import { atom } from "recoil";
import { v4 } from "uuid";

export const contactID_atom = atom({
  key: v4(),
  default: "",
});

export const SociallinkTypes_atom = atom({
  key: v4(),
  default: [],
});
