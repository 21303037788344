import { getPartners, postAddPlatforms2Partners } from "@/api/request";
import { Avatar, Filters, Modal, OptionList, Stack, Text } from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react";

import { AntPagination } from "@/components/AntToShopify/AntPagination";
import { AvatarNameInList } from "@/components/EnhanceShopify/AvatarName";
import { OptionAvatarBox } from "@/components/EnhanceShopify/OptionAvatarBox";
import { useGlobalToast } from "@/customHooks/useGlobalToast";
import { useScopeSpinner } from "@/customHooks/useScopeSpinner";
import { RowBlckList } from "@/pages/Partner/Profiles/Common/RowBlckList";
import { useDebounceFn } from "ahooks";
import { Pagination } from "antd";

export const RelatedPartners = ({ active, handleChange, refreshList, platformIDs }) => {
  const { handleGlobalToast } = useGlobalToast();

  const { ScopeSpinner, setIsLoading } = useScopeSpinner();

  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);

  const [queryValue, setQueryValue] = useState("");
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);

  //#region request
  const [total, setTotal] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  // 首次加载请求的传参:
  const initParams = useMemo(() => {
    return {
      name: queryValue,
      page: currentPage,
      per_page: perPage,
    };
  }, [currentPage, perPage, queryValue]);

  // 在请求展示所有条目的基础上,筛选当前页条目:
  const { run } = useDebounceFn(
    async (params) => {
      setIsLoading(true);
      const { data } = await getPartners({ ...initParams, ...params });
      const optionArr = data.list.map(({ id, avatar, first_name, is_blacklisted }) => {
        const label = (
          <AvatarNameInList isBlackList={!!is_blacklisted}>
            <div className="avatar">
              <Avatar
                source={avatar}
                size="extraSmall"
                customer={!!is_blacklisted}
              />
            </div>
            <RowBlckList is_blacklisted={is_blacklisted}>
              <Text variant="bodyMd" as="span" color={!is_blacklisted ? "strong" : "subdued"}>
                {first_name}
              </Text>
            </RowBlckList>
          </AvatarNameInList>
        );
        return { value: String(id), label };
      });
      setOptions(optionArr);
      setTotal(data.meta.pagination.total);
      setPerPage(data.meta.pagination.per_page);
      setCurrentPage(data.meta.pagination.current_page);
      setIsLoading(false);
    },
    { wait: 600 }
  );
  const reqCurrentTable = useCallback(run, [initParams, run]);

  // tabs/搜搜栏/creator/sort/project 变化
  useEffect(() => {
    reqCurrentTable({ page: 1 });
  }, [
    // 不能监听页码和每页数, 会造成挂载执行两次
    reqCurrentTable,
    queryValue,
  ]);

  //#endregion

  const handleClearStatus = useCallback(() => {
    setSelected([]);
    setQueryValue("");
    setCurrentPage(1);
  }, []);

  return (
    <Modal
      open={active}
      onClose={() => {
        handleClearStatus();
        handleChange();
      }}
      title={`Related ${
        platformIDs?.length > 1
          ? platformIDs.length + " social links"
          : platformIDs.length + " social link"
      } with ${
        selected?.length > 1 ? selected.length + " influencers" : selected.length + " influencer"
      } `}
      primaryAction={{
        content: "Save",
        onAction: async () => {
          try {
            if (selected.length) {
              await postAddPlatforms2Partners(platformIDs, selected[0]);
              refreshList();
              handleChange();
              handleGlobalToast({ msg: "Related with influencer successfully" });
            }
          } catch (error) {
            handleGlobalToast({ msg: "Related with influencer failed", isErr: true });
          }
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction() {
            handleClearStatus();
            handleChange();
          },
        },
      ]}
    >
      <div style={{ padding: "16px 16px 4px", display: "flex" }}>
        <div style={{ flex: 1 }}>
          <Filters
            queryPlaceholder="Search by influencer name"
            queryValue={queryValue}
            filters={[]}
            appliedFilters={[]}
            onQueryChange={setQueryValue}
            onQueryClear={handleQueryValueRemove}
          />
        </div>
      </div>
      <OptionAvatarBox>
        {ScopeSpinner}
        <OptionList onChange={setSelected} options={options} selected={selected} />
        {options.length ? (
          <Stack distribution="trailing">
            <AntPagination>
              <Pagination
                // showTotal={(total, range) => `[${range[0]}-${range[1]}]/${total}`}
                current={currentPage}
                pageSize={perPage}
                pageSizeOptions={[5, 10]}
                showSizeChanger
                total={total}
                onChange={(page, pageSize) => {
                  reqCurrentTable({ ...initParams, page: page, per_page: pageSize });
                }}
              />
            </AntPagination>
          </Stack>
        ) : null}
      </OptionAvatarBox>
    </Modal>
  );
};
