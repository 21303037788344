export const treeData = [
  {
    title: "Animals & Nature",
    value: "Animals & Nature",
    key: "Animals & Nature",
    children: [
      {
        title: "Nature",
        value: "Nature",
        key: "Nature",
        children: [
          {
            title: "Natural environment",
            value: "Natural environment",
            key: "Natural environment",
          },
        ],
      },
      {
        title: "Pets & Animals",
        value: "Pets & Animals",
        key: "Pets & Animals",
        children: [
          {
            title: "Exotic Animals & Wildlife",
            value: "Exotic Animals & Wildlife",
            key: "Exotic Animals & Wildlife",
          },
          {
            title: "Livestock",
            value: "Livestock",
            key: "Livestock",
          },
          {
            title: "Other Animals",
            value: "Other Animals",
            key: "Other Animals",
          },
          {
            title: "Pets",
            value: "Pets",
            key: "Pets",
          },
        ],
      },
    ],
  },
  {
    title: "Beauty & Fashion",
    value: "Beauty & Fashion",
    key: "Beauty & Fashion",
    children: [
      {
        title: "Beauty & Self Care",
        value: "Beauty & Self Care",
        key: "Beauty & Self Care",
        children: [
          {
            title: "Cosmetics & Makeup",
            value: "Cosmetics & Makeup",
            key: "Cosmetics & Makeup",
          },
          {
            title: "Cosmetic Surgery",
            value: "Cosmetic Surgery",
            key: "Cosmetic Surgery",
          },
          {
            title: "Hair",
            value: "Hair",
            key: "Hair",
          },
          {
            title: "Nails",
            value: "Nails",
            key: "Nails",
          },
          {
            title: "Oral Care",
            value: "Oral Care",
            key: "Oral Care",
          },
          {
            title: "Other Beauty",
            value: "Other Beauty",
            key: "Other Beauty",
          },
          {
            title: "Perfumes",
            value: "Perfumes",
            key: "Perfumes",
          },
          {
            title: "Personal Care",
            value: "Personal Care",
            key: "Personal Care",
          },
          {
            title: "Skincare",
            value: "Skincare",
            key: "Skincare",
          },
        ],
      },
      {
        title: "Fashion & Accessories",
        value: "Fashion & Accessories",
        key: "Fashion & Accessories",
        children: [
          {
            title: "Accessories",
            value: "Accessories",
            key: "Accessories",
          },
          {
            title: "Bags",
            value: "Bags",
            key: "Bags",
          },
          {
            title: "Body Art",
            value: "Body Art",
            key: "Body Art",
          },
          {
            title: "Clothing",
            value: "Clothing",
            key: "Clothing",
          },
          {
            title: "Jewelry",
            value: "Jewelry",
            key: "Jewelry",
          },
          {
            title: "Other Fashion",
            value: "Other Fashion",
            key: "Other Fashion",
          },
          {
            title: "Shoes",
            value: "Shoes",
            key: "Shoes",
          },
        ],
      },
    ],
  },
  {
    title: "Creativity & Leisure",
    value: "Creativity & Leisure",
    key: "Creativity & Leisure",
    children: [
      {
        title: "Arts & Crafts",
        value: "Arts & Crafts",
        key: "Arts & Crafts",
        children: [
          {
            title: "Crafts",
            value: "Crafts",
            key: "Crafts",
          },
          {
            title: "Drawing & Painting",
            value: "Drawing & Painting",
            key: "Drawing & Painting",
          },
          {
            title: "Other Art",
            value: "Other Art",
            key: "Other Art",
          },
        ],
      },
      {
        title: "DIY & Life Hacks",
        value: "DIY & Life Hacks",
        key: "DIY & Life Hacks",
        children: [
          {
            title: "DIY",
            value: "DIY",
            key: "DIY",
          },
          {
            title: "Life Hacks",
            value: "Life Hacks",
            key: "Life Hacks",
          },
        ],
      },
      {
        title: "Home & Garden",
        value: "Home & Garden",
        key: "Home & Garden",
        children: [
          {
            title: "Garden",
            value: "Garden",
            key: "Garden",
          },
          {
            title: "Home",
            value: "Home",
            key: "Home",
          },
        ],
      },
      {
        title: "Oddly Satisfying",
        value: "Oddly Satisfying",
        key: "Oddly Satisfying",
        children: [
          {
            title: "Aesthetics",
            value: "Aesthetics",
            key: "Aesthetics",
          },
          {
            title: "Slime & ASMR",
            value: "Slime & ASMR",
            key: "Slime & ASMR",
          },
        ],
      },
    ],
  },
  {
    title: "Education",
    value: "Education",
    key: "Education",
    children: [
      {
        title: "General Education",
        value: "General Education",
        key: "General Education",
      },
      {
        title: "Upskilling",
        value: "Upskilling",
        key: "Upskilling",
      },
    ],
  },
  {
    title: "Entertainment",
    value: "Entertainment",
    key: "Entertainment",
    children: [
      {
        title: "Animation & Cosplay",
        value: "Animation & Cosplay",
        key: "Animation & Cosplay",
        children: [
          {
            title: "Comics, Cartoon & Anime",
            value: "Comics, Cartoon & Anime",
            key: "Comics, Cartoon & Anime",
          },
          {
            title: "Cosplay",
            value: "Cosplay",
            key: "Cosplay",
          },
        ],
      },
      {
        title: "Entertainment & Music",
        value: "Entertainment & Music",
        key: "Entertainment & Music",
        children: [
          {
            title: "Entertainment News",
            value: "Entertainment News",
            key: "Entertainment News",
          },
          {
            title: "Fan Account",
            value: "Fan Account",
            key: "Fan Account",
          },
          {
            title: "Internet Personality",
            value: "Internet Personality",
            key: "Internet Personality",
          },
          {
            title: "Movies & TV",
            value: "Movies & TV",
            key: "Movies & TV",
          },
          {
            title: "Music",
            value: "Music",
            key: "Music",
          },
          {
            title: "Other Pop Culture",
            value: "Other Pop Culture",
            key: "Other Pop Culture",
          },
          {
            title: "Variety Shows & Reality TV",
            value: "Variety Shows & Reality TV",
            key: "Variety Shows & Reality TV",
          },
        ],
      },
      {
        title: "Games",
        value: "Games",
        key: "Games",
        children: [
          {
            title: "Action & Shooting Games",
            value: "Action & Shooting Games",
            key: "Action & Shooting Games",
          },
          {
            title: "Adventure Games",
            value: "Adventure Games",
            key: "Adventure Games",
          },
          {
            title: "Casual & Intellectual Games",
            value: "Casual & Intellectual Games",
            key: "Casual & Intellectual Games",
          },
          {
            title: "Elimination Games",
            value: "Elimination Games",
            key: "Elimination Games",
          },
          {
            title: "Large Online Games",
            value: "Large Online Games",
            key: "Large Online Games",
          },
          {
            title: "Massively Multiplayer Online Games",
            value: "Massively Multiplayer Online Games",
            key: "Massively Multiplayer Online Games",
          },
          {
            title: "Metaverse",
            value: "Metaverse",
            key: "Metaverse",
          },
          {
            title: "Other Games",
            value: "Other Games",
            key: "Other Games",
          },
          {
            title: "Other Role-Playing Games",
            value: "Other Role-Playing Games",
            key: "Other Role-Playing Games",
          },
          {
            title: "Parent Child Games",
            value: "Parent Child Games",
            key: "Parent Child Games",
          },
          {
            title: "Racing & Sports Games",
            value: "Racing & Sports Games",
            key: "Racing & Sports Games",
          },
          {
            title: "Sandbox Games",
            value: "Sandbox Games",
            key: "Sandbox Games",
          },
          {
            title: "Strategy Games",
            value: "Strategy Games",
            key: "Strategy Games",
          },
          {
            title: "Video Games",
            value: "Video Games",
            key: "Video Games",
          },
        ],
      },
    ],
  },
  {
    title: "Family & Emotion",
    value: "Family & Emotion",
    key: "Family & Emotion",
    children: [
      {
        title: "Emotion",
        value: "Emotion",
        key: "Emotion",
        children: [
          {
            title: "Romance",
            value: "Romance",
            key: "Romance",
          },
          {
            title: "Wedding",
            value: "Wedding",
            key: "Wedding",
          },
        ],
      },
      {
        title: "Family",
        value: "Family",
        key: "Family",
        children: [
          {
            title: "Family & Relationships",
            value: "Family & Relationships",
            key: "Family & Relationships",
          },
          {
            title: "Kids",
            value: "Kids",
            key: "Kids",
          },
          {
            title: "Toddlers",
            value: "Toddlers",
            key: "Toddlers",
          },
        ],
      },
    ],
  },
  {
    title: "Finance",
    value: "Finance",
    key: "Finance",
  },
  {
    title: "Lifestyle",
    value: "Lifestyle",
    key: "Lifestyle",
    children: [
      {
        title: "Food",
        value: "Food",
        key: "Food",
        children: [
          {
            title: "Drinks & Beverages",
            value: "Drinks & Beverages",
            key: "Drinks & Beverages",
          },
          {
            title: "Food Displays",
            value: "Food Displays",
            key: "Food Displays",
          },
          {
            title: "Food Recommendations",
            value: "Food Recommendations",
            key: "Food Recommendations",
          },
          {
            title: "Mukbang & Tasting",
            value: "Mukbang & Tasting",
            key: "Mukbang & Tasting",
          },
          {
            title: "Recipes",
            value: "Recipes",
            key: "Recipes",
          },
        ],
      },
      {
        title: "Travel",
        value: "Travel",
        key: "Travel",
        children: [
          {
            title: "Adventure Travel",
            value: "Adventure Travel",
            key: "Adventure Travel",
          },
          {
            title: "Destination",
            value: "Destination",
            key: "Destination",
          },
          {
            title: "Global Culture",
            value: "Global Culture",
            key: "Global Culture",
          },
          {
            title: "Hotels & Resorts",
            value: "Hotels & Resorts",
            key: "Hotels & Resorts",
          },
          {
            title: "Luxury Travel",
            value: "Luxury Travel",
            key: "Luxury Travel",
          },
          {
            title: "Travel Guide",
            value: "Travel Guide",
            key: "Travel Guide",
          },
        ],
      },
      {
        title: "Vlog",
        value: "Vlog",
        key: "Vlog",
        children: [
          {
            title: "Career",
            value: "Career",
            key: "Career",
          },
          {
            title: "Daily Life",
            value: "Daily Life",
            key: "Daily Life",
          },
          {
            title: "Fortune Telling",
            value: "Fortune Telling",
            key: "Fortune Telling",
          },
          {
            title: "Inspirational",
            value: "Inspirational",
            key: "Inspirational",
          },
          {
            title: "Interviews",
            value: "Interviews",
            key: "Interviews",
          },
          {
            title: "School Life",
            value: "School Life",
            key: "School Life",
          },
          {
            title: "Spirituality",
            value: "Spirituality",
            key: "Spirituality",
          },
        ],
      },
    ],
  },
  {
    title: "Miscellaneous",
    value: "Miscellaneous",
    key: "Miscellaneous",
  },
  {
    title: "Performance",
    value: "Performance",
    key: "Performance",
    children: [
      {
        title: "Acting & Drama",
        value: "Acting & Drama",
        key: "Acting & Drama",
      },
      {
        title: "Funny",
        value: "Funny",
        key: "Funny",
        children: [
          {
            title: "Fail",
            value: "Fail",
            key: "Fail",
          },
          {
            title: "Other Comedy",
            value: "Other Comedy",
            key: "Other Comedy",
          },
          {
            title: "Prank",
            value: "Prank",
            key: "Prank",
          },
          {
            title: "Reaction Videos",
            value: "Reaction Videos",
            key: "Reaction Videos",
          },
          {
            title: "Scripted Comedy",
            value: "Scripted Comedy",
            key: "Scripted Comedy",
          },
        ],
      },
      {
        title: "Lipsync",
        value: "Lipsync",
        key: "Lipsync",
        children: [
          {
            title: "Dialogue Lipsync",
            value: "Dialogue Lipsync",
            key: "Dialogue Lipsync",
          },
          {
            title: "Music Lipsync",
            value: "Music Lipsync",
            key: "Music Lipsync",
          },
        ],
      },
      {
        title: "Talent",
        value: "Talent",
        key: "Talent",
        children: [
          {
            title: "Beginner Dance",
            value: "Beginner Dance",
            key: "Beginner Dance",
          },
          {
            title: "Instrument",
            value: "Instrument",
            key: "Instrument",
          },
          {
            title: "Magic",
            value: "Magic",
            key: "Magic",
          },
          {
            title: "Professional Dance",
            value: "Professional Dance",
            key: "Professional Dance",
          },
          {
            title: "Singing",
            value: "Singing",
            key: "Singing",
          },
          {
            title: "Unusual Talent",
            value: "Unusual Talent",
            key: "Unusual Talent",
          },
        ],
      },
    ],
  },
  {
    title: "Society",
    value: "Society",
    key: "Society",
    children: [
      {
        title: "Life & Society",
        value: "Life & Society",
        key: "Life & Society",
        children: [
          {
            title: "Social News & Events",
            value: "Social News & Events",
            key: "Social News & Events",
          },
          {
            title: "Street Interview & Social Experiment",
            value: "Street Interview & Social Experiment",
            key: "Street Interview & Social Experiment",
          },
        ],
      },
    ],
  },
  {
    title: "Sports & Outdoor",
    value: "Sports & Outdoor",
    key: "Sports & Outdoor",
    children: [
      {
        title: "Fitness & Health",
        value: "Fitness & Health",
        key: "Fitness & Health",
        children: [
          {
            title: "Fitness",
            value: "Fitness",
            key: "Fitness",
          },
          {
            title: "Health",
            value: "Health",
            key: "Health",
          },
          {
            title: "Yoga & Meditation",
            value: "Yoga & Meditation",
            key: "Yoga & Meditation",
          },
        ],
      },
      {
        title: "Outdoor Activity",
        value: "Outdoor Activity",
        key: "Outdoor Activity",
        children: [
          {
            title: "Adventure",
            value: "Adventure",
            key: "Adventure",
          },
          {
            title: "Camping & Hiking",
            value: "Camping & Hiking",
            key: "Camping & Hiking",
          },
          {
            title: "Fishing & Hunting",
            value: "Fishing & Hunting",
            key: "Fishing & Hunting",
          },
        ],
      },
      {
        title: "Sports",
        value: "Sports",
        key: "Sports",
        children: [
          {
            title: "Extreme Sports",
            value: "Extreme Sports",
            key: "Extreme Sports",
          },
          {
            title: "Sports News",
            value: "Sports News",
            key: "Sports News",
          },
          {
            title: "Sports & Recreation",
            value: "Sports & Recreation",
            key: "Sports & Recreation",
          },
        ],
      },
    ],
  },
  {
    title: "Technology",
    value: "Technology",
    key: "Technology",
    children: [
      {
        title: "Photography",
        value: "Photography",
        key: "Photography",
        children: [
          {
            title: "Photography & Videography",
            value: "Photography & Videography",
            key: "Photography & Videography",
          },
        ],
      },
      {
        title: "Product Education",
        value: "Product Education",
        key: "Product Education",

        children: [
          {
            title: "Tutorials",
            value: "Tutorials",
            key: "Tutorials",
          },
        ],
      },
      {
        title: "Product Showcase",
        value: "Product Showcase",
        key: "Product Showcase",
        children: [
          {
            title: "Device Comparison",
            value: "Device Comparison",
            key: "Device Comparison",
          },
          {
            title: "Product Testing",
            value: "Product Testing",
            key: "Product Testing",
          },
          {
            title: "Unboxing",
            value: "Unboxing",
            key: "Unboxing",
          },
        ],
      },
    ],
  },
  {
    title: "Vehicles & Transportation",
    value: "Vehicles & Transportation",
    key: "Vehicles & Transportation",
    children: [
      {
        title: "Auto and Vehicles",
        value: "Auto and Vehicles",
        key: "Auto and Vehicles",
        children: [
          {
            title: "Cars & Trucks",
            value: "Cars & Trucks",
            key: "Cars & Trucks",
          },
          {
            title: "Special Events",
            value: "Special Events",
            key: "Special Events",
          },
          {
            title: "Vehicle Test & Showcase",
            value: "Vehicle Test & Showcase",
            key: "Vehicle Test & Showcase",
          },
        ],
      },
      {
        title: "Other Transportation",
        value: "Other Transportation",
        key: "Other Transportation",
        children: [
          {
            title: "Transportation Vehicles",
            value: "Transportation Vehicles",
            key: "Transportation Vehicles",
          },
        ],
      },
    ],
  },
];
