import {
  getNoRelatedPlatformReport,
  getPartnerPrincipalOptions,
  getPartnerReportGeneral,
  getPlatformReport,
} from "@/api/request";
import profiles_icon from "@/assets/svg/Profile.svg";
import { Loading, Page } from "@shopify/polaris";
import { useMount, useToggle, useUnmount } from "ahooks";
import { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import ins_icon from "@/assets/svg/platform/PMP-Instagram.svg";
import titok_icon from "@/assets/svg/platform/PMP-TikTok.svg";
import twitch_icon from "@/assets/svg/platform/PMP-Twitch.svg";
import twitter_icon from "@/assets/svg/platform/PMP-Twitter.svg";
import youtube_icon from "@/assets/svg/platform/PMP-YouTube.svg";
import { AntTabs } from "@/components/AntToShopify/AntTabs";
import { TabProp } from "@/components/AntToShopify/TabProp";
import { useCustomToast } from "@/customHooks/useCustomToast";
import { useTopLoading } from "@/customHooks/useTopLoading";
import { NoPermission } from "@/error/403";
import { ResponseError } from "@/error/500";
import { loadableAuthAtom } from "@/global/authAtom";
import { s_to_ms } from "@/utils/accurateTimeTrans";
import { filterPlatformList } from "@/utils/filterPlatformList";
import { moment2Date } from "@/utils/moment2Date";
import { EditMinor } from "@shopify/polaris-icons";
import { Tabs } from "antd";
import { useAtom } from "jotai";
import { EmptyLayout } from "../../PartnerReport/Common/EmptyLayout";
import { NoReport } from "../../PartnerReport/Common/NoReport";
import { General } from "../../PartnerReport/General/General";
import { Instagram } from "../../PartnerReport/Instagram";
import { TikTok } from "../../PartnerReport/TikTok/TikTok";
import { Twitch } from "../../PartnerReport/Twitch/Twitch";
import { Twitter } from "../../PartnerReport/Twitter";
import { YouTuBe } from "../../PartnerReport/Youtube/YouTuBe";
import { AddToCampaign } from "../../Profiles/Common/AddToCampaign";
import { AddToCollection } from "../Common/AddToCollection";

function MatchTabIcon(type) {
  switch (type) {
    case "TikTok":
      return titok_icon;
    case "YouTube":
      return youtube_icon;
    case "Twitch":
      return twitch_icon;
    case "Instagram":
      return ins_icon;
    case "Twitter":
      return twitter_icon;
    default:
      return null;
  }
}

const PlatformsListDetail = () => {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      LOADING: permission.state === "loading",
      ERROR: permission.state === "hasError",
      WRITE: permission.data?.["pmp/resources/discovery/write"],
      IMPORT: permission.data?.["pmp/resources/discovery/import"],
      EXPORT: permission.data?.["pmp/resources/discovery/export"],
      READ: permission.data?.["pmp/resources/discovery/read"],
    }),
    [permission]
  );

  const history = useHistory();
  const { partnerID, platformID } = useParams();
  console.log("PlatformsListDetail - partnerID, platformID: ", partnerID, platformID);

  const { TopLoadingJSX, hiddenTopLoading, showTopLoading } = useTopLoading();

  const [isPageLoading, setIsPageLoading] = useState(false);

  const { toastJSX: ErrToastJSX, toggleActive: toggleErrToastJSX } = useCustomToast(
    "Network Error",
    true
  );

  const [defaultActiveKey, setDefaultActiveKey] = useState("");

  const [platformList, setPlatformList] = useState([]); //总表包含[ { TikTok } , { YouTube } ]

  const [partnerInfomation, setPartnerInfomation] = useState({}); // PMP-partner详情

  const [memberOptions, setMemberOptions] = useState([]);

  const [updateTime, setUpdateTime] = useState("");
  const [updateBy, setUpdateBy] = useState("");

  const [add2CampaignModalActive, { toggle: toggleadd2CampaignModal }] = useToggle();
  const [add2CollectionModalActive, { toggle: toggleadd2CollectionModal }] = useToggle();

  function MatchPlatform(platform) {
    switch (platform.type) {
      case "TikTok":
        return (
          <TikTok
            selectPlatform={platform}
            setUpdateTime={setUpdateTime}
            setUpdateBy={setUpdateBy}
          />
        );
      case "YouTube":
        return (
          <YouTuBe
            selectPlatform={platform}
            setUpdateTime={setUpdateTime}
            setUpdateBy={setUpdateBy}
          />
        );
      case "Twitch":
        return (
          <Twitch
            selectPlatform={platform}
            setUpdateTime={setUpdateTime}
            setUpdateBy={setUpdateBy}
          />
        );
      case "Instagram":
        return (
          <Instagram
            selectPlatform={platform}
            setUpdateTime={setUpdateTime}
            setUpdateBy={setUpdateBy}
          />
        );
      case "Twitter":
        return (
          <Twitter
            selectPlatform={platform}
            setUpdateTime={setUpdateTime}
            setUpdateBy={setUpdateBy}
          />
        );
      default:
        return null;
    }
  }

  useMount(async () => {
    try {
      setIsPageLoading(true);
      showTopLoading();
      if (platformID !== "null") {
        setDefaultActiveKey(platformID);
      } else {
        setDefaultActiveKey("general");
      }

      const memberRes = await getPartnerPrincipalOptions();
      setMemberOptions(memberRes?.data?.list ?? []);

      // 根据有无关联伙伴 请求的接口是不一样的
      if (partnerID !== "no_related_partner") {
        // 有关联伙伴: 可能有多个平台
        const platform = await getPlatformReport(partnerID);
        const paltformAllList = filterPlatformList(platform);

        setPlatformList(paltformAllList);
        const { data } = await getPartnerReportGeneral(partnerID);
        setPartnerInfomation(data);
      } else {
        // 无关联伙伴的情况
        const platform = await getNoRelatedPlatformReport(platformID);
        const paltformAllList = filterPlatformList(platform);
        setPlatformList(paltformAllList);
      }
      hiddenTopLoading();
    } catch (error) {
      // setTabContents([]);
      toggleErrToastJSX();
      hiddenTopLoading();
    } finally {
      setIsPageLoading(false);
    }
  });
  // 卸载时
  useUnmount(() => {
    hiddenTopLoading();
  });

  if (access.LOADING) return <Loading />;
  if (access.ERROR) return <ResponseError />;
  if (!access.READ) return <NoPermission />;

  return (
    <Page
      // 这里 social link 页面进来的详情页面
      title="Influencer details"
      subtitle={
        updateTime ? `${moment2Date(s_to_ms(updateTime))} update by ${updateBy ?? ""}` : null
      }
      actionGroups={
        platformList?.length === 0 || !access.WRITE
          ? undefined
          : [
              {
                title: "More actions",
                actions: [
                  {
                    content: "Add to collection",
                    onAction: toggleadd2CollectionModal,
                  },
                  {
                    content: "Add to campaign",
                    onAction: toggleadd2CampaignModal,
                  },
                ],
              },
            ]
      }
      secondaryActions={
        partnerID === "no_related_partner" || partnerID === "undefined" || !access.WRITE
          ? undefined
          : [
              {
                content: "Edit basic profile",
                icon: EditMinor,
                onAction: () => history.push(`/home/${partnerID}/edit`),
              },
            ]
      }
    >
      {TopLoadingJSX}
      {ErrToastJSX}
      <AddToCampaign
        active={add2CampaignModalActive}
        handleChange={toggleadd2CampaignModal}
        handleSave={() => {}}
        partnerIDs={[partnerID]}
      />
      <AddToCollection
        active={add2CollectionModalActive}
        handleChange={toggleadd2CollectionModal}
        handleSave={() => {}}
        partnerIDs={[partnerID]}
      />
      {isPageLoading ? (
        <EmptyLayout />
      ) : (
        <AntTabs>
          {platformList?.length > 0 ? (
            <Tabs
              size="middle"
              type="card"
              activeKey={defaultActiveKey}
              destroyInactiveTabPane
              onTabClick={(key) => {
                setDefaultActiveKey(key);
              }}
            >
              {partnerID !== "no_related_partner" && (
                <Tabs.TabPane
                  tab={
                    <TabProp
                      type="Basic profile"
                      name={partnerInfomation?.first_name}
                      avatar={profiles_icon}
                    />
                  }
                  key="general"
                >
                  {!!partnerInfomation && (
                    <General
                      partnerInfomation={partnerInfomation}
                      memberOptions={memberOptions}
                      setUpdateTime={setUpdateTime}
                      setUpdateBy={setUpdateBy}
                    />
                  )}
                </Tabs.TabPane>
              )}
              {platformList.map((platform) => {
                console.log("platform.platform_id: ", platform.platform_id);
                return (
                  <Tabs.TabPane
                    tab={
                      <TabProp
                        type={platform.type}
                        name={platform?.options?.username}
                        avatar={MatchTabIcon(platform.type)}
                      />
                    }
                    key={String(platform.platform_id)}
                  >
                    {MatchPlatform(platform)}
                  </Tabs.TabPane>
                );
              })}
            </Tabs>
          ) : (
            <NoReport />
          )}
        </AntTabs>
      )}
    </Page>
  );
};

export default PlatformsListDetail;
