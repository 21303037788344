// contact status 根据传入的值返回不同的 Badge
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  EmptyState,
  Stack,
  Text,
  Tooltip,
} from "@shopify/polaris";

// eslint-disable-next-line
import EmptyStatePng from "@/assets/svg/Group 182@2x.png";
import Facebook_svg from "@/assets/svg/platform/PMP-Facebook.svg";
import Blog_svg from "@/assets/svg/platform/PMP-blog.svg";
import Redbook_svg from "@/assets/svg/platform/PMP-redbook.svg";
import { CustomEmpty } from "@/components/EnhanceShopify/CustomEmpty";
import { ViewMinor } from "@shopify/polaris-icons";
import { PlatformLink } from "../Common/PlatformLink";
// import blog_icon from "@/assets/blog_icon.png";
import ins_icon from "@/assets/svg/platform/PMP-Instagram.svg";
import titok_icon from "@/assets/svg/platform/PMP-TikTok.svg";
import twitch_icon from "@/assets/svg/platform/PMP-Twitch.svg";
import twitter_icon from "@/assets/svg/platform/PMP-Twitter.svg";
import youtube_icon from "@/assets/svg/platform/PMP-YouTube.svg";
import website_icon from "@/assets/website_icon.png";
import { transformNumber } from "@/utils/tools";
import { v4 } from "uuid";

// 各种有的没的的网站
export const plaType = (platforms) => {
  let Markup = [];
  pushEle(Markup, "YouTube", youtube_icon);
  pushEle(Markup, "Instagram", ins_icon);
  pushEle(Markup, "TikTok", titok_icon);
  pushEle(Markup, "Twitch", twitch_icon);
  pushEle(Markup, "Twitter", twitter_icon);
  pushEle(Markup, "Xiaohongshu", Redbook_svg);
  pushEle(Markup, "Facebook", Facebook_svg);
  pushEle(Markup, "Blog", Blog_svg);
  pushEle(Markup, "Others", website_icon);
  return Markup; // 每个类型的平台可能有多个需要展示的数量

  // 辅助函数: 数据中每一类平台有多少个.
  function typeArr(title = "") {
    return platforms?.filter((o) => o?.type?.title === title);
  }
  // 辅助函数: 如果数据中存在平台信息,每一类平台遍历出所有项,统一加入到总的数组中
  function pushEle(markupArr, title, icon) {
    if (typeArr(title)?.length) {
      const arr = typeArr(title).map((o) => (
        <div style={{ marginLeft: 8 }} key={v4()}>
          <Stack vertical spacing="extraTight" key={o}>
            <PlatformLink icon={icon} name={o?.type?.title} url={o.link} />
            <Stack.Item>
              <Stack distribution="center">
                {!!o?.subscribers && (
                  <Tooltip content={`${o.type.title} subscribers:${o.subscribers}`}>
                    <Text variant="bodyMd" as="span" fontWeight="bold">
                      {transformNumber(o.subscribers)}
                    </Text>
                  </Tooltip>
                )}
              </Stack>
            </Stack.Item>
          </Stack>
        </div>
      ));
      markupArr.push(...arr);
    }
  }
};

// 显示伙伴类型: Partner type
export const displayPartnerType = (num) => {
  if (num === 0) return "Influencer";
  if (num === 1) return "Media contact";
  if (num === 2) return "Affiliate";
  return "";
};

// 伙伴信息次栏
export const PartnerInfoField = (props) => {
  // eslint-disable-next-line
  const { partnerType, languages, birthday, role, accountLV, principal } = props;
  const nullState = "";

  return (
    <div style={{ textAlign: "center" }}>
      <Stack distribution="fillEvenly">
        {/* <Stack.Item>
          <Text variant="bodyMd" as="span" color="subdued">Partner type</Text>
          <div style={{ height: 10 }}></div>
          <Text variant="bodyMd" as="span" fontWeight="bold">{displayPartnerType(partnerType)}</Text>
        </Stack.Item> */}
        <Stack.Item>
          <Text variant="bodyMd" as="span" color="subdued">
            Language
          </Text>
          <div style={{ height: 10 }}></div>
          <Text variant="bodyMd" as="span" fontWeight="bold">
            {languages?.length ? languages.map((o) => `${o.name};`) : nullState}
          </Text>
        </Stack.Item>
        {/* <Stack.Item>
          <Text variant="bodyMd" as="span" color="subdued">Birthday</Text>
          <div style={{ height: 10 }}></div>
          <Text variant="bodyMd" as="span" fontWeight="bold">{birthday ? birthday : nullState}</Text>
        </Stack.Item> */}
        <Stack.Item>
          <Text variant="bodyMd" as="span" color="subdued">
            Tier
          </Text>
          <div style={{ height: 10 }}></div>
          <Text variant="bodyMd" as="span" fontWeight="bold">
            {accountLV?.title ?? nullState}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <Text variant="bodyMd" as="span" color="subdued">
            Owner
          </Text>
          <div style={{ height: 10 }}></div>
          <Text variant="bodyMd" as="span" fontWeight="bold">
            {principal ? `@${principal}` : nullState}
          </Text>
        </Stack.Item>
      </Stack>
    </div>
  );
};

// 四张小卡片code点击跳转
export const codeNavigate = (code, handleClick) => (
  <div style={{ cursor: "pointer" }} onClick={handleClick}>
    {code}
  </div>
);
// 四张小卡片的按钮组合
export const CardButtonGroup = ({
  primaryContent = "Create",
  plainContent = "View all",
  handlePlainClick,
  handlePrimaryClick,
}) => (
  <Card.Section>
    <Stack distribution="trailing">
      <ButtonGroup>
        <Button plain icon={ViewMinor} onClick={handlePlainClick}>
          {plainContent}
        </Button>
        <Button primary size="slim" onClick={handlePrimaryClick}>
          {primaryContent}
        </Button>
      </ButtonGroup>
    </Stack>
  </Card.Section>
);

// 四张小卡片的 headings
export const lastCooperHeadings = [
  Head("Cooperation code"),
  Head("Campaign"),
  Head("Status"),
  Head("Price"),
];
export const lastBudgetHeadings = [
  Head("Budget code"),
  Head("Campaign"),
  Head("Status"),
  Head("Price"),
];
export const lastPromotionHeadings = [
  Head("Promotion code"),
  Head("Campaign"),
  Head("Status"),
  Head("Link"),
];
export const lastDeliveryHeadings = [
  Head("delivery code"),
  Head("Campaign"),
  Head("Status"),
  Head("Receipt date"),
];
export const lastPaymentsHeadings = [
  Head("Payment code"),
  Head("Campaign"),
  Head("Status"),
  Head("Price"),
];
// 标题加粗
function Head(str) {
  return (
    <Text variant="bodyMd" as="span" fontWeight="bold">
      {str}
    </Text>
  );
}

export const EmptyStateCard = ({ btnTitle, content = "", handleCreate, access }) => {
  return (
    <Card>
      <CustomEmpty>
        <EmptyState
          heading={`This partner hasn't placed any ${content}`}
          action={!access ? undefined : { content: `Create ${btnTitle}`, onAction: handleCreate }}
          image={EmptyStatePng}
        ></EmptyState>
      </CustomEmpty>
    </Card>
  );
};

// 侧边栏需要的状态展示:
export const ContactStatus = ({ status }) => {
  if (status === 0) return <Badge>Not contacted</Badge>;
  if (status === 1) return <Badge status="info">In contact</Badge>;
  if (status === 2) return <Badge status="critical">Rejected</Badge>;
  if (status === 3) return <Badge status="success">Accepted</Badge>;
  return "status prop error";
};

export const BlacklistStatus = ({ status }) => {
  if (status === 0) return <Badge status="success">Unblock</Badge>;
  if (status === 1) return <Badge status="critical">Block</Badge>;
  return "status prop error";
};
