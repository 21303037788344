import { deletePartnerCompanyMany, getPartnerCompanys } from "@/api/request";
import {
  Button,
  Card,
  EmptySearchResult,
  Filters,
  IndexTable,
  Loading,
  Modal,
  Page,
  Pagination,
  Stack,
  Tabs,
  Text,
  useIndexResourceState,
} from "@shopify/polaris";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { LoadingContext } from "@/context/loading-context";
import { NoPermission } from "@/error/403";
import { ResponseError } from "@/error/500";
import { loadableAuthAtom } from "@/global/authAtom";
import { useAtom } from "jotai";
import { useHistory } from "react-router";

const PER_PAGE = 50;

export default function CompanyDetail() {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      LOADING: permission.state === "loading",
      ERROR: permission.state === "hasError",
      ALL: permission.data?.["pmp/settings/companys/all"],
    }),
    [permission]
  );
  const history = useHistory();
  const loadingContext = useContext(LoadingContext);

  const [tableLoading, setTableLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [filterInfo, setFilterInfo] = useState({
    name: "",
    page: 1,
    per_page: PER_PAGE,
    sort_by: "updated_at",
    sort_type: "desc",
  });

  const fetchCompanyList = useCallback((data = {}) => {
    setTableLoading(true);
    getPartnerCompanys(data).then((res) => {
      setCompanyList(res?.data?.list);
      setTotalCount(res?.data?.meta?.pagination?.total);
      setTotalPage(res?.data?.meta?.pagination?.total_pages);
      setTableLoading(false);
    });
  }, []);

  useEffect(() => {
    fetchCompanyList(filterInfo);
  }, [filterInfo, fetchCompanyList]);

  const [companyList, setCompanyList] = useState([]);

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(companyList);
  const customHandleSelect = (a, b, c) => {
    handleSelectionChange(a, b, c);
  };

  const handleGoDetail = useCallback(
    (id) => () => {
      history.push(`/account/detail/company/${id}`);
    },
    [history]
  );

  const resourceName = {
    singular: "company",
    plural: "companies",
  };

  const emptyStateMarkup = (
    <EmptySearchResult
      title={"No companies found"}
      description={"Try changing the filters or search term"}
      withIllustration
    />
  );

  const rowMarkup = companyList.map(({ id, name }, index) => (
    <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
      <IndexTable.Cell>
        <div onClick={handleGoDetail(id)}>
          <Text variant="bodyMd" as="span" fontWeight="bold">
            <Button plain>
              <span style={{ fontSize: "16px", color: "#202223" }}>{name}</span>
            </Button>
          </Text>
        </div>
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  const [queryValue, setQueryValue] = useState(null);
  const handleQueryChange = useCallback((newValue) => {
    setQueryValue(newValue);
    setFilterInfo((filter) => ({ ...filter, name: newValue, page: 1 }));
  }, []);
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue("");
    setFilterInfo((filter) => ({ ...filter, name: "", page: 1 }));
  }, []);
  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);

  const handleDeleteCompany = () => {
    loadingContext.loading(true);
    handleChange();
    deletePartnerCompanyMany(selectedResources)
      .then(() => {
        setTableLoading(false);
        loadingContext.loading(false, "Deleted successfully !");
        customHandleSelect("page", false);
        setTotalCount((total) => total - selectedResources.length);
        setCompanyList((List) => {
          let temp = List.filter((item) => !selectedResources.includes(item.id));
          return temp;
        });
      })
      .catch(() => {
        // setTableLoading(false);
      });
  };

  const [active, setActive] = useState(false);
  const handleChange = useCallback(() => setActive(!active), [active]);

  const promotedBulkActions = [
    {
      content: "Delete company",
      onAction: handleChange,
    },
  ];

  const prePage = useCallback(() => {
    setFilterInfo((filter) => ({ ...filter, page: filter.page - 1 }));
  }, []);
  const nextPage = useCallback(() => {
    setFilterInfo((filter) => ({ ...filter, page: filter.page + 1 }));
  }, []);

  // Tab 切换
  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);
  const tabs = [
    {
      id: "all",
      content: "All",
      accessibilityLabel: "All customers",
      panelID: "all-customers-content-1",
    },
  ];

  if (access.LOADING) return <Loading />;
  if (access.ERROR) return <ResponseError />;
  if (!access.ALL) return <NoPermission />;

  return (
    <Page
      title="Companies"
      // breadcrumbs={[
      //   {
      //     content: "Item Details",
      //     onAction: () => {
      //       history.goBack();
      //     },
      //   },
      // ]}
      primaryAction={
        <Stack>
          <Button
            onClick={() => {
              history.push("/account/detail/company/add");
            }}
            primary
          >
            Add company
          </Button>
        </Stack>
      }
      fullWidth
    >
      {/* <Card title="Company" sectioned>
        <p>As company information, used to assist other forms to fill in.</p>
      </Card> */}
      <Card title="">
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}></Tabs>
        <div style={{ padding: "16px" }} className="special-checkbox_first">
          <div style={{ flex: 1 }}>
            <Filters
              queryValue={queryValue}
              filters={[]}
              appliedFilters={[]}
              onQueryChange={handleQueryChange}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleClearAll}
              queryPlaceholder="Search"
            />
          </div>
          <IndexTable
            loading={tableLoading}
            itemCount={companyList.length}
            selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
            onSelectionChange={customHandleSelect}
            headings={[{ title: "Company name" }]}
            promotedBulkActions={promotedBulkActions}
            resourceName={resourceName}
            emptyState={emptyStateMarkup}
          >
            {rowMarkup}
          </IndexTable>
          <Stack distribution="center">
            <div style={{ display: companyList.length === 0 ? "none" : "block" }}>
              <Pagination
                label={`${filterInfo.page} / ${totalPage}`}
                hasPrevious={filterInfo.page !== 1}
                onPrevious={prePage}
                hasNext={filterInfo.page * PER_PAGE < totalCount}
                onNext={nextPage}
              />
            </div>
          </Stack>
        </div>
      </Card>
      <Modal
        open={active}
        onClose={handleChange}
        title="Confirm delete"
        primaryAction={{
          destructive: true,
          content: "Delete",
          onAction: handleDeleteCompany,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleChange,
          },
        ]}
      >
        <Modal.Section>Are you sure you want to delete the selected company?</Modal.Section>
      </Modal>
    </Page>
  );
}
