import {
  deletePlatforms,
  getYoutubeList,
  getYoutubeListOptions,
  postExportPlatforms,
} from "@/api/request";
import {
  filterOptions,
  showOptionLabelsInTextFeild,
  updateTextRegFilter,
} from "@/utils/updateTextRegFilter";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  ChoiceList,
  Filters,
  IndexTable,
  Modal,
  Popover,
  Select,
  Stack,
  Text,
  TextContainer,
  TextField,
  useIndexResourceState,
} from "@shopify/polaris";
import { Columns3Minor, SortMinor } from "@shopify/polaris-icons";
import { useDebounceFn, useMount, useToggle, useUpdateEffect } from "ahooks";
import { DatePicker as AntDatePicker, Pagination, TreeSelect } from "antd";
import { compact, omit, unionBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  exportYTB_atom,
  ytb_current_page,
  ytb_filter_creatime,
  ytb_filter_creatorDesOps,
  ytb_filter_creatorOps,
  ytb_filter_creatorSelect,
  ytb_filter_followerMax,
  ytb_filter_followerMin,
  ytb_filter_locationDesOps,
  ytb_filter_locationOps,
  ytb_filter_locationSelect,
  ytb_filter_platsrDesOps,
  ytb_filter_platsrcOps,
  ytb_filter_platsrcSelect,
  ytb_filter_search,
  ytb_filter_sort,
  ytb_filter_viewMax,
  ytb_filter_viewMin,
  ytb_per_page,
  ytb_search_type,
} from "./atom";
import { YoutubeColumsOptions, Youtube_headings } from "./helper";

import { AntPagination } from "@/components/AntToShopify/AntPagination";
import { AntTreeSelect } from "@/components/AntToShopify/AntTreeSelect";
import { FilterBox } from "@/components/EnhanceShopify/FilterBox";
import { StopPropagation } from "@/components/EnhanceShopify/StopPropagation";
import { useGlobalToast } from "@/customHooks/useGlobalToast";
import { useRefrehTable } from "@/customHooks/useRefrehTable";
import { useTopLoading } from "@/customHooks/useTopLoading";
import { loadableAuthAtom } from "@/global/authAtom";
import { useDispatchResize } from "@/hooks/useDispatchResize";
import { useDynamicHeadings } from "@/hooks/useDynamicHeadings";
import { useRecoilToggle } from "@/hooks/useRecoilToggle";
import { useStoreLocalState } from "@/hooks/useStoreLocalState";
import { ConfirmDeleteModal } from "@/utils/ConfirmDeleteModal";
import { s_to_ms } from "@/utils/accurateTimeTrans";
import { splitChoiceValue } from "@/utils/checkType";
import { datepickToShopifyStyle } from "@/utils/datepickToShopifyStyle";
import { downLoadFile } from "@/utils/downLoadFile";
import { formatNumber } from "@/utils/formatNumber";
import { treeData } from "@/utils/frontEndData/_treeTestData";
import { searchOps } from "@/utils/frontEndData/searchBy";
import { isEmpty } from "@/utils/isEmpty";
import { moment2Date } from "@/utils/moment2Date";
import { wait } from "@/utils/wait";
import { useAtom } from "jotai";
import moment from "moment";
import { useRecoilState } from "recoil";
import { v4 } from "uuid";
import { refrehCollectionPlatformAtom } from "../../Collections/Common/atom/atom";
import { Eidtor } from "../../Influencers/Eidtor/Eidtor";
import { editorActive_atom } from "../../Influencers/Eidtor/atom";
import { Craete_Lead_Atom } from "../../PartnersTabs/atom";
import { AddTag } from "../../Profiles/Common/AddTag";
import { AddToCampaign } from "../../Profiles/Common/AddToCampaign";
import { AddToCollection } from "../../Profiles/Common/AddToCollection";
import { Operation } from "../../Profiles/Common/Operation";
import { RowPlatforms } from "../../Profiles/Common/RowPlatforms";
import { Region } from "../Common/Region";
import { RemovePlatforms } from "../Common/RemovePlatforms";
import { RowNav } from "../Common/RowNav";
import { StrTagPopover } from "../Common/StrTagPopover";
import { matchSearchType } from "../Common/matchSearchType";
import { Plat_PrincipalOps_Atom } from "../atom/commonAtom";

const { RangePicker: AntDateRangePicker } = AntDatePicker;
const { SHOW_PARENT } = TreeSelect;

export const YoutubeTable = ({ CollectionID }) => {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      WRITE: permission.data?.["pmp/resources/discovery/write"],
      READ: permission.data?.["pmp/resources/discovery/read"],
    }),
    [permission]
  );
  const { refreshMark } = useRefrehTable(Craete_Lead_Atom); // 刷新关联的table
  const { refreshMark: refreshFromAddPlatform } = useRefrehTable(refrehCollectionPlatformAtom);
  const { handleGlobalToast } = useGlobalToast();

  // search type
  const [searchType, setSearchType] = useRecoilState(ytb_search_type);
  // sort
  const sortOptions = useMemo(
    () => [
      { label: "Followers (Min-Max)", value: "followers|asc" },
      { label: "Followers (Max-Min)", value: "followers|desc" },
      { label: "Views (Min-Max)", value: "views|asc" },
      { label: "Views (Max-Min)", value: "views|desc" },
      { label: "Creation time (oldest first)", value: "created_at|asc" },
      { label: "Creation time (newest first)", value: "created_at|desc" },
      { label: "Update time (oldest first)", value: "updated_at|asc" },
      { label: "Update time (newest first)", value: "updated_at|desc" },
    ],
    []
  );
  const [sortState, setSortState] = useState("updated_at"); // 排序类型:最新时间
  const [sortOrder, setSortOrder] = useState("desc"); // 排序倒序

  const [sortValue, setsortValue] = useRecoilState(ytb_filter_sort);
  const handleSortChange = useCallback((value) => setsortValue(value), [setsortValue]);

  // const { selected: sortValue, handleSelectChange: handleSortChange } =
  //   useChoiceListLogic("updated_at|desc");
  // 筛选参数
  useEffect(() => {
    const [update, sort] = splitChoiceValue(sortValue);
    setSortState(update);
    setSortOrder(sort);
  }, [sortValue]);

  const [columnsActive, { toggle: toggleColumnsActive }] = useToggle();

  // popover
  const [popoverSortActive, { toggle: togglePopoverSortActive }] = useToggle();
  // indextable 上方的筛选框和搜索框
  const [queryValue, setQueryValue] = useRecoilState(ytb_filter_search);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), [setQueryValue]);

  //#region source
  const [platformSourcesLoading, setPlatformSourcesLoading] = useState(false);
  const [deselectedPlatformSourceOption, setDeselectedPlatformSourceOption] =
    useRecoilState(ytb_filter_platsrDesOps);
  const [platformSourceOptions, setPlatformSourceOptions] = useRecoilState(ytb_filter_platsrcOps);
  const [selectedPlatformSource, setSelectedPlatformSource] =
    useRecoilState(ytb_filter_platsrcSelect);
  const [platformSourceInput, setPlatformSourceInput] = useState("");
  const handlePlatformSourceOnSelect = useCallback(
    (selected) => {
      setSelectedPlatformSource(selected);
    },
    [setSelectedPlatformSource]
  );
  const updatePlatformSourceText = useCallback(
    (value) => {
      setPlatformSourceInput(value);
      if (value === "") {
        setPlatformSourceOptions(deselectedPlatformSourceOption);
        return;
      }
      const resultOptions = updateTextRegFilter(value, deselectedPlatformSourceOption);
      setPlatformSourceOptions(resultOptions);
    },
    [deselectedPlatformSourceOption, setPlatformSourceOptions]
  );
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(
      selectedPlatformSource,
      deselectedPlatformSourceOption
    );
    setPlatformSourceInput(showtext);
  }, [selectedPlatformSource, deselectedPlatformSourceOption]);

  const handlePlatformSourceRemove = useCallback(
    () => setSelectedPlatformSource([]),
    [setSelectedPlatformSource]
  );
  //#endregion

  //#region country_regoin
  const [locationLoading, setLocationLoading] = useState(false);
  const [deselectedProfileLocationOption, setDeselectedProfileLocationOption] =
    useRecoilState(ytb_filter_locationDesOps);
  const [profileLocationOptions, setProfileLocationOptions] =
    useRecoilState(ytb_filter_locationOps);
  const [selectedProfileLocation, setSelectedProfileLocation] =
    useRecoilState(ytb_filter_locationSelect);
  const [profileLocationInput, setProfileLocationInput] = useState("");
  const handleProfileLocationOnSelect = useCallback(
    (selected) => {
      setSelectedProfileLocation(selected);
    },
    [setSelectedProfileLocation]
  );
  const updateProfileLocationText = useCallback(
    (value) => {
      setLocationLoading(true);
      setProfileLocationInput(value);
      if (value === "") {
        setProfileLocationOptions(deselectedProfileLocationOption);
        setLocationLoading(false);
        return;
      }
      const resultOptions = updateTextRegFilter(value, deselectedProfileLocationOption);
      setProfileLocationOptions(resultOptions);
      setLocationLoading(false);
    },
    [deselectedProfileLocationOption, setProfileLocationOptions]
  );
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(
      selectedProfileLocation,
      deselectedProfileLocationOption
    );
    setProfileLocationInput(showtext);
  }, [selectedProfileLocation, deselectedProfileLocationOption]);

  const handleProfileLocationRemove = useCallback(
    () => setSelectedProfileLocation([]),
    [setSelectedProfileLocation]
  );
  //#endregion

  //#region  TotalFollowerCount
  const [minTotalFollowerCount, setMinTotalFollowerCount] = useRecoilState(ytb_filter_followerMin);
  const [maxTotalFollowerCount, setMaxTotalFollowerCount] = useRecoilState(ytb_filter_followerMax);
  const handleMinTotalFollowerCountChange = useCallback(
    (newValue) => {
      setMinTotalFollowerCount(newValue.replace(/-/gi, ""));
    },
    [setMinTotalFollowerCount]
  );
  const handleMaxTotalFollowerCountChange = useCallback(
    (newValue) => {
      setMaxTotalFollowerCount(newValue.replace(/-/gi, ""));
    },
    [setMaxTotalFollowerCount]
  );
  const handleTotalFollowerCountRemove = useCallback(() => {
    setMinTotalFollowerCount("");
    setMaxTotalFollowerCount("");
  }, [setMaxTotalFollowerCount, setMinTotalFollowerCount]);
  //#endregion

  //#region  TotalViewCount
  const [minTotalViewCount, setMinTotalViewCount] = useRecoilState(ytb_filter_viewMin);
  const [maxTotalViewCount, setMaxTotalViewCount] = useRecoilState(ytb_filter_viewMax);
  const handleMinTotalViewCountChange = useCallback(
    (newValue) => {
      setMinTotalViewCount(newValue.replace(/-/gi, ""));
    },
    [setMinTotalViewCount]
  );
  const handleMaxTotalViewCountChange = useCallback(
    (newValue) => {
      setMaxTotalViewCount(newValue.replace(/-/gi, ""));
    },
    [setMaxTotalViewCount]
  );
  const handleTotalViewCountRemove = useCallback(() => {
    setMinTotalViewCount("");
    setMaxTotalViewCount("");
  }, [setMaxTotalViewCount, setMinTotalViewCount]);
  //#endregion

  //#region Tags
  const [selectTreeValue, setSelectTreeValue] = useState([]);

  useEffect(() => {
    console.log("selectTreeValue: ", selectTreeValue);
  }, [selectTreeValue]);

  const onSlelectTreeChange = (newValue) => {
    setSelectTreeValue(newValue);
  };

  const handleTagRemove = useCallback(() => {
    setSelectTreeValue([]);
  }, []);

  const tProps = {
    treeData: treeData,
    value: selectTreeValue,
    onChange: onSlelectTreeChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT, // 若全选只选择上一级
    placeholder: "",
    style: {
      width: "100%",
    },
    dropdownStyle: {
      // 下拉框的样式
    },
    listHeight: 400, // 滚动高度
  };
  //#endregion

  //#region Creator
  const [creatorsOps] = useRecoilState(Plat_PrincipalOps_Atom);
  const [deselectedCreatorOption, setDeselectedCreatorOption] =
    useRecoilState(ytb_filter_creatorDesOps);
  const [creatorOptions, setCreatorOptions] = useRecoilState(ytb_filter_creatorOps);
  useEffect(() => {
    setDeselectedCreatorOption(creatorsOps ?? []);
    setCreatorOptions(creatorsOps ?? []);
  }, [creatorsOps, setCreatorOptions, setDeselectedCreatorOption]);
  const [selectedCreator, setSelectedCreator] = useRecoilState(ytb_filter_creatorSelect);
  const [creatorInput, setCreatorInput] = useState("");
  const handleCreatorOnSelect = useCallback(
    (selected) => {
      setSelectedCreator(selected);
    },
    [setSelectedCreator]
  );
  const updateCreatorText = useCallback(
    (value) => {
      setCreatorInput(value);
      if (value === "") {
        setCreatorOptions(deselectedCreatorOption);
        return;
      }
      const resultOptions = updateTextRegFilter(value, deselectedCreatorOption);
      setCreatorOptions(resultOptions);
    },
    [deselectedCreatorOption, setCreatorOptions]
  );
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(selectedCreator, deselectedCreatorOption);
    setCreatorInput(showtext);
  }, [selectedCreator, deselectedCreatorOption]);

  const handleCreatorRemove = useCallback(() => setSelectedCreator([]), [setSelectedCreator]);
  //#endregion

  //#region Creation time
  const [creationTime, setCreationTime] = useRecoilState(ytb_filter_creatime);
  const onCreationTimeChange = useCallback(
    (dates, dateStrings) => {
      setCreationTime(dateStrings);
    },
    [setCreationTime]
  );

  const handleCreationTimeRemove = useCallback(() => setCreationTime(null), [setCreationTime]);
  //#endregion

  // Clear All filters
  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
    handlePlatformSourceRemove();
    handleProfileLocationRemove();
    handleTotalFollowerCountRemove();
    handleTotalViewCountRemove();
    handleCreatorRemove();
    handleCreationTimeRemove();
    handleTagRemove();
  }, [
    handleQueryValueRemove,
    handlePlatformSourceRemove,
    handleProfileLocationRemove,
    handleTotalFollowerCountRemove,
    handleTotalViewCountRemove,
    handleCreatorRemove,
    handleCreationTimeRemove,
    handleTagRemove,
  ]);

  const filters = [
    // source
    {
      key: "source",
      label: "Source",
      filter: (
        <>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            loading={platformSourcesLoading}
            options={platformSourceOptions}
            selected={selectedPlatformSource}
            textField={
              <Autocomplete.TextField
                autoComplete="off"
                onChange={updatePlatformSourceText}
                // label="Tags"
                value={platformSourceInput}
              />
            }
            onSelect={handlePlatformSourceOnSelect}
            listTitle="Source"
          />
        </>
      ),
      shortcut: true,
    },

    // country_regoin
    {
      key: "country_regoin",
      label: "Country/region",
      filter: (
        <div onClick={(e) => e.stopPropagation()}>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            loading={locationLoading}
            options={profileLocationOptions}
            selected={selectedProfileLocation}
            textField={
              <Autocomplete.TextField
                // placeholder="Country/region"
                autoComplete="off"
                onChange={updateProfileLocationText}
                // label="Tags"
                value={profileLocationInput}
              />
            }
            onSelect={handleProfileLocationOnSelect}
            listTitle="Country/region"
          />
        </div>
      ),
      shortcut: true,
    },
    // TotalFollowerCount
    {
      key: "TotalFollowerCount",
      label: "Followers",
      filter: (
        <>
          <Stack vertical>
            <TextField
              label="TotalFollowerCount"
              labelHidden
              type="number"
              value={minTotalFollowerCount}
              onChange={handleMinTotalFollowerCountChange}
              placeholder="Minimum"
              min={0}
              autoComplete="off"
            />
            <TextField
              label="Maximum"
              type="number"
              labelHidden
              value={maxTotalFollowerCount}
              onChange={handleMaxTotalFollowerCountChange}
              placeholder="Maximum"
              min={0}
              autoComplete="off"
            />
          </Stack>
        </>
      ),
      shortcut: true,
    },
    // TotalViewCount
    {
      key: "TotalViewCount",
      label: "Views",
      filter: (
        <>
          <Stack vertical>
            <TextField
              label="TotalViewCount"
              labelHidden
              type="number"
              value={minTotalViewCount}
              onChange={handleMinTotalViewCountChange}
              placeholder="Minimum"
              min={0}
              autoComplete="off"
            />
            <TextField
              label="Maximum"
              type="number"
              labelHidden
              value={maxTotalViewCount}
              onChange={handleMaxTotalViewCountChange}
              placeholder="Maximum"
              min={0}
              autoComplete="off"
            />
          </Stack>
        </>
      ),
      shortcut: true,
    },
    // // Tags
    {
      key: "Tags",
      label: "Tag",
      filter: (
        <StopPropagation>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <AntTreeSelect>
            <TreeSelect {...tProps} />
          </AntTreeSelect>
        </StopPropagation>
      ),
      shortcut: true,
    },
    {
      key: "Creator",
      label: "Create by",
      filter: (
        <div onClick={(e) => e.stopPropagation()}>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            options={creatorOptions}
            selected={selectedCreator}
            textField={
              <Autocomplete.TextField
                autoComplete="off"
                onChange={updateCreatorText}
                value={creatorInput}
              />
            }
            onSelect={handleCreatorOnSelect}
            listTitle="Create by"
          />
        </div>
      ),
      shortcut: true,
    },
    // Creation time
    {
      key: "Creation_time",
      label: "Creation time",
      filter: (
        <>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <AntDateRangePicker
            style={datepickToShopifyStyle}
            ranges={{
              Today: [moment(), moment()],
              "This Week": [moment().startOf("week"), moment().endOf("week")],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
            }}
            allowClear={false}
            onChange={onCreationTimeChange}
            value={creationTime ? [moment(creationTime[0]), moment(creationTime[1])] : null}
          />
        </>
      ),
    },
  ];

  const appliedFilters = [];
  //#region  appliedFilters
  // source
  if (!isEmpty(selectedPlatformSource)) {
    const key = "source";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedPlatformSource),
      onRemove: handlePlatformSourceRemove,
    });
  }
  // country_regoin
  if (!isEmpty(selectedProfileLocation)) {
    const key = "country_regoin";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedProfileLocation),
      onRemove: handleProfileLocationRemove,
    });
  }
  // TotalFollowerCount
  if (minTotalFollowerCount !== "" || maxTotalFollowerCount !== "") {
    const key = "TotalFollowerCount";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, {
        min: minTotalFollowerCount,
        max: maxTotalFollowerCount,
      }),
      onRemove: handleTotalFollowerCountRemove,
    });
  }
  // TotalViewCount
  if (minTotalViewCount !== "" || maxTotalViewCount !== "") {
    const key = "TotalViewCount";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, {
        min: minTotalViewCount,
        max: maxTotalViewCount,
      }),
      onRemove: handleTotalViewCountRemove,
    });
  }
  // // Tag
  if (!isEmpty(selectTreeValue)) {
    const key = "Tags";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectTreeValue),
      onRemove: handleTagRemove,
    });
  }

  // Creator
  if (!isEmpty(selectedCreator)) {
    const key = "Creator";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedCreator),
      onRemove: handleCreatorRemove,
    });
  }
  // Creation time
  if (!!creationTime && !!compact(creationTime).length) {
    const key = "Creation_time";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, creationTime),
      onRemove: handleCreationTimeRemove,
    });
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case "source": {
        const checkedPlatformSource = filterOptions(value, deselectedPlatformSourceOption);
        return "Source: " + checkedPlatformSource.map((o) => `${o.label}`).join(", ");
      }
      case "country_regoin": {
        const checkedProfileLocation = filterOptions(value, deselectedProfileLocationOption);
        return "Country/region: " + checkedProfileLocation.map((o) => `${o.label}`).join(", ");
      }
      case "TotalFollowerCount":
        return `Followers: ${value?.min || ""} ${value?.max ? ` - ${value?.max}` : ""}`;
      case "TotalViewCount":
        return `Views: ${value?.min || ""} ${value?.max ? ` - ${value?.max}` : ""}`;
      case "Tags":
        return "Tags: " + selectTreeValue.map((s) => s).join(", ");
      case "Creator": {
        const checkedCreator = filterOptions(value, deselectedCreatorOption);
        return "Create by: " + checkedCreator.map((o) => `${o.label}`).join(", ");
      }
      case "Creation_time":
        return `Creation time: ${value[0]} ~ ${value[1]}`;
      default:
        return value;
    }
  }
  //#endregion

  //#region request
  const [total, setTotal] = useState(1);
  const [currentPage, setCurrentPage] = useRecoilState(ytb_current_page);
  const [perPage, setPerPage] = useRecoilState(ytb_per_page);
  // 首次加载请求的传参:
  const initParams = useMemo(() => {
    return {
      search_by: searchType,
      search: queryValue ?? "",
      source: selectedPlatformSource,
      region: selectedProfileLocation,
      followers: [minTotalFollowerCount, maxTotalFollowerCount],
      views: [minTotalViewCount, maxTotalViewCount],
      created_at: creationTime,
      creator_ids: selectedCreator,
      sort_by: sortState,
      sort_type: sortOrder,
      page: currentPage,
      per_page: perPage,
      group_ids: CollectionID ? [CollectionID] : "",
      tags: selectTreeValue,
    };
  }, [
    searchType,
    currentPage,
    perPage,
    queryValue,
    selectedPlatformSource,
    // selectedRelatedPartner,
    selectedProfileLocation,
    minTotalFollowerCount,
    maxTotalFollowerCount,
    minTotalViewCount,
    maxTotalViewCount,
    // selectedProfileKeyword,
    // selectedContentHashtagVideoFeatured,
    creationTime,
    selectedCreator,
    sortOrder,
    sortState,
    CollectionID,
    selectTreeValue,
  ]);

  // 在请求展示所有条目的基础上,筛选当前页条目:
  const { run } = useDebounceFn(
    async (params) => {
      setIndexTableLoading(true);
      const { data } = await getYoutubeList({ ...initParams, ...params });

      setIndexTableLoading(false);
      setYoutubeList(data.list);
      setPerPage(data.meta.pagination.per_page);
      setCurrentPage(data.meta.pagination.current_page);
      setTotal(data.meta.pagination.total);
      window.dispatchEvent(new Event("resize"));
    },
    { wait: 600 }
  );
  const reqCurrentTable = useCallback(run, [initParams, setCurrentPage, run]);

  // tabs/搜搜栏/creator/sort/project 变化
  useEffect(() => {
    reqCurrentTable();
  }, [reqCurrentTable, sortOrder, sortState]);

  useUpdateEffect(() => {
    reqCurrentTable({ page: 1 });
  }, [
    reqCurrentTable,
    queryValue,
    selectedPlatformSource,
    selectedProfileLocation,
    minTotalFollowerCount,
    maxTotalFollowerCount,
    minTotalViewCount,
    maxTotalViewCount,
    creationTime,
    selectedCreator,
    refreshMark,
    refreshFromAddPlatform,
    selectTreeValue,
  ]);
  //#endregion

  //#region Columns
  const [YoutubeHeading, setYoutubeHeading] = useState(Youtube_headings);
  const [columns, setColumns] = useState([
    "Influencer name",
    // "Influencer id",
    "Country/region",
    "Social link",
    "Followers",
    // "Views",
    "Tag",
    // "Source",
    "Operation",
  ]);
  useStoreLocalState("YouTube_columns_8", { state: columns, setState: setColumns });
  useDispatchResize(columns);
  // 动态加载列:
  useDynamicHeadings({
    columns: columns,
    staticHeadings: Youtube_headings,
    setTableHeadings: setYoutubeHeading,
  });
  //#endregion Columns

  //#region IndexTable
  // index table 配置项
  const resourceName = {
    singular: "influencer",
    plural: "influencers",
  };
  const [indexTableLoading, setIndexTableLoading] = useState(false); // indexTable 加载状态显示

  const [youtubeList, setYoutubeList] = useState([]);
  // index Table 选中处理函数
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(youtubeList);

  const [add2CampaignModalActive, { toggle: toggleadd2CampaignModal }] = useToggle();
  // handle Add to campaign
  const handleAddToCampaign = useCallback(() => {
    reqCurrentTable();
  }, [reqCurrentTable]);

  const [partnerIDs, setPartnerIDs] = useState([]);
  const [list, setList] = useState([]); // 每次翻页会重置对应的翻页列表,这里做一个存储, 与selectedResources及时对应

  useEffect(() => {
    setList((list) => {
      return unionBy([...list, ...youtubeList], "id");
    });
  }, [youtubeList]);

  useEffect(() => {
    const partners = list.filter(({ id }) => selectedResources.includes(id));
    const IDs = partners.map(({ related_partner }) => related_partner.id);
    setPartnerIDs(IDs);
  }, [selectedResources, list]);

  // // Related with partner
  // const [relatedPartnerModalActive, { toggle: toggleRelatedPartnerModal }] = useToggle();
  const [addTagModalActive, { toggle: toggleAddTagModal }] = useToggle();

  // Add to collection
  const [add2CollectionModalActive, { toggle: toggleadd2CollectionModal }] = useToggle();
  // Remove platform with collection
  const [removePlatformActive, { toggle: toggleRemovePlatform }] = useToggle();

  // 确认删除: 弹出modal
  const [deleteComfirmActive, { toggle: toggleDeleteComfirmActive }] = useToggle();
  // 确认删除按钮:确认删除处理函数:
  const handleConfirmDelete = async () => {
    try {
      await deletePlatforms(selectedResources);
      await wait(1000);
      toggleDeleteComfirmActive();
      setIndexTableLoading(true);
      handleSelectionChange("all", false); // 清除indextable选中状态
      await reqCurrentTable();
      setIndexTableLoading(false);
      handleGlobalToast({ msg: "Delete successfully!" });
    } catch (error) {
      handleGlobalToast({ msg: "Delete unsuccessfully!", isErr: true });

      setIndexTableLoading(false);
    }
  };

  const promotedBulkActions = useMemo(
    () =>
      CollectionID
        ? [
            {
              content: "Add tag",
              onAction: toggleAddTagModal,
            },
            {
              content: "Remove platform",
              onAction: toggleRemovePlatform,
            },
            {
              content: "Add to collection",
              onAction: toggleadd2CollectionModal,
            },
            {
              content: "Add to campaign",
              onAction: toggleadd2CampaignModal,
            },
            {
              content: "Delete influencer",
              onAction: toggleDeleteComfirmActive,
            },
          ]
        : [
            {
              content: "Add tag",
              onAction: toggleAddTagModal,
            },
            {
              content: "Add to collection",
              onAction: toggleadd2CollectionModal,
            },
            {
              content: "Add to campaign",
              onAction: toggleadd2CampaignModal,
            },
            {
              content: "Delete influencer",
              onAction: toggleDeleteComfirmActive,
            },
          ],
    [
      // toggleRelatedPartnerModal,
      toggleadd2CampaignModal,
      toggleadd2CollectionModal,
      toggleRemovePlatform,
      toggleDeleteComfirmActive,
      CollectionID,
      toggleAddTagModal,
    ]
  );

  const rowMarkup = youtubeList.map(
    (
      {
        id,
        related_partner, // { id } || null
        platform_data,
        source,
        creator,
        updater,
        created_at,
        updated_at,
      },
      index
    ) => {
      // 字段展示
      const props = [
        // "Influencer name",
        <RowNav
          key={v4()}
          title={platform_data?.username}
          avatar={platform_data?.profile_picture_url}
          partnerID={related_partner?.id}
          platformID={id}
        />,
        // "Influencer id",
        platform_data?.user_id,

        // "Country/region",
        <Region
          key={v4()}
          name={platform_data?.region?.name}
          image={platform_data?.region?.image}
        />,
        // "Social link",
        <RowPlatforms
          platforms={[
            {
              avatar: platform_data.profile_picture_url,
              followers: platform_data.followers,
              id: platform_data.id,
              likes: platform_data.likes,
              link: platform_data.profile_url,
              posts: platform_data.posts,
              subscribers: platform_data.followers,
              type: { title: platform_data.type },
              user_id: platform_data.user_id,
              username: platform_data.username,
              views: platform_data.views,
            },
          ]}
          key={v4()}
        />,

        // "Followers",
        // transformNumber(platform_data?.followers),
        formatNumber("unit", platform_data?.followers),

        // "Views",
        // transformNumber(platform_data?.views),
        formatNumber("unit", platform_data?.views),

        // "Tag",
        <StrTagPopover key={v4()} tags={platform_data?.tag} />,

        // "Source",
        source.title,

        // "Create by",
        creator?.name,
        // "Creation time",
        moment2Date(s_to_ms(created_at)),
        // "Update by",
        updater?.name,
        // "Update time",
        moment2Date(s_to_ms(updated_at)),
        // "Operation",
        <StopPropagation key={v4()}>
          <Operation partnerID={related_partner.id} platformID={id} />
        </StopPropagation>,
      ];
      let SN = []; // 总的序列对应关系
      YoutubeColumsOptions.forEach(({ value }, index) => {
        SN.push({ column: value, prop: props[index] });
      });
      let showCell = SN.filter(({ column }) => columns.includes(column));

      return (
        <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
          {showCell.map(({ prop }) => (
            <IndexTable.Cell key={v4()}>{prop}</IndexTable.Cell>
          ))}
        </IndexTable.Row>
      );
    }
  );
  //#endregion

  //#region  ---------------------- ↓ export ↓ ----------------------
  const { TopLoadingJSX, hiddenTopLoading, showTopLoading } = useTopLoading();
  const [isExport] = useRecoilState(exportYTB_atom);
  const [exportModalActive, { toggle: toggleExportModal, setLeft: closeExportModal }] = useToggle();

  const handleExportYoutube = useCallback(async () => {
    try {
      showTopLoading();
      const filterObj = {
        platform_type: "YouTube",
        ...omit(initParams, ["page", "per_page", "group_ids"]),
      };
      const { data } = await postExportPlatforms(filterObj);
      closeExportModal();
      downLoadFile(data.file_link, "PartnerList", false);
    } catch (error) {
      //
    } finally {
      hiddenTopLoading();
    }
  }, [initParams, hiddenTopLoading, showTopLoading, closeExportModal]);

  const checkExport = useCallback(() => {
    if (total > 10000) {
      toggleExportModal(); // 多余一千要提示
    } else {
      handleExportYoutube();
    }
  }, [toggleExportModal, total, handleExportYoutube]);

  useUpdateEffect(() => {
    checkExport();
  }, [isExport]);

  const ConfirmExportModal = (
    <Modal title="Confirm export" open={exportModalActive} onClose={toggleExportModal}>
      <Modal.Section>
        <p>
          Export up to 10000 items of data at a time, if more than 10000 items, the first 1000 items
          will be exported in order of the current list.
        </p>
      </Modal.Section>

      <Modal.Section>
        <Stack distribution="trailing">
          <ButtonGroup>
            <Button onClick={toggleExportModal}>Cancel</Button>
            <Button destructive onClick={handleExportYoutube}>
              Export
            </Button>
          </ButtonGroup>
        </Stack>
      </Modal.Section>
    </Modal>
  );

  //#endregion -------------------- ↑ export ↑ ----------------------

  useMount(async () => {
    try {
      setPlatformSourcesLoading(true);
      setLocationLoading(true);

      const { data } = await getYoutubeListOptions();
      const { sources, region } = data;
      const platformSources = sources.map(({ id, title }) => ({
        label: title,
        value: String(id),
      }));

      const locations = region.map((region) => ({
        label: region,
        value: region,
      }));
      setDeselectedPlatformSourceOption(platformSources);
      setPlatformSourceOptions(platformSources);

      setDeselectedProfileLocationOption(locations);
      setProfileLocationOptions(locations);

      setPlatformSourcesLoading(false);
      setLocationLoading(false);
    } catch (error) {
      setPlatformSourcesLoading(false);
      setLocationLoading(false);
    }
  });

  const { active: EditorActive } = useRecoilToggle(editorActive_atom);
  return (
    <>
      <Eidtor title="Edit influencer basic profile" visible={EditorActive} />

      {TopLoadingJSX}
      {ConfirmExportModal}

      <AddTag
        active={addTagModalActive}
        handleChange={toggleAddTagModal}
        handleSave={reqCurrentTable}
        partnerIDs={partnerIDs}
      />
      <AddToCollection
        active={add2CollectionModalActive}
        handleChange={toggleadd2CollectionModal}
        handleSave={reqCurrentTable}
        partnerIDs={partnerIDs}
      />
      <AddToCampaign
        active={add2CampaignModalActive}
        handleChange={toggleadd2CampaignModal}
        handleSave={handleAddToCampaign}
        partnerIDs={partnerIDs}
      />
      <RemovePlatforms
        active={removePlatformActive}
        toggle={toggleRemovePlatform}
        CollectionID={CollectionID} // 分组ID
        selectPlatforms={selectedResources} // 选中的平台
        reqCurrentTable={reqCurrentTable} // 刷新当前table
        handleSelectionChange={handleSelectionChange} // 删除后要清空选中状态
      />
      <ConfirmDeleteModal
        title="Confirm delete"
        deleteComfirmActive={deleteComfirmActive}
        toggleDeleteComfirmActive={toggleDeleteComfirmActive}
        content="Are you sure you want to delete the selected influencer?"
        buttonText="Delete"
        handleConfirmDelete={handleConfirmDelete}
      />
      <FilterBox>
        <Select
          options={searchOps}
          value={searchType}
          onChange={(value) => {
            setSearchType(value);
            setQueryValue("");
          }}
        />
        <Filters
          queryValue={queryValue}
          queryPlaceholder={`Search ${matchSearchType(searchOps, searchType)}`}
          filters={filters}
          appliedFilters={appliedFilters}
          onQueryChange={setQueryValue}
          onQueryClear={handleQueryValueRemove}
          onClearAll={handleClearAll}
        />
        <div style={{ paddingLeft: "0.4rem" }}>
          <Popover
            active={popoverSortActive}
            activator={
              <Button onClick={togglePopoverSortActive} icon={SortMinor}>
                Sort
              </Button>
            }
            onClose={togglePopoverSortActive}
            preferredAlignment="right"
          >
            <Popover.Pane>
              <Popover.Section>
                <TextContainer>
                  <Text variant="bodyMd" as="span" color="subdued">
                    Sort by
                  </Text>
                  <ChoiceList
                    choices={sortOptions}
                    selected={sortValue}
                    onChange={handleSortChange}
                  />
                </TextContainer>
              </Popover.Section>
            </Popover.Pane>
          </Popover>
        </div>
        <div style={{ paddingLeft: "0.4rem" }}>
          <Popover
            active={columnsActive}
            activator={
              <Button icon={Columns3Minor} onClick={toggleColumnsActive}>
                Columns
              </Button>
            }
            onClose={toggleColumnsActive}
          >
            <Popover.Section>
              <TextContainer>
                <Text variant="bodyMd" as="span" color="subdued">
                  Columns
                </Text>
              </TextContainer>
              <ChoiceList
                allowMultiple
                choices={YoutubeColumsOptions}
                selected={columns}
                onChange={setColumns}
              />
            </Popover.Section>
          </Popover>
        </div>
      </FilterBox>
      <IndexTable
        loading={indexTableLoading}
        resourceName={resourceName}
        itemCount={youtubeList.length}
        selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
        onSelectionChange={handleSelectionChange}
        headings={YoutubeHeading}
        promotedBulkActions={!access.WRITE ? undefined : promotedBulkActions}
        // emptyState={emptyStateMarkup}
        lastColumnSticky
      >
        {rowMarkup}
      </IndexTable>
      {youtubeList.length ? (
        <Stack distribution="trailing">
          <AntPagination>
            <Pagination
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              current={currentPage}
              pageSize={perPage}
              pageSizeOptions={[10, 20, 50, 100]}
              showSizeChanger
              total={total}
              onChange={(page, pageSize) => {
                reqCurrentTable({ ...initParams, page: page, per_page: pageSize });
              }}
            />
          </AntPagination>
        </Stack>
      ) : null}
    </>
  );
};
