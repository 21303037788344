import { Toast } from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import Spinner from "../UI/Spinner/Spinner";

export const LoadingContext = React.createContext({
  isLoading: false,
  loading: () => {},
  message: "",
});

const LoadingContextProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(false);
  const [isError, setIsError] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const loadingHandler = useCallback((test, message = "", error = false) => {
    setIsLoading(test);
    if (message) {
      setActive(!!message);
      setMessage(message);
      setIsError(error);
    }
  }, []);

  const toastMarkup = active ? 
    <Toast content={message} error={isError} onDismiss={toggleActive} />
   : null;

  return (
    <LoadingContext.Provider value={{ loading: loadingHandler, isLoading: isLoading }}>
      {props.children}
      <div className="content-spinner" style={{ display: isLoading ? "block" : "none" }}>
        <Spinner loadingStatus={!isLoading} />
      </div>
      <div style={{ height: "0px" }}>{toastMarkup}</div>
    </LoadingContext.Provider>
  );
};

export default LoadingContextProvider;
