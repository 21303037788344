import { EmptySearchResult } from "@shopify/polaris";

const teamsSortOptions = [
  { label: "Updated (oldest first)", value: "updated_at|asc" },
  { label: "Updated (newest first)", value: "updated_at|desc" },
];
const teamsEmptyStateMarkup = 
  <EmptySearchResult
    title={"No teams found"}
    description={"Try changing the filters or search term"}
    withIllustration
  />
;

const teamsResourceName = {
  singular: "team",
  plural: "teams",
};

const teamColumns = ["Team code", "Note", "Create by", "Creation time", "Update by", "Update time"];
const teamsColumnsOptions = teamColumns.map((str) => ({
  label: str,
  value: str,
}));
// 第一个和第二个不能删除
teamsColumnsOptions.unshift({
  label: "Team title",
  value: "Team title",
  disabled: true,
});

const teams_headings = teamsColumnsOptions.map(({ value }) => ({ title: value }));

export {
  teamsSortOptions,
  teamsEmptyStateMarkup,
  teamsResourceName,
  teamsColumnsOptions,
  teams_headings,
};
