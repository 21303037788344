import styled from "styled-components";

export const TimelineBox = styled.div`
  display: flex;
  width: 330px;
  > .ant-timeline {
    width: 100%;
    /* li { */
     .ant-timeline-item .ant-timeline-item-content .Polaris-Caption{
        width: 300px;
      /* } */
    }
    li:last-of-type {
      padding: 0px;
    }
  }
`;
