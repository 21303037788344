import { atom } from "recoil";
import { v4 } from "uuid";

// 快捷添加侧边栏
export const toggleContactsDrawer_atom = atom({
  key: v4(),
  default: false,
});
// 导入
export const toggleImportContacts_atom = atom({
  key: v4(),
  default: false,
});

// 传递模版url
export const importContactsUrl_atom = atom({
  key: v4(),
  default: "",
});
