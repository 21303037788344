import "./YouTuBe.scss";

import { DollarTwoTone, SafetyCertificateTwoTone } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Layout,
  Link,
  Stack,
  Text,
  TextContainer,
  Tooltip
} from "@shopify/polaris";
import { useEffect, useMemo, useState } from "react";

import { AnchorMark } from "@/components/EnhanceShopify/AnchorMark";
import { InfoRow } from "@/components/EnhanceShopify/InfoRow";
import { LayoutStick } from "@/components/EnhanceShopify/LayoutStick";
import { RowStack } from "@/components/EnhanceShopify/RowStack";
import { TextClamp } from "@/components/EnhanceShopify/TextClamp";
import { IconFont } from "@/components/Icon/PMPicon";
import { useCopy } from "@/hooks/useCopy";
import { formatNumber } from "@/utils/formatNumber";
import { DuplicateMinor } from "@shopify/polaris-icons";
import { Anchor, Divider } from "antd";
import moment from "moment";
import { v4 } from "uuid";
import { Region } from "../../Platforms/Common/Region";
import { CustomerAvatar } from "../Common/CustomerAvatar";
import { EmailClamp } from "../Common/EmailClamp";
import { LongTextCollect } from "../Common/LongTextCollect";
import { NoReportUnderLink } from "../Common/NoReport";
import { PlatagList } from "../Common/Platags/PlatagList";
import { ShowTags } from "../Common/ShowTags";
import { SimilarChannels } from "./SimilarChannels";
import { VideoGallery } from "./VideoGallery";

export const YouTuBe = ({ selectPlatform: YTB, setUpdateTime, setUpdateBy }) => {
  const { copiedMark, handleCopy } = useCopy();

  const [region, setRegion] = useState({});
  const [ID, setID] = useState(null);
  const [platformLink, setPlatformLink] = useState("");
  const [userName, setuserName] = useState("");
  const [userAvatar, setUserAvatar] = useState("");
  const [userID, setUserID] = useState("");
  const [authIcon, setAuthIcon] = useState(0); // 是否认证,认证后颜色为:color="primary"
  const [isMoneied, setIsMoneied] = useState(false);
  const [profile_url, setProfile_url] = useState("");

  const [joinDate, setJoinDate] = useState();
  const [subscribers, setSubscribers] = useState(0);
  const [views, setViews] = useState(0);

  const [recentContents, setRecentContents] = useState([]);

  const [emails, setEmails] = useState([]);
  const [description, setDescription] = useState("");
  const [channelTags, setChannelTags] = useState([]);
  const [hashTags, setHashTags] = useState([]);
  const tags = useMemo(() => [...channelTags, ...hashTags], [channelTags, hashTags]);
  const [websiteUrls, setWebsiteUrls] = useState([]);
  const [similarChannels, setSimilarChannels] = useState([]);

  useEffect(() => {
    setID(YTB.id);
    setPlatformLink(YTB.platform_url);
    setRegion(YTB.region);
    setUserAvatar(YTB?.options?.profilePictureUrl);
    setuserName(YTB?.options?.username);
    setUserID(YTB?.options?.userId);
    setProfile_url(YTB?.options?.profileUrl);
    setAuthIcon(YTB?.options?.isProfileVerified);
    setIsMoneied(YTB?.options?.isMonetizationEnabled);
    setJoinDate(YTB?.options?.joinDate);
    setSubscribers(YTB?.options?.totalFollowerCount);
    setViews(YTB?.options?.totalViewCount);
    // recent contents
    setRecentContents(YTB?.options?.latestPost ? YTB?.options?.latestPost : []);
    // 侧边栏
    setEmails(YTB?.options?.email ?? []);
    setDescription(YTB?.options?.profileBioRaw);
    setChannelTags(YTB?.options?.profileKeyword ?? []);
    setHashTags(YTB?.options?.contentHashtagVideoFeatured ?? []);
    setWebsiteUrls(YTB?.options?.websiteUrl ?? []);
    setSimilarChannels(YTB?.options?.suggestedProfile ?? []);
    setUpdateTime(YTB?.updated_at);
    setUpdateBy(YTB?.updater?.name);
  }, [YTB, setUpdateTime, setUpdateBy]);

  return ID ? (
    <>
      {copiedMark}
      <Layout>
        <LayoutStick width={300}>
          <Card sectioned>
            <RowStack>
              <Region name={region?.name} image={region?.image} code={region?.code} />
            </RowStack>
            <br />
            <CustomerAvatar
              avatar={<Avatar size="large" source={userAvatar} />}
              name={userName}
              subName={`@${window.decodeURIComponent(userID)}`}
              exta={
                <>
                  {authIcon && (
                    <Tooltip content="Is profile verified" preferredPosition="above">
                      <SafetyCertificateTwoTone twoToneColor="#2E72D2" />
                    </Tooltip>
                  )}
                  {isMoneied && (
                    <Tooltip content="Is monetization enabled" preferredPosition="above">
                      &nbsp;
                      <DollarTwoTone twoToneColor="#FAAD14" />
                    </Tooltip>
                  )}
                </>
              }
            />
            <br />
            <Stack spacing="tight">
              {PlatagList([
                // 为了统一成general组件中的平台列表tag为以后扩展准备
                {
                  subscribers: subscribers,
                  link: profile_url,
                  type: {
                    title: YTB?.type,
                  },
                },
              ])}
            </Stack>
            <br />
            <TextContainer>
              <Text variant="bodyMd" as="span" color="subdued">
                <LongTextCollect longText={description} />
              </Text>
            </TextContainer>
            <br />
            <Stack wrap={false}>
              <div style={{ width: 50, marginTop: -4 }}>
                <IconFont code="&#xe75a;" title="Email" color="#008685" />
              </div>
              <Stack.Item fill>
                <EmailClamp>{emails?.join("\n")}</EmailClamp>
              </Stack.Item>
              {!!emails?.join("\n") && (
                <Button
                  icon={DuplicateMinor}
                  plain
                  onClick={() => handleCopy({ copyText: emails?.join("\n") })}
                />
              )}
            </Stack>
            <Divider />
            <Anchor offsetTop={60}>
              <Anchor.Link href="#Overview" title="Overview" />
              <Anchor.Link href="#Post_contents" title="Post contents" />
              <Anchor.Link href="#Other_websites" title="Other websites" />
              <Anchor.Link href="#Suggested_accounts" title="Suggested accounts" />
            </Anchor>
          </Card>
        </LayoutStick>
        <Layout.Section>
          <Card sectioned title="Overview">
            <AnchorMark id="Overview" />
            <InfoRow label="Followers">{formatNumber("unit", subscribers)}</InfoRow>
            <InfoRow label="Views">{formatNumber("unit", views)}</InfoRow>
            <InfoRow label="Join date">{moment(joinDate).format("yyyy-MM-DD")}</InfoRow>
            <InfoRow label="Tag" end>
              <ShowTags tags={tags} />
            </InfoRow>
          </Card>
          <Card title="Post contents">
            <AnchorMark id="Post_contents" />
            <VideoGallery title="Post contents" contents={recentContents} />
          </Card>
          <Card sectioned title="Other websites">
            <AnchorMark id="Other_websites" />
            {Array.isArray(websiteUrls) ? (
              websiteUrls?.map(({ anchorText, websiteUrl }, i) => (
                <InfoRow key={v4()} label={<TextClamp>{anchorText}</TextClamp>}>
                  <Link url={websiteUrl} removeUnderline>
                    <TextClamp clamp={1}>{websiteUrl}</TextClamp>
                  </Link>
                </InfoRow>
              ))
            ) : (
              <Link url={websiteUrls} removeUnderline>
                {websiteUrls}
              </Link>
            )}
          </Card>

          <SimilarChannels list={similarChannels} mark={<AnchorMark id="Suggested_accounts" />} />
        </Layout.Section>
      </Layout>
    </>
  ) : (
    <NoReportUnderLink link={platformLink} />
  );
};
