import { Icon, Stack, Text } from "@shopify/polaris";
import styled from "styled-components";

export const IconText = ({ icon, text }) => {
  return (
    <IconSchma>
      <Stack alignment="center" spacing="extraTight">
        <Text>
          <Icon source={icon} color="subdued" />
        </Text>
        <Text variant="bodySm" as="span" color="subdued">
          {text}
        </Text>
      </Stack>
    </IconSchma>
  );
};

const IconSchma = styled.div`
  .Polaris-Icon {
    transform: scale(0.8);
  }
`;
