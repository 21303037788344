import { useLocalStorageState, useMount } from "ahooks";
import axios from "axios";

import { atom, useRecoilState } from "recoil";

const { REACT_APP_WORLDMAP_URL } = process.env;

export const CountryRegionListAtom = atom({
  key: "CountryRegionListAtom",
  default: [],
});

//TODO - 公用国家选择器接口
const getCountryRegionList = () =>
  axios.get(`${REACT_APP_WORLDMAP_URL}/general/worldmap/countries`);

export const InitCountryRegions = () => {
  const [localCRlist, setLocalCRList] = useLocalStorageState("CRList", {
    defaultValue: [],
  });
  const [, setCRList] = useRecoilState(CountryRegionListAtom);

  useMount(async () => {
    if (!localCRlist?.length) {
      const { data } = await getCountryRegionList();
      setCRList(data.countries);
      setLocalCRList(data.countries);
    } else {
      setCRList(localCRlist);
    }
  });
  return <></>;
};
