const profilesCheckBox = [
  "Country/region",
  "Language",
  "Gender",
  "Social link",
  "Total followers",
  "Tier",
  "Owner",
  "Price",
  "Collection",
  "Tag",
  "Contact status",
  "Blacklist",
  "Email",
  "Other contact",
  "Company name",
  "Create by",
  "Creation time",
  "Update by",
  "Update time",
];

const profilesColumsOptions = profilesCheckBox.map((str) => ({
  label: str,
  value: str,
}));

profilesColumsOptions.unshift({
  label: "Influencer name",
  value: "Influencer name",
  disabled: true,
});
profilesColumsOptions.push({
  label: "Operation",
  value: "Operation",
  disabled: true,
});

const profiles_headings = profilesColumsOptions.map(({ value }) => ({ title: value }));

export { profilesColumsOptions, profiles_headings };
