
import { Spinner } from "@shopify/polaris";

const spinner = (props) => 
  <div
    className={props.loadingClass || "loading"}
    style={{ display: props.loadingStatus ? "none" : "block" }}
  >
    <Spinner accessibilityLabel="Spinner" size={props.size || "large"} />
  </div>
;

export default spinner;
