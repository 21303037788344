import { Page } from "@shopify/polaris";
import { useHistory, useParams } from "react-router";

export default function PageAuxiliaryItemDetail({ children }) {
  const history = useHistory();
  const { ID } = useParams();
  console.log(history);
  let info = "",
    pathName = history.location.pathname;
  if (pathName.includes("company")) info = "company";
  else if (pathName.includes("contact")) info = "contact type";
  else info = "platform type";
  if (ID !== "add") {
    info = info.slice(0, 1).toUpperCase() + info.slice(1);
  }
  return (
    <Page
      title={ID === "add" ? `Add ${info}` : `${info} details`}
      breadcrumbs={[
        {
          content: "Item details",
          onAction: () => {
            history.goBack();
          },
        },
      ]}
    >
      {children}
    </Page>
  );
}
