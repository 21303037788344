import { getPartnerUserInfo, postAddNewPartner, putPartnerUserInfo } from "@/api/request";
import { Select, Stack, TextField } from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  blacklistOptions,
  ContactStatusOptions,
  genderOptions,
  TierOptions
} from "../Common/Options";
import { editorActive_atom, languageOps_atom, partnerID_atom, regionOps_atom } from "./atom";

import { AntDrawer } from "@/components/EnhanceShopify/AntDrawer";
import { DrawerSection } from "@/components/EnhanceShopify/DrawerSection";
import { DrawerSkeleton } from "@/components/EnhanceShopify/DrawerSkeleton";
import { SelectPlus } from "@/components/EnhanceShopify/SelectPlus";
import { useRecoilToggle } from "@/hooks/useRecoilToggle";
import { selectTagState } from "@/pages/Media/Common/InputTags/atom";
import { InputTags } from "@/pages/Media/Common/InputTags/InputTags";
import { link_atom } from "@/pages/Media/MediaContacts/MediaContactEditor/SocialLink/atom";
import { SocialLink } from "@/pages/Media/MediaContacts/MediaContactEditor/SocialLink/SocialLink";
import { transformNumber } from "@/utils/tools";
import { wait } from "@/utils/wait";
import { useUnmount } from "ahooks";
import { useHistory } from "react-router-dom";
import { influencer_refresh } from "../atom";
import { contacts_atom } from "../Common/OtherContact/atom";
import { OtherContact } from "../Common/OtherContact/OtherContact";
import { prices_atom } from "../Common/PriceInfo/atom";
import { PriceInfo } from "../Common/PriceInfo/PriceInfo";

export const Eidtor = ({ title, visible }) => {
  const { toggle: refreshInfluencers } = useRecoilToggle(influencer_refresh);

  const history = useHistory();

  const [partnerID] = useRecoilState(partnerID_atom);
  const resetPartnerID = useResetRecoilState(partnerID_atom);

  const [loading, setLoading] = useState(true); // 数据加载时

  const [saveLoading, setSaveLoading] = useState(false); // 保存请求时

  const { toggle } = useRecoilToggle(editorActive_atom);
  const resetEditorActive = useResetRecoilState(editorActive_atom);

  const [influencerNameInput, setInfluencerNameInput] = useState("");
  const [selectGender, setSelectGender] = useState(["0"]);
  const [selectRegion, setSelectRegion] = useState([]);
  const [selectLang, setSelectLang] = useState([]);
  const [selectContactStatus, setSelectContactStatus] = useState("0");
  const [emailInput, setEmailInput] = useState("");
  const [selectTier, setSelectTier] = useState("");
  const [selectBlackList, setSelectBlackList] = useState("0");
  const [noteInput, setNoteInput] = useState("");
  // tags : 交互数组
  const [seelctedTags, setSelectedTags] = useRecoilState(selectTagState);
  const resetSelectTags = useResetRecoilState(selectTagState);

  // 用的 MediaContactEditor 里面的 social links , 复用组件
  const [socialLinks, setSocialLinks] = useRecoilState(link_atom);
  const resetSocialLinks = useResetRecoilState(link_atom);

  const [contacts, setCcontacts] = useRecoilState(contacts_atom);
  const resetContacts = useResetRecoilState(contacts_atom);

  const [selectPrices, setSelectPrices] = useRecoilState(prices_atom);
  const resetPrices = useResetRecoilState(prices_atom);

  // SociallinkTypes_atom 对应的 选项列表请求数据 统一放在 公共父组件 Influencers 中, Price type , Currency, tags 同理
  const [RegionOps] = useRecoilState(regionOps_atom);
  const [LanguageOps] = useRecoilState(languageOps_atom);
  // const [ContactTypeOps] = useRecoilState(contactTypeOps_atom);
  // const [PriceTypeOps] = useRecoilState(priceTypeOps_atom);
  // const [CurrecyOps] = useRecoilState(currecyOps_atom);

  // 保存成功 / 关闭 Drawer 时候, 重置数据栏
  const handleClearAll = useCallback(() => {
    resetPartnerID();
    setInfluencerNameInput("");
    setSelectGender("0");
    setSelectRegion([]);
    setSelectLang([]);
    setSelectContactStatus("0");
    setEmailInput("");
    setSelectTier("");
    setSelectBlackList("0");
    setNoteInput("");
    resetSelectTags();
    resetSocialLinks();
    resetContacts();
    resetPrices();
  }, [resetPartnerID, resetSelectTags, resetSocialLinks, resetContacts, resetPrices]);

  // Cancel 按钮
  const editorCancel = () => {
    toggle();
    handleClearAll();
  };
  // Advaced Settings
  const editorAdvaceSetting = () => {
    if (!partnerID) {
      history.push(`/home/fresh/add`);
    } else {
      history.push(`/home/${partnerID}/edit`);
    }
  };

  //#region  ---------------------- ↓ 请求函数 ↓ ----------------------
  const [restUpdateInfo, setRestUpdateInfo] = useState({
    last_name: "",
    birthday: "",
    type: "0",
    role: "",
    mobile_phone: "",
    telephone: "",
    fax: "",
    company_id: "",
    groups_ids: [],
    principal: "",
  });
  const [restAddress, setRestAddress] = useState({
    state: "",
    city: "",
    postal_code: "",
    street: "",
  });
  const updateData = useMemo(() => {
    // first_name
    // gender
    // address[country_id]
    // language_ids: ['1']
    // contact_status: '3'
    // email: ''
    // account_level_id: '2'
    // is_blacklisted: '1'
    // tags: [{ title:"", type: 1 }]
    // brief_description: ''
    // platforms: [{ type_id, link, subscribers }]
    // contacts: [{ type_id, account }]
    // prices: [{ type_id, value, currency_id }]
    // //  --- 需要带上已有的信息, 不然会覆盖导致已有信息失效, 包含分组 ---
    // last_name
    // birthday
    // type
    // role
    // mobile_phone
    // telephone
    // fax
    // address 的其他字段
    // {
    //   state: "",
    //   city: "",
    //   postal_code: "",
    //   street: "",
    // }
    // company_id
    // groups_ids
    const tagsArr = seelctedTags.map((str) => ({ type: 1, title: str }));

    const platformsArr = socialLinks
      .filter((o) => !o.isNew)
      .map(({ platform, link, fans }) => ({
        type_id: platform,
        link,
        subscribers: fans,
      }));

    const contactArr = contacts.map(({ type, contact }) => ({
      type_id: type,
      account: contact,
    }));
    const priceArr = selectPrices.map(({ priceType, price, currency }) => ({
      type_id: priceType,
      value: price,
      currency_id: currency,
    }));

    return {
      first_name: influencerNameInput,
      gender: Number(selectGender),
      address: {
        country_id: selectRegion.toString(),
        ...restAddress,
      },
      language_ids: selectLang,
      contact_status: selectContactStatus,
      email: emailInput,
      account_level_id: selectTier.toString(),
      is_blacklisted: selectBlackList,
      tags: tagsArr,
      brief_description: noteInput,
      platforms: platformsArr,
      contacts: contactArr,
      prices: priceArr,
      ...restUpdateInfo,
    };
  }, [
    influencerNameInput,
    selectGender,
    selectRegion,
    selectLang,
    selectContactStatus,
    emailInput,
    selectTier,
    selectBlackList,
    seelctedTags,
    noteInput,
    socialLinks,
    contacts,
    selectPrices,
    restUpdateInfo,
    restAddress,
  ]);
  // 根据 id 请求快捷编辑的详情
  const requestDetail = useCallback(async () => {
    try {
      if (partnerID) {
        setLoading(true);
        const { data } = await getPartnerUserInfo(partnerID);
        // 侧边栏 数据格式如下:
        // first_name: ''
        // gender: 0  必须是数字
        // address: {country: null ? {id, name, image} }
        // languages: [{id,name}]
        // contact_status: 1
        // email: null
        // account_level: null?{id,title}
        // is_blacklisted: 0
        // tags:[]
        // brief_description: null
        // platforms:[ { type:{id,title}, link, subscribers:null } ]
        // contacts: [ { type:{id,title}, account ]
        // prices: [{ type:{id,title}, value:'123.00', currency:{id,code,symbol} }]
        setInfluencerNameInput(data.first_name);
        setSelectGender(String(data.gender || ""));
        setSelectRegion([String(data.address?.country?.id || "")]);

        const langIDs = data.languages.map(({ id }) => String(id));
        setSelectLang(langIDs);

        setSelectContactStatus(String(data.contact_status));
        setEmailInput(data.email ?? "");
        setSelectTier([String(data.account_level?.id || "")]); // 多选要传数组
        setSelectBlackList(String(data.is_blacklisted));

        const tags = data.tags.map(({ title }) => title);
        setSelectedTags(tags);

        setNoteInput(data.brief_description ?? "");

        const socialLinks = data.platforms.map(({ type, link, subscribers }) => ({
          platform: String(type?.id || ""),
          link,
          fans: String(!isNaN(subscribers) ? transformNumber(subscribers) : ""),
          isEdit: false,
          isNew: false,
        }));
        setSocialLinks(socialLinks);

        const contacts = data.contacts.map(({ type, account }) => ({
          type: String(type.id),
          contact: String(account),
          isEdit: false,
          isNew: false,
        }));
        setCcontacts(contacts);

        const prices = data.prices.map(({ type, value, currency }) => ({
          priceType: String(type.id || ""),
          price: isNaN(Number(value)) ? "" : String(Number(value)),
          currency: String(currency.id || ""),
          isEdit: false,
          isNew: false,
        }));
        setSelectPrices(prices);
        // 携带的已有其他信息
        setRestUpdateInfo({
          last_name: data.last_name,
          birthday: data.birthday,
          type: data.type,
          role: data.role,
          mobile_phone: data.mobile_phone,
          telephone: data.telephone,
          fax: data.fax,
          company_id: data.company?.id ?? "",
          groups_ids: data.groups?.map(({ id }) => id) ?? [],
          principal: data.principal,
        });
        setRestAddress({
          state: data.address?.state,
          city: data.address?.city,
          postal_code: data.address?.postal_code,
          street: data.address?.street,
        });
      }
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [partnerID, setSelectedTags, setSocialLinks, setCcontacts, setSelectPrices]);

  // 修改请求
  const requestEdit = useCallback(async () => {
    // 收集对象
    try {
      setSaveLoading(true);
      await putPartnerUserInfo(partnerID, updateData);
      refreshInfluencers(); // 比如改变了黑名单状态, 列表状态显示会受影响, 需要刷新一下
      toggle();
      handleClearAll();
    } catch (error) {
      //
    } finally {
      setSaveLoading(false);
    }
  }, [toggle, handleClearAll, partnerID, updateData, refreshInfluencers]);

  // 新增请求
  const requestAdd = useCallback(async () => {
    // 收集对象
    try {
      setSaveLoading(true);
      await postAddNewPartner(updateData);
      await wait(1000)
      refreshInfluencers();
      toggle();
      handleClearAll();
    } catch (error) {
      //
    } finally {
      setSaveLoading(false);
    }
  }, [toggle, handleClearAll, updateData, refreshInfluencers]);
  //#endregion -------------------- ↑ 请求函数 ↑ ----------------------

  // Save 按钮
  const editorSave = async () => {
    // 收集对象
    if (!partnerID) {
      requestAdd();
    } else {
      requestEdit();
    }
  };

  // outletID 变化, 且存在时候 -> 请求数据 -> outletID存在 -> 编辑模式
  useEffect(() => {
    if (partnerID) {
      requestDetail();
    } else {
      setLoading(false); // 新增时候不需要加载状态
    }
  }, [partnerID, requestDetail]);

  // 跳转页面卸载时, 重置侧边栏开合状态
  useUnmount(() => {
    resetEditorActive();
  });

  return (
    <AntDrawer
      title={title}
      visible={visible}
      handleSave={editorSave}
      handleCancel={editorCancel}
      handleSetting={editorAdvaceSetting}
      saveLoading={saveLoading}
      disabledSave={!influencerNameInput}
    >
      {loading ? (
        <DrawerSkeleton />
      ) : (
        <>
          <DrawerSection title="Basic information">
            <Stack distribution="fillEvenly">
              <TextField
                autoComplete="off"
                requiredIndicator
                label="Influencer name"
                value={influencerNameInput}
                onChange={setInfluencerNameInput}
              />
              <SelectPlus
                label="Gender"
                options={genderOptions}
                select={selectGender}
                onSelect={setSelectGender}
              />
            </Stack>
            <br />
            <Stack distribution="fillEvenly">
              <SelectPlus
                label="Country/region"
                options={RegionOps}
                select={selectRegion}
                onSelect={setSelectRegion}
              />
              <SelectPlus
                label="Language"
                allowMultiple
                options={LanguageOps}
                select={selectLang}
                onSelect={setSelectLang}
              />
            </Stack>
            <br />
            <Stack distribution="fillEvenly">
              <Select
                label="Contact status"
                options={ContactStatusOptions}
                value={selectContactStatus}
                onChange={setSelectContactStatus}
              />
              <TextField
                autoComplete="off"
                label="Email"
                value={emailInput}
                onChange={setEmailInput}
              />
            </Stack>
            <br />
            <Stack distribution="fillEvenly">
              <SelectPlus
                label="Tier"
                options={TierOptions}
                select={selectTier}
                onSelect={setSelectTier}
              />
              <Select
                label="Blacklist"
                options={blacklistOptions}
                value={selectBlackList}
                onChange={setSelectBlackList}
              />
            </Stack>
            <br />
            <InputTags label="Tag" />
            <br />
            <TextField
              autoComplete="off"
              label="Note"
              multiline={2}
              value={noteInput}
              onChange={setNoteInput}
            />
          </DrawerSection>
          <DrawerSection title="Social link">
            <SocialLink />
          </DrawerSection>
          <DrawerSection title="Other contact">
            <OtherContact />
          </DrawerSection>
          <DrawerSection title="Price information">
            <PriceInfo />
          </DrawerSection>
        </>
      )}
    </AntDrawer>
  );
};
