import { deleteKeywordResults, getKeywordResultConfig, getKeywordResultList } from "@/api/request";
import { AntPagination } from "@/components/AntToShopify/AntPagination";
import { DownloadLink } from "@/components/EnhanceShopify/DownloadLink";
import { StopPropagation } from "@/components/EnhanceShopify/StopPropagation";
import { useCustomToast } from "@/customHooks/useCustomToast";
import { useTabs } from "@/customHooks/useTabs";
import { useStoreLocalState } from "@/hooks/useStoreLocalState";
import { ConfirmDeleteModal } from "@/utils/ConfirmDeleteModal";
import { s_to_ms } from "@/utils/accurateTimeTrans";
import { splitChoiceValue } from "@/utils/checkType";
import { datepickToShopifyStyle } from "@/utils/datepickToShopifyStyle";
import { filterDivStyle } from "@/utils/filterDivStyle";
import { formatDuration } from "@/utils/formatDuration";
import { isEmpty } from "@/utils/isEmpty";
import { moment2Date } from "@/utils/moment2Date";
import {
  filterOptions,
  showOptionLabelsInTextFeild,
  updateTextRegFilter
} from "@/utils/updateTextRegFilter";
import {
  Autocomplete,
  Badge,
  Button,
  Card,
  ChoiceList,
  Filters,
  Icon,
  IndexTable,
  Page,
  Popover,
  Stack,
  Text,
  TextContainer,
  useIndexResourceState
} from "@shopify/polaris";
import { Columns3Minor, SortMinor, TroubleshootMajor } from "@shopify/polaris-icons";
import { useDebounceFn, useMount, useToggle, useUpdateEffect } from "ahooks";
import { DatePicker as AntDatePicker, Pagination } from "antd";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { v4 } from "uuid";
import { ColumnsOps, Headings } from "./LaunchResults.columns";
const { RangePicker: AntDateRangePicker } = AntDatePicker;

// toast 配置信息
const isErrorToast = true;
const toastMsg = {
  successDelete: "Delete successfully!",
  failedDelete: "Delete unsuccessfully!",
};
// sort 配置信息
const sortOps = [
  { label: "Creation time (oldest first)", value: "created_at|asc" },
  { label: "Creation time (newest first)", value: "created_at|desc" },
  { label: "Update time (oldest first)", value: "updated_at|asc" },
  {
    label: "Update time (newest first)",
    value: "updated_at|desc",
  },
];
// indextable 配置信息
const resourceName = {
  singular: "last activity",
  plural: "last activities",
};

export const LaunchResults = () => {
  const { id } = useParams();
  const history = useHistory();
  const nav2Settings = () => history.push(`/tools/keywords/settings/${id}`);

  const { toastJSX: deleteSuccessToast, toggleActive: toggleDeleteSuccess } = useCustomToast(
    toastMsg.successDelete
  );
  const { toastJSX: deleteFailedToast, toggleActive: toggleDeleteFailed } = useCustomToast(
    toastMsg.failedDelete,
    isErrorToast
  );

  const { tabJSX, selected: tabIndex } = useTabs({
    titles: ["All", "Successful", "Failed"],
  });

  // search text
  const [queryValue, setQueryValue] = useState("");
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), [setQueryValue]);
  // sort
  const [sortValue, setSortValue] = useState("updated_at|desc");
  const sortOrder = useMemo(() => splitChoiceValue(sortValue)[0], [sortValue]);
  const sortType = useMemo(() => splitChoiceValue(sortValue)[1], [sortValue]);

  useEffect(() => {
    console.log("sortOrder,sortType: ", sortOrder, sortType);
  }, [sortOrder, sortType]);

  // 动态列 columns
  const [columnsActive, { toggle: toggleColumnsActive }] = useToggle();
  const [columns, setColumns] = useState(["Code", "Status", "Duration", "Output"]);
  useStoreLocalState("keywords_results_columns", { state: columns, setState: setColumns });

  // 排序 sort
  const [sortActive, { toggle: toggleSortActive }] = useToggle();
  const sortActivator = (
    <Button icon={<Icon source={SortMinor} />} onClick={toggleSortActive}>
      Sort
    </Button>
  );

  // #region --------- ↓ creator ↓ ----------------------
  const [deselectedCreatorOptions, setDeselectedCreatorOptions] = useState([]);
  const [creatorOptions, setCreatorOptions] = useState([]);
  const [selectedCreator, setSelectedCreator] = useState([]);
  const [creatorInputValue, setCreatorInputValue] = useState("");
  const handleCreatorOnSelect = useCallback(
    (selectList) => {
      setSelectedCreator(selectList);
    },
    [setSelectedCreator]
  );
  const updateCreatorText = useCallback(
    (value) => {
      setCreatorInputValue(value);
      if (value === "") {
        setCreatorOptions(deselectedCreatorOptions);
        return;
      }
      const resultOptions = updateTextRegFilter(value, deselectedCreatorOptions);
      setCreatorOptions(resultOptions);
    },
    [deselectedCreatorOptions, setCreatorOptions]
  );
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(selectedCreator, deselectedCreatorOptions);
    setCreatorInputValue(showtext);
  }, [selectedCreator, deselectedCreatorOptions]);

  const handleCreatorRemove = useCallback(() => setSelectedCreator([]), [setSelectedCreator]);
  // #endregion ------ ↑ creator ↑ ----------------------

  // #region --------- ↓ creation time ↓ ----------------------
  const [creationTime, setCreationTime] = useState(null);
  const onCreationTimeChange = useCallback(
    (dates, dateStrings) => {
      setCreationTime(dateStrings);
    },
    [setCreationTime]
  );
  const handleCreationTimeRemove = useCallback(() => setCreationTime(null), [setCreationTime]);
  // #endregion ------ ↑ creation time ↑ ----------------------

  // 清楚所有筛选项
  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
    handleCreatorRemove();
    handleCreationTimeRemove();
  }, [handleQueryValueRemove, handleCreatorRemove, handleCreationTimeRemove]);

  // #region --------- ↓ filters ↓ ----------------------
  const filters = [
    // Create by
    {
      key: "Creator",
      label: "Create by",
      filter: (
        <StopPropagation>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            options={creatorOptions}
            selected={selectedCreator}
            textField={
              <Autocomplete.TextField
                autoComplete="off"
                onChange={updateCreatorText}
                // label="Tags"
                value={creatorInputValue}
              />
            }
            onSelect={handleCreatorOnSelect}
            listTitle="Create by"
          />
        </StopPropagation>
      ),
      shortcut: true,
    },
    // Creation time
    {
      key: "Creation_time",
      label: "Creation time",
      filter: (
        <div onClick={(e) => e.stopPropagation()}>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <AntDateRangePicker
            size="large"
            style={datepickToShopifyStyle}
            ranges={{
              Today: [moment(), moment()],
              "This Week": [moment().startOf("week"), moment().endOf("week")],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
            }}
            allowClear={false}
            onChange={onCreationTimeChange}
            value={creationTime ? [moment(creationTime[0]), moment(creationTime[1])] : null}
          />
        </div>
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  // Create by
  if (!isEmpty(selectedCreator)) {
    const key = "Creator";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedCreator),
      onRemove: handleCreatorRemove,
    });
  }
  if (!!creationTime && !!_.compact(creationTime).length) {
    const key = "Creation_time";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, creationTime),
      onRemove: handleCreationTimeRemove,
    });
  }
  //  show the filter tags under the Search
  function disambiguateLabel(key, value) {
    switch (key) {
      case "Creator": {
        const checkedCreator = filterOptions(value, deselectedCreatorOptions);
        return "Create by: " + checkedCreator.map((o) => `${o.label}`).join(", ");
      }
      case "Creation_time":
        return `Creation time: ${value[0]} ~ ${value[1]}`;
      default:
        return value;
    }
  }
  // #endregion ------ ↑ filters ↑ ----------------------

  // #region --------- ↓ request function ↓ ----------------------
  const [total, setTotal] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  // 首次加载请求的传参:
  const initParams = useMemo(() => {
    const matchingStatus = (tabIndex) => {
      if (tabIndex === 0) return [];
      if (tabIndex === 1) return ["success"];
      if (tabIndex === 2) return ["error"];
    };
    return {
      keyword_id: id,
      status: matchingStatus(tabIndex),
      search: queryValue ?? "",
      page: currentPage,
      per_page: perPage,
      creator_ids: selectedCreator,
      created_ats: creationTime,
      sort_by: sortOrder,
      sort_type: sortType,
    };
  }, [
    id,
    queryValue,
    currentPage,
    perPage,
    tabIndex,
    selectedCreator,
    creationTime,
    sortOrder,
    sortType,
  ]);

  // 在请求展示所有条目的基础上,筛选当前页条目:
  const { run } = useDebounceFn(
    async (params) => {
      setIndexTableLoading(true);

      const { data } = await getKeywordResultList({ ...initParams, ...params });

      setIndexTableList(data.list);
      setTotal(data.meta.pagination.total);
      setPerPage(data.meta.pagination.per_page);
      setCurrentPage(data.meta.pagination.current_page);
      window.dispatchEvent(new Event("resize"));

      setIndexTableLoading(false);
    },
    { wait: 600 }
  );
  const reqCurrentTable = useCallback(run, [initParams, run]);

  useEffect(() => {
    reqCurrentTable({ page: 1 });
  }, [reqCurrentTable, tabIndex, queryValue, selectedCreator, creationTime]);

  useEffect(() => {
    reqCurrentTable();
  }, [reqCurrentTable, sortValue]);

  // #endregion ------ ↑ request function ↑ ----------------------

  // #region --------- ↓ index table ↓ ----------------------
  const [indexTableLoading, setIndexTableLoading] = useState(false); // indexTable 加载状态显示
  const [indexTableList, setIndexTableList] = useState([]);
  // index Table 选中处理函数
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(indexTableList);

  // 清除indextable选中状态
  const clearSelect = useCallback(
    () => handleSelectionChange("all", false),
    [handleSelectionChange]
  );

  const [indexTableHeading, setIndexTableHeading] = useState(Headings);

  // 动态加载列:
  useEffect(() => {
    const headings = Headings.filter(({ title }) => columns.includes(title));
    setIndexTableHeading(headings);
    window.dispatchEvent(new Event("resize"));
  }, [columns]);

  const rowMarkup = indexTableList.map(
    ({ id, code, status, duration, file, creator, created_at, updater, updated_at }, index) => {
      const CodeBox = (
        <Text variant="bodyMd" as="span" fontWeight="bold">
          {code}
        </Text>
      );
      const statusBadge = (status) => {
        switch (status) {
          case "success":
            return <Badge status="success">Successful</Badge>;
          case "error":
          default:
            return <Badge status="critical">Failed</Badge>;
        }
      };
      const filelink = <DownloadLink link={file} />;
      const props = [
        CodeBox,
        statusBadge(status),
        // "Duration",
        formatDuration(duration),
        filelink,
        // "Create by",
        creator?.name,
        // "Creation time",
        moment2Date(s_to_ms(created_at)),
        // "Update by",
        updater?.name,
        // "Update time",
        moment2Date(s_to_ms(updated_at)),
      ];

      let SN = []; // 总的序列对应关系
      ColumnsOps.forEach(({ value }, index) => {
        SN.push({ column: value, prop: props[index] });
      });

      let showCell = SN.filter(({ column }) => columns.includes(column));

      return (
        <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
          {showCell.map(({ prop }) => (
            <IndexTable.Cell key={v4()}>{prop}</IndexTable.Cell>
          ))}
        </IndexTable.Row>
      );
    }
  );

  const [deleteComfirmActive, { toggle: toggleDeleteComfirmActive }] = useToggle();
  const handleConfirmDelete = useCallback(async () => {
    try {
      await deleteKeywordResults(selectedResources);
      toggleDeleteSuccess();
      clearSelect();
      toggleDeleteComfirmActive();
      reqCurrentTable();
    } catch (error) {
      toggleDeleteFailed();
    }
  }, [
    selectedResources,
    clearSelect,
    toggleDeleteSuccess,
    toggleDeleteFailed,
    toggleDeleteComfirmActive,
    reqCurrentTable,
  ]);

  const promotedBulkActions = [
    {
      content: "Delete last activity",
      onAction: toggleDeleteComfirmActive,
    },
  ];
  // #endregion ------ ↑ index table ↑ ----------------------

  useMount(async () => {
    const { data } = await getKeywordResultConfig();
    console.log("data: ", data);
    const userOps = data.users.map(({ id, name }) => ({ label: name, value: String(id) }));
    setDeselectedCreatorOptions(userOps);
    setCreatorOptions(userOps);
  });

  return (
    <Page
      title="Last activities"
      fullWidth
      breadcrumbs={[
        {
          content: "back",
          onAction() {
            history.push('/tools/keywords');
          },
        },
      ]}
      secondaryActions={[
        { content: "Keyword settings", icon: TroubleshootMajor, onAction: nav2Settings },
      ]}
    >
      {deleteSuccessToast}
      {deleteFailedToast}
      <ConfirmDeleteModal
        deleteComfirmActive={deleteComfirmActive}
        toggleDeleteComfirmActive={toggleDeleteComfirmActive}
        content="Are you sure you want to delete the selected last activity?"
        handleConfirmDelete={handleConfirmDelete}
      />
      <Card>
        {tabJSX}
        <div style={filterDivStyle}>
          <div style={{ flex: 1 }}>
            <Filters
              queryPlaceholder="Search by code"
              queryValue={queryValue}
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={setQueryValue}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleClearAll}
            />
          </div>

          <div style={{ paddingLeft: "0.4rem" }}>
            <Popover active={sortActive} activator={sortActivator} onClose={toggleSortActive}>
              <Popover.Section>
                <TextContainer>
                  <Text variant="bodyMd" as="span" color="subdued">
                    Sort by
                  </Text>
                </TextContainer>
                <ChoiceList choices={sortOps} selected={sortValue} onChange={setSortValue} />
              </Popover.Section>
            </Popover>
          </div>

          <div style={{ paddingLeft: "0.4rem" }}>
            <Popover
              active={columnsActive}
              activator={
                <Button icon={Columns3Minor} onClick={toggleColumnsActive}>
                  Columns
                </Button>
              }
              onClose={toggleColumnsActive}
            >
              <Popover.Section>
                <TextContainer>
                  <Text variant="bodyMd" as="span" color="subdued">
                    Columns
                  </Text>
                </TextContainer>
                <ChoiceList
                  allowMultiple
                  choices={ColumnsOps}
                  selected={columns}
                  onChange={setColumns}
                />
              </Popover.Section>
            </Popover>
          </div>
        </div>
        <IndexTable
          loading={indexTableLoading}
          resourceName={resourceName}
          itemCount={indexTableList.length}
          selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
          onSelectionChange={handleSelectionChange}
          headings={indexTableHeading}
          promotedBulkActions={promotedBulkActions}
          // emptyState={emptyStateMarkup}
          lastColumnSticky
        >
          {rowMarkup}
        </IndexTable>
        {!indexTableList.length ? null : (
          <Stack distribution="trailing">
            <AntPagination>
              <Pagination
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                current={currentPage}
                pageSize={perPage}
                pageSizeOptions={[10, 20, 50, 100]}
                showSizeChanger
                total={total}
                onChange={(page, pageSize) => {
                  reqCurrentTable({ ...initParams, page: page, per_page: pageSize });
                }}
              />
            </AntPagination>
          </Stack>
        )}
      </Card>
    </Page>
  );
};
