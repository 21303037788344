import keyword_face from "@/assets/autoTools/keyword-face.png";
import contact_face from "@/assets/autoTools/media-contact-face.png";
import link_face from "@/assets/autoTools/social-link-face.png";
import { Layout, MediaCard, Page, VideoThumbnail } from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const help = {
  ContactExtractor:
    "https://www.notion.so/fastlane-wiki/Media-Contact-Extractor-346590d1645b450882220c51c48abcd9",
  KeywordExtractor:
    "https://www.notion.so/fastlane-wiki/Keyword-Extractor-3f583512f5154217a3e5e2a068e23465",
  SocialLinkExtractor:
    "https://www.notion.so/fastlane-wiki/Social-Link-Extractor-ef0c7d58f56e423fba9531ee5a005dec",
};

export const AutoTools = () => {
  const history = useHistory();

  const mediaContact = () => history.push(`/tools/lastactive`);
  const mediaContactHelp = () => window.open(help.ContactExtractor);

  const keyword = () => history.push(`/tools/keywords`);
  const keywordHelp = () => window.open(help.KeywordExtractor);

  const socialLink = () => history.push(`/tools/leads`);
  const socialLinkHelp = () => window.open(help.SocialLinkExtractor);

  const Tools = [
    {
      title: "Media Contact Extractor",
      description:
        "Enter media website URLs and the system will automatically extract the associated media contact profiles for you.",
      primaryAction: {
        content: "Try this tool",
        onAction: mediaContact,
      },
      secondaryAction: {
        content: "Learn more",
        onAction: mediaContactHelp,
      },

      thumbnailUrl: contact_face,
    },
    {
      title: "Keyword Extractor",
      description:
        "Enter keywords and the system will automatically extract the associated channel profiles for you.",
      primaryAction: {
        content: "Try this tool",
        onAction: keyword,
      },
      secondaryAction: {
        content: "Learn more",
        onAction: keywordHelp,
      },
      thumbnailUrl: keyword_face,
    },
    {
      title: "Social Link Extractor",
      description:
        "Enter social links and the system will automatically extract the associated channel profiles for you.",
      primaryAction: {
        content: "Try this tool",
        onAction: socialLink,
      },
      secondaryAction: {
        content: "Learn more",
        onAction: socialLinkHelp,
      },
      thumbnailUrl: link_face,
    },
  ];
  return (
    // 只有三个才用的 Layout.Section oneThird , 大于三个需要考虑使用 Grid (Alpha)
    <Page title="Automation tools">
      <Layout>
        {Tools.map(
          ({ title, description, primaryAction, secondaryAction, thumbnailUrl }, index) => (
            <Layout.Section oneThird key={index}>
              <FixContent>
                <MediaCard
                  portrait
                  title={title}
                  description={description}
                  primaryAction={primaryAction}
                  secondaryAction={secondaryAction}
                >
                  <VideoThumbnail thumbnailUrl={thumbnailUrl} />
                </MediaCard>
              </FixContent>
            </Layout.Section>
          )
        )}
      </Layout>
    </Page>
  );
};

const FixContent = styled.div`
  p {
    height: 100px;
    overflow: hidden;
  }
`;
