import { IndexTable, Stack } from "@shopify/polaris";
import { useEffect, useMemo, useState } from "react";
import { atom, useRecoilState } from "recoil";

import { AntPagination } from "@/components/AntToShopify/AntPagination";
import { loadableAuthAtom } from "@/global/authAtom";
import { EmptyStateCard } from "@/pages/Partner/PartnerReport/General/General.support";
import { progressStatusBadge } from "@/utils/progressStatusBadge";
import { Pagination } from "antd";
import { useAtom } from "jotai";
import { chunk } from "lodash";
import { useHistory } from "react-router-dom";
import { v4 } from "uuid";

export const BoardCoopers_atom = atom({
  key: v4(),
  default: [],
});

const emptyNavto = `/campaign/Cooperations/cooperationDetail`;

const headings = ["Cooperation code", "Campaign", "Status", "Price"];
const tableHeading = headings.map((str) => ({ title: str }));

export const Cooperation = () => {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      READ: permission.data?.["pmp/workflows/cooperations/read"],
      WRITE: permission.data?.["pmp/workflows/cooperations/write"],
    }),
    [permission]
  );
  const history = useHistory();
  const [cooperations] = useRecoilState(BoardCoopers_atom);

  const [tableList, setTableList] = useState([]);
  const [listCunk, setListCunk] = useState([]);

  const [total, setTotal] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const rowMarkup = tableList?.map(({ id, code, status, campaign, price }, index) => {
    return (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>{code}</IndexTable.Cell>
        <IndexTable.Cell>{campaign}</IndexTable.Cell>
        <IndexTable.Cell>{progressStatusBadge(status)}</IndexTable.Cell>
        <IndexTable.Cell>
          {(price?.[0]?.type?.title ?? "-") +
            ": " +
            (price?.[0]?.value ?? "-") +
            " " +
            (price?.[0]?.currency?.code ?? "-")}
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  useEffect(() => {
    const pages = chunk(cooperations, pageSize);
    setTableList(pages[0]); // 第一页
    setCurrentPage(1);
    setListCunk(pages); // 各个页数
    setTotal(cooperations?.length); // 总数
  }, [cooperations, pageSize]);

  return (
    <>
      {tableList?.length ? (
        <IndexTable
          resourceName={{ singular: "item", plural: "items" }}
          itemCount={tableList.length}
          headings={tableHeading}
          selectable={false}
        >
          {rowMarkup}
        </IndexTable>
      ) : (
        <EmptyStateCard
          btnTitle="cooperation"
          content="cooperations"
          handleCreate={() => history.push(emptyNavto)}
          access={access.WRITE}
        />
      )}
      {tableList?.length ? (
        <Stack distribution="trailing" alignment="center">
          <AntPagination>
            <Pagination
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              pageSize={pageSize}
              current={currentPage}
              pageSizeOptions={[5, 10, 15, 20]}
              showSizeChanger={true}
              total={total}
              onChange={(page, pageSize) => {
                setPageSize(pageSize);
                setCurrentPage(page);
                setTableList(listCunk[page - 1]);
              }}
            />
          </AntPagination>
        </Stack>
      ) : null}
    </>
  );
};
