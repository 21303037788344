import { toast } from "react-hot-toast";

const color = {
  critical: "rgba(216, 44, 13, 1)", // --p-action-critical
  default: "rgba(32, 33, 35, 1)", // --p-surface-dark
};

/**
 * @typedef {Object} Options - 配置对象
 * @property {boolean} [error] - 是否为错误信息
 */

/**
 * 热提示函数
 * @param {string} msg - 提示信息
 * @param {Options} [options] - 配置对象
 */
export const hotToast = (msg, options) => {
  toast(msg, {
    style: {
      borderRadius: "4px",
      background: !options?.error ? color.default : color.critical,
      color: "#fff",
    },
  });
};
