import styled from "styled-components";

/**
 * 用于:
 * 侧边栏其他信息展示的时候, 需要头像 + 姓名的样式
 */
export const AvatarName = styled.div`
  display: flex;
  align-items: center;
  > .avatar {
    padding: 8px 8px 8px 0;
    span img {
      filter: ${(props) => (props.isBlackList ? `grayscale(1)` : null)};
    }
  }
`;
/**
 * 用于:
 * 放在 option list 中 头像不需要上下边距
 */
export const AvatarNameInList = styled.div`
  display: flex;
  align-items: center;
  > .avatar {
    padding: 0 8px 0;
    span img {
      filter: ${(props) => (props.isBlackList ? `grayscale(1)` : null)};
    }
  }
`;
