import { downLoadFile } from "@/utils/downLoadFile";
import { Link, Tooltip } from "@shopify/polaris";
import { StopPropagation } from "./StopPropagation";
import { TextClamp } from "./TextClamp";

export const DownloadLink = ({ link }) => {
  const handleDownLoad = () => downLoadFile(link, link);
  // 展示的时候 不要域名前缀
  const display = link?.split("/pmp/keyword_launch_results/")?.[1] ?? "";
  return (
    <StopPropagation>
      {display && (
        <Tooltip content={link}>
          <div style={{ maxWidth: 400 }} onClick={handleDownLoad}>
            <Link removeUnderline>
              <TextClamp clamp={1}>{display}</TextClamp>
            </Link>
          </div>
        </Tooltip>
      )}
    </StopPropagation>
  );
};
