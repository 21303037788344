import { TextClamp } from "@/components/EnhanceShopify/TextClamp";
import { Text } from "@shopify/polaris";

export const EmailClamp = ({ children }) => {
  return (
    <div style={{ maxWidth: 170 }}>
      <Text variant="bodyMd" as="span" color="subdued">
        <TextClamp>{children}</TextClamp>
      </Text>
    </div>
  );
};
