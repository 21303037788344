import { Spinner, Stack, Text } from "@shopify/polaris";

import { useState } from "react";
import styled from "styled-components";

const PositionStyled = styled.div`
  display: ${(props) => (props.isLoading ? "block" : "none")};
  position: absolute;
  z-index: 1000;
  bottom: 0;
  width: 100%;
`;

export const useBottomSpinner = () => {
  const [isLoading, setIsLoading] = useState(true);

  const BottomSpinner = (
    <PositionStyled isLoading={isLoading}>
      <Stack distribution="center" alignment="center">
        <Stack.Item>
          <Spinner accessibilityLabel="Spinner" size="small" />
        </Stack.Item>
        <Stack.Item>
          <div style={{ paddingBottom: 4 }}>
            <Text variant="bodyMd" as="span" color="subdued">
              loading ...
            </Text>
          </div>
        </Stack.Item>
      </Stack>
    </PositionStyled>
  );
  return { BottomSpinner, setIsLoading };
};
