import { Avatar, Stack, Text, Tooltip } from "@shopify/polaris";
import { useEffect, useState } from "react";

import Amazon_svg from "@/assets/svg/platform/PMP-Amazon.svg";
import Blog_svg from "@/assets/svg/platform/PMP-blog.svg";
import Facebook_svg from "@/assets/svg/platform/PMP-Facebook.svg";
import Instagram_svg from "@/assets/svg/platform/PMP-Instagram.svg";
import LinkedIn_svg from "@/assets/svg/platform/PMP-LinkedIn.svg";
import Redbook_svg from "@/assets/svg/platform/PMP-redbook.svg";
import Reddit_svg from "@/assets/svg/platform/PMP-Reddit.svg";
import TikTok_svg from "@/assets/svg/platform/PMP-TikTok.svg";
import Twitch_svg from "@/assets/svg/platform/PMP-Twitch.svg";
import Twitter_svg from "@/assets/svg/platform/PMP-Twitter.svg";
import Website_svg from "@/assets/svg/platform/PMP-Website.svg";
import YouTube_svg from "@/assets/svg/platform/PMP-YouTube.svg";

import { TextClamp } from "@/components/EnhanceShopify/TextClamp";
import { formatNumber } from "@/utils/formatNumber";
import styled from "styled-components";
import { v4 } from "uuid";

const OverRide = styled.div`
  background-color: white;
  height: 100%;
  width: 100%;
`;

const PlatformPosition = styled.div`
  position: relative;
`;
// eslint-disable-next-line
const ImgBox = styled.div`
  position: absolute;
  right: -6px;
  bottom: -6px;
`;

const TextBox = styled.div`
  text-align: center;
  padding-right: 2px;
`;

export const RowPlatforms = ({ platforms }) => {
  // const { type, link, avatar, username, user_id, followers, views, posts, likes } = platforms;
  const [platformsList, setPlatformsList] = useState([]);

  useEffect(() => {
    setPlatformsList(platforms ?? []);
  }, [platforms]);

  const MapTypeIcon = (type) => {
    switch (type.title) {
      case "TikTok":
        return <Avatar source={TikTok_svg} size="extraSmall" />;
      case "YouTube":
        return <Avatar source={YouTube_svg} size="extraSmall" />;
      case "Twitch":
        return <Avatar source={Twitch_svg} size="extraSmall" />;
      case "Instagram":
        return <Avatar source={Instagram_svg} size="extraSmall" />;
      case "Twitter":
        return <Avatar source={Twitter_svg} size="extraSmall" />;
      case "Xiaohongshu":
        return <Avatar source={Redbook_svg} size="extraSmall" />;
      case "Blog":
        return <Avatar source={Blog_svg} size="extraSmall" />;
      case "Facebook":
        return <Avatar source={Facebook_svg} size="extraSmall" />;
      case "Website":
        return <Avatar source={Website_svg} size="extraSmall" />;
      case "Amazon":
        return <Avatar source={Amazon_svg} size="extraSmall" />;
      case "Linkedin":
        return <Avatar source={LinkedIn_svg} size="extraSmall" />;
      case "Reddit":
        return <Avatar source={Reddit_svg} size="extraSmall" />;
      default:
        return null;
    }
  };

  return platformsList?.map(
    ({ type, link, avatar, username, user_id, followers, views, posts, likes }) => {
      // console.log("type.title: ", type.title);
      // const filterType = !(type?.title === "Blog" || type?.title === "Facebook");
      // if (!filterType) return null;

      return (
        <Tooltip
          key={v4()}
          content={
            user_id ? (
              <Stack vertical>
                {/* 头像+平台+名字+id */}
                <Stack.Item>
                  <OverRide>
                    <Stack spacing="extraTight" wrap={false}>
                      <PlatformPosition>
                        <Avatar size="medium" source={avatar} />
                      </PlatformPosition>
                      <div style={{ maxWidth: 150 }}>
                        <Text variant="bodyMd" as="span" fontWeight="bold">
                          {/* {sliceEllipsis(username, 18)} */}
                          <TextClamp clamp={2}>{username}</TextClamp>
                          <Text variant="bodySm" as="p">
                            <TextClamp clamp={1}>{user_id}</TextClamp>
                            {/* {sliceEllipsis(user_id, 18)} */}
                          </Text>
                        </Text>
                      </div>
                    </Stack>
                  </OverRide>
                </Stack.Item>
                {/* 订阅,粉丝信息 */}
                <Stack.Item>
                  <Stack wrap={false} distribution="fillEvenly" spacing="extraTight">
                    {followers && (
                      <TextBox>
                        <Text variant="bodyMd" as="span" color="subdued">
                          Followers
                        </Text>
                        <p>{formatNumber("unit", followers)}</p>
                      </TextBox>
                    )}
                    {views && (
                      <TextBox>
                        <Text variant="bodyMd" as="span" color="subdued">
                          Views
                        </Text>
                        <p>{formatNumber("unit", views)}</p>
                      </TextBox>
                    )}
                    {posts && (
                      <TextBox>
                        <Text variant="bodyMd" as="span" color="subdued">
                          Posts
                        </Text>
                        <p>{formatNumber("unit", posts)}</p>
                      </TextBox>
                    )}
                    {likes && (
                      <TextBox>
                        <Text variant="bodyMd" as="span" color="subdued">
                          Likes
                        </Text>
                        <p>{formatNumber("unit", likes)}</p>
                      </TextBox>
                    )}
                  </Stack>
                </Stack.Item>
              </Stack>
            ) : (
              "No platform data."
            )
          }
        >
          <div
            style={{ display: "inline-block", marginRight: 4 }}
            onClick={(e) => {
              e.stopPropagation();
              window.open(link);
            }}
          >
            {MapTypeIcon(type)}
          </div>
        </Tooltip>
      );
    }
  );
};
