import { atom } from "recoil";
import { v4 } from "uuid";

export const exportTwitch_atom = atom({
  key: v4(),
  default: false,
});

const twitch_search_type = atom({
  key: v4(),
  default: "all",
});
const twitch_current_page = atom({
  key: v4(),
  default: 1,
});
const twitch_per_page = atom({
  key: v4(),
  default: 10,
});

const twitch_filter_sort = atom({
  key: v4(),
  default: "updated_at|desc",
});

const twitch_filter_search = atom({
  key: v4(),
  default: "",
});

const twitch_filter_platsrDesOps = atom({
  key: v4(),
  default: [],
});

const twitch_filter_platsrcOps = atom({
  key: v4(),
  default: [],
});

const twitch_filter_platsrcSelect = atom({
  key: v4(),
  default: [],
});

const twitch_filter_keywordDesOps = atom({
  key: v4(),
  default: [],
});

const twitch_filter_keywordOps = atom({
  key: v4(),
  default: [],
});

const twitch_filter_keywordSelect = atom({
  key: v4(),
  default: [],
});

const twitch_filter_relatedPtnsDesOps = atom({
  key: v4(),
  default: [],
});

const twitch_filter_relatedPtnsOps = atom({
  key: v4(),
  default: [],
});

const twitch_filter_relatedPtnsSelect = atom({
  key: v4(),
  default: [],
});

const twitch_filter_languageDesOps = atom({
  key: v4(),
  default: [],
});

const twitch_filter_languageOps = atom({
  key: v4(),
  default: [],
});

const twitch_filter_languageSelect = atom({
  key: v4(),
  default: [],
});

const twitch_filter_followerMin = atom({
  key: v4(),
  default: "",
});
const twitch_filter_followerMax = atom({
  key: v4(),
  default: "",
});

const twitch_filter_tagsBackUp = atom({
  key: v4(),
  default: [],
});

const twitch_filter_tagsDesOps = atom({
  key: v4(),
  default: [],
});

const twitch_filter_tagsOps = atom({
  key: v4(),
  default: [],
});

const twitch_filter_tagsSelect = atom({
  key: v4(),
  default: [],
});

// const twitch_filter_xxDesOps = atom({
//   key: v4(),
//   default: [],
// });

// const twitch_filter_xxOps = atom({
//   key: v4(),
//   default: [],
// });

// const twitch_filter_xxSelect = atom({
//   key: v4(),
//   default: [],
// });

const twitch_filter_creatorDesOps = atom({
  key: v4(),
  default: [],
});

const twitch_filter_creatorOps = atom({
  key: v4(),
  default: [],
});

const twitch_filter_creatorSelect = atom({
  key: v4(),
  default: [],
});

const twitch_filter_creatime = atom({
  key: v4(),
  default: null,
});

export {
  twitch_search_type,
  twitch_current_page,
  twitch_per_page,
  twitch_filter_sort,
  twitch_filter_search,
  twitch_filter_platsrDesOps,
  twitch_filter_platsrcOps,
  twitch_filter_platsrcSelect,
  twitch_filter_keywordDesOps,
  twitch_filter_keywordOps,
  twitch_filter_keywordSelect,
  twitch_filter_relatedPtnsDesOps,
  twitch_filter_relatedPtnsOps,
  twitch_filter_relatedPtnsSelect,
  twitch_filter_languageDesOps,
  twitch_filter_languageOps,
  twitch_filter_languageSelect,
  twitch_filter_followerMin,
  twitch_filter_followerMax,
  twitch_filter_tagsBackUp,
  twitch_filter_tagsDesOps,
  twitch_filter_tagsOps,
  twitch_filter_tagsSelect,
  twitch_filter_creatorDesOps,
  twitch_filter_creatorOps,
  twitch_filter_creatorSelect,
  twitch_filter_creatime,
};
