import styled from "styled-components";

export const AntTabs = styled.div`
  /* 去除下边框 */
  > .ant-tabs .ant-tabs-nav::before {
    border: none;
  }
  /* 选中和未选中的 tab.pane 样式 */
  > .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
    .ant-tabs-tab {
      border-radius: 8px;
      .ant-avatar > img {
        filter: grayscale(0.5);
      }
    }
    /* 选中的 tab.pane 样式 */
    .ant-tabs-tab-active {
      border-radius: 8px;
      .ant-avatar > img {
        filter: grayscale(0);
      }
    }

    .ant-tabs-ink-bar {
      height: 3px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
`;

/**
 * 使用: 在 ant 的 tabs 外面包一层
 *  <AntTabs>
      <Tabs
        size="middle"
        type="card"
      >
        <Tabs.TabPane></Tabs.TabPane>
      </Tabs>
    </AntTabs>
 */
