import { Button, Link, List, ProgressBar, Text } from "@shopify/polaris";
import { useEffect, useState } from "react";

import { downLoadFile } from "@/utils/downLoadFile";
import { openNewWindow } from "@/utils/openNewWindow";

const FakeProgress = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((num) => {
        if (num < 98) {
          return num + 1;
        } else {
          return num;
        }
      });
    }, 300);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <div style={{ marginBottom: "1rem" }}>Importing...</div>
      <ProgressBar color="primary" progress={progress} />
      <div style={{ marginTop: "1rem", color: "var(--p-text-subdued)" }}>
        <List>
          <List.Item>
            Some files are too large, it may take several minutes to import, please wait patiently.
          </List.Item>
          <List.Item>
            After closing this window, the import will continue, and you can enter the import
            activities to{" "}
            <Link removeUnderline onClick={() => openNewWindow(`/account/import`)}>
              view the import progress.
            </Link>
          </List.Item>
        </List>
      </div>
    </>
  );
};

const FillProgress = ({ successInfo }) => (
  <>
    <div style={{ marginBottom: "1rem" }}>Import completed!</div>
    <ProgressBar color="primary" progress={100} />
    <br />
    <List type="bullet">
      <List.Item>
        Succeed:{" "}
        <Text variant="bodyMd" as="span" fontWeight="bold">
          {successInfo.success}
        </Text>{" "}
        items
      </List.Item>
      <List.Item>
        Failed:{" "}
        <Text variant="bodyMd" as="span" fontWeight="bold">
          {successInfo.fail}
        </Text>{" "}
        items
      </List.Item>
      <List.Item>
        <Button
          plain
          onClick={() => {
            downLoadFile(successInfo.link, "Import records", false);
          }}
        >
          Click here
        </Button>
        &nbsp; to download import result.
      </List.Item>
    </List>
  </>
);

const ErrorProgress = () => (
  <>
    <div style={{ marginBottom: "1rem" }}>Import failed, please re-import!</div>
    <ProgressBar color="critical" progress={100} />
  </>
);

// 假进度, 完成进度, 上传错误进度
export { FakeProgress, FillProgress, ErrorProgress };
