import { atom } from "recoil";
import { v4 } from "uuid";
const ptns_search_type = atom({
  key: v4(),
  default: "all",
});

const ptns_current_page = atom({
  key: v4(),
  default: 1,
});
const ptns_per_page = atom({
  key: v4(),
  default: 10,
});

const ptns_tab_init = atom({
  key: v4(),
  default: 0,
});

// 没有用常量全大写的方式,因为这是个全局状态的默认值
const ptns_search_text = atom({
  key: v4(),
  default: "",
});

const ptns_filter_gender = atom({
  key: v4(),
  default: "",
});

const ptns_filter_sort = atom({
  key: v4(),
  default: "updated_at|desc",
});

const ptns_filter_langDesOps = atom({
  key: v4(),
  default: [],
});

const ptns_filter_langOps = atom({
  key: v4(),
  default: [],
});

const ptns_filter_langSelect = atom({
  key: v4(),
  default: [],
});

const ptns_filter_popDesOps = atom({
  key: v4(),
  default: [],
});

const ptns_filter_popOps = atom({
  key: v4(),
  default: [],
});

const ptns_filter_popSelect = atom({
  key: v4(),
  default: [],
});

const ptns_filter_principalDesOps = atom({
  key: v4(),
  default: [],
});

const ptns_filter_principalOps = atom({
  key: v4(),
  default: [],
});

const ptns_filter_principalSelect = atom({
  key: v4(),
  default: [],
});

const ptns_filter_plaTypeDesOps = atom({
  key: v4(),
  default: [],
});

const ptns_filter_plaTypeOps = atom({
  key: v4(),
  default: [],
});

const ptns_filter_plaTypeSelect = atom({
  key: v4(),
  default: [],
});

const ptns_filter_subsSelect = atom({
  key: v4(),
  default: [],
});

const ptns_filter_subsMin = atom({
  key: v4(),
  default: "",
});

const ptns_filter_subsMax = atom({
  key: v4(),
  default: "",
});

const ptns_filter_priceMin = atom({
  key: v4(),
  default: "",
});

const ptns_filter_priceMax = atom({
  key: v4(),
  default: "",
});

const ptns_filter_currency = atom({
  key: v4(),
  default: "",
});

const ptns_filter_currencyLabel = atom({
  key: v4(),
  default: "",
});

const ptns_filter_currencyOps = atom({
  key: v4(),
  default: [],
});

const ptns_filter_contactSelect = atom({
  key: v4(),
  default: [],
});

const ptns_filter_blackListSelect = atom({
  key: v4(),
  default: null,
});

const ptns_filter_countryDesOps = atom({
  key: v4(),
  default: [],
});

const ptns_filter_countryOps = atom({
  key: v4(),
  default: [],
});

const ptns_filter_countrySelect = atom({
  key: v4(),
  default: [],
});

const ptns_filter_companyDesOps = atom({
  key: v4(),
  default: [],
});

const ptns_filter_companyOps = atom({
  key: v4(),
  default: [],
});

const ptns_filter_companySelect = atom({
  key: v4(),
  default: [],
});

const ptns_filter_groupDesOps = atom({
  key: v4(),
  default: [],
});

const ptns_filter_groupOps = atom({
  key: v4(),
  default: [],
});

const ptns_filter_groupSelect = atom({
  key: v4(),
  default: [],
});

const ptns_filter_tagDesOps = atom({
  key: v4(),
  default: [],
});

const ptns_filter_tagOps = atom({
  key: v4(),
  default: [],
});

const ptns_filter_tagSelect = atom({
  key: v4(),
  default: [],
});

const ptns_filter_creatorDesOps = atom({
  key: v4(),
  default: [],
});

const ptns_filter_creatorOps = atom({
  key: v4(),
  default: [],
});

const ptns_filter_creatorSelect = atom({
  key: v4(),
  default: [],
});

const ptns_filter_creatimeSelect = atom({
  key: v4(),
  default: null,
});

export {
  ptns_search_type,
  ptns_current_page,
  ptns_per_page,
  ptns_tab_init,
  ptns_search_text,
  ptns_filter_gender,
  ptns_filter_sort,
  ptns_filter_langDesOps,
  ptns_filter_langOps,
  ptns_filter_langSelect,
  ptns_filter_popDesOps,
  ptns_filter_popOps,
  ptns_filter_popSelect,
  ptns_filter_principalDesOps,
  ptns_filter_principalOps,
  ptns_filter_principalSelect,
  ptns_filter_plaTypeDesOps,
  ptns_filter_plaTypeOps,
  ptns_filter_plaTypeSelect,
  ptns_filter_subsSelect,
  ptns_filter_subsMin,
  ptns_filter_subsMax,
  ptns_filter_priceMin,
  ptns_filter_priceMax,
  ptns_filter_currency,
  ptns_filter_currencyLabel,
  ptns_filter_currencyOps,
  ptns_filter_contactSelect,
  ptns_filter_blackListSelect,
  ptns_filter_countryDesOps,
  ptns_filter_countryOps,
  ptns_filter_countrySelect,
  ptns_filter_companyDesOps,
  ptns_filter_companyOps,
  ptns_filter_companySelect,
  ptns_filter_groupDesOps,
  ptns_filter_groupOps,
  ptns_filter_groupSelect,
  ptns_filter_tagDesOps,
  ptns_filter_tagOps,
  ptns_filter_tagSelect,
  ptns_filter_creatorDesOps,
  ptns_filter_creatorOps,
  ptns_filter_creatorSelect,
  ptns_filter_creatimeSelect,
};
