import { IndexTable, Stack } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";

import { AntPagination } from "@/components/AntToShopify/AntPagination";
import { EmptyStateCard } from "@/pages/Partner/PartnerReport/General/General.support";
import { paymentStatusBadge } from "@/utils/paymentStatusBadge";
import { Pagination } from "antd";
import { chunk } from "lodash";
import { useHistory } from "react-router-dom";
import { v4 } from "uuid";

export const BoardPayments_atom = atom({
  key: v4(),
  default: [],
});

const emptyNavto = `/orders/PaymentOrders/PaymentDetail`;

const headings = ["Payment code", "Campaign", "Status", "Price"];
const tableHeading = headings.map((str) => ({ title: str }));

export const Payment = () => {
  const history = useHistory();
  const [payments] = useRecoilState(BoardPayments_atom);

  const [tableList, setTableList] = useState([]);
  const [listCunk, setListCunk] = useState([]);

  const [total, setTotal] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const rowMarkup = tableList?.map(
    ({ id, code, campaign, payment_status, total_amount, currency }, index) => {
      return (
        <IndexTable.Row id={id} key={id} position={index}>
          <IndexTable.Cell>{code}</IndexTable.Cell>
          <IndexTable.Cell>{campaign}</IndexTable.Cell>
          <IndexTable.Cell>{paymentStatusBadge(payment_status)}</IndexTable.Cell>
          <IndexTable.Cell>
            {total_amount ? Number(total_amount) + " " + (currency?.symbol ?? "") : "-"}
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );

  useEffect(() => {
    const pages = chunk(payments, pageSize);
    setTableList(pages[0]); // 第一页
    setListCunk(pages); // 各个页数
    setCurrentPage(1);
    setTotal(payments.length); // 总数
  }, [payments, pageSize]);

  return (
    <>
      {tableList?.length ? (
        <IndexTable
          resourceName={{ singular: "item", plural: "items" }}
          itemCount={tableList.length}
          headings={tableHeading}
          selectable={false}
        >
          {rowMarkup}
        </IndexTable>
      ) : (
        <EmptyStateCard
          btnTitle="payment"
          content="payments"
          handleCreate={() => history.push(emptyNavto)}
          // access={access.WRITE}
        />
      )}
      {tableList?.length ? (
        <Stack distribution="trailing" alignment="center">
          <AntPagination>
            <Pagination
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              pageSize={pageSize}
              current={currentPage}
              pageSizeOptions={[5, 10, 15, 20]}
              showSizeChanger={true}
              total={total}
              onChange={(page, pageSize) => {
                setPageSize(pageSize);
                setCurrentPage(page);
                setTableList(listCunk[page - 1]);
              }}
            />
          </AntPagination>
        </Stack>
      ) : null}
    </>
  );
};
