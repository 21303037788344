export const LayoutStick = ({ children, top, width }) => {
  return (
    <div
      style={{
        position: "sticky",
        width: width ? width : 260,
        marginTop: "1rem",
        top: top ? top : 70,
        padding: "0 0 0 24px",
      }}
    >
      {children}
    </div>
  );
};
