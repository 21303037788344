import { Avatar, Stack, Tag } from "@shopify/polaris";

export const Platag = ({ link, src, platname }) => {
  return (
    <Tag onClick={() => window.open(link)}>
      <Stack spacing="extraTight" alignment="center">
        <Avatar size="extraSmall" source={src} />
        <span>{platname}</span>
      </Stack>
    </Tag>
  );
};
