import { isUrl } from "./isUrl";

export const openNewWindow = (url) => {
  const origin = window.location.origin;

  if (isUrl(url)) {
    window.open(url);
  } else {
    window.open(`${origin}${url}`);
  }
};
