import { EmptySearchResult } from "@shopify/polaris";

// Sort
const cooperSortOptions = [
  { label: "Partner name A-Z", value: "partner_name|asc" },
  { label: "Partner name Z-A", value: "partner_name|desc" },
  { label: "Updated(oldest)", value: "updated_at|asc" },
  { label: "Updated(newest)", value: "updated_at|desc" },
];
// index Table Searh_emptyStatus
const cooperListEmptyStateMarkup = 
  <EmptySearchResult
    title={"No cooperations found"}
    description={"Try changing the filters or search term"}
    withIllustration
  />
;

const cooperListColumns = [
  "Cooperation code",
  "Cooperation status",
  "Start date",
  "End date",
  "Note",
  "Budgets",
  "Deliveries",
  "Promotions",
  "Payments",
  "Create by",
  "Creation time",
  "Update by",
  "Update time",
];
const cooperListColumnsOptions = cooperListColumns.map((str) => ({
  label: str,
  value: str,
}));
cooperListColumnsOptions.unshift({
  label: "Cooperation partner",
  value: "Cooperation partner",
  disabled: true,
});

const cooperList_headings = cooperListColumnsOptions.map(({ value }) => ({ title: value }));

export {
  cooperSortOptions,
  cooperListEmptyStateMarkup,
  cooperListColumnsOptions,
  cooperList_headings,
};
