import { Stack, Text } from "@shopify/polaris";

import { v4 } from "uuid";

export const BlackBoard = ({ dataList = [], margin = "34px 0 20px" }) => {
  return (
    <>
      <div
        style={{
          borderRadius: 12,
          textAlign: "center",
          backgroundColor: "#1f1f25",
          margin,
          paddingBottom: 18,
          // boxShadow: "3px 3px 3px #fff0,-3px -3px 3px #fff0",
        }}
      >
        <Stack distribution="fillEvenly">
          {dataList.map(({ label, value }) => (
            <Stack.Item key={v4()}>
              <Text>
                <span style={{ color: "#fff" }}>{label}</span>
              </Text>
              <div style={{ height: 10 }} />
              <Text variant="headingLg" as="p">
                <Text variation="strong">
                  <span style={{ color: "#fff" }}>{value}</span>
                </Text>
              </Text>
            </Stack.Item>
          ))}
        </Stack>
      </div>
    </>
  );
};
