const init_options = [
  "Country/region",
  "Tag",
  "Media website",
  "Monthly visits",
  "Traffic in the main country",
  "Create by",
  "Creation time",
  "Update by",
  "Update time",
];

const columnOps = init_options.map((str) => ({
  label: str,
  value: str,
}));

columnOps.unshift({
  label: "Media outlet name",
  value: "Media outlet name",
  disabled: true,
});

columnOps.push({
  label: "Operation",
  value: "Operation",
  disabled: true,
});

const init_headings = columnOps.map(({ value }) => ({ title: value }));

export { columnOps, init_headings };
