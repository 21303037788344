import { useBomLocation } from "@/hooks/useBomLocation";
import { Banner } from "@shopify/polaris";
import styled from "styled-components";

export const ErrorFallback = ({ error, resetErrorBoundary = () => window.location.reload() }) => {
  const { origin } = useBomLocation();

  const BackToHomePage = () => (window.location = origin + "/dashboard");

  return (
    <Center>
      <Box>
        <Banner
          status="critical"
          title="Error"
          action={{ content: "Retry", onAction: resetErrorBoundary }}
          secondaryAction={{ content: "Back to the home page", onAction: BackToHomePage }}
        >
          <p>{error.message}</p>
        </Banner>
      </Box>
    </Center>
  );
};

const Center = styled.div`
  height: 100vh;
  display: grid;
  place-items: center;
`;

const Box = styled.div`
  min-width: 40%;
`;
