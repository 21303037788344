import { useLocalStorageState, useMount, useUpdateEffect } from "ahooks";

export const useStoreLocalState = (key, { state, setState }) => {
  const [localState, setLocalState] = useLocalStorageState(key);

  useMount(() => {
    if (localState) {
      setState(localState);
    }
  });

  useUpdateEffect(() => {
    setLocalState(state);
  }, [state]);
};
