import { Tabs } from "@shopify/polaris";
import { v4 as uid } from "uuid";
import { useRecoilState } from "recoil";

/**
 * Tabs
 * @param {Object} {titles,fitted}
 * @returns {tabJSX,selected}
 */

// useTabs 的 全局缓存版本 (刷新重置) tabInitAtom 必须传入 recoil 概念状态基本单元 atom
export const useStaleTabs = ({ tabInitAtom, titles = [], fitted = false }) => {
  // tabs 选择处理函数和状态
  const [selected, setSelected] = useRecoilState(tabInitAtom);
 
  //
  const tabs = titles.map((tab) => ({
      id: `${uid()}`,
      content: tab,
      panelID: `${uid()}`,
    }));

  const tabJSX = (
    <Tabs tabs={tabs} selected={selected} onSelect={setSelected} fitted={fitted}></Tabs>
  );

  return {
    tabJSX,
    selected,
    setSelected,
  };
};
