import { useCallback } from "react";

import { deletePlatformsInCollections } from "@/api/request";
import { useGlobalToast } from "@/customHooks/useGlobalToast";
import { ConfirmDeleteModal } from "@/utils/ConfirmDeleteModal";

export const RemovePlatforms = ({
  active,
  toggle,
  CollectionID,
  reqCurrentTable,
  selectPlatforms,
  handleSelectionChange,
}) => {
  // 全局的 toast 展示
  const { handleGlobalToast } = useGlobalToast();
  // 分组下面的平台: 通用的接口 都是从当前分组分离当前选中的平台
  const handleRemove = useCallback(async () => {
    try {
      await deletePlatformsInCollections([CollectionID], selectPlatforms);
      toggle();
      handleGlobalToast({ msg: "Remove successfully!" });
      handleSelectionChange("all", false);
      reqCurrentTable();
    } catch (error) {
      //
    }
  }, [
    CollectionID,
    selectPlatforms,
    toggle,
    handleGlobalToast,
    reqCurrentTable,
    handleSelectionChange,
  ]);

  return (
    <ConfirmDeleteModal
      title="Confirm remove"
      deleteComfirmActive={active}
      toggleDeleteComfirmActive={toggle}
      content="Are you sure you want to remove the selected platform ?"
      handleConfirmDelete={handleRemove}
      buttonText="Remove"
    />
  );
};
