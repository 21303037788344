export const multipleTextArray = (str) => {
  let arr = str.split(/[(\r\n)\r\n]+/);
  arr.forEach((e, i) => {
    if (!e) {
      arr.splice(i, 1);
    }
  });
  const res = Array.from(new Set(arr));
  return res;
};
