import { atom } from "recoil";

// 全局管理状态
export const GlobalToastAtom = atom({
  key: "GlobalToastAtom",
  default: false,
});
// 全局管理信息
export const GlobalToastMessageAtom = atom({
  key: "GlobalToastMessage",
  default: "successfully!",
});
// 布尔值
export const GlobalToastColorAtom = atom({
  key: "GlobalToastColorAtom",
  default: false,
});
