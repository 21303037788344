import svg403 from "@/assets/svg/403.svg";
import { Card, EmptyState, Page } from "@shopify/polaris";
import { useHistory } from "react-router-dom";

/**
 * Renders a component indicating that the user does not have permission to access the page.
 *
 * @param {boolean} isPage - Flag indicating whether the component should be rendered as a full page or not.
 * @return {ReactNode} The rendered component.
 */
export const NoPermission = ({ isPage = true }) => {
  const history = useHistory();

  const CardMark = (
    <Card sectioned>
      <EmptyState
        heading="Sorry, you do not have permission to access this page."
        action={{
          content: "Back to previous page",
          onAction: () => history.goBack(),
        }}
        image={svg403}
      >
        <p>
          {`If you need help, please contact `}
          <a href="mailto:armin@fstln.io">{"FastGrowth team"}</a>
        </p>
      </EmptyState>
    </Card>
  );

  if (isPage) {
    return <Page>{CardMark}</Page>;
  } else {
    return <>{CardMark}</>;
  }
};
