import { isUrl } from "./isUrl";

export function downLoadFile(url, filename, blank = true) {
  if (!isUrl(url)) return;
  let downloadLink = document.createElement("a");
  downloadLink.download = filename;
  downloadLink.href = new URL(url);
  blank && (downloadLink.target = "_blank");
  downloadLink.click();
  window.URL.revokeObjectURL(downloadLink.href);
}

export const downloadBlob = (blob, fileName) => {
  if ("download" in document.createElement("a")) {
    // 非IE下载
    const elink = document.createElement("a");
    elink.download = fileName;
    elink.style.display = "none";
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href); // 释放URL 对象
    document.body.removeChild(elink);
  } else {
    // IE10+下载
    navigator.msSaveBlob(blob, fileName);
  }
};
