// 用于autocompelete textfield 筛选用
// 用正则匹配输入的文字内容, 做到简单的搜索效果
export function updateTextRegFilter(value, deselectedOptions) {
  const filterRegex = new RegExp(value, "i");
  const resultOptions = deselectedOptions.filter((option) => option.label.match(filterRegex));

  return resultOptions;
}

// 用于将autocompelete中选中的label 展示到inputvalue的通用部分逻辑:
export function showOptionLabelsInTextFeild(selectedOptions, deselectedOptions) {
  const objArr = deselectedOptions.filter((item) => selectedOptions.includes(item.value));
  const showLabelText = objArr.map((o) => o.label).join(", ");

  return showLabelText;
}

// 用于disambiguateLabel函数中过滤出选中的对应的值的对象数组 :
export function filterOptions(value, deselectedOptions) {
  const objArr = deselectedOptions.filter((o) => value.includes(o.value));

  return objArr;
}
