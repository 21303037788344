import {
  Avatar,
  Button,
  Card,
  Layout,
  Link,
  Stack,
  Text,
  TextContainer,
  Tooltip
} from "@shopify/polaris";
import { useEffect, useState } from "react";

import { AnchorMark } from "@/components/EnhanceShopify/AnchorMark";
import { InfoRow } from "@/components/EnhanceShopify/InfoRow";
import { LayoutStick } from "@/components/EnhanceShopify/LayoutStick";
import { TextClamp } from "@/components/EnhanceShopify/TextClamp";
import { IconFont } from "@/components/Icon/PMPicon";
import { useCopy } from "@/hooks/useCopy";
import { formatNumber } from "@/utils/formatNumber";
import { SafetyCertificateTwoTone } from "@ant-design/icons";
import { DuplicateMinor } from "@shopify/polaris-icons";
import { Anchor, Divider } from "antd";
import { v4 } from "uuid";
import { CustomerAvatar } from "../Common/CustomerAvatar";
import { EmailClamp } from "../Common/EmailClamp";
import { LongTextCollect } from "../Common/LongTextCollect";
import { NoReportUnderLink } from "../Common/NoReport";
import { PlatagList } from "../Common/Platags/PlatagList";
import { ShowTags } from "../Common/ShowTags";
import { SuggestedAccounts } from "./SuggestedAccounts";

export const TikTok = (props) => {
  const { selectPlatform, setUpdateTime, setUpdateBy } = props;
  const [ID, setID] = useState(null);
  const [platformLink, setPlatformLink] = useState("");
  const { copiedMark, handleCopy } = useCopy();

  // left bar state
  const [userName, setuserName] = useState("");
  const [userAvatar, setUserAvatar] = useState("");
  const [userID, setUserID] = useState("");
  const [userBio, setUserBio] = useState("");
  const [authIcon, setAuthIcon] = useState(0); // 是否认证,认证后颜色为:color="primary"
  const [profile_url, setProfile_url] = useState("");

  // right bar state
  const [contactInfo, setContactInfo] = useState([]);
  const [tags, setTags] = useState([]);
  const [suggAccounts, setSuggAccounts] = useState([]);

  // Tik Tok  切换时填充
  useEffect(() => {
    setTags(selectPlatform?.options?.tags ?? []);
    setSuggAccounts(selectPlatform?.options?.suggested_accounts ?? []);
    setContactInfo(selectPlatform?.options?.emails ?? []);
    setuserName(selectPlatform?.options?.username ?? "");
    setUserID(selectPlatform?.options?.user_id ?? "");
    setUserBio(selectPlatform?.options?.bio ?? "");
    setAuthIcon(selectPlatform?.options?.is_verified ?? 0);
    setProfile_url(selectPlatform?.options?.profile_url ?? "");
    setUserAvatar(selectPlatform?.options?.profile_picture_url ?? "");
    setID(selectPlatform.id);
    setPlatformLink(selectPlatform.platform_url);
    setUpdateTime(selectPlatform?.updated_at);
    setUpdateBy(selectPlatform?.updater?.name);
  }, [selectPlatform, setUpdateTime, setUpdateBy]);

  return ID ? (
    <Layout>
      {copiedMark}
      <LayoutStick width={300}>
        <Card sectioned>
          <CustomerAvatar
            avatar={<Avatar size="large" source={userAvatar} />}
            name={userName}
            subName={`@${window.decodeURIComponent(userID)}`}
            exta={
              <>
                {authIcon && (
                  <Tooltip content="Is profile verified" preferredPosition="above">
                    <SafetyCertificateTwoTone twoToneColor="#2E72D2" />
                  </Tooltip>
                )}
              </>
            }
          />
          <br />
          <Stack spacing="tight">
            {PlatagList([
              // 为了统一成general组件中的平台列表tag为以后扩展准备
              {
                subscribers: selectPlatform?.options?.total_followers,
                link: profile_url,
                type: {
                  title: selectPlatform?.type,
                },
              },
            ])}
          </Stack>
          <br />
          <TextContainer>
            <Text variant="bodyMd" as="span" color="subdued">
              <LongTextCollect longText={userBio} />
            </Text>
          </TextContainer>
          <br />
          <Stack wrap={false}>
            <div style={{ width: 50, marginTop: -4 }}>
              <IconFont code="&#xe75a;" title="Email" color="#008685" />
            </div>
            <Stack.Item fill>
              <EmailClamp>{contactInfo?.join("\n")}</EmailClamp>
            </Stack.Item>
            {!!contactInfo?.join("\n") && (
              <Button
                icon={DuplicateMinor}
                plain
                onClick={() => handleCopy({ copyText: contactInfo?.join("\n") })}
              />
            )}
          </Stack>
          <Divider />
          <Anchor offsetTop={60}>
            <Anchor.Link href="#Overview" title="Overview" />
            <Anchor.Link href="#Other_websites" title="Other websites" />
            <Anchor.Link href="#Suggested_accounts" title="Suggested accounts" />
          </Anchor>
        </Card>
      </LayoutStick>
      <Layout.Section>
        <Card title="Overview" sectioned>
          <AnchorMark id="Overview" />
          <InfoRow label="Followers">
            {formatNumber("unit", selectPlatform?.options?.total_followers)}
          </InfoRow>
          <InfoRow label="Following">
            {formatNumber("unit", selectPlatform?.options?.total_following)}
          </InfoRow>
          <InfoRow label="Likes">
            {formatNumber("unit", selectPlatform?.options?.total_likes)}
          </InfoRow>
          <InfoRow label="Posts">
            {formatNumber("unit", selectPlatform?.options?.total_videos)}
          </InfoRow>
          <InfoRow label="Join date">
            {selectPlatform?.options?.join_date?.replace(/\./g, "-")}
          </InfoRow>
          <InfoRow label="Tag" end>
            <ShowTags tags={tags} />
          </InfoRow>
        </Card>
        <Card title="Other websites" sectioned>
          <AnchorMark id="Other_websites" />
          {selectPlatform?.options?.websiteUrl?.map(({ websiteName, websiteUrl }) => {
            return (
              <InfoRow key={v4()} label={websiteName || "-"}>
                <Link url={websiteUrl} removeUnderline>
                  <TextClamp>{websiteUrl}</TextClamp>
                </Link>
              </InfoRow>
            );
          })}
        </Card>
        <>
          <SuggestedAccounts list={suggAccounts} />
          <AnchorMark id="Suggested_accounts" />
        </>
      </Layout.Section>
    </Layout>
  ) : (
    <NoReportUnderLink link={platformLink} />
  );
};
