import axios from "axios";

import {
  requsetErrorHandler,
  requsetHandler,
  responseErrorHandler,
  responseHandler,
} from "./interceptor.handler";

// 假请求不需要 baseURL
const fakereq = axios.create({
  timeout: 10000,
});

// 请求拦截器
fakereq.interceptors.request.use(requsetHandler, requsetErrorHandler);

// 响应拦截器
fakereq.interceptors.response.use(responseHandler, responseErrorHandler);

export { fakereq };
