import { Stack, Text } from "@shopify/polaris";

export const InfoCol = ({ children, label, alignment = "" }) => {
  return (
    <Stack vertical alignment={alignment} spacing="tight">
      <Stack.Item>
        <Text variant="bodyMd" as="p" color="subdued">
          {label}
        </Text>
      </Stack.Item>
      <Stack.Item>{children}</Stack.Item>
    </Stack>
  );
};
