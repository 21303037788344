import { Button, ButtonGroup, IndexTable, Stack, Text } from "@shopify/polaris";
import { useState } from "react";
import { columsOptions, table_headings } from "./Column/columnOps";

import { AntPagination } from "@/components/AntToShopify/AntPagination";
import { TagPopover } from "@/pages/Partner/Profiles/Common/TagPopover";
import { Pagination } from "antd";
import { chunk } from "lodash";
import { useEffect } from "react";
import { columns_atom } from "./Column/atom";
import { selectedContacts_atom } from "./SelectContacts/atom";
// eslint-disable-next-line
import { ButtonLink } from "@/components/EnhanceShopify/ButtonLink";
import { RowHeader } from "@/components/EnhanceShopify/RowHeader";
import { Region } from "@/pages/Partner/Platforms/Common/Region";
import { RowTextList } from "@/pages/Partner/Profiles/Common/RowTextList";
import { useHistory } from "react-router-dom";
import { useRecoilState, useResetRecoilState } from "recoil";
import { v4 } from "uuid";

export const Contacts = () => {
  const history = useHistory();
  const [contacts, setContacts] = useRecoilState(selectedContacts_atom);
  const resetContacts = useResetRecoilState(selectedContacts_atom);

  useEffect(() => {
    return () => {
      resetContacts();
    };
  }, [resetContacts]);

  const [tableList, setTableList] = useState([]);

  const [listCunk, setListCunk] = useState([]);
  const [tableHeading, setTableHeading] = useState(table_headings);
  const [columns] = useRecoilState(columns_atom);

  const [total, setTotal] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  // 动态加载列:
  useEffect(() => {
    const headings = table_headings.filter(({ title }) => columns.includes(title));
    setTableHeading(headings);
    window.dispatchEvent(new Event("resize"));
  }, [columns, contacts]);

  const rowMarkup = tableList?.map(
    ({ id, name, role, tags, address, email, mobile_phone, platforms, media_outlets }, index) => {
      // 总的序列对应关系
      let SN = [];
      // 字段展示
      const props = [
        <RowHeader
          key={v4()}
          onClick={(e) => {
            e.stopPropagation();
            window.open(`${origin}/contacts/board/${id}`);
          }}
        >
          <div style={{ cursor: "pointer" }}>
            <Text variant="bodyMd" as="span" fontWeight="bold">
              {name}
            </Text>
          </div>
        </RowHeader>,
        // "Role",
        role,
        // "Email",
        email,
        // "Phone",
        mobile_phone,
        // "Country/region",
        <Region key={v4()} name={address?.country?.name} image={address?.country?.image} />,
        // "Media outlet",
        <RowTextList
          key={v4()}
          textList={media_outlets?.map(({ id, name }) => (
            <p key={id}>{name}</p>
          ))}
        />,
        // "Social link",
        <RowTextList
          key={v4()}
          textList={platforms?.map(({ link }) => {
            return (
              <ButtonLink key={v4()} link={link}>
                {link}{" "}
              </ButtonLink>
            );
          })}
        />,
        // "Tag",
        <TagPopover key={v4()} tags={tags} />,
        // "Operation",
        <ButtonGroup key={v4()} segmented>
          <Button
            plain
            onClick={() => {
              history.push(`/contacts/detail/${id}`);
            }}
          >
            Edit
          </Button>
          <Button
            plain
            onClick={() => {
              setContacts((contacts) => {
                return contacts.filter((_, i) => i !== index);
              });
            }}
          >
            remove
          </Button>
        </ButtonGroup>,
      ];
      columsOptions.forEach(({ value }, index) => {
        SN.push({ column: value, prop: props[index] });
      });
      let showCell = SN.filter(({ column }) => columns.includes(column));

      return (
        <IndexTable.Row id={id} key={v4()} position={index}>
          {showCell.map(({ prop }) => (
            <IndexTable.Cell key={v4()}>{prop}</IndexTable.Cell>
          ))}
        </IndexTable.Row>
      );
    }
  );

  useEffect(() => {
    const pages = chunk(contacts, pageSize);
    setTableList(pages[0]); // 第一页
    setListCunk(pages); // 各个页数
    setTotal(contacts.length); // 总数
  }, [contacts, pageSize]);

  useEffect(() => {
    console.log("tableList: ", tableList);
  }, [tableList]);

  return (
    <>
      {tableList?.length ? (
        <IndexTable
          resourceName={{ singular: "item", plural: "items" }}
          itemCount={tableList.length}
          headings={tableHeading}
          selectable={false}
          lastColumnSticky
        >
          {rowMarkup}
        </IndexTable>
      ) : (
        <div style={{ padding: "8px 0" }} />
      )}
      {tableList?.length ? (
        <Stack distribution="trailing" alignment="center">
          <AntPagination>
            <Pagination
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              pageSize={pageSize}
              total={total}
              pageSizeOptions={[5, 10, 15, 20]}
              showSizeChanger={true}
              onChange={(page, pageSize) => {
                setPageSize(pageSize);
                setTableList(listCunk[page - 1]);
              }}
            />
          </AntPagination>
        </Stack>
      ) : null}
    </>
  );
};
