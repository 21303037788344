import { Autocomplete, Icon, List, TextContainer, TextField, Tooltip } from "@shopify/polaris";
import { SearchMinor, SelectMinor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

SelectPlus.propTypes = {
  allowMultiple: PropTypes.bool, // 多选
  disabled: PropTypes.bool,
  error: PropTypes.string, // 行内错误提示
  label: PropTypes.string, // 标题
  onBlur: PropTypes.func,
  onSelect: PropTypes.func, // 选择回调
  options: PropTypes.array.isRequired, // 选项栏, 必填
  placeholder: PropTypes.string, // placeholder
  requiredIndicator: PropTypes.bool, // 是否需要校验
  select: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export function SelectPlus({
  options,
  label = "",
  allowMultiple = false,
  select,
  onSelect = () => {},
  placeholder = "",
  requiredIndicator,
  error,
  onBlur = () => {},
  disabled = false,
}) {
  const [inputValue, setInputValue] = useState(""); // 输入搜索框
  const [holder, setHolder] = useState(""); // placeholder
  const [ops, setOps] = useState([]); // dynamic options

  // 没有输入,options就全部展示
  useEffect(() => {
    if (!options) return;

    if (!inputValue) setOps(options);
  }, [options, inputValue]);
  // 输入状态
  const updateText = useCallback(
    (value) => {
      setInputValue(value);
      // 输入状态 -> 搜索行为
      if (value === "") {
        setOps(options);
        return;
      }
      try {
        const resultOptions = Search(value, options);
        setOps(resultOptions);
      } catch (error) {
        setInputValue("");
      }
    },
    [options]
  );
  // 映射 placeholder 对应选中的 label
  useEffect(() => {
    if (!options) return;
    const res = options.filter((item) => select.includes(item.value));

    const showLabelText = res.map((o) => o.label).join(", ");
    setHolder(showLabelText);
  }, [select, options]);

  // 实时显示
  useEffect(() => {
    if (!options) return;

    const showtext = MapTextInput(select, options);
    setInputValue(showtext);
  }, [select, options]);

  const HoverContent = useMemo(
    () => (
      <List type="bullet">
        {options
          ?.filter(({ value }) => select.includes(value))
          ?.map(({ label, value }) => (
            <List.Item key={value}>{label}</List.Item>
          ))}
      </List>
    ),
    [select, options]
  );

  const textField = (
    <SuffixOrder disabled={disabled}>
      <Autocomplete.TextField
        autoComplete="off"
        onChange={updateText}
        onFocus={() => {
          setInputValue("");
        }}
        label={label}
        value={inputValue}
        suffix={<Icon source={SelectMinor} color="base" />}
        placeholder={holder ? holder : placeholder}
        onBlur={() => {
          setInputValue(holder);
          onBlur();
        }}
        clearButton={!disabled}
        onClearButtonClick={() => {
          onSelect([]);
          setHolder("");
        }}
        requiredIndicator={requiredIndicator}
        error={error}
        disabled={disabled}
      />
    </SuffixOrder>
  );
  // 空状态
  const emptyState = (
    <React.Fragment>
      <Icon source={SearchMinor} />
      <div style={{ textAlign: "center" }}>
        <TextContainer>Could not find any results</TextContainer>
      </div>
    </React.Fragment>
  );

  return disabled ? (
    <Tooltip content={HoverContent} preferredPosition="below">
      <TextField label={label} disabled={disabled} value={inputValue} onChange={() => {}} />
    </Tooltip>
  ) : (
    <Autocomplete
      options={ops}
      selected={select}
      onSelect={onSelect}
      textField={textField}
      allowMultiple={allowMultiple}
      emptyState={emptyState}
    />
  );
}

// 用于将autocompelete中选中的label 展示到inputvalue的通用部分逻辑:
function MapTextInput(selectedOptions, deselectedOptions) {
  const objArr = deselectedOptions.filter((item) => selectedOptions.includes(item.value));
  const showLabelText = objArr.map((o) => o.label).join(", ");

  return showLabelText;
}
// 检索逻辑
function Search(value, deselectedOptions) {
  const filterRegex = new RegExp(value, "i");
  const resultOptions = deselectedOptions.filter((option) => option.label.match(filterRegex));

  return resultOptions;
}

// 为了实现 clearButton 在输入框的最末尾 , 改变了 shopify 原有的位置, suffix 置于最右侧
const SuffixOrder = styled.div`
  .Polaris-TextField {
    .Polaris-TextField__Suffix {
      order: 1;
    }
  }
  .Polaris-TextField--hasValue {
    .Polaris-TextField__Suffix {
      position: absolute;
      right: 0;
    }
    .Polaris-TextField__ClearButton {
      opacity: 0;
    }
    &:hover {
      .Polaris-TextField__ClearButton {
        opacity: 1;
      }
      .Polaris-TextField__Suffix {
        opacity: ${(props) => (props.disabled ? 1 : 0)};
      }
    }
  }
`;
