import Website_svg from "@/assets/svg/platform/PMP-Website.svg";
import {
  filterOptions,
  showOptionLabelsInTextFeild,
  updateTextRegFilter,
} from "@/utils/updateTextRegFilter";
import {
  Autocomplete,
  Avatar,
  Button,
  ChoiceList,
  Filters,
  IndexTable,
  Popover,
  Select,
  Stack,
  Text,
  TextContainer,
  Tooltip,
  useIndexResourceState,
} from "@shopify/polaris";

import { Columns3Minor, SortMinor } from "@shopify/polaris-icons";
import { useDebounceFn, useLocalStorageState, useMount, useToggle, useUpdateEffect } from "ahooks";
import { DatePicker as AntDatePicker, Pagination } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  contactsRefresh_atom,
  contacts_filter_countryDesOps,
  contacts_filter_countryOps,
  contacts_filter_countrySelect,
  contacts_filter_creatimeSelect,
  contacts_filter_creatorDesOps,
  contacts_filter_creatorOps,
  contacts_filter_creatorSelect,
  contacts_filter_outletsDesOps,
  contacts_filter_outletsOps,
  contacts_filter_outletsSelect,
  contacts_search_type,
  current_page_atom,
  exportContactAtom,
  per_page_atom,
  sort_atom,
} from "./atom";
import { columnOps, init_headings } from "./column";

import {
  deleteContactsToCollections,
  deleteMediaContacts,
  getExportFile,
  getMediaContactConfig,
  getMediaContactList,
} from "@/api/request";
import { AntPagination } from "@/components/AntToShopify/AntPagination";
import { CountryEmoji } from "@/components/EnhanceShopify/CountryEmoji";
import { FilterBox } from "@/components/EnhanceShopify/FilterBox";
import { RowHeader } from "@/components/EnhanceShopify/RowHeader";
import { StopPropagation } from "@/components/EnhanceShopify/StopPropagation";
import { CountryRegionListAtom } from "@/components/InitCountryRegions";
import { useGlobalToast } from "@/customHooks/useGlobalToast";
import { useRefrehTable } from "@/customHooks/useRefrehTable";
import { loadableAuthAtom } from "@/global/authAtom";
import { useDispatchResize } from "@/hooks/useDispatchResize";
import { useDynamicHeadings } from "@/hooks/useDynamicHeadings";
import { useRecoilToggle } from "@/hooks/useRecoilToggle";
import { matchSearchType } from "@/pages/Partner/Platforms/Common/matchSearchType";
import { RowPlatforms } from "@/pages/Partner/Profiles/Common/RowPlatforms";
import { RowTextList } from "@/pages/Partner/Profiles/Common/RowTextList";
import { TagPopover } from "@/pages/Partner/Profiles/Common/TagPopover";
import { ConfirmDeleteModal } from "@/utils/ConfirmDeleteModal";
import { s_to_ms } from "@/utils/accurateTimeTrans";
import { splitChoiceValue } from "@/utils/checkType";
import { datepickToShopifyStyle } from "@/utils/datepickToShopifyStyle";
import { searchOps } from "@/utils/frontEndData/searchBy";
import { isEmpty } from "@/utils/isEmpty";
import { moment2Date } from "@/utils/moment2Date";
import { openNewWindow } from "@/utils/openNewWindow";
import { wait } from "@/utils/wait";
import { useAtom } from "jotai";
import { compact, omit } from "lodash";
import moment from "moment";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import { v4 } from "uuid";
import { AddToCampaign } from "./Common/AddToCampaign";
import { AddToCollection } from "./Common/AddToCollection";
import { ImportMediaContacts } from "./Import/ImportMediaContacts";
import { importContactsUrl_atom, toggleContactsDrawer_atom } from "./Import/atom";
import { MediaContactEditor } from "./MediaContactEditor";
import { Operation } from "./Operation";

const { RangePicker: AntDateRangePicker } = AntDatePicker;

const sortOptions = [
  { label: "Media contact name A-Z", value: "name|asc" },
  { label: "Media contact name Z-A", value: "name|desc" },
  { label: "Creation time (oldest first)", value: "created_at|asc" },
  { label: "Creation time (newest first)", value: "created_at|desc" },
  { label: "Update time (oldest first)", value: "updated_at|asc" },
  {
    label: "Update time (newest first)",
    value: "updated_at|desc",
  },
];

const resourceName = {
  singular: "media contact",
  plural: "media contacts",
};

export const isUpdateContactsAtom = atom({
  key: "isUpdateContactsAtom",
  default: false,
});

export const MediaContacts = ({ CollectionID, outletID }) => {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      READ: permission.data?.["pmp/resources/discovery/read"],
      WRITE: permission.data?.["pmp/resources/discovery/write"],
      IMPORT: permission.data?.["pmp/resources/discovery/import"],
      EXPORT: permission.data?.["pmp/resources/discovery/export"],
    }),
    [permission]
  );
  const regions = useRecoilValue(CountryRegionListAtom);

  const [tableLoading, setTableLoading] = useState(false);
  const {
    active: drawerVisible,
    setActive: setDrawerVisible,
    toggle: toggleDrawerVisible,
  } = useRecoilToggle(toggleContactsDrawer_atom);
  const [, setTemplateFileUrl] = useRecoilState(importContactsUrl_atom); // 下载模版连接初始化
  const { handleGlobalToast } = useGlobalToast();

  const { refreshMark } = useRefrehTable(contactsRefresh_atom);
  const [, setIsUpdateContacts] = useRecoilState(isUpdateContactsAtom);

  //#region  ---------------------- ↓ Filters ↓ ----------------------
  const [queryValue, setQueryValue] = useState("");
  // search type
  const [searchType, setSearchType] = useRecoilState(contacts_search_type);
  // Sort
  const [popoverSortActive, { toggle: togglePopoverSortActive }] = useToggle();
  const [sortState, setSortState] = useState("updated_at"); // 排序类型:最新时间
  const [sortOrder, setSortOrder] = useState("desc"); // 排序倒序

  const [sortValue, setSortValue] = useRecoilState(sort_atom);
  const handleSortChange = useCallback((value) => setSortValue(value), [setSortValue]);

  // Columns
  const [columnsActive, { toggle: toggleColumnsActive }] = useToggle();

  // 筛选参数
  useEffect(() => {
    const [update, sort] = splitChoiceValue(sortValue);
    setSortState(update);
    setSortOrder(sort);
  }, [sortValue]);

  //#region Country filter
  const [deselectedCountryOptions, setDeselectedCountryOptions] = useRecoilState(
    contacts_filter_countryDesOps
  );
  const [countryOptions, setCountryOptions] = useRecoilState(contacts_filter_countryOps);
  const [selectedCountry, setSelectedCountry] = useRecoilState(contacts_filter_countrySelect);
  const [countryInputValue, setCountryInputValue] = useState("");
  const handleCountryOnSelect = useCallback(
    (selectList) => {
      setSelectedCountry(selectList);
    },
    [setSelectedCountry]
  );
  const updateCountryText = useCallback(
    (value) => {
      setCountryInputValue(value);
      if (value === "") {
        setCountryOptions(deselectedCountryOptions);
        return;
      }
      const resultOptions = updateTextRegFilter(value, deselectedCountryOptions);
      setCountryOptions(resultOptions);
    },
    [deselectedCountryOptions, setCountryOptions]
  );
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(selectedCountry, deselectedCountryOptions);
    setCountryInputValue(showtext);
  }, [selectedCountry, deselectedCountryOptions]);

  const handleCountryRemove = useCallback(() => setSelectedCountry([]), [setSelectedCountry]);
  //#endregion

  //#region Media Outlets filter
  const [deselectedOutletOptions, setDeselectedOutletOptions] = useRecoilState(
    contacts_filter_outletsDesOps
  );
  const [outletOptions, setOutletOptions] = useRecoilState(contacts_filter_outletsOps);
  const [selectedOutlet, setSelectedOutlet] = useRecoilState(contacts_filter_outletsSelect);
  const [outletInputValue, setOutletInputValue] = useState("");
  const handleOutletOnSelect = useCallback(
    (selectList) => {
      setSelectedOutlet(selectList);
    },
    [setSelectedOutlet]
  );
  const updateOutletText = useCallback(
    (value) => {
      setOutletInputValue(value);
      if (value === "") {
        setOutletOptions(deselectedOutletOptions);
        return;
      }
      const resultOptions = updateTextRegFilter(value, deselectedOutletOptions);
      setOutletOptions(resultOptions);
    },
    [deselectedOutletOptions, setOutletOptions]
  );
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(selectedOutlet, deselectedOutletOptions);
    setOutletInputValue(showtext);
  }, [selectedOutlet, deselectedOutletOptions]);

  const handleOutletRemove = useCallback(() => {
    !outletID && setSelectedOutlet([]);
    setOutletOptions(deselectedOutletOptions);
  }, [outletID, setSelectedOutlet, setOutletOptions, deselectedOutletOptions]);
  //#endregion

  //#region Create by
  const [deselectedCreatorOptions, setDeselectedCreatorOptions] = useRecoilState(
    contacts_filter_creatorDesOps
  );
  const [creatorOptions, setCreatorOptions] = useRecoilState(contacts_filter_creatorOps);
  const [selectedCreator, setSelectedCreator] = useRecoilState(contacts_filter_creatorSelect);
  const [creatorInputValue, setCreatorInputValue] = useState("");
  const handleCreatorOnSelect = useCallback(
    (selectList) => {
      setSelectedCreator(selectList);
    },
    [setSelectedCreator]
  );
  const updateCreatorText = useCallback(
    (value) => {
      setCreatorInputValue(value);
      if (value === "") {
        setCreatorOptions(deselectedCreatorOptions);
        return;
      }
      const resultOptions = updateTextRegFilter(value, deselectedCreatorOptions);
      setCreatorOptions(resultOptions);
    },
    [deselectedCreatorOptions, setCreatorOptions]
  );
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(selectedCreator, deselectedCreatorOptions);
    setCreatorInputValue(showtext);
  }, [selectedCreator, deselectedCreatorOptions]);

  const handleCreatorRemove = useCallback(() => setSelectedCreator([]), [setSelectedCreator]);
  //#endregion

  //#region Creation time
  const [creationTime, setCreationTime] = useRecoilState(contacts_filter_creatimeSelect);
  const onCreationTimeChange = useCallback(
    (dates, dateStrings) => {
      setCreationTime(dateStrings);
    },
    [setCreationTime]
  );

  const handleCreationTimeRemove = useCallback(() => setCreationTime(null), [setCreationTime]);
  //#endregion

  // Clear filter
  const handleClearAll = useCallback(() => {
    setQueryValue("");
    handleCountryRemove();
    handleOutletRemove();
    handleCreatorRemove();
    handleCreationTimeRemove();
  }, [handleCountryRemove, handleOutletRemove, handleCreatorRemove, handleCreationTimeRemove]);

  const filters = [
    // Country/region
    {
      key: "Country",
      label: "Country/region",
      filter: (
        <StopPropagation>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            options={countryOptions}
            selected={selectedCountry}
            textField={
              <Autocomplete.TextField
                autoComplete="off"
                onChange={updateCountryText}
                // label="Tags"
                value={countryInputValue}
              />
            }
            onSelect={handleCountryOnSelect}
            listTitle="Country/region"
          />
        </StopPropagation>
      ),
      shortcut: true,
    },
    // Media outlet
    {
      key: "outlets",
      label: "Media outlet",
      filter: (
        <StopPropagation>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            options={outletOptions}
            selected={selectedOutlet}
            textField={
              <Autocomplete.TextField
                autoComplete="off"
                onChange={updateOutletText}
                // label="Tags"
                value={outletInputValue}
              />
            }
            onSelect={handleOutletOnSelect}
            listTitle="Media outlet"
          />
        </StopPropagation>
      ),
      shortcut: true,
    },
    // Create by
    {
      key: "Creator",
      label: "Create by",
      filter: (
        <div>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            options={creatorOptions}
            selected={selectedCreator}
            textField={
              <Autocomplete.TextField
                autoComplete="off"
                onChange={updateCreatorText}
                // label="Tags"
                value={creatorInputValue}
              />
            }
            onSelect={handleCreatorOnSelect}
            listTitle="Create by"
          />
        </div>
      ),
      shortcut: true,
    },
    // Creation time
    {
      key: "Creation_time",
      label: "Creation time",
      filter: (
        <>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <StopPropagation>
            <AntDateRangePicker
              size="large"
              style={datepickToShopifyStyle}
              // showTime
              ranges={{
                Today: [moment(), moment()],
                "This Week": [moment().startOf("week"), moment().endOf("week")],
                "This Month": [moment().startOf("month"), moment().endOf("month")],
              }}
              allowClear={false}
              onChange={onCreationTimeChange}
              value={creationTime ? [moment(creationTime[0]), moment(creationTime[1])] : null}
            />
          </StopPropagation>
        </>
      ),
    },
  ].filter(({ key }) => (!outletID ? true : key !== "outlets"));

  const appliedFilters = [];
  // Country/region
  if (!isEmpty(selectedCountry)) {
    const key = "Country";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedCountry),
      onRemove: handleCountryRemove,
    });
  }
  // media outlets
  if (!isEmpty(selectedOutlet)) {
    const key = "outlets";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedOutlet),
      onRemove: handleOutletRemove,
    });
  }
  // Create by
  if (!isEmpty(selectedCreator)) {
    const key = "Creator";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedCreator),
      onRemove: handleCreatorRemove,
    });
  }
  if (!!creationTime && !!compact(creationTime).length) {
    const key = "Creation_time";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, creationTime),
      onRemove: handleCreationTimeRemove,
    });
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case "Country": {
        const checkedCountry = filterOptions(value, deselectedCountryOptions);
        return "Country/region: " + checkedCountry.map((o) => `${o.label}`).join(", ");
      }
      case "outlets": {
        const checkedOutlets = filterOptions(value, deselectedOutletOptions);
        return "Media outlets: " + checkedOutlets.map((o) => `${o.label}`).join(", ");
      }
      case "Creator": {
        const checkedCreator = filterOptions(value, deselectedCreatorOptions);
        return "Create by: " + checkedCreator.map((o) => `${o.label}`).join(", ");
      }
      case "Creation_time":
        return `Creation time: ${value[0]} ~ ${value[1]}`;
      default:
        return value;
    }
  }

  //#endregion -------------------- ↑ Filters ↑ ----------------------

  //#region  ---------------------- ↓ Request ↓ ----------------------

  const [total, setTotal] = useState(1);
  const [currentPage, setCurrentPage] = useRecoilState(current_page_atom);
  const [perPage, setPerPage] = useRecoilState(per_page_atom);

  const initParams = useMemo(() => {
    return {
      search_by: searchType,
      search: queryValue ?? "",
      page: currentPage,
      per_page: perPage,
      country_ids: selectedCountry,
      media_outlet_ids: !outletID ? selectedOutlet : [outletID],
      creator_ids: selectedCreator,
      created_ats: creationTime,
      sort_by: sortState,
      sort_type: sortOrder,
      group_ids: CollectionID ? [CollectionID] : "",
    };
  }, [
    searchType,
    currentPage,
    perPage,
    queryValue,
    selectedCountry,
    selectedOutlet,
    selectedCreator,
    creationTime,
    sortState,
    sortOrder,
    CollectionID,
    outletID,
  ]);
  //ANCHOR -  导出 media contacts
  const { active: exportMark } = useRecoilToggle(exportContactAtom);
  const startExport = useCallback(async () => {
    try {
      setTableLoading(true);
      // const params = pick(initParams, ["search", "search_by"]);
      const params = omit(initParams, ["page", "per_page"]);

      // const { data } = await postExportOutlets({ ...params, type: "influencer" });
      // downLoadFile(data.file_link, "PartnerList", false);
      await getExportFile({ ...params, type: "media_contact" });

      // history.push(`/account/export`);
      openNewWindow("/account/export");
    } catch (error) {
      //
    } finally {
      setTableLoading(false);
    }
  }, [initParams]);

  useUpdateEffect(() => {
    startExport();
  }, [exportMark]);

  const { run } = useDebounceFn(
    async (params) => {
      setTableLoading(true);

      const { data } = await getMediaContactList({ ...initParams, ...params });

      setTableList(data.list);
      setTotal(data.meta.pagination.total);
      setPerPage(data.meta.pagination.per_page);
      setCurrentPage(data.meta.pagination.current_page);
      window.dispatchEvent(new Event("resize"));
      setIsUpdateContacts((b) => !b);
      setTableLoading(false);
    },
    { wait: 600 }
  );
  const reqCurrentTable = useCallback(run, [initParams, run]);

  // 筛选项的变化 应该重新请求至第一页, 因为如果保留页码,请求到最大页面<当前页码会导致页面为空
  useEffect(() => {
    reqCurrentTable({ page: 1 });
  }, [
    reqCurrentTable,
    queryValue,
    queryValue,
    selectedCountry,
    selectedOutlet,
    selectedCreator,
    creationTime,
    refreshMark,
  ]);

  useEffect(() => {
    reqCurrentTable();
  }, [
    // 不能监听页码和每页数, 会造成挂载执行两次
    reqCurrentTable,
    sortValue,
  ]);
  //#endregion -------------------- ↑ Request ↑ ----------------------

  //#region  ---------------------- ↓ index table ↓ ----------------------
  const [tableList, setTableList] = useState([]);
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(tableList);

  const [deleteActive, { toggle: toggleDeleteModal }] = useToggle();
  const [add2CampaignModalActive, { toggle: toggleadd2CampaignModal }] = useToggle();
  const [add2CollectionModalActive, { toggle: toggleadd2CollectionModal }] = useToggle();

  // handle delete influencer
  const handleDeletePartner = useCallback(async () => {
    try {
      if (!CollectionID) {
        await deleteMediaContacts(selectedResources);
        await wait(1000);
        toggleDeleteModal();
        handleSelectionChange("all", false); // 清除indextable选中状态
        reqCurrentTable();
        handleGlobalToast({ msg: "Delete successfully!" });
      } else {
        await deleteContactsToCollections([CollectionID], selectedResources);
        await wait(1000);

        toggleDeleteModal();
        handleSelectionChange("all", false); // 清除indextable选中状态
        reqCurrentTable();
        handleGlobalToast({ msg: "Remove successfully!" });
      }
    } catch (error) {
      handleGlobalToast({ msg: "Delete unsuccessfully!", isErr: true });
    }
  }, [
    toggleDeleteModal,
    selectedResources,
    reqCurrentTable,
    handleSelectionChange,
    handleGlobalToast,
    CollectionID,
  ]);

  const promotedBulkActions = [
    {
      content: "Add to collection",
      onAction: toggleadd2CollectionModal,
    },
    {
      content: "Add to campaign",
      onAction: toggleadd2CampaignModal,
    },
    {
      content: `${CollectionID ? "Remove" : "Delete"} media contact`,
      onAction: toggleDeleteModal,
    },
  ];
  const [local_columns, setLocal_columns] = useLocalStorageState("media_contacts_column", {
    defaultValue: [],
  });
  const [headings, setHeadings] = useState(init_headings);

  const [columns, setColumns] = useState([
    "Media contact name",
    // "Media outlet name",
    "Country/region",
    // "Role",
    "Email",
    // "Phone",
    // "Media contact homepage URL",
    // "Social link",
    // "Tag",
    "Operation",
  ]);
  // 挂载时读取记录
  useMount(() => {
    if (local_columns?.length) {
      setColumns(local_columns);
    }
  });
  // 变化时存储记录
  useUpdateEffect(() => {
    setLocal_columns(columns);
    window.dispatchEvent(new Event("resize"));
  }, [columns]);
  // 动态加载列:
  useDynamicHeadings({
    columns: columns,
    staticHeadings: init_headings,
    setTableHeadings: setHeadings,
  });
  useDispatchResize([columns]);

  const rowMarkup = tableList.map(
    (
      {
        id,
        homepage_url,
        address,
        avatar,
        created_at,
        creator,
        email,
        name,
        mobile_phone,
        platforms,
        media_outlets,
        role,
        tags,
        updated_at,
        updater,
      },
      index
    ) => {
      // 总的序列对应关系
      let SN = [];
      // 实际渲染的每列
      let Cell = [];
      // 每一列的属性展示

      const code = address?.country?.code;
      const regionObj = regions.filter(({ iso2 }) => iso2 === code)?.[0];
      const renderRegion = (
        <div style={{ display: "flex", alignItems: "center" }}>
          <CountryEmoji>{regionObj?.emoji ?? ""}</CountryEmoji>
          <span>{regionObj?.name ?? ""}</span>
        </div>
      );

      const props = [
        //  "Media contact name",
        <RowHeader
          key={v4()}
          onClick={(e) => {
            e.stopPropagation();
            window.open(`${origin}/contacts/board/${id}`);
          }}
        >
          <Stack alignment="center" wrap={false}>
            <Avatar source={avatar} />
            <Text variant="bodyMd" as="span" fontWeight="bold">
              {name}
            </Text>
          </Stack>
        </RowHeader>,
        // "Media outlet name",
        <RowTextList
          key={v4()}
          textList={media_outlets?.map(({ id, name }) => (
            <p key={id}>{name}</p>
          ))}
        />,
        // "Country/region",
        // <Region key={v4()} name={address?.country?.name} image={address?.country?.image} />,
        renderRegion,
        // "Role",
        role,
        // "Email",
        email,
        // "Phone",
        mobile_phone,
        // "Media contact homepage URL",
        //TODO - 230117002 homgpage url 字段
        !homepage_url ? null : (
          <Tooltip>
            <div onClick={() => window.open(homepage_url)}>
              <Avatar source={Website_svg} size="extraSmall" />
            </div>
          </Tooltip>
        ),
        // "Social link",
        <RowPlatforms platforms={platforms} key={v4()} />,
        // "Tag",
        <TagPopover key={v4()} tags={tags} />,
        // "Create by",
        creator?.name,
        // "Creation time",
        moment2Date(s_to_ms(created_at)),
        // "Update by",
        updater?.name,
        // "Update time",
        moment2Date(s_to_ms(updated_at)),
        // "Operation",
        <Operation key={v4()} id={id} handleEdit={toggleDrawerVisible} />,
      ];

      columnOps.forEach(({ value }, index) => {
        SN.push({ column: value, prop: props[index] });
      });

      Cell = SN.filter(({ column }) => columns.includes(column));

      return (
        <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
          {Cell.map(({ prop }) => (
            <IndexTable.Cell key={v4()}>{prop}</IndexTable.Cell>
          ))}
        </IndexTable.Row>
      );
    }
  );

  //#endregion -------------------- ↑ index table ↑ ----------------------

  useMount(async () => {
    const options = await getMediaContactConfig();
    // import template
    setTemplateFileUrl(options.data.media_contact_template);
    // 国家
    const countryList = options?.data?.countrys?.map(({ id, name }) => ({
      label: name,
      value: `${id}`,
    }));
    setDeselectedCountryOptions(countryList);
    setCountryOptions(countryList);
    // outlets
    const outletList = options?.data?.media_outlets?.map(({ id, first_name }) => ({
      label: first_name,
      value: `${id}`,
    }));
    setDeselectedOutletOptions(outletList);
    setOutletOptions(outletList);
    const Creator = options?.data?.users?.map(({ id, name }) => ({
      label: name,
      value: String(id),
    }));
    setDeselectedCreatorOptions(Creator);
    setCreatorOptions(Creator);
  });

  return (
    <>
      <ImportMediaContacts />
      <ConfirmDeleteModal
        title={`Confirm ${CollectionID ? "remove" : "delete"}`}
        content={`Are you sure you want to ${
          CollectionID ? "remove" : "delete"
        } the selected media contact?`}
        buttonText={CollectionID ? "Remove" : "Delete"}
        deleteComfirmActive={deleteActive}
        toggleDeleteComfirmActive={toggleDeleteModal}
        handleConfirmDelete={handleDeletePartner}
      />
      <AddToCampaign
        active={add2CampaignModalActive}
        handleChange={toggleadd2CampaignModal}
        handleSave={reqCurrentTable}
        contactIDs={selectedResources}
      />
      <AddToCollection
        active={add2CollectionModalActive}
        handleChange={toggleadd2CollectionModal}
        handleSave={reqCurrentTable}
        contactIDs={selectedResources}
      />
      <MediaContactEditor
        title="Edit media contact basic profile"
        visible={drawerVisible}
        toggle={toggleDrawerVisible}
        setActive={setDrawerVisible}
      />
      <FilterBox>
        <Select
          options={searchOps}
          value={searchType}
          onChange={(value) => {
            setSearchType(value);
            setQueryValue("");
          }}
        />

        <Filters
          queryValue={queryValue}
          filters={filters}
          appliedFilters={appliedFilters}
          onQueryChange={setQueryValue}
          onQueryClear={() => setQueryValue("")}
          onClearAll={handleClearAll}
          queryPlaceholder={`Search ${matchSearchType(searchOps, searchType)}`}
        />

        <div style={{ paddingLeft: "0.4rem" }}>
          <Popover
            active={popoverSortActive}
            activator={
              <Button onClick={togglePopoverSortActive} icon={SortMinor}>
                Sort
              </Button>
            }
            onClose={togglePopoverSortActive}
            preferredAlignment="right"
          >
            <Popover.Pane>
              <Popover.Section>
                <TextContainer>
                  <Text variant="bodyMd" as="span" color="subdued">
                    Sort by
                  </Text>
                </TextContainer>

                <ChoiceList
                  choices={sortOptions}
                  selected={sortValue}
                  onChange={handleSortChange}
                />
              </Popover.Section>
            </Popover.Pane>
          </Popover>
        </div>
        <div style={{ paddingLeft: "0.4rem" }}>
          <Popover
            active={columnsActive}
            activator={
              <Button icon={Columns3Minor} onClick={toggleColumnsActive}>
                Columns
              </Button>
            }
            onClose={toggleColumnsActive}
          >
            <Popover.Section>
              <TextContainer>
                <Text variant="bodyMd" as="span" color="subdued">
                  Columns
                </Text>
              </TextContainer>
              <ChoiceList
                allowMultiple
                choices={columnOps}
                selected={columns}
                onChange={setColumns}
              />
            </Popover.Section>
          </Popover>
        </div>
      </FilterBox>
      <IndexTable
        loading={tableLoading}
        resourceName={resourceName}
        itemCount={tableList.length}
        selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
        onSelectionChange={handleSelectionChange}
        promotedBulkActions={!access.WRITE ? undefined : promotedBulkActions}
        headings={headings}
        lastColumnSticky
      >
        {rowMarkup}
      </IndexTable>
      {tableList.length ? (
        <Stack distribution="trailing">
          <AntPagination>
            <Pagination
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              current={currentPage}
              pageSize={perPage}
              pageSizeOptions={[10, 20, 50, 100]}
              showSizeChanger
              total={total}
              onChange={(page, pageSize) => {
                reqCurrentTable({ ...initParams, page: page, per_page: pageSize });
              }}
            />
          </AntPagination>
        </Stack>
      ) : null}
    </>
  );
};
