import { Badge } from "@shopify/polaris";

export function promotionStatusBadge(status) {
  status = Number(status);
  const colors = new Map([
    [1, ""], // 灰色
    [2, "success"], // 绿色
    [3, "critical"], // 红色
  ]);
  const currentStatus = new Map([
    [1, "Unpublished"],
    [2, "Published"],
    [3, "Offline"],
  ]);
  const content = (status) => currentStatus.get(status);
  const badgeColor = (status) => colors.get(status);
  return (
    <Badge status={badgeColor(status)}>
      <span style={{ whiteSpace: "nowrap" }}>{content(status)}</span>
    </Badge>
  );
}

export function deliveryStatusBadge(status) {
  status = Number(status);
  const colors = new Map([
    [1, ""], // 灰色
    [2, "success"], // 绿色
  ]);
  const currentStatus = new Map([
    [1, "Unshipped"],
    [2, "Shipped"],
  ]);
  const content = (status) => currentStatus.get(status);
  const badgeColor = (status) => colors.get(status);
  return (
    <Badge status={badgeColor(status)}>
      <span style={{ whiteSpace: "nowrap" }}>{content(status)}</span>
    </Badge>
  );
}
