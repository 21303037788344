import "gantt-task-react/dist/index.css";

import {
  filterOptions,
  showOptionLabelsInTextFeild,
  updateTextRegFilter
} from "@/utils/updateTextRegFilter";
import {
  Autocomplete,
  Button,
  Checkbox,
  ChoiceList,
  EmptySearchResult,
  Filters,
  Text
} from "@shopify/polaris";
import { useDebounceFn, useMount, useUpdateEffect } from "ahooks";
import { Gantt, ViewMode } from "gantt-task-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { clacStatusColor } from "../helper";

import { promotionsSchedules } from "@/api/request";
import { useTopLoading } from "@/customHooks/useTopLoading";
import { creatorOptions } from "@/utils/frontEndData/common.option";
import { isEmpty } from "@/utils/isEmpty";
import { useHistory } from "react-router-dom";
import styles from "../Schedules.module.scss";

export const PromotionSchedle = ({ cooperList, campaignList }) => {
  const history = useHistory();
  const { TopLoadingJSX, hiddenTopLoading, showTopLoading } = useTopLoading();
  // calender

  //#region gantt
  // view mode
  const [selectViewMode, setSelectViewMode] = useState(["Month"]);
  const handleViewModeOnSelect = useCallback((value) => setSelectViewMode(value), []);
  // const handleViewModeRemove = useCallback(() => setSelectViewMode(["Month"]), []);
  // show task list
  const [showTaskList, setShowTaskList] = useState(true);
  const handleChange = useCallback((newChecked) => setShowTaskList(newChecked), []);
  const [view, setView] = useState(ViewMode.Month);
  const [tasks, setTasks] = useState([]);

  let columnWidth = 65;
  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  const handleDblClick = (task) => {
    // history.push(`/campaign/Promotions/PromotionDetail/${task.id}`);
    console.log("On Double Click event Id:" + task.id);
  };

  const handleSelect = (task) => {
    console.log("task: ", task);
    history.push(`/campaign/Promotions/PromotionDetail/${task.id}`);

    // console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    // console.log("On expander click Id:" + task.id);
  };

  // 甘特图查看视图的模式
  useEffect(() => {
    if (!Array.isArray(selectViewMode)) return;

    if (selectViewMode[0] === "Month") {
      setView(ViewMode.Month);
    }
    if (selectViewMode[0] === "Week") {
      setView(ViewMode.Week);
    }
    if (selectViewMode[0] === "Day") {
      setView(ViewMode.Day);
    }
  }, [selectViewMode]);
  //#endregion

  // staus state
  const [Status, setStatus] = useState(null);
  const handleStatusOnSelect = useCallback((value) => setStatus(value), []);
  const handleStatusRemove = useCallback(() => setStatus(null), []);
  // autocompelete系列多选6个要素: 1.总表,2.筛选展示表,3.选中的数组,4.输入的文字,5.选中处理函数,6.移除处理函数
  // Campaign filter
  const [deselectedCampaignOptions, setDeselectedCampaignOptions] = useState([]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [selectedCampaignOptions, setSelectedCampaignOptions] = useState([]);
  const [campaignInputValue, setCampaignInputValue] = useState("");
  const handleCampaignOnSelect = useCallback((selectList) => {
    setSelectedCampaignOptions(selectList);
  }, []);
  const handleCampaignRemove = useCallback(() => setSelectedCampaignOptions([]), []);
  // cooperation filter
  const [deselectedCooperationOptions, setDeselectedCooperationOptions] = useState([]);
  const [cooperationOptions, setCooperationOptions] = useState([]);
  const [selectedCooperationOptions, setSelectedCooperationOptions] = useState([]);
  const [cooperationInputValue, setCooperationInputValue] = useState("");
  const handleCooperationOnSelect = useCallback((value) => {
    setSelectedCooperationOptions(value);
  }, []);
  const handleCooperationRemove = useCallback(() => setSelectedCooperationOptions([]), []);
  // creator filter
  const [selectedCreatorOptions, setSelectedCreatorOptions] = useState(["0"]);
  const handleCreatorOnSelect = useCallback((value) => setSelectedCreatorOptions(value), []);
  const handleCreatorRemove = useCallback(() => setSelectedCreatorOptions(["0"]), []);

  //#region  request
  const initParams = useMemo(
    () => ({
      campaign_ids: isEmpty(selectedCampaignOptions) ? "" : selectedCampaignOptions,
      cooperation_ids: selectedCooperationOptions,
      status: Status ? Status : "",
      created_by_current_user: selectedCreatorOptions[0] === "0" ? "" : selectedCreatorOptions[0],
    }),
    [selectedCampaignOptions, selectedCooperationOptions, Status, selectedCreatorOptions]
  );

  const { run } = useDebounceFn(
    async (params) => {
      showTopLoading();
      try {
        const {
          data: { list },
        } = await promotionsSchedules({ ...initParams, ...params });
        const promotionTasks = list.map(({ id, cooperation, status, time }) => {
          return {
            id: String(id),
            name: cooperation.partner.first_name,
            start: new Date(time),
            end: new Date(time),
            progress: 0,
            type: "milestone",
            styles: clacStatusColor("Promotion")(status),
          };
        });
        setTasks(promotionTasks);

        hiddenTopLoading();
      } catch (error) {
        hiddenTopLoading();
      }
    },
    { wait: 600 }
  );
  // 渲染当前列表 - useMemo 中已经依赖项 - initParams
  const renderlist = useCallback(run, [run, initParams]);

  useUpdateEffect(() => {
    renderlist();
  }, [selectedCampaignOptions, selectedCooperationOptions, Status, selectedCreatorOptions]);
  //#endregion request

  // textfeild show selected labels
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(
      selectedCampaignOptions,
      deselectedCampaignOptions
    );
    setCampaignInputValue(showtext);
  }, [selectedCampaignOptions, deselectedCampaignOptions]);
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(
      selectedCooperationOptions,
      deselectedCooperationOptions
    );
    setCooperationInputValue(showtext);
  }, [selectedCooperationOptions, deselectedCooperationOptions]);

  // clear all filters
  const handleClearAll = useCallback(() => {
    handleStatusRemove();
    handleCampaignRemove();
    handleCooperationRemove();
    handleCreatorRemove();
  }, [handleStatusRemove, handleCampaignRemove, handleCooperationRemove, handleCreatorRemove]);

  // autocompelete updateText: campaign,cooperation,creator
  const updateCampaignText = useCallback(
    (value) => {
      setCampaignInputValue(value);
      if (value === "") {
        setCampaignOptions(deselectedCampaignOptions);
        return;
      }
      const resultOptions = updateTextRegFilter(value, deselectedCampaignOptions);
      setCampaignOptions(resultOptions);
    },
    [deselectedCampaignOptions]
  );
  const updateCooperationText = useCallback(
    (value) => {
      setCooperationInputValue(value);
      if (value === "") {
        setCooperationOptions(value);
      }
      const resultOptions = updateTextRegFilter(value, deselectedCooperationOptions);
      setCooperationOptions(resultOptions);
    },
    [deselectedCooperationOptions]
  );

  // Autocomplete.TextField:
  const campaignTextField = (
    <Autocomplete.TextField
      autoComplete="off"
      onChange={updateCampaignText}
      // label="Tags"
      value={campaignInputValue}
    />
  );
  const cooperationTextField = (
    <Autocomplete.TextField
      autoComplete="off"
      onChange={updateCooperationText}
      // label="Tags"
      value={cooperationInputValue}
    />
  );
  const deselectedStatusOptions = [
    { label: "Unpublished", value: "1" },
    { label: "Published", value: "2" },
    { label: "Offline", value: "3" },
  ];

  const filters = [
    {
      key: "Status",
      label: "Status",
      filter: (
        <ChoiceList
          title="Account status"
          titleHidden
          choices={deselectedStatusOptions}
          selected={Status || []}
          onChange={handleStatusOnSelect}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "Campaign",
      label: "Campaign",
      filter: (
        <div>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            options={campaignOptions}
            selected={selectedCampaignOptions}
            textField={campaignTextField}
            onSelect={handleCampaignOnSelect}
            listTitle="Campaign"
          />
        </div>
      ),
      shortcut: true,
    },
    {
      key: "Cooperation",
      label: "Cooperation",
      filter: (
        <div>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            options={cooperationOptions}
            selected={selectedCooperationOptions}
            textField={cooperationTextField}
            onSelect={handleCooperationOnSelect}
            listTitle="cooperation"
          />
        </div>
      ),
      shortcut: true,
    },
    {
      key: "Creator",
      label: "Create by",
      filter: (
        <ChoiceList
          titleHidden
          choices={creatorOptions}
          selected={selectedCreatorOptions || []}
          onChange={handleCreatorOnSelect}
        />
      ),
      shortcut: true,
    },
    {
      key: "viewMode",
      label: "View mode",
      filter: (
        <>
          <Checkbox label="Show header" checked={showTaskList} onChange={handleChange} />
          <ChoiceList
            titleHidden
            choices={[
              { label: "Day", value: "Day" },
              { label: "Week", value: "Week" },
              { label: "Month", value: "Month" },
            ]}
            selected={selectViewMode || []}
            onChange={handleViewModeOnSelect}
          />
        </>
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];

  if (!isEmpty(Status)) {
    const key = "Status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, Status),
      onRemove: handleStatusRemove,
    });
  }
  if (selectedCampaignOptions.length !== 0) {
    const key = "Campaign";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedCampaignOptions),
      onRemove: handleCampaignRemove,
    });
  }
  if (selectedCooperationOptions.length !== 0) {
    const key = "Cooperation";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedCooperationOptions),
      onRemove: handleCooperationRemove,
    });
  }
  if (selectedCreatorOptions["0"] !== "0") {
    const key = "Creator";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedCreatorOptions),
      onRemove: handleCreatorRemove,
    });
  }

  //  show the filter tags under the Search
  function disambiguateLabel(key, value) {
    switch (key) {
      case "Status": {
        const statusArr = filterOptions(value, deselectedStatusOptions);
        return "Status: " + statusArr.map((val) => `${val.label}`).join(", ");
      }
      case "Campaign": {
        const checkedCampaign = filterOptions(value, deselectedCampaignOptions);
        return "Campaign: " + checkedCampaign.map((o) => `${o.label}`).join(", ");
      }
      case "Cooperation": {
        const checkedCooperation = filterOptions(value, deselectedCooperationOptions);
        return "Cooperation: " + checkedCooperation.map((o) => `${o.label}`).join(", ");
      }
      case "Creator": {
        const checkedCreator = filterOptions(value, creatorOptions);
        return "Create by: " + checkedCreator.map((o) => `${o.label}`).join(", ");
      }
      default:
        return value;
    }
  }

  useMount(() => {
    renderlist();
    setDeselectedCampaignOptions(campaignList);
    setCampaignOptions(campaignList);

    setDeselectedCooperationOptions(cooperList);
    setCooperationOptions(cooperList);
  });

  return (
    <>
      {TopLoadingJSX}
      <div style={{ flex: 1 }}>
        <Filters
          filters={filters}
          appliedFilters={appliedFilters}
          onClearAll={handleClearAll}
          hideQueryField
        />
        {tasks.length ? (
          <div className={styles.ganttWraper}>
            <Gantt
              tasks={tasks}
              viewMode={view}
              // onDateChange={handleTaskChange}
              // onDelete={handleTaskDelete}
              // onProgressChange={handleProgressChange}
              onDoubleClick={handleDblClick}
              onSelect={handleSelect}
              onExpanderClick={handleExpanderClick}
              listCellWidth={showTaskList ? "160px" : ""}
              ganttHeight={600}
              columnWidth={columnWidth}
              todayColor={"rgba(228, 247, 250, 0.6)"}
            />
          </div>
        ) : (
          <EmptySearchResult
            title={"No promotions found"}
            description={"Try changing the filters or search term"}
            withIllustration
          />
        )}
      </div>
    </>
  );
};
