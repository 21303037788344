import { Button, ButtonGroup, Stack } from "@shopify/polaris";

import { SettingsMinor } from "@shopify/polaris-icons";
import { Drawer } from "antd";
import PropTypes from "prop-types";

AntDrawer.propTypes = {
  children: PropTypes.element,
  disabledSave: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleSave: PropTypes.func,
  handleSetting: PropTypes.func,
  saveLoading: PropTypes.bool,
  title: PropTypes.string,
  visible: PropTypes.bool,
};

export function AntDrawer({
  children,
  handleSetting,
  handleSave,
  handleCancel,
  title,
  visible,
  saveLoading,
  disabledSave,
}) {
  return (
    <Drawer
      zIndex={520} // shopify 最高的层级是520
      title={title}
      visible={visible}
      onClose={handleCancel} // 点击遮罩层或左上角叉或取消按钮的回调
      getContainer={false} // 默认挂载body, false 是挂载到最近的相对定位父元素
      // size="large"
      width={688}
      destroyOnClose={false}
      mask={true} // 显示遮罩
      maskClosable={true} // 允许点击遮罩关闭
      placement="right" // 抽抽屉方向
      headerStyle={{ padding: 16 }}
      bodyStyle={{ padding: 0 }}
      footer={
        <Stack alignment="center">
          <Stack.Item fill>
            <Button icon={SettingsMinor} onClick={handleSetting}>
              Advance settings
            </Button>
          </Stack.Item>

          <ButtonGroup>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button primary onClick={handleSave} loading={saveLoading} disabled={disabledSave}>
              Save
            </Button>
          </ButtonGroup>
        </Stack>
      }
    >
      {children}
    </Drawer>
  );
}
