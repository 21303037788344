import { TextClamp } from "@/components/EnhanceShopify/TextClamp";
import { downLoadFile } from "@/utils/downLoadFile";
import { Button, Icon, Link, Stack, TextContainer, Tooltip } from "@shopify/polaris";
import { ImportMinor } from "@shopify/polaris-icons";
import { v4 } from "uuid";

export const SideAttachment = ({ title = "Attachment information", attachments = [] }) => {
  return (
    <TextContainer>
      {attachments.map(({ name, path }) => (
        <div key={v4()}>
          <Stack distribution="equalSpacing" wrap={false} alignment="center">
            <Stack.Item>
              <Tooltip content={name}>
                <div style={{ overflow: "hidden", width: 420 }}>
                  <Link removeUnderline onClick={() => downLoadFile(path, name)}>
                    <TextClamp clamp={1}>{name}</TextClamp>
                  </Link>
                </div>
              </Tooltip>
            </Stack.Item>
            <Stack.Item>
              <div>
                <Button plain onClick={() => downLoadFile(path, name)}>
                  <Icon source={ImportMinor} />
                </Button>
              </div>
            </Stack.Item>
          </Stack>
        </div>
      ))}
    </TextContainer>
  );
};
