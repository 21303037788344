import { Button, Tooltip } from "@shopify/polaris";

import { sliceEllipsis } from "@/utils/sliceEllipsis";

export const ButtonLink = ({ link, len = 30, emptyState = null }) => {
  if (typeof link !== "string" || !link?.length) return emptyState;
  return (
    <Tooltip content={link}>
      <Button plain onClick={() => window.open(link)}>
        {sliceEllipsis(link, len)}
      </Button>
    </Tooltip>
  );
};
