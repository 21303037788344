import { Modal } from "@shopify/polaris";
import { useToggle } from "ahooks";
import BeforeUnloadComponent from "react-beforeunload-component";

const ConfirmLeave = ({ onClose, onSubmit, open = true }) => 
    <Modal
      open={open}
      onClose={onClose}
      title="Leave page with unsaved changes?"
      primaryAction={{
        content: "Leave page",
        onAction: onSubmit,
        destructive: true,
      }}
      secondaryActions={[
        {
          content: "Stay",
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <p>Leave this page will delete all unsaved changes.</p>
      </Modal.Section>
    </Modal>
  ;
/** 包裹组件,阻断浏览器切换路由的行为:
 * 阻断该组件跳转路由,blockRoute: 是否触发阻断效果
 *  */
export const BeforeUnload = ({ children, blockRoute = true }) => 
    <BeforeUnloadComponent
      blockRoute={blockRoute}
      modalComponentHandler={({ handleModalLeave, handleModalCancel }) => <ConfirmLeave onClose={handleModalCancel} onSubmit={handleModalLeave} />}
      ignoreChildrenLinks={true}
    >
      {children}
    </BeforeUnloadComponent>
  ;
/**
 * useBeforeUnload 这个钩子是用于`返回按钮`的阻断并确认
 */
export const useBeforeUnload = ({ action }) => {
  const [active, { toggle: toggleConfirmLeave }] = useToggle();

  const ConfirmLeaveModal = 
    <ConfirmLeave open={active} onClose={toggleConfirmLeave} onSubmit={action} />
  ;
  return { ConfirmLeaveModal, toggleConfirmLeave };
};
