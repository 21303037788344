import { KeepAlive, useKeepAlive } from "@/customHooks/useKeepAlive";
import { deliveryStatusBadge, promotionStatusBadge } from "@/utils/statusMapBadge";
import { ManOutlined, WomanOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  DataTable,
  Icon,
  Layout,
  Link,
  Stack,
  Text,
  TextContainer,
  Tooltip,
} from "@shopify/polaris";
import { DuplicateMinor, ImportMinor } from "@shopify/polaris-icons";
import { useEffect, useMemo, useState } from "react";
import {
  BlacklistStatus,
  CardButtonGroup,
  ContactStatus,
  EmptyStateCard,
  codeNavigate,
  lastBudgetHeadings,
  lastCooperHeadings,
  lastDeliveryHeadings,
  lastPromotionHeadings,
} from "./General.support";

import { AnchorMark } from "@/components/EnhanceShopify/AnchorMark";
import { AvatarGender } from "@/components/EnhanceShopify/AvatarGender";
import { InfoRow } from "@/components/EnhanceShopify/InfoRow";
import { LayoutStick } from "@/components/EnhanceShopify/LayoutStick";
import { RowStack } from "@/components/EnhanceShopify/RowStack";
import { TextClamp } from "@/components/EnhanceShopify/TextClamp";
import { UpdateInfo } from "@/components/EnhanceShopify/UpdateInfo";
import { IconFont } from "@/components/Icon/PMPicon";
import { useTabs } from "@/customHooks/useTabs";
import { loadableAuthAtom } from "@/global/authAtom";
import { useCopy } from "@/hooks/useCopy";
import { s_to_ms } from "@/utils/accurateTimeTrans";
import { approvalStatusBadge } from "@/utils/approvalStatusBadge";
import { downLoadFile } from "@/utils/downLoadFile";
import { moment2Date } from "@/utils/moment2Date";
import { progressStatusBadge } from "@/utils/progressStatusBadge";
import { Anchor, Divider } from "antd";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";
import { v4 } from "uuid";
import { Region } from "../../Platforms/Common/Region";
import { CustomerAvatar } from "../Common/CustomerAvatar";
import { EmailClamp } from "../Common/EmailClamp";
import { PlatagList } from "../Common/Platags/PlatagList";
import { ShowTags } from "../Common/ShowTags";
import { PureTimeLine } from "./PureTimeLine";

export const General = ({ partnerInfomation: INFO, memberOptions, setUpdateTime, setUpdateBy }) => {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      LOADING: permission.state === "loading",
      ERROR: permission.state === "hasError",
      WRITE: permission.data?.["pmp/resources/discovery/write"],
      IMPORT: permission.data?.["pmp/resources/discovery/import"],
      EXPORT: permission.data?.["pmp/resources/discovery/export"],
      READ: permission.data?.["pmp/resources/discovery/read"],
    }),
    [permission]
  );
  const history = useHistory();
  const { copiedMark, handleCopy } = useCopy();

  const [principal, setPrincipal] = useState("");
  const [lastCooperation, setLastCooperation] = useState([]);
  const [lastPromotion, setLastPromotion] = useState([]);
  const [lastDelivery, setLastDelivery] = useState([]);
  const [lastPayment, setLastPayment] = useState([]);
  const [lastBudget, setLastBudget] = useState([]);

  //#region Rows
  const lastCooperRows =
    lastCooperation?.map(({ id, code, campaign, status, price }, index) => [
      codeNavigate(code, () => {
        history.push(`/campaign/Cooperations/cooperationDetail/${id}`);
      }),
      campaign,
      progressStatusBadge(status),
      (price[0]?.type?.title ? ": " : "") +
        (price[0]?.value ?? "-") +
        " " +
        (price[0]?.currency?.code ?? "-"),
    ]) ?? [];
  const lastBudgetRows =
    lastBudget?.map(({ id, code, approval_status, campaign, price }, index) => [
      codeNavigate(code, () => {
        history.push(`/orders/BudgetApps/InfluencerDetail/${id}`);
      }),
      campaign,
      approvalStatusBadge(approval_status),
      (price[0]?.type?.title ?? "-") +
        ": " +
        (price[0]?.value ?? "-") +
        " " +
        (price[0]?.currency?.code ?? "-"),
    ]) ?? [];
  const lastPromotionRows =
    lastPromotion?.map(({ id, code, campaign, status, link }, index) => [
      codeNavigate(code, () => {
        history.push(`/campaign/Promotions/PromotionDetail/${id}`);
      }),
      campaign,
      promotionStatusBadge(status),
      <Link removeUnderline url={link}>
        {link}
      </Link>,
    ]) ?? [];
  const lastDeliveryRows =
    lastDelivery?.map(({ id, code, campaign, delivery_status, receipt_time }, index) => [
      codeNavigate(code, () => {
        history.push(`/orders/Deliveries/DeliveryDetails/${id}`);
      }),
      campaign,
      deliveryStatusBadge(delivery_status),
      receipt_time,
    ]) ?? [];

  //#endregion

  // side bar

  const [otherContacts, setOtherContacts] = useState([]);
  const otherContactsMarkup = (
    <RowStack>
      <Stack distribution="fillEvenly">
        {otherContacts?.map((o, i) => (
          <Stack.Item key={v4()}>
            {/* <SiderInfoRow label={o.type.title + ":"} value={o.account} /> */}
            <Text variant="bodyMd" as="span" color="subdued">
              {o.type.title + ": "}
            </Text>
            <Text variant="bodyMd" as="span">
              {o.account}
            </Text>
          </Stack.Item>
        ))}
      </Stack>
    </RowStack>
  );

  // Attachment information Card
  const [attachments, setAttachments] = useState([]);

  // Other information
  const [otherInfo, setOtherInfo] = useState({});

  useEffect(() => {
    setUpdateTime(INFO?.updated_at);
    setUpdateBy(INFO?.updater?.name);
    console.log("INFO: ", INFO);

    const Principal = memberOptions.filter((o) => o.id === INFO.principal)[0]?.name;
    setPrincipal(Principal);
    // 四大列表
    setLastCooperation(INFO.cooperation);
    setLastPromotion(INFO.promotion);
    setLastDelivery(INFO.delivery);
    setLastPayment(INFO.paymentApplication);
    setLastBudget(INFO.budget);
    // timeline

    setOtherContacts(INFO.contacts);
    // // 附件
    setAttachments(INFO.attachments);
    // otherinfo
    setOtherInfo({
      creatorAvatar: INFO?.creator?.avatar,
      creator: INFO?.creator?.name,
      createTime: moment2Date(s_to_ms(INFO?.created_at)),
      updaterAvatar: INFO?.updater?.avatar,
      updater: INFO?.updater?.name,
      updateTime: moment2Date(s_to_ms(INFO?.updated_at)),
    });
  }, [INFO, memberOptions, setUpdateTime, setUpdateBy]);
  const { tabJSX, selected } = useTabs({
    titles: ["Cooperation", "Budget", "Promotion", "Delivery"],
  });
  const [cooperShow, setCooperShow] = useState(true);
  const [budgetShow, setBudgetShow] = useState(false);
  const [promotionShow, setPromotionShow] = useState(false);
  const [deliveryShow, setDeliveryShow] = useState(false);
  const { keepShow } = useKeepAlive([
    setCooperShow,
    setBudgetShow,
    setPromotionShow,
    setDeliveryShow,
  ]);
  useEffect(() => {
    switch (selected) {
      case 0:
        keepShow(setCooperShow, true);
        break;
      case 1:
        keepShow(setBudgetShow, true);
        break;
      case 2:
        keepShow(setPromotionShow, true);
        break;
      case 3:
        keepShow(setDeliveryShow, true);
        break;
      default:
        break;
    }
  }, [selected, keepShow]);

  const PersonAddressMark = (
    <RowStack>
      <Stack distribution="equalSpacing">
        <Stack.Item>
          <Text variant="bodyMd" as="span" color="subdued">
            Country/region: &nbsp;
          </Text>
          <Text variant="bodyMd" as="span">
            {INFO?.address?.country?.name}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <Text variant="bodyMd" as="span" color="subdued">
            State/province: &nbsp;
          </Text>
          <Text variant="bodyMd" as="span">
            {INFO?.address?.state}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <Text variant="bodyMd" as="span" color="subdued">
            City: &nbsp;
          </Text>
          <Text variant="bodyMd" as="span">
            {INFO?.address?.city}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <Text variant="bodyMd" as="span" color="subdued">
            Address: &nbsp;
          </Text>
          <Text variant="bodyMd" as="span">
            {INFO?.address?.street}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <Text variant="bodyMd" as="span" color="subdued">
            Postal code: &nbsp;
          </Text>
          <Text variant="bodyMd" as="span">
            {INFO?.address?.postal_code}
          </Text>
        </Stack.Item>
      </Stack>
    </RowStack>
  );

  const CompanyAddressMark = (
    <RowStack>
      <Stack distribution="equalSpacing">
        <Stack.Item>
          <Text variant="bodyMd" as="span" color="subdued">
            Country/region: &nbsp;
          </Text>
          <Text variant="bodyMd" as="span">
            {INFO?.company?.address?.country?.name}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <Text variant="bodyMd" as="span" color="subdued">
            State/province: &nbsp;
          </Text>
          <Text variant="bodyMd" as="span">
            {INFO?.company?.address?.state}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <Text variant="bodyMd" as="span" color="subdued">
            City: &nbsp;
          </Text>
          <Text variant="bodyMd" as="span">
            {INFO?.company?.address?.city}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <Text variant="bodyMd" as="span" color="subdued">
            Address: &nbsp;
          </Text>
          <Text variant="bodyMd" as="span">
            {INFO?.company?.address?.street}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <Text variant="bodyMd" as="span" color="subdued">
            Postal code: &nbsp;
          </Text>
          <Text variant="bodyMd" as="span">
            <TextClamp>{INFO?.company?.address?.postal_code}</TextClamp>
          </Text>
        </Stack.Item>
      </Stack>
    </RowStack>
  );

  return (
    <>
      {copiedMark}
      <Layout>
        <LayoutStick width={300}>
          <Card sectioned>
            <RowStack>
              <Region
                name={INFO?.address?.country?.name}
                image={INFO?.address?.country?.image}
                languages={INFO?.languages}
                code={INFO?.address?.country?.code}
              />
            </RowStack>
            {!!INFO?.languages && !!INFO?.address?.country?.name && <br />}
            <CustomerAvatar
              avatar={
                <AvatarGender>
                  <Avatar size="large" source={INFO.avatar} />
                  {INFO.gender === 1 ? (
                    <ManOutlined style={{ fontSize: 16, color: "#ffffff" }} />
                  ) : INFO.gender === 2 ? (
                    <WomanOutlined style={{ fontSize: 16, color: "#ffffff" }} />
                  ) : null}
                </AvatarGender>
              }
              name={<TextClamp>{INFO.first_name}</TextClamp>}
              subName={INFO.last_name && "@" + INFO.last_name}
            />
            <br />
            <Stack spacing="tight">{PlatagList(INFO.platforms)}</Stack>
            {!!INFO.brief_description && <br />}
            <TextContainer>
              <Text variant="bodyMd" as="span" color="subdued">
                <TextClamp>{INFO.brief_description}</TextClamp>
              </Text>
            </TextContainer>
            <br />
            <Stack wrap={false}>
              <div style={{ width: 50, marginTop: -4 }}>
                <IconFont code="&#xe75a;" title="Email" color="#008685" />
              </div>
              <Stack.Item fill>
                <EmailClamp>{INFO.email}</EmailClamp>
              </Stack.Item>
              {INFO.email && (
                <Button
                  icon={DuplicateMinor}
                  plain
                  onClick={() => handleCopy({ copyText: INFO.email })}
                />
              )}
            </Stack>

            <Divider />
            <Anchor offsetTop={60}>
              <Anchor.Link href="#Overview" title="Overview" />
              <Anchor.Link href="#Contact_methods" title="Contact methods" />
              <Anchor.Link href="#Company_information" title="Company information" />
              <Anchor.Link href="#Historical_activities" title="Historical activities" />
              <Anchor.Link href="#Other_information" title="Other information" />
              <Anchor.Link href="#Attachments" title="Attachments" />
              <Anchor.Link href="#Timeline" title="Timeline" />
            </Anchor>
          </Card>
        </LayoutStick>
        <Layout.Section>
          <Card title="Overview" sectioned>
            <AnchorMark id="Overview" />
            <InfoRow label="Tier">{INFO.account_level?.title}</InfoRow>
            <InfoRow label="Owner">{principal}</InfoRow>
            <InfoRow label="Price">
              {INFO.prices?.map(({ currency, type, value }) => {
                return (
                  <Text key={v4()} variant="bodyMd" as="p">
                    <Text variant="bodyMd" as="span" color="subdued">
                      {type?.title ? type?.title + " :" : ""} &nbsp;
                    </Text>
                    {currency?.symbol} {value}
                  </Text>
                );
              })}
            </InfoRow>
            <InfoRow label="Collection">
              <ShowTags tags={INFO.groups} isProfile />
            </InfoRow>
            <InfoRow label="Tag" end>
              <ShowTags tags={INFO?.tags ?? []} isProfile />
            </InfoRow>
          </Card>
          <Card title="Contact methods" sectioned>
            <AnchorMark id="Contact_methods" />
            <InfoRow label="Contact status">
              <ContactStatus status={INFO.contact_status} />
            </InfoRow>
            <InfoRow label="Blacklist">
              <BlacklistStatus status={INFO.is_blacklisted} />
            </InfoRow>
            <InfoRow label="Email">
              <RowStack>
                <Stack wrap={false}>
                  <Stack.Item>
                    <Text variant="bodyMd" as="span">
                      <TextClamp>{INFO.email}</TextClamp>
                    </Text>
                  </Stack.Item>
                  {INFO.email && (
                    <Button
                      icon={DuplicateMinor}
                      plain
                      onClick={() => handleCopy({ copyText: INFO.email })}
                    />
                  )}
                </Stack>
              </RowStack>
            </InfoRow>
            <InfoRow label="Phone">{INFO.mobile_phone}</InfoRow>
            <InfoRow label="Fax">{INFO?.fax}</InfoRow>
            <InfoRow label="Other contacts">{otherContactsMarkup}</InfoRow>
            <InfoRow label="Personal address" end>
              {PersonAddressMark}
            </InfoRow>
          </Card>
          <Card title="Company information" sectioned>
            <AnchorMark id="Company_information" />
            <InfoRow label="Company name">{INFO?.company?.name}</InfoRow>
            <InfoRow label="Contact person">{INFO?.company?.contact_person}</InfoRow>
            <InfoRow label="Email">{INFO?.company?.email}</InfoRow>
            <InfoRow label="Phone">
              {INFO?.company?.telephone ? "/" + INFO?.company?.telephone : ""}
              {INFO?.company?.mobile_phone}
            </InfoRow>
            <InfoRow label="Fax">{INFO?.company?.fax}</InfoRow>
            <InfoRow label="Official website">{INFO?.company?.official_website}</InfoRow>
            <InfoRow label="Company address" end>
              {CompanyAddressMark}
            </InfoRow>
          </Card>
          <Card title="Historical activities">
            <AnchorMark id="Historical_activities" />
            {tabJSX}
            <KeepAlive show={cooperShow} destroy={true}>
              {!!lastCooperation?.length ? (
                <>
                  <DataTable
                    columnContentTypes={["text", "text", "text", "text"]}
                    headings={lastCooperHeadings}
                    rows={lastCooperRows}
                  />
                  <div style={{ height: 1, borderTop: "1px solid #dcdfe1" }}></div>

                  {!access.WRITE ? undefined : (
                    <CardButtonGroup
                      primaryContent="Create cooperation"
                      plainContent={lastCooperation.length ? "View all cooperations" : null}
                      handlePlainClick={() => {
                        history.push(`/campaign/Cooperations`);
                      }}
                      handlePrimaryClick={() => {
                        history.push(`/campaign/Cooperations/cooperationDetail/`);
                      }}
                    />
                  )}
                </>
              ) : (
                <EmptyStateCard
                  btnTitle="cooperation"
                  content="cooperations"
                  handleCreate={() => history.push(`/campaign/Cooperations/cooperationDetail`)}
                  access={access.WRITE}
                />
              )}
            </KeepAlive>
            <KeepAlive show={budgetShow} destroy={true}>
              {!!lastBudget?.length ? (
                <>
                  <DataTable
                    columnContentTypes={["text", "text", "text", "text"]}
                    headings={lastBudgetHeadings}
                    rows={lastBudgetRows}
                  />
                  <div style={{ height: 1, borderTop: "1px solid #dcdfe1" }}></div>

                  {!access.WRITE ? undefined : (
                    <CardButtonGroup
                      primaryContent="Create budget"
                      plainContent={lastBudget.length ? "View all budgets" : null}
                      handlePlainClick={() => {
                        history.push(`/orders/BudgetApps`);
                      }}
                      handlePrimaryClick={() => {
                        history.push(`/orders/BudgetApps/InfluencerDetail`);
                      }}
                    />
                  )}
                </>
              ) : (
                <EmptyStateCard
                  btnTitle="budget"
                  content="budgets"
                  handleCreate={() => history.push(`/orders/BudgetApps/InfluencerDetail`)}
                  access={access.WRITE}
                />
              )}
            </KeepAlive>
            <KeepAlive show={promotionShow} destroy={true}>
              {!!lastPromotion?.length ? (
                <>
                  <DataTable
                    columnContentTypes={["text", "text", "text", "text"]}
                    headings={lastPromotionHeadings}
                    rows={lastPromotionRows}
                  />
                  <div style={{ height: 1, borderTop: "1px solid #dcdfe1" }}></div>

                  {!access.WRITE ? undefined : (
                    <CardButtonGroup
                      primaryContent="Create promotion"
                      plainContent={lastPromotion.length ? "View all promotions" : null}
                      handlePlainClick={() => {
                        history.push(`/campaign/Promotions`);
                      }}
                      handlePrimaryClick={() => {
                        history.push(`/campaign/Promotions/PromotionDetail/`);
                      }}
                    />
                  )}
                </>
              ) : (
                <EmptyStateCard
                  btnTitle="promotion"
                  content="promotions"
                  handleCreate={() => history.push(`/campaign/Promotions/PromotionDetail/`)}
                  access={access.WRITE}
                />
              )}
            </KeepAlive>
            <KeepAlive show={deliveryShow} destroy={true}>
              {lastDelivery?.length ? (
                <>
                  <DataTable
                    columnContentTypes={["text", "text", "text", "text"]}
                    headings={lastDeliveryHeadings}
                    rows={lastDeliveryRows}
                  />
                  <div style={{ height: 1, borderTop: "1px solid #dcdfe1" }}></div>
                  {!access.WRITE ? undefined : (
                    <CardButtonGroup
                      primaryContent="Create delivery"
                      plainContent={lastDelivery.length ? "View all deliveries" : null}
                      handlePlainClick={() => {
                        history.push(`/orders/Deliveries`);
                      }}
                      handlePrimaryClick={() => {
                        history.push(`/orders/Deliveries/DeliveryDetails`);
                      }}
                    />
                  )}
                </>
              ) : (
                <EmptyStateCard
                  btnTitle="delivery"
                  content="deliveries"
                  handleCreate={() => history.push(`/orders/Deliveries/DeliveryDetails`)}
                  access={access.WRITE}
                />
              )}
            </KeepAlive>
          </Card>
          <Card title="Other information" sectioned>
            <AnchorMark id="Other_information" />
            <UpdateInfo
              creatorAvatar={otherInfo.creatorAvatar}
              creator={otherInfo.creator}
              createTime={otherInfo.createTime}
              updaterAvatar={otherInfo.updaterAvatar}
              updater={otherInfo.updater}
              updateTime={otherInfo.updateTime}
            />
          </Card>
          <Card title="Attachments" sectioned>
            <AnchorMark id="Attachments" />
            {!!attachments?.length && (
              <TextContainer>
                {attachments.map(({ name, path }) => (
                  <div key={v4()}>
                    <Stack distribution="equalSpacing" wrap={false} alignment="center">
                      <Stack.Item>
                        <Tooltip content={name}>
                          <div style={{ overflow: "hidden", width: 420 }}>
                            <Link removeUnderline onClick={() => downLoadFile(path, name)}>
                              <TextClamp clamp={1}>{name}</TextClamp>
                            </Link>
                          </div>
                        </Tooltip>
                      </Stack.Item>
                      <Stack.Item>
                        <div>
                          <Button
                            // icon={ImportMinor}
                            plain
                            onClick={() => downLoadFile(path, name)}
                          >
                            <Icon source={ImportMinor} />
                          </Button>
                        </div>
                      </Stack.Item>
                    </Stack>
                  </div>
                ))}
              </TextContainer>
            )}
          </Card>
          <Card title="Timeline" sectioned>
            <AnchorMark id="Timeline" />
            <PureTimeLine events={INFO.events} />
          </Card>
        </Layout.Section>
      </Layout>
    </>
  );
};
