import styled from "styled-components";

export const OptionAvatarBox = styled.div`
  position: relative;
  .Polaris-OptionList
    .Polaris-OptionList__Options
    .Polaris-OptionList-Option
    .Polaris-OptionList-Option__Label {
    align-items: center;
  }
`;
