import { useState } from "react";

export function useFiltersPagination() {
  const [totalPages, setTotalPages] = useState(1);
  let [currentPage, setCurrentPage] = useState(1);
  return {
    currentPage,
    totalPages,
    setCurrentPage,
    setTotalPages,
  };
}
