// 顺序很重要,否则不能覆盖对应的样式
import "./App.less";
import "./styles/common.less";
import "./styles/global.less";

import logo_black from "@/assets/svg/logo_black.svg";
import logo_white from "@/assets/svg/logo_white.svg";
import { ContextualSaveBar, Frame, Loading } from "@shopify/polaris";
import { Suspense, useCallback, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import Routes from "./Routes";
import { ErrorFallback } from "./components/ErrorFallback/ErrorFallback";
import { InitCountryRegions } from "./components/InitCountryRegions";
import Header from "./components/header/header";
import Leftbar from "./components/leftbar/leftbar";
import AxiosErrorContainer from "./container/AxiosErrorContainer";
import { GlobalToast } from "./container/GlobalToast";
import LoadingContextProvider from "./context/loading-context";
import {
  globalLoadingStatus,
  isTopBarSaveDisabled,
  topBarSaveDisplay,
  topBarSignal,
} from "./recoils/atoms";

const Logo = {
  width: 120,
  topBarSource: logo_black,
  contextualSaveBarSource: logo_white, // 出现topbar深色保存操作栏时logo位置显示的图片
  url: "/",
};
export function App() {
  const location = useLocation();

  const [isLoading, setIsLoading] = useRecoilState(globalLoadingStatus);
  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    [setIsLoading]
  );
  const loadingMarkup = isLoading ? <Loading /> : null;

  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  // 开关: 控制topSaveBar是否显示
  const [isSave, setIsSave] = useRecoilState(topBarSaveDisplay);
  // 每一个需要topbar提供操作的信号
  const [siganl, setSignal] = useRecoilState(topBarSignal);
  // 控制save按钮是否禁用
  const [isSaveDisabled] = useRecoilState(isTopBarSaveDisabled);

  // 切换开关动作函数
  const toggleIsSave = useCallback(() => setIsSave((isSave) => !isSave), [setIsSave]);
  // 统一topbar信号源: 理由是shopify每一个页面至多触发唯一的topbarSave
  const toggleSave = () => {
    if (siganl === "topSave") {
      setSignal("topSaveComfirm");
    }
    // toggleIsSave() // 放在了有对应操作的组件内:方便请求完成/失败->是否触发
  };
  // 点击取消按钮
  const toggleCancel = () => {
    if (siganl === "topSave") {
      setSignal("cancel");
    }
    toggleIsSave(); // 取消不会引起请求操作,直接统一处理
  };
  // 全局topBarSaveBtn组件
  const contextualSaveBarMarkup = isSave ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={
        isSaveDisabled
          ? {
              onAction: toggleSave,
              disabled: true,
            }
          : {
              onAction: toggleSave,
              disabled: false,
            }
      }
      discardAction={{
        content: "Discard",
        onAction: toggleCancel,
      }}
    />
  ) : null;

  const toggleMobileNavigationActive = useCallback(
    () => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive),
    []
  );

  const headerMemo = useMemo(() => {
    return (
      <Suspense fallback={<></>}>
        <Header toggleMobileNavigationActive={toggleMobileNavigationActive} />
      </Suspense>
    );
  }, [toggleMobileNavigationActive]);

  return (
    <>
      <InitCountryRegions />
      <Suspense fallback={<></>}>
        <Frame
          logo={Logo}
          topBar={headerMemo}
          navigation={<Leftbar location={location} toggleIsLoading={toggleIsLoading} />}
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={toggleMobileNavigationActive}
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <LoadingContextProvider>
              {contextualSaveBarMarkup}

              <Routes />

              {loadingMarkup}
              <AxiosErrorContainer />
            </LoadingContextProvider>
            <GlobalToast />
          </ErrorBoundary>
        </Frame>
      </Suspense>
    </>
  );
}
