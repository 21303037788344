import {
  getFiltersCountrys,
  getFiltersCurrency,
  getPartnerContactTypes,
  getPartnerLanguagesOptions,
  getPartnerPlatform,
  getPartnerPriceList
} from "@/api/request";
import { SociallinkTypes_atom } from "@/pages/Media/MediaContacts/MediaContactEditor/atom";
import { useMount } from "ahooks";
import { useRecoilState } from "recoil";
import {
  contactTypeOps_atom,
  currecyOps_atom, languageOps_atom,
  priceTypeOps_atom,
  regionOps_atom
} from "../../Influencers/Eidtor/atom";

export const ConfigInit = () => {
  //#region  ---------------------- ↓ Editor ↓ ----------------------
  const [, setSocailLinkTypes] = useRecoilState(SociallinkTypes_atom);
  const [, setRegionOps] = useRecoilState(regionOps_atom);
  const [, setLanguageOps] = useRecoilState(languageOps_atom);
  const [, setContactTypeOps] = useRecoilState(contactTypeOps_atom);
  const [, setPriceTypeOps] = useRecoilState(priceTypeOps_atom);
  const [, setCurrecyOps] = useRecoilState(currecyOps_atom);

  useMount(async () => {
    const platformRes = await getPartnerPlatform({
      title: "",
      is_not_paged: 1,
      sort_by: "updated_at",
      sort_type: "desc",
    });
    const typeOptions = platformRes.data.list.map(({ id, title }) => ({
      label: title,
      value: String(id),
    }));
    setSocailLinkTypes(typeOptions);

    const countryRes = await getFiltersCountrys();
    const countryOps = countryRes.data.list.map(({ id, name }) => ({
      label: name,
      value: String(id),
    }));
    setRegionOps(countryOps);

    const langRes = await getPartnerLanguagesOptions();
    const langOps = langRes.data.list.map(({ id, name }) => ({ label: name, value: String(id) }));
    setLanguageOps(langOps);

    const contactRes = await getPartnerContactTypes();
    const contactOps = contactRes.data.list.map(({ id, title }) => ({
      label: title,
      value: String(id),
    }));
    setContactTypeOps(contactOps);

    const currencyRes = await getFiltersCurrency();
    const currencyOps = currencyRes.data.list.map(({ id, code }) => ({
      label: code,
      value: String(id),
    }));
    setCurrecyOps(currencyOps);

    const priceTypeRes = await getPartnerPriceList();
    const priceTypeOps = priceTypeRes.data.list.map(({ id, title }) => ({
      label: title,
      value: String(id),
    }));
    setPriceTypeOps(priceTypeOps);
  });

  //#endregion -------------------- ↑ Editor ↑ ----------------------
  return <div></div>;
};
