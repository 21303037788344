import { api } from "@/configs";

const { REACT_APP_TENANT_URL } = process.env;

/** 根据 token 获取用户信息 */
export const getTenantInfo = () =>
  api.request({
    method: "get",
    url: REACT_APP_TENANT_URL + "/account/info",
    headers: {},
  });

/** 获取租户权限码 */
export const getTenantPermissionCode = ({ tenantId, codes }) =>
  api.request({
    method: "get",
    url: REACT_APP_TENANT_URL + `/permissions/tenant/${tenantId}/permissions/list`,
    params: {
      codes,
    },
  });

/** 退出登录 */
export const postLogout = () =>
  api.request({
    method: "post",
    url: REACT_APP_TENANT_URL + "/account/logout",
    data: {}, // 没有这个就没有 Content-Type: application/json
  });
