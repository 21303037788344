import { Avatar, Text } from "@shopify/polaris";

import { AvatarName } from "@/components/EnhanceShopify/AvatarName";
import { RowHeader } from "@/components/EnhanceShopify/RowHeader";
import { useBomLocation } from "@/hooks/useBomLocation";

export const RowNav = ({ title, avatar, partnerID, platformID }) => {
  // const history = useHistory();
  const { origin } = useBomLocation();

  return (
    <RowHeader
      onClick={(e) => {
        e.stopPropagation();
        window.open(
          `${origin}/home/platforms/${platformID}/report/${partnerID ?? "no_related_partner"}`
        );
      }}
    >
      <AvatarName>
        <div className="avatar">
          <Avatar source={avatar} />
        </div>
        <Text variant="bodyMd" as="span" fontWeight="bold">
          {title}{" "}
        </Text>
      </AvatarName>
    </RowHeader>
  );
};
