import { atom } from "recoil";
import { v4 } from "uuid";

export const exportTikTok_atom = atom({
  key: v4(),
  default: false,
});

const tiktok_search_type = atom({
  key: v4(),
  default: "all",
});
const tiktok_current_page = atom({
  key: v4(),
  default: 1,
});
const tiktok_per_page = atom({
  key: v4(),
  default: 10,
});

const tiktok_filter_sort = atom({
  key: v4(),
  default: "updated_at|desc",
});

const tiktok_filter_search = atom({
  key: v4(),
  default: "",
});

const tiktok_filter_platsrDesOps = atom({
  key: v4(),
  default: [],
});

const tiktok_filter_platsrcOps = atom({
  key: v4(),
  default: [],
});

const tiktok_filter_platsrcSelect = atom({
  key: v4(),
  default: [],
});

const tiktok_filter_keywordDesOps = atom({
  key: v4(),
  default: [],
});

const tiktok_filter_keywordOps = atom({
  key: v4(),
  default: [],
});

const tiktok_filter_keywordSelect = atom({
  key: v4(),
  default: [],
});

const tiktok_filter_followerMin = atom({
  key: v4(),
  default: "",
});
const tiktok_filter_followerMax = atom({
  key: v4(),
  default: "",
});

const tiktok_filter_likesMin = atom({
  key: v4(),
  default: "",
});
const tiktok_filter_likesMax = atom({
  key: v4(),
  default: "",
});

const tiktok_filter_videosMin = atom({
  key: v4(),
  default: "",
});
const tiktok_filter_videosMax = atom({
  key: v4(),
  default: "",
});

// const tiktok_filter_xxDesOps = atom({
//   key: v4(),
//   default: [],
// });

// const tiktok_filter_xxOps = atom({
//   key: v4(),
//   default: [],
// });

// const tiktok_filter_xxSelect = atom({
//   key: v4(),
//   default: [],
// });

const tiktok_filter_creatorDesOps = atom({
  key: v4(),
  default: [],
});

const tiktok_filter_creatorOps = atom({
  key: v4(),
  default: [],
});

const tiktok_filter_creatorSelect = atom({
  key: v4(),
  default: [],
});

const tiktok_filter_creatime = atom({
  key: v4(),
  default: null,
});

export {
  tiktok_search_type,
  tiktok_current_page,
  tiktok_per_page,
  tiktok_filter_sort,
  tiktok_filter_search,
  tiktok_filter_platsrDesOps,
  tiktok_filter_platsrcOps,
  tiktok_filter_platsrcSelect,
  tiktok_filter_keywordDesOps,
  tiktok_filter_keywordOps,
  tiktok_filter_keywordSelect,
  tiktok_filter_followerMin,
  tiktok_filter_followerMax,
  tiktok_filter_likesMin,
  tiktok_filter_likesMax,
  tiktok_filter_videosMin,
  tiktok_filter_videosMax,
  tiktok_filter_creatorDesOps,
  tiktok_filter_creatorOps,
  tiktok_filter_creatorSelect,
  tiktok_filter_creatime,
};
