const CheckBox = [
  "Influencer id",
  "Country/region",
  "Social link",
  "Followers",
  "Views",
  "Tag",
  "Source",

  "Create by",
  "Creation time",
  "Update by",
  "Update time",
];

const YoutubeColumsOptions = CheckBox.map((str) => ({
  label: str,
  value: str,
}));

YoutubeColumsOptions.unshift({
  label: "Influencer name",
  value: "Influencer name",
  disabled: true,
});
YoutubeColumsOptions.push({
  label: "Operation",
  value: "Operation",
  disabled: true,
});

const Youtube_headings = YoutubeColumsOptions.map(({ value }) => ({ title: value }));

export { YoutubeColumsOptions, Youtube_headings };
