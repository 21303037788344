import Icon from "@ant-design/icons";
import styled from "styled-components";
const IconStyled = styled.span`
  padding-right: 4px;
  font-size: ${(props) => (props.size ? props.size : "inherit")};
`;
export const AntIcon = ({ component, size }) => {
  return (
    <IconStyled size={size}>
      <Icon component={component} />
    </IconStyled>
  );
};

export const AntPureIcon = ({ component }) => {
  return <Icon component={component} />;
};
