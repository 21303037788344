import { getPartnerPrincipalOptions, postAddTeamMembers } from "@/api/request";
import { EmptySearchResult, Filters, Modal, OptionList, Pagination, Stack } from "@shopify/polaris";
import { useMount } from "ahooks";
import { useCallback, useEffect, useState } from "react";

import { useFiltersPagination } from "@/customHooks/useFiltersPagination";
import _ from "lodash";

const teamsMembersEmptyState = 
  <EmptySearchResult
    title={"No members found"}
    description={"Try changing the filters or search term"}
    withIllustration
  />
;

// sort options
const teamsMembersSortOptions = [
  { label: "New partners (Min-Max)", value: "new_partners|asc" },
  { label: "New partners (Min-Max)", value: "new_partners|desc" },
  { label: "Partners reached out (Min-Max)", value: "partners_reached_out|asc" },
  { label: "Partners reached out (Max-Min)", value: "partners_reached_out|desc" },
  { label: "Partners on board (Min-Max)", value: "partners_on_board|asc" },
  { label: "Partners on board (Max-Min)", value: "partners_on_board|desc" },
  { label: "Response rate (Min-Max)", value: "respone_rate|asc" },
  { label: "Response rate (Max-Min)", value: "respone_rate|desc" },
];

const teamsMembersResourceName = {
  singular: "influencer",
  plural: "influencers",
};

const teamsMembersColumns = [
  "User code",
  "New partners",
  "Partners reached out",
  "Partners on board",
  "Response rate",
];
const teamsMembersColumnsOptions = teamsMembersColumns.map((str) => ({
  label: str,
  value: str,
}));
teamsMembersColumnsOptions.unshift({
  label: "User name",
  value: "User name",
  disabled: true,
});

const teamsMembers_headings = teamsMembersColumnsOptions.map(({ value }) => ({ title: value }));

// Add member 组件
const AddMember = ({ teamID, addMemberActive, toggleAddMemberActive, renderlist }) => {
  // 只需要请求万添加接口后,  刷新列表 renderlist 就好了
  const [memberOptions, setMemberOptions] = useState([]); // 总选项表 [ [obj] ]
  const [chunkMembers, setChunkMembers] = useState([]); // 前端分页选项列表 [ obj ]
  const [selectedMembers, setSelectedMembers] = useState([]); // 选中的列表 [ id ]
  // eslint-disable-next-line
  const [memberIDs, setMemberIDs] = useState([]); // 需要渲染的memberIDs [ id  ]
  // add members searh
  const [queryValue, setQueryValue] = useState(null);
  const handleQueryChange = useCallback((newValue) => {
    setQueryValue(newValue);
  }, []);
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue("");
  }, []);
  const { currentPage, setCurrentPage, totalPages, setTotalPages } = useFiltersPagination();

  // 下一页
  const Next = useCallback(() => {
    setCurrentPage((currentPage) => ++currentPage);
    const chunkmember = _.chunk(memberOptions, 10);
    const g = [...chunkmember][currentPage]?.map(({ id, name }) => ({
      label: name,
      value: String(id),
    }));
    setChunkMembers(g);
  }, [setCurrentPage, memberOptions, currentPage]);
  // 上一页
  const Previous = useCallback(() => {
    setCurrentPage((currentPage) => --currentPage);
    const chunkmember = _.chunk(memberOptions, 10);
    const g = chunkmember[currentPage - 2]?.map(({ id, name }) => ({
      label: name,
      value: String(id),
    }));
    setChunkMembers(g);
  }, [setCurrentPage, memberOptions, currentPage]);
  // 前端分页 初始化
  useEffect(() => {
    const chunkmember = _.chunk(memberOptions, 10);
    const g = chunkmember[0]?.map(({ id, name }) => ({
      label: name,
      value: String(id),
    }));
    setChunkMembers(g);
    setTotalPages(chunkmember?.length ? chunkmember.length : 1);
  }, [memberOptions, setTotalPages]);

  // 监听搜索输入:
  useEffect(() => {
    if (queryValue) {
      if (queryValue.trim()) {
        const filterRegex = new RegExp(queryValue, "i");

        const resultOptions = memberOptions.filter((option) => option.name.match(filterRegex));
        const chunkmember = _.chunk(resultOptions, 10);
        const g = chunkmember[0]?.map(({ id, name }) => ({
          label: name,
          value: String(id),
        }));
        setChunkMembers(g);

        setCurrentPage(1);
        setTotalPages(1);
      }
    } else {
      const chunkmember = _.chunk(memberOptions, 10);
      const g = chunkmember[0]?.map(({ id, name }) => ({
        label: name,
        value: String(id),
      }));
      setChunkMembers(g);
      setTotalPages(chunkmember?.length ? chunkmember.length : 1);
    }
  }, [queryValue, memberOptions, setCurrentPage, setTotalPages]);

  // Menmber List IndexTable
  useMount(async () => {
    const memberRes = await getPartnerPrincipalOptions();
    setMemberOptions(memberRes.data.list); // 存一份member总表
  });

  return (
    <Modal
      title="Select member"
      small
      open={addMemberActive}
      onClose={toggleAddMemberActive}
      primaryAction={{
        content: "Save",
        onAction: async () => {
          //  改变memberIDs 就行了, 来源是 选择的ids
          setMemberIDs([...selectedMembers]);
          setCurrentPage(1);
          const chunkmember = _.chunk(memberOptions, 10);
          const g = [...chunkmember][0]?.map(({ id, name }) => ({
            label: name,
            value: String(id),
          }));
          setChunkMembers(g);

          await postAddTeamMembers({ team_id: teamID, user_ids: selectedMembers });
          toggleAddMemberActive();
          renderlist();
          setSelectedMembers([]);
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction() {
            setCurrentPage(1);
            const chunkmember = _.chunk(memberOptions, 10);
            const g = [...chunkmember][0]?.map(({ id, name }) => ({
              label: name,
              value: String(id),
            }));
            setChunkMembers(g);
            toggleAddMemberActive();
          },
        },
      ]}
    >
      <Modal.Section>
        <div style={{ flex: 1 }}>
          <Filters
            queryValue={queryValue}
            filters={[]}
            appliedFilters={[]}
            onQueryChange={handleQueryChange}
            onQueryClear={handleQueryValueRemove}
            queryPlaceholder="Search by user name"
          />
        </div>
      </Modal.Section>
      <Modal.Section>
        <OptionList
          title=""
          onChange={setSelectedMembers}
          options={chunkMembers}
          selected={selectedMembers}
          allowMultiple
        />
        <Stack distribution="center">
          <Pagination
            label={
              <>
                {currentPage} / {totalPages}
              </>
            }
            hasPrevious={currentPage < 2 ? false : true}
            onPrevious={Previous}
            hasNext={currentPage >= totalPages ? false : true}
            onNext={Next}
          />
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

export {
  teamsMembers_headings,
  teamsMembersColumnsOptions,
  teamsMembersEmptyState,
  teamsMembersResourceName,
  teamsMembersSortOptions,
  AddMember,
};
