import { Button, ButtonGroup, IndexTable, Stack, Text } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { columsOptions, table_headings } from "./Column/columnOps";

import { AntPagination } from "@/components/AntToShopify/AntPagination";
import { ButtonLink } from "@/components/EnhanceShopify/ButtonLink";
import { CountryEmoji } from "@/components/EnhanceShopify/CountryEmoji";
import { RowHeader } from "@/components/EnhanceShopify/RowHeader";
import { CountryRegionListAtom } from "@/components/InitCountryRegions";
import { Region } from "@/pages/Partner/Platforms/Common/Region";
import { TagPopover } from "@/pages/Partner/Profiles/Common/TagPopover";
import { transformNumber } from "@/utils/tools";
import { useUnmount } from "ahooks";
import { Pagination } from "antd";
import { chunk } from "lodash";
import { useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { v4 } from "uuid";
import { columns_atom } from "./Column/atom";
import { selectedOutlets_atom } from "./SelectOutlets/atom";

// 这是联系人侧边栏和详情中 添加机构用的
export const AddOutlet = () => {
  const regions = useRecoilValue(CountryRegionListAtom);

  const [outlets, setOutlets] = useRecoilState(selectedOutlets_atom);

  const resetOutlets = useResetRecoilState(selectedOutlets_atom);
  const history = useHistory();

  const [tableList, setTableList] = useState([]);
  const [listCunk, setListCunk] = useState([]);
  const [tableHeading, setTableHeading] = useState(table_headings);
  const [columns] = useRecoilState(columns_atom);

  const [total, setTotal] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  // 动态加载列:
  useEffect(() => {
    const headings = table_headings.filter(({ title }) => columns.includes(title));
    setTableHeading(headings);
    window.dispatchEvent(new Event("resize"));
  }, [columns, outlets]);

  const rowMarkup = outlets?.map(({ id, address, name, media_website, platforms, tags }, index) => {
    // 总的序列对应关系
    let SN = [];
    const trafficArr = [];

    media_website?.traffic_in_the_main_country_region?.forEach((r, index) => {
      trafficArr.push({
        ...r,
        ...media_website?.traffic_in_the_main_country_percentage?.[index],
      });
    });
    const trafficList = trafficArr.map(({ name, code, value }, i) => {
      const img = regions.filter(({ iso2 }) => iso2 === code)?.[0];
      return (
        <Region
          key={i}
          name={
            <div style={{ display: "flex", alignItems: "center" }}>
              <CountryEmoji>{img?.emoji}</CountryEmoji>
              <span>
                {name} {value}
              </span>
            </div>
          }
        />
      );
    });
    // 字段展示
    const props = [
      // "Media outlet name",
      <RowHeader
        key={v4()}
        onClick={(e) => {
          e.stopPropagation();
          // history.push(`/home/${partnerID}/edit/report`);
          window.open(`${origin}/media/board/${id}`);
        }}
      >
        <Text variant="bodyMd" as="span" fontWeight="bold">
          {name}
        </Text>
      </RowHeader>,
      // "Country/region",
      <Region key={v4()} name={address?.country?.name} image={address?.country?.image} />,
      // "Media website",
      <ButtonLink key={v4()} link={media_website?.url}>
        {media_website?.url}
      </ButtonLink>,
      // "Monthly visits",
      <Text key={v4()}>
        {!media_website?.total_visits ? "" : transformNumber(media_website.total_visits)}
      </Text>,
      // "Traffic in the main country",
      trafficList,
      // "Tag",
      <TagPopover key={v4()} tags={tags} />,
      // "Operation",
      <ButtonGroup key={v4()} segmented>
        <Button
          plain
          onClick={() => {
            history.push(`/media/detail/${id}`);
          }}
        >
          Edit
        </Button>
        <Button
          plain
          onClick={() => {
            setOutlets((outlets) => {
              return outlets.filter((_, i) => i !== index);
            });
          }}
        >
          Remove
        </Button>
      </ButtonGroup>,
    ];
    columsOptions.forEach(({ value }, index) => {
      SN.push({ column: value, prop: props[index] });
    });
    let showCell = SN.filter(({ column }) => columns.includes(column));

    return (
      <IndexTable.Row id={id} key={v4()} position={index}>
        {showCell.map(({ prop }) => (
          <IndexTable.Cell key={v4()}>{prop}</IndexTable.Cell>
        ))}
      </IndexTable.Row>
    );
  });

  useEffect(() => {
    const pages = chunk(outlets, pageSize);
    setTableList(pages[0]); // 第一页
    setCurrentPage(1);
    setListCunk(pages); // 各个页数
    setTotal(outlets?.length); // 总数
  }, [outlets, pageSize]);

  useUnmount(() => {
    resetOutlets();
  });

  return (
    <>
      {tableList?.length ? (
        <IndexTable
          resourceName={{ singular: "item", plural: "items" }}
          itemCount={tableList.length}
          headings={tableHeading}
          selectable={false}
          lastColumnSticky
        >
          {rowMarkup}
        </IndexTable>
      ) : (
        <div style={{ padding: "8px 0" }} />
      )}
      {tableList?.length ? (
        <Stack distribution="trailing" alignment="center">
          <AntPagination>
            <Pagination
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              pageSize={pageSize}
              current={currentPage}
              pageSizeOptions={[5, 10, 15, 20]}
              showSizeChanger={true}
              total={total}
              onChange={(page, pageSize) => {
                setPageSize(pageSize);
                setCurrentPage(page);
                setTableList(listCunk[page - 1]);
              }}
            />
          </AntPagination>
        </Stack>
      ) : null}
    </>
  );
};
