import { Modal, TextField } from "@shopify/polaris";
import { useEffect, useMemo, useState } from "react";

export const AddKeywords = ({ input, setInput, active, toggle, cancel, save, loading }) => {
  const [disabledSave, setDisabledSave] = useState(true);

  const canSave = useMemo(() => !!input?.trim(), [input]);
  useEffect(() => {
    if (canSave) {
      setDisabledSave(false);
    } else {
      setDisabledSave(true);
    }
  }, [canSave]);

  return (
    <Modal
      open={active}
      onClose={() => {
        toggle();
        cancel();
      }}
      title="Create keyword"
      primaryAction={{
        content: "Save",
        disabled: disabledSave,
        loading,
        onAction() {
          save();
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction() {
            toggle();
            cancel();
          },
        },
      ]}
    >
      <Modal.Section>
        <TextField
          label="Keyword"
          multiline={3}
          value={input}
          onChange={setInput}
          helpText="If you want to enter multiple keywords, please enter multiple keywords through newlines."
        />
      </Modal.Section>
    </Modal>
  );
};
