const CheckBox = [
  "Influencer id",
  "Country/region",
  "Social link",
  "Followers",
  "Likes",
  "Lists",
  "Posts",
  "Source",

  "Create by",
  "Creation time",
  "Update by",
  "Update time",
];

const TwitterColumsOptions = CheckBox.map((str) => ({
  label: str,
  value: str,
}));

TwitterColumsOptions.unshift({
  label: "Influencer name",
  value: "Influencer name",
  disabled: true,
});

TwitterColumsOptions.push({
  label: "Operation",
  value: "Operation",
  disabled: true,
});

const Twitter_headings = TwitterColumsOptions.map(({ value }) => ({ title: value }));

export { TwitterColumsOptions, Twitter_headings };
