import styled from "styled-components";

export const FilterBox = styled.div`
  display: flex;
  padding: 16px 16px 8px;
  .Polaris-Filters {
    flex: 1;
    padding-left: 8px;
  }
`;

export const FilterNarrow = styled.div`
  display: flex;
  padding: 16px 16px 8px;
  margin-bottom: 8px;
  .Polaris-Filters {
    flex: 1;
  }
`;
