import styled from "styled-components";

export const ColumnStyled = styled.span`
  .Polaris-Button__Content {
    color: rgba(92, 95, 98, 1);
    .Polaris-Button__Icon {
      .Polaris-Icon {
        .Polaris-Icon__Svg {
          fill: rgba(92, 95, 98, 1);
        }
      }
    }
  }
`;
