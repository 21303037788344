import { atom, selector } from "recoil";

import { v4 } from "uuid";

export const prices_atom = atom({
  key: v4(),
  default: [],
});
export const pricesEditing_atom = selector({
  key: v4(),
  get: ({ get }) => {
    const data = get(prices_atom);
    return data.some((o) => o.isEdit);
  },
});
