import { formatSeconds, transformNumber } from "@/utils/tools";
import { Button, Card, Icon, Stack, Text, Tooltip } from "@shopify/polaris";
import { CaretDownMinor, CaretUpMinor, ViewMinor } from "@shopify/polaris-icons";
import { useEffect, useState } from "react";

import twitch_face from "@/assets/twitch_face.jpg";
import MultiClamp from "react-multi-clamp";
import styled from "styled-components";
import { v4 } from "uuid";

const ItemBox = styled.div`
  margin-bottom: 16px;
`;

const ImgBox = styled.div`
  position: relative;
  width: 182px;
  height: 104px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid rgba(241, 242, 243, 1);

  img {
    height: 100%;
    width: 100%;
  }
`;

const PlayTime = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  bottom: 0px;
  right: 0px;
  border-radius: 4px;
  padding: 0 4px;
`;

const TextBox = styled.div`
  width: 182px;
`;

export const VideoGallery = ({ contents, title, showNum = 9 }) => {
  const [allList, setAllList] = useState([]); // 总数
  const [contentList, setContentList] = useState([]); // 展示数量
  useEffect(() => {
    setAllList(contents);
    const shortCut = contents?.slice(0, showNum);
    setContentList(shortCut);
  }, [contents, showNum]);

  return (
    <Card.Section>
      <Stack spacing="tight" distribution="fillEvenly">
        {contentList.map(
          ({
            url,
            contentCategory,
            authorUsername,
            contentLengthSecond,
            viewCount,
            postDate,
            thumbnailUrl,
          }) => {
            function getPostDate(str) {
              if (typeof str !== "string") return;
              return str.replace(/[A-Z]/g, " ").trim().split(" ")[0];
            }
            return (
              <ItemBox key={v4()}>
                <Stack vertical spacing="extraTight">
                  <Stack.Item>
                    <ImgBox>
                      <img
                        alt="post"
                        src={thumbnailUrl ? thumbnailUrl : twitch_face}
                        onClick={() => window.open(url)}
                      />
                      <PlayTime>{formatSeconds(contentLengthSecond)}</PlayTime>
                    </ImgBox>
                  </Stack.Item>
                  <Stack.Item>
                    <TextBox>
                      <Tooltip content={contentCategory + " - " + authorUsername}>
                        <MultiClamp ellipsis="..." clamp={3}>
                          {contentCategory + "-" + authorUsername}
                        </MultiClamp>
                      </Tooltip>
                    </TextBox>
                  </Stack.Item>
                  <Stack.Item>
                    <Stack>
                      <Stack.Item>
                        <Stack spacing="tight">
                          <Icon source={ViewMinor} color="subdued" />
                          <Text variant="bodyMd" as="span" color="subdued">
                            {transformNumber(viewCount)}
                          </Text>
                        </Stack>
                      </Stack.Item>
                    </Stack>
                  </Stack.Item>
                  <Stack.Item>
                    <Text variant="bodyMd" as="span" color="subdued">
                      {getPostDate(postDate)}
                    </Text>
                  </Stack.Item>
                </Stack>
              </ItemBox>
            );
          }
        )}
      </Stack>
      {contentList?.length !== allList?.length && allList?.length > showNum && (
        <Stack distribution="center">
          <Button
            plain
            removeUnderline
            icon={CaretDownMinor}
            onClick={() => {
              setContentList([...allList]);
            }}
          >
            show all
          </Button>
        </Stack>
      )}
      {contentList?.length === allList?.length && allList?.length > showNum && (
        <Stack distribution="center">
          <Button
            plain
            removeUnderline
            icon={CaretUpMinor}
            onClick={() => {
              setContentList([...allList.slice(0, showNum)]);
            }}
          >
            Show less
          </Button>
        </Stack>
      )}
    </Card.Section>
  );
};
