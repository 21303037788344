import {
  getFiltersCountrys,
  getFiltersCurrency,
  getPartnerContactTypes,
  getPartnerLanguagesOptions,
  getPartnerPlatform,
  getPartnerPriceList,
  getPartnersFilterInit,
  postImportPartners
} from "@/api/request";
import {
  ErrorProgress,
  FakeProgress,
  FillProgress
} from "@/components/EnhanceShopify/UploadProgress";
import {
  Banner,
  Button,
  DropZone,
  List,
  Modal,
  Page,
  Stack,
  Text,
  Thumbnail
} from "@shopify/polaris";
import { DeleteMinor, ExportMinor, ImportMinor, NoteMinor } from "@shopify/polaris-icons";
import { useMount, useToggle } from "ahooks";
import { useCallback, useEffect, useState } from "react";
import { pubExportActionAtom } from "../PartnersTabs/atom";
import {
  contactTypeOps_atom,
  currecyOps_atom,
  editorActive_atom,
  languageOps_atom,
  priceTypeOps_atom,
  regionOps_atom
} from "./Eidtor/atom";

import { useRefrehTable } from "@/customHooks/useRefrehTable";
import { useRecoilToggle } from "@/hooks/useRecoilToggle";
import { SociallinkTypes_atom } from "@/pages/Media/MediaContacts/MediaContactEditor/atom";
import { downLoadFile } from "@/utils/downLoadFile";
import { useRecoilState } from "recoil";
import { Platforms } from "../Platforms";
import { Plat_tab_init } from "../Platforms/atom/commonAtom";
import { toggleImportAtom } from "../Platforms/import/atom";
import { epxortINS_atom } from "../Platforms/InstagramTable/atom";
import { exportTikTok_atom } from "../Platforms/TikTokTable/atom";
import { exportTwitch_atom } from "../Platforms/TwitchTable/atom";
import { exportTwitter_atom } from "../Platforms/TwitterTable/atom";
import { exportYTB_atom } from "../Platforms/YoutubeTable/atom";
import { influencer_refresh } from "./atom";
import { Eidtor } from "./Eidtor/Eidtor";

export const Discoveries = () => {
  // const history = useHistory();
  // const { handleRefresh } = useRefrehTable(Craete_Lead_Atom); // 刷新关联的table
  // const { handleGlobalToast } = useGlobalToast(); // 全局toast

  //#region 导入伙伴弹窗

  const [importActive, setImportActive] = useRecoilState(toggleImportAtom);
  const toggleImportActive = useCallback(() => {
    setImportActive((active) => !active);
  }, [setImportActive]);

  const [isUploaded, setIsUploaded] = useState(false); // 是否上传完毕
  const [isUploadedErr, setIsUploadedErr] = useState(false); // 是否上传错误
  const [successInfo, setSuccessInfo] = useState({ success: 0, fail: 0, link: "" });

  const [templateFileUrl, setTemplateFileUrl] = useState(""); // 下载模版连接初始化

  useMount(async () => {
    const options = await getPartnersFilterInit();
    setTemplateFileUrl(options.data.partner_template); // 给外层的import modal 模板链接
  });

  const { toggle: refreshInfluencers } = useRecoilToggle(influencer_refresh);

  const [file, setFile] = useState(); // 上传框里是否有文件
  const [disableNextButton, setDisableNextButton] = useState(true); // 没有文件则禁用next按钮无法进入下一步
  useEffect(() => {
    if (file) {
      setDisableNextButton(false);
    } else {
      setDisableNextButton(true);
    }
  }, [file]);
  // eslint-disable-next-line
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const hasError = rejectedFiles.length > 0;
  const handleDropZoneDrop = useCallback((_dropFiles, acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const fileUpload = !file && <DropZone.FileUpload actionTitle="Add file" />;
  const uploadedFile = file && (
    <Stack alignment="center">
      <Stack.Item fill>
        <Stack>
          <Thumbnail
            size="small"
            alt={file.name}
            source={
              validImageTypes.includes(file.type) ? window.URL.createObjectURL(file) : NoteMinor
            }
          />
          <div>
            {file.name}
            <Text variant="bodySm" as="p">
              {file.size} bytes
            </Text>
          </div>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <div
          style={{ paddingRight: 16 }}
          onClick={(e) => {
            e.stopPropagation();
            setFile();
          }}
        >
          <Button icon={DeleteMinor} plain destructive />
        </div>
      </Stack.Item>
    </Stack>
  );
  const errorMessage = hasError && (
    <Banner title="The following images couldn't be uploaded:" status="critical">
      <List type="bullet">
        {rejectedFiles.map((file, index) => (
          <List.Item key={index}>
            {`"${file.name}" is not supported. File type must be .gif, .jpg, .png or .svg.`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );
  //#endregion

  //#region 上传伙伴模板文件弹窗
  const [uploadActive, { toggle: toggleUploadActive }] = useToggle();
  const handleClearUploadStatus = useCallback(() => {
    setIsUploaded(false);
    setIsUploadedErr(false);
    setFile();
  }, []);
  // Drop 添加文件modal
  const ImportModal = (
    <Modal
      open={importActive}
      onClose={toggleImportActive}
      title="Import influencer"
      primaryAction={{
        content: "Next",
        disabled: disableNextButton,
        onAction: async () => {
          toggleImportActive();
          toggleUploadActive();
          let formData = new FormData();
          formData.append("file", file);
          try {
            await new Promise((resolve) => {
              setTimeout(() => {
                resolve(1);
              }, 2000);
            });
            const { data } = await postImportPartners(formData);
            setSuccessInfo({ success: data.succeeded, fail: data.failed, link: data.file_link });
            refreshInfluencers();
            setIsUploaded(true);
          } catch (error) {
            setIsUploadedErr(true);
          }
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction() {
            setFile();
            toggleImportActive();
          },
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical>
          {errorMessage}
          <DropZone
            allowMultiple={false}
            onDrop={handleDropZoneDrop}
            accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            type="file"
          >
            {uploadedFile}
            {fileUpload}
          </DropZone>
          <Text variant="bodyMd" as="span" color="subdued">
            <List type="bullet">
              <List.Item>
                You can&nbsp;
                <Button
                  plain
                  onClick={() => {
                    downLoadFile(templateFileUrl, "Template", false);
                  }}
                >
                  click here
                </Button>
                &nbsp;to download the template file!
              </List.Item>
              <List.Item>The size of the uploaded file should not exceed 50M.</List.Item>
            </List>
          </Text>
        </Stack>
      </Modal.Section>
    </Modal>
  );
  const MatchProgressStatus = (isUploadedErr, isUploaded) => {
    // 上传错误
    if (isUploadedErr) {
      return <ErrorProgress />;
    }
    // 上传成功 or 上传中
    if (isUploaded) {
      return <FillProgress successInfo={successInfo} />;
    } else {
      return <FakeProgress />;
    }
  };
  // Progress 上传modal 查看进度
  const UploadInfoModal = (
    <Modal
      open={uploadActive}
      onClose={() => {
        toggleUploadActive();
        handleClearUploadStatus();
      }}
      title="Import influencer"
      primaryAction={{
        content: "Done",
        disabled: !isUploaded,
        loading: !isUploaded,
        onAction() {
          toggleUploadActive();
          handleClearUploadStatus();
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction() {
            toggleUploadActive();
            handleClearUploadStatus();
          },
        },
      ]}
    >
      <Modal.Section>{MatchProgressStatus(isUploadedErr, isUploaded)}</Modal.Section>
    </Modal>
  );
  //#endregion

  // -------------------- Influencers 改造 --------------------
  // const history = useHistory();
  // const handleAddPartner = useCallback(() => history.push(`/home/fresh/add`), [history]);
  const { handleRefresh: exportPartners } = useRefrehTable(pubExportActionAtom);
  const { handleRefresh: exportYouTuBe } = useRefrehTable(exportYTB_atom);
  const { handleRefresh: exportINS } = useRefrehTable(epxortINS_atom);
  const { handleRefresh: exportTikTok } = useRefrehTable(exportTikTok_atom);
  const { handleRefresh: exportTwitch } = useRefrehTable(exportTwitch_atom);
  const { handleRefresh: exportTwitter } = useRefrehTable(exportTwitter_atom);

  // 导入伙伴弹窗
  const { toggle: toggleActive } = useRecoilToggle(toggleImportAtom);

  //#region  ---------------------- ↓ 平台导出 ↓ ----------------------
  // 选中平台的 tab index
  const [influencerTab] = useRecoilState(Plat_tab_init);

  // 通知各个列表中的导出函数执行导出操作, 因为各自的筛选项的值在各自的组件中收集的, 尽量降低耦合
  const matchExportTab = useCallback(
    (tabIndex) => {
      switch (tabIndex) {
        case 0:
          return exportPartners;
        case 1:
          return exportYouTuBe;
        case 2:
          return exportINS;
        case 3:
          return exportTikTok;
        case 4:
          return exportTwitch;
        case 5:
          return exportTwitter;
        default:
          return exportPartners;
      }
    },
    [exportPartners, exportYouTuBe, exportINS, exportTikTok, exportTwitch, exportTwitter]
  );

  //#endregion -------------------- ↑ 平台导出 ↑ ----------------------

  //#region  ---------------------- ↓ Editor ↓ ----------------------
  const { active: EditorActive, toggle: toggleEditor } = useRecoilToggle(editorActive_atom);
  const [, setSocailLinkTypes] = useRecoilState(SociallinkTypes_atom);
  const [, setRegionOps] = useRecoilState(regionOps_atom);
  const [, setLanguageOps] = useRecoilState(languageOps_atom);
  const [, setContactTypeOps] = useRecoilState(contactTypeOps_atom);
  const [, setPriceTypeOps] = useRecoilState(priceTypeOps_atom);
  const [, setCurrecyOps] = useRecoilState(currecyOps_atom);

  useMount(async () => {
    const platformRes = await getPartnerPlatform({
      title: "",
      is_not_paged: 1,
      sort_by: "updated_at",
      sort_type: "desc",
    });
    const typeOptions = platformRes.data.list.map(({ id, title }) => ({
      label: title,
      value: String(id),
    }));
    setSocailLinkTypes(typeOptions);

    const countryRes = await getFiltersCountrys();
    const countryOps = countryRes.data.list.map(({ id, name }) => ({
      label: name,
      value: String(id),
    }));
    setRegionOps(countryOps);

    const langRes = await getPartnerLanguagesOptions();
    const langOps = langRes.data.list.map(({ id, name }) => ({ label: name, value: String(id) }));
    setLanguageOps(langOps);

    const contactRes = await getPartnerContactTypes();
    const contactOps = contactRes.data.list.map(({ id, title }) => ({
      label: title,
      value: String(id),
    }));
    setContactTypeOps(contactOps);

    const currencyRes = await getFiltersCurrency();
    const currencyOps = currencyRes.data.list.map(({ id, code }) => ({
      label: code,
      value: String(id),
    }));
    setCurrecyOps(currencyOps);

    const priceTypeRes = await getPartnerPriceList();
    const priceTypeOps = priceTypeRes.data.list.map(({ id, title }) => ({
      label: title,
      value: String(id),
    }));
    setPriceTypeOps(priceTypeOps);
  });

  //#endregion -------------------- ↑ Editor ↑ ----------------------

  return (
    <Page
      title="Influencers"
      fullWidth
      primaryAction={{ content: "Add influencer", onAction: toggleEditor }}
      secondaryActions={[
        {
          content: "Export",
          onAction: matchExportTab(influencerTab),
          icon: ExportMinor,
        },
        {
          content: "Import",
          onAction: toggleActive,
          icon: ImportMinor,
        },
      ]}
    >
      {ImportModal}
      {UploadInfoModal}
      <Platforms />
      {influencerTab !== 0 && (
        <Eidtor title="Edit influencer basic profile" visible={EditorActive} />
      )}
    </Page>
  );
};
