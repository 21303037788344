import "./header.scss";

import { topbarName1stChar } from "@/utils/topbarUserName";
import { CircleRightMajor, CustomerPlusMajor, ProfileMajor } from "@shopify/polaris-icons";
import { Suspense, useCallback, useMemo, useState } from "react";

import { postLogout } from "@/api/api_tenant";
import { authAtom } from "@/global/authAtom";
import { userAtom } from "@/global/userAtom";
import { cookieScope } from "@/utils/cookieScope";
import { openNewWindow } from "@/utils/openNewWindow";
import { qstring } from "@/utils/qstring";
import { TopBar } from "@shopify/polaris";
import { useAtom } from "jotai";

export default function Header(props) {
  const { REACT_APP_ADMIN_URL, REACT_APP_PRODUCT_URL } = process.env;

  const token = cookieScope.getToken();
  const id = cookieScope.getTenantId();

  const [userData] = useAtom(userAtom);
  const [authCodes] = useAtom(authAtom); // 直接拿到权限码数组
  const isAdmin = authCodes["pmp/settings/admin/all"];

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
    []
  );

  // 删除函数 : 先声明, 再使用
  const handleYesLogout = useCallback(async () => {
    try {
      await postLogout();
    } finally {
      cookieScope.removeToken();
      cookieScope.removeTenantId();
      window.location.href = `${REACT_APP_PRODUCT_URL}`;
    }
  }, [REACT_APP_PRODUCT_URL]);

  const Actions = useMemo(() => {
    const renderActions = [
      {
        key: "settings",
        content: "Account settings",
        icon: ProfileMajor,
        onAction: () => {
          openNewWindow(
            `${REACT_APP_PRODUCT_URL}/redirect?` +
              qstring.stringify({ token, id, goto: "UserCenter" })
          );
        },
      },
      {
        key: "is_admin",
        content: "Enter administrator center",
        icon: CustomerPlusMajor,
        onAction: () => {
          window.open(`${REACT_APP_ADMIN_URL}/redirect?` + qstring.stringify({ token, id }));
        },
      },
      {
        key: "log_out",
        content: "Log out",
        icon: CircleRightMajor,
        onAction: handleYesLogout,
      },
    ].filter((o) => (isAdmin ? true : o.key !== "is_admin"));

    return renderActions;
  }, [token, id, isAdmin, handleYesLogout, REACT_APP_ADMIN_URL, REACT_APP_PRODUCT_URL]);

  const userMenuMarkup = (
    <Suspense fallback={<></>}>
      <TopBar.UserMenu
        actions={[
          {
            items: Actions,
          },
        ]}
        avatar={userData?.avatar ?? ""}
        name={userData?.name ?? ""}
        // detail="Jaded Pixel"
        initials={topbarName1stChar(userData?.name ?? "")}
        open={isUserMenuOpen}
        onToggle={toggleIsUserMenuOpen}
      />
    </Suspense>
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      onNavigationToggle={props.toggleMobileNavigationActive}
    />
  );
  return topBarMarkup;
}
