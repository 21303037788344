import { Button, Card, Icon, Stack, Text, Tooltip } from "@shopify/polaris";
import { CaretDownMinor, CaretUpMinor, HeartMajor, ViewMinor } from "@shopify/polaris-icons";
import { useEffect, useState } from "react";

import YouTube_face from "@/assets/YouTube_face.png";
import { transformNumber } from "@/utils/tools";
import MultiClamp from "react-multi-clamp";
import styled from "styled-components";
import { v4 } from "uuid";

const ItemBox = styled.div`
  margin-bottom: 16px;
`;

const ImgBox = styled.div`
  position: relative;
  width: 182px;
  height: 104px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid rgba(241, 242, 243, 1);

  img {
    height: 100%;
    width: 100%;
  }

  /* &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px;
    border: 5px solid rgba(0, 0, 0, 0.3);
    border-radius: 100%;
    -webkit-transition: border-color 300ms;
    -moz-transition: border-color 300ms;
    transition: border-color 300ms;
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    margin: -20px 0 0 -5px;
    border-left: 20px solid rgba(0, 0, 0, 0.3);
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    -webkit-transition: border-color 300ms;
    -moz-transition: border-color 300ms;
    transition: border-color 300ms;
  }
  &:hover,
  &:focus {
    &:before {
      border-color: rgba(255, 255, 255, 0.3);
      box-shadow: 1px 1px 50px white;
      background-color: rgba(255, 255, 255, 0.3);
    }
    &:after {
      border-left-color: rgba(255, 255, 255, 0.7);
    }
  } */
`;
// eslint-disable-next-line
const PlayTime = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  bottom: 0px;
  right: 0px;
  border-radius: 4px;
  padding: 0 4px;
`;

const TextBox = styled.div`
  width: 182px;
`;

export const VideoGallery = ({ contents, title, showNum = 9 }) => {
  const [allList, setAllList] = useState([]); // 总数
  const [contentList, setContentList] = useState([]); // 展示数量

  useEffect(() => {
    setAllList(contents);
    const shortCut = contents?.slice(0, showNum);
    setContentList(shortCut);
  }, [contents, showNum]);

  return (
    <Card.Section>
      <Stack spacing="tight" distribution="fillEvenly">
        {contentList.map(({ title, url, likeCount, viewCount, postDate, thumbnailUrl }) => {
          function getPostDate(str) {
            if (typeof str !== "string") return;
            return str.replace(/[A-Z]/g, " ").trim().split(" ")[0];
          }
          return (
            <ItemBox key={v4()}>
              <Stack vertical spacing="extraTight">
                <Stack.Item>
                  <ImgBox>
                    <img
                      alt="post"
                      src={thumbnailUrl ? thumbnailUrl : YouTube_face}
                      onClick={() => window.open(url)}
                    />
                    {/* <PlayTime>7:3011</PlayTime> */}
                  </ImgBox>
                </Stack.Item>
                <Stack.Item>
                  <TextBox>
                    <Tooltip content={title}>
                      <MultiClamp ellipsis="..." clamp={3}>
                        {title}
                      </MultiClamp>
                    </Tooltip>
                  </TextBox>
                </Stack.Item>
                <Stack.Item>
                  <Stack>
                    <Stack.Item>
                      <Stack spacing="extraTight">
                        <Icon source={ViewMinor} color="subdued" />
                        <Text variant="bodyMd" as="span" color="subdued">
                          {transformNumber(viewCount)}
                        </Text>
                      </Stack>
                    </Stack.Item>
                    <Stack.Item>
                      <Stack spacing="extraTight">
                        <div style={{ transform: "scale(0.7)" }}>
                          <Icon source={HeartMajor} color="subdued" />
                        </div>
                        <Text variant="bodyMd" as="span" color="subdued">
                          {transformNumber(likeCount)}
                        </Text>
                      </Stack>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
                <Stack.Item>
                  <Text variant="bodyMd" as="span" color="subdued">
                    {getPostDate(postDate)}
                  </Text>
                </Stack.Item>
              </Stack>
            </ItemBox>
          );
        })}
      </Stack>
      {contentList?.length !== allList?.length && allList?.length > showNum && (
        <Stack distribution="center">
          <Button
            plain
            removeUnderline
            icon={CaretDownMinor}
            onClick={() => {
              setContentList([...allList]);
            }}
          >
            Show all
          </Button>
        </Stack>
      )}
      {contentList?.length === allList?.length && allList?.length > showNum && (
        <Stack distribution="center">
          <Button
            plain
            removeUnderline
            icon={CaretUpMinor}
            onClick={() => {
              setContentList([...allList.slice(0, showNum)]);
            }}
          >
            Show less
          </Button>
        </Stack>
      )}
    </Card.Section>
  );
};
