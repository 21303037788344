import { Button, ButtonGroup, Stack, Text, TextField } from "@shopify/polaris";
import { useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { contactsEditing_atom, contacts_atom } from "./atom";

import { SelectPlus } from "@/components/EnhanceShopify/SelectPlus";
import { SpaceHeight } from "@/components/EnhanceShopify/SpaceHeight";
import styled from "styled-components";
import { v4 } from "uuid";
import { contactTypeOps_atom } from "../../Eidtor/atom";

const titles = ["Contact type", "Contact account", "Operation"];

export const OtherContact = () => {
  const [contacts, setCcontacts] = useRecoilState(contacts_atom);
  const isAddOrEdit = useRecoilValue(contactsEditing_atom);

  const handleAdd = () => {
    setCcontacts((arr) => {
      return [
        ...arr,
        {
          type: "",
          contact: "",
          isEdit: true,
          isNew: true,
        },
      ];
    });
  };

  return (
    <>
      <Table>
        <Header>
          <Row className="header">
            {titles.map((head) => {
              return (
                <Cell key={v4()}>
                  <Text variant="bodyMd" as="span" fontWeight="bold">
                    {head}
                  </Text>
                </Cell>
              );
            })}
          </Row>
        </Header>
        <Body>
          {contacts.map(({ type, contact, isEdit }, index) => {
            if (isEdit) return <EditMode key={v4()} type={type} contact={contact} index={index} />;
            else return <TextMode key={v4()} type={type} contact={contact} index={index} />;
          })}
        </Body>
      </Table>
      <div style={{ height: 8 }} />
      <Stack distribution="">
        <Button plain disabled={isAddOrEdit} onClick={handleAdd}>
          Add contact
        </Button>
      </Stack>
    </>
  );
};

function TextMode({ type, contact, index }) {
  const [contactTypes] = useRecoilState(contactTypeOps_atom);

  const [, setCcontacts] = useRecoilState(contacts_atom);

  const isAddOrEdit = useRecoilValue(contactsEditing_atom);

  const matchPlatform = (type) => {
    const hasValue = contactTypes.map(({ value }) => value).includes(type);
    if (hasValue) {
      return contactTypes.filter(({ value }) => value === type)[0].label;
    } else {
      return "";
    }
  };

  const handleEdit = (index) => () => {
    setCcontacts((arr) => {
      const newArr = [...arr].map((o, i) => {
        if (i === index) {
          return {
            ...o,
            isEdit: true,
          };
        }
        return o;
      });
      return newArr;
    });
  };
  const handleDelete = (index) => () => {
    setCcontacts((arr) => {
      const newArr = [...arr].filter((o, i) => {
        if (i !== index) {
          return true;
        }
        return false;
      });
      return newArr;
    });
  };
  return (
    <Row>
      <Cell className="xSelect">{matchPlatform(type)}</Cell>
      <Cell className="xContacts">{contact}</Cell>
      <Cell>
        <ButtonGroup segmented>
          <Button plain disabled={isAddOrEdit} onClick={handleEdit(index)}>
            Edit
          </Button>
          <Button plain disabled={isAddOrEdit} onClick={handleDelete(index)}>
            delete
          </Button>
        </ButtonGroup>
      </Cell>
    </Row>
  );
}

function EditMode({ type, contact, index }) {
  const [contactTypes] = useRecoilState(contactTypeOps_atom);
  const [, setCcontacts] = useRecoilState(contacts_atom);
  const [selectContacts, setSelectContacts] = useState(type ? [type] : []);

  const [contactInput, setContactInput] = useState(contact ? contact : "");

  const [typeError, setTypeError] = useState("");
  const [contactError, setContactError] = useState("");

  // 是否存在校验错误信息
  const hasErr = useMemo(() => !!typeError || !!contactError, [typeError, contactError]);

  // 撑开每个 td 的间隔
  const SpaceH = <SpaceHeight height={28} />;

  // 取消
  const handleCancel = () => {
    setCcontacts((arr) => {
      const newArr = arr.filter((o) => !o.isNew);

      return newArr.map((o) => ({
        ...o,
        isEdit: false,
      }));
    });
  };

  // 保存
  const handleSave = (index) => () => {
    if (!selectContacts.toString()) {
      setTypeError("Required!");
    }

    if (!contactInput?.trim()) {
      setContactError("Required!");
    }

    if (!selectContacts.toString() || !contactInput?.trim()) return;

    const updateobj = {
      type: selectContacts.toString(),
      contact: contactInput,
      isEdit: false,
      isNew: false,
    };
    setCcontacts((arr) => {
      const newArr = [...arr].map((o, i) => {
        if (i === index) {
          return updateobj;
        }
        return o;
      });
      return newArr;
    });
  };

  return (
    <Row>
      <Cell className="xSelect">
        <SelectPlus
          options={contactTypes}
          select={selectContacts}
          onSelect={setSelectContacts}
          error={typeError}
          onBlur={() => {
            if (selectContacts.toString()) {
              setTypeError("");
            }
          }}
        />
        {hasErr && SpaceH}
      </Cell>
      <Cell className="xContacts">
        <TextField
          autoComplete="off"
          value={contactInput}
          onChange={setContactInput}
          error={contactError}
          onBlur={() => {
            if (contactInput?.trim()) {
              setContactError("");
            }
          }}
        />
        {hasErr && SpaceH}
      </Cell>
      <Cell>
        <ButtonGroup segmented>
          <Button plain onClick={handleSave(index)}>
            Save
          </Button>
          <Button plain onClick={handleCancel}>
            Cancel
          </Button>
        </ButtonGroup>
      </Cell>
    </Row>
  );
}

const Table = styled.table`
  width: 100%;
`;

const Header = styled.thead`
  border-bottom: 1px solid #f0f0f0;
`;

const Body = styled.tbody``;

const Row = styled.tr`
  border-bottom: 1px solid #f0f0f0;
  &:not(.header):hover {
    background-color: rgba(241, 242, 243, 1);
  }
  transition: all 0.2s;
`;

const Cell = styled.td`
  padding: 8px 4px;
  position: relative;
  .Polaris-Labelled__Error {
    position: absolute;
  }
  /* 选择栏宽度 */
  &.xSelect {
    width: 30%;
  }
  /* 输入栏宽度 */
  &.xContacts {
    width: 57%;
  }
`;
