export function initCooperTasks() {
  const tasks = [
    {
      start: new Date(),
      end: new Date(),
      name: "Loading... there is no schedules.",
      id: "Loading...",
      progress: 0,
      type: "task",
    },
  ];
  let res = tasks.map((o) => ({ ...o, styles: ganttTaskStyle }));
  return res;
}

export function initCampaignTasks() {
  const tasks = [
    {
      start: new Date(),
      end: new Date(),
      name: "Loading... there is no schedules.",
      id: "Loading...",
      progress: 0,
      type: "task",
    },
  ];
  let res = tasks.map((o) => ({ ...o, styles: ganttTaskStyle }));
  return res;
}

export function initPromotionTasks() {
  const tasks = [
    {
      start: new Date(),
      end: new Date(),
      name: "Loading... there is no schedules.",
      id: "Loading...",
      progress: 0,
      type: "milestone",
    },
  ];
  let res = tasks.map((o) => ({ ...o, styles: ganttTaskStyle }));
  return res;
}

export const ganttTaskStyle = {
  backgroundColor: "rgba(68, 157, 167, 1)",
  backgroundSelectedColor: "rgba(52, 124, 132, 1)",
};
/** 活动状态/合作状态
 *  [1, "Not start"], 	rgba(228, 229, 231, 1)
    [2, "In progress"], rgba(164, 232, 242, 1)
    [3, "Completed"],   rgba(174, 233, 209, 1)
    [4, "Expired"],     rgba(254, 211, 209, 1)
   
    推广状态
 *  [1, "Unpublished"], rgba(228, 229, 231, 1)
    [2, "Published"],   rgba(174, 233, 209, 1)
    [3, "Offline"],     rgba(254, 211, 209, 1)
 * 
 */
export const clacStatusColor = (type) => (status) => {
  // campaign & cooperation
  if (type === "Campaign/Cooperation") {
    if (status === 1) return { backgroundColor: "rgba(228, 229, 231, 1)" };
    if (status === 2) return { backgroundColor: "rgba(164, 232, 242, 1)" };
    if (status === 3) return { backgroundColor: "rgba(174, 233, 209, 1)" };
    if (status === 4) return { backgroundColor: "rgba(254, 211, 209, 1)" };
  }
  if (type === "Promotion") {
    if (status === 1) return { backgroundColor: "rgba(228, 229, 231, 1)" };
    if (status === 2) return { backgroundColor: "rgba(174, 233, 209, 1)" };
    if (status === 3) return { backgroundColor: "rgba(254, 211, 209, 1)" };
  }

  return null;
};
