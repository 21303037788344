const CheckBox = [
  "Influencer id",
  "Social link",
  "Language",
  "Followers",
  "Tag",
  "Source",

  "Create by",
  "Creation time",
  "Update by",
  "Update time",
];

const TwitchColumsOptions = CheckBox.map((str) => ({
  label: str,
  value: str,
}));

TwitchColumsOptions.unshift({
  label: "Influencer name",
  value: "Influencer name",
  disabled: true,
});
TwitchColumsOptions.push({
  label: "Operation",
  value: "Operation",
  disabled: true,
});

const Twitch_headings = TwitchColumsOptions.map(({ value }) => ({ title: value }));

export { TwitchColumsOptions, Twitch_headings };
