import { useCallback, useState } from "react";

// 准备废弃! 用 useTabs 替代

// tabs 切换逻辑
// tabContents 传参为自定义 tab 内容 : [ String ], 可选传参 iden: String
// ! 如果同一个组件内使用多个tabs组件,钩子请传入标识字符串,目的是为了保证id唯一性
export function useTabsChangeLogic(tabContents, iden = "") {
  const tabs = tabContents.map((tab) => ({
      id: `_id_${tab}_${iden}`,
      content: tab,
      panelID: `_panelID_${tab}_${iden}`,
    }));
  // tabs选择处理函数和状态
  const [tabSelected, setTabSelected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex) => setTabSelected(selectedTabIndex), []);

  return { tabs, tabSelected, setTabSelected, handleTabChange };
}
