import { Button, Stack, Text } from "@shopify/polaris";
import { useEffect, useMemo, useState } from "react";

import { TimelineBox } from "@/components/AntToShopify/TimelineBox";
import { TimelineDot } from "@/components/AntToShopify/TimelineDot";
import { useRecoilToggle } from "@/hooks/useRecoilToggle";
import { s_to_ms } from "@/utils/accurateTimeTrans";
import { moment2Date } from "@/utils/moment2Date";
import { Timeline } from "antd";
import { atom } from "recoil";

export const pureTimeLineUpdateAtom = atom({
  key: "pureTimeLineUpdateAtom",
  default: false,
});

export const PureTimeLine = ({ events }) => {
  const { toggle } = useRecoilToggle(pureTimeLineUpdateAtom);
  const [list, setList] = useState([]);
  const [renderList, setRenderList] = useState([]);
  const isLess = useMemo(() => list?.length !== renderList?.length, [list, renderList]);
  const isHasMore = useMemo(() => list?.length > 5, [list]);

  useEffect(() => {
    setList(events);
    const shortCut = events?.slice(0, 5);
    setRenderList(shortCut);
  }, [events]);

  useEffect(() => {
    toggle();
  }, [renderList, toggle]);

  return (
    <>
      <TimelineBox>
        <Timeline mode={"left"}>
          {renderList?.map((event) => (
            <Timeline.Item
              key={event.id}
              dot={<TimelineDot />}
              label={
                <Text variant="bodyMd" as="span" color="subdued">
                  {moment2Date(s_to_ms(event.created_at))}
                </Text>
              }
            >
              <Text variant="bodyMd" as="span" fontWeight="bold">
                {event?.creator?.name}
                <Text variant="bodySm" as="p">
                  {event?.content}
                </Text>
              </Text>
            </Timeline.Item>
          ))}
        </Timeline>
      </TimelineBox>
      {isHasMore && (
        <Stack distribution="center">
          <Button
            plain
            disclosure={isLess ? "down" : "up"}
            onClick={() => {
              if (isLess) {
                setRenderList([...list]);
              } else {
                setRenderList([...list.slice(0, 5)]);
              }
            }}
          >
            {isLess ? "Show all" : "Show less"}
          </Button>
        </Stack>
      )}
    </>
  );
};
