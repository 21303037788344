import numeral from "numeral";

export const formatNumber = (type, number) => {
  if (isNaN(Number(number))) return;

  const n = numeral(Number(number));

  switch (type) {
    case "unit":
      return n.format("0.[0]a").toUpperCase();
    default:
      return "";
  }
};
