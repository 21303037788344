import svg404 from "@/assets/svg/404.svg";
import { Card, EmptyState, Page } from "@shopify/polaris";
import { useHistory } from "react-router-dom";

/**
 * Renders a component for displaying a "Page Not Found" message.
 *
 * @param {object} isPage - Determines whether the component is rendered as a page or not.
 * @return {JSX.Element} The rendered component.
 */
export const NotFound = ({ isPage = true }) => {
  const history = useHistory();

  const CardMark = (
    <Card sectioned>
      <EmptyState
        heading=" Sorry, the page is not found."
        action={{
          content: "Reload the page",
          onAction: () => window.location.reload(),
        }}
        secondaryAction={{
          content: "Back to home page",
          onAction: () => history.replace("/"),
        }}
        image={svg404}
      >
        <p>
          {
            "There is a technical issue with the current page. Please try reloading the page or navigate to another page. If this doesn't work, please seek assistance from the project managers to address the issue."
          }
        </p>
      </EmptyState>
    </Card>
  );

  if (isPage) {
    return <Page>{CardMark}</Page>;
  } else {
    return <>{CardMark}</>;
  }
};
