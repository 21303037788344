const DATE_REG = /^(\d{4})\/(\d{1,2})\/(\d{1,2})$/;

// 表单验证处理
// 邮箱验证
const moreSeriousEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export const regEmail = function (content) {
  // let reg = /^[A-Za-z0-9_-\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
  let reg = moreSeriousEmail;
  if (content === "") {
    return true;
  } else {
    return reg.test(content);
  }
};

// 空值验证
export const isEmpty = function (val) {
  if (val === "") {
    return true;
  } else {
    return false;
  }
};

// 日期校验
export const verifyDate = (dateStr) => {
  if (!DATE_REG.test(dateStr)) {
    return false;
  }
  const dateArr = DATE_REG.exec(dateStr);
  const dateObj = new Date(dateArr[1], +dateArr[2] - 1, dateArr[3]);

  if (+dateArr[1] !== dateObj.getFullYear() || +dateArr[2] !== dateObj.getMonth() + 1 || +dateArr[3] !== dateObj.getDate()) {
    return false;
  }
  return true;
};