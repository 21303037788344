import { getHomeData, getHomeSearchPartners, getHomeTotal } from "@/api/request";
import {
  ActionList,
  Avatar,
  Button,
  Card,
  Icon,
  Layout,
  Loading,
  Page,
  Popover,
  Select,
  Spinner,
  Stack,
  Text,
  TextContainer,
  TextField,
} from "@shopify/polaris";
import { ChevronRightMinor, NavigationMajor, SearchMinor, ViewMinor } from "@shopify/polaris-icons";
import { useCookieState, useDebounceFn, useMount, useToggle } from "ahooks";
import { useMemo, useState } from "react";
import { ImgBox, TitlleIcon } from "./Styled/ImgBox";

import HomeTitlleIcon from "@/assets/Home/Home-Discover-result-fire.png";
import HomeResultImg from "@/assets/Home/Home-Discover-result-image.png";
import profile_icon from "@/assets/svg/Profile.svg";
import ins_icon from "@/assets/svg/platform/PMP-Instagram.svg";
import tiktok_icon from "@/assets/svg/platform/PMP-TikTok.svg";
import twitch_icon from "@/assets/svg/platform/PMP-Twitch.svg";
import twiter_icon from "@/assets/svg/platform/PMP-Twitter.svg";
import youtube_icon from "@/assets/svg/platform/PMP-YouTube.svg";
import { msg } from "@/configs";
import { useTextFieldLogic } from "@/customHooks/useTextFieldLogic";
import { useTopLoading } from "@/customHooks/useTopLoading";
import { NoPermission } from "@/error/403";
import { ResponseError } from "@/error/500";
import { loadableAuthAtom } from "@/global/authAtom";
import { userAtom } from "@/global/userAtom";
import { useBomLocation } from "@/hooks/useBomLocation";
import { CreateInlineSpace } from "@/utils/CreateInlineSpace";
import { hotToast } from "@/utils/hotToast";
import { isEmpty } from "@/utils/isEmpty";
import { transformNumber } from "@/utils/tools";
import { useAtom } from "jotai";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { CampaignsKittyCard } from "./HomeKittyCard/CampaignsKittyCard";
import { CollectionsKittyCard } from "./HomeKittyCard/CollectionsKittyCard";
import { EmptyKittyCard } from "./HomeKittyCard/EmptyKittyCard";
import { ManageCollections } from "./Styled/ManageCollections";

const PrefixBox = styled.div`
  position: relative;
  img.type_icon {
    position: absolute;
    height: 14px;
    width: 14px;
    right: 0;
    bottom: 0;
    background-color: white;
    border: 1px solid white;
    border-radius: 50%;
  }
`;

const FastIcon = ({ src }) => {
  return <img className="type_icon" src={src} alt="icon" />;
};

const MatchTypeIcon = (type) => {
  switch (type) {
    case "TikTok":
      return <FastIcon src={tiktok_icon} />;
    case "YouTube":
      return <FastIcon src={youtube_icon} />;
    case "Twitch":
      return <FastIcon src={twitch_icon} />;
    case "Instagram":
      return <FastIcon src={ins_icon} />;
    case "Twitter":
      return <FastIcon src={twiter_icon} />;
    default:
      return <FastIcon src={profile_icon} />;
  }
};

const searchOps = [
  {
    label: "Influencers",
    value: "influencers",
  },
  {
    label: "Media outlets",
    value: "media_outlets",
  },
  {
    label: "Media contacts",
    value: "media_contacts",
  },
];

const Home = () => {
  const history = useHistory();
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      LOADING: permission.state === "loading",
      ERROR: permission.state === "hasError",
      ALL: permission.data?.["pmp/home/home/all"],
      COLLECTION_WRITE: permission.data?.["pmp/resources/collections/write"],
      CAMPAIGN_WRITE: permission.data?.["pmp/workflows/campaigns/write"],
    }),
    [permission]
  );

  const { origin } = useBomLocation();
  const [userData] = useAtom(userAtom);

  const { TopLoadingJSX, hiddenTopLoading, showTopLoading } = useTopLoading();

  const [totalDatabase, setTotalDatabase] = useState([]);
  const [collections, setCollections] = useState([]);
  const [campaigns, setCampaigns] = useState([]);

  //#region Search Popover
  // select

  // const [searchType, setSearchType] = useState("influencers"); // 单选初始值 value
  const [searchType, setSearchType] = useCookieState("home_search_type", {
    defaultValue: "influencers",
    expires: 7,
  });
  const matchHomeSearchType = useCallback((searchType) => {
    switch (searchType) {
      case "influencers":
        return "Search influencers by name or link or tag";
      case "media_outlets":
        return "Search media outlets by name or link or tag";
      case "media_contacts":
        return "Search media contacts by name or link or tag";
      default:
        break;
    }
  }, []);

  const [popoverActive, { toggle: togglePopoverActive, setRight: popShow }] = useToggle();
  const { value, setValue, handleChange } = useTextFieldLogic(); // 输入框
  const [searchItems, setSearchItems] = useState([]); // action list

  const loadingSearch = useMemo(
    () => [
      {
        content: (
          <TextContainer>
            <Stack alignment="center">
              <Spinner size="small" />
              <Text variant="bodyMd" as="span" color="subdued">
                {" "}
                Loading ...
              </Text>
            </Stack>
          </TextContainer>
        ),
      },
    ],
    []
  );
  const emptySearch = useMemo(
    () => [
      {
        content: (
          <TextContainer>
            <Stack alignment="center">
              <Text variant="bodyMd" as="span" color="subdued">
                Try entering keyword to search
              </Text>
            </Stack>
          </TextContainer>
        ),
      },
    ],
    []
  );
  const notFoundSearch = useMemo(
    () => [
      {
        content: (
          <TextContainer>
            <Stack alignment="center">
              <Text variant="bodyMd" as="span" color="subdued">
                No profiles and platforms found
              </Text>
            </Stack>
          </TextContainer>
        ),
      },
    ],
    []
  );

  const matchNav2Board = useCallback(
    (type, partner_id, platform_id) => () => {
      switch (type) {
        case "influencers":
          window.open(
            `${origin}/home/platforms/${platform_id ?? "null"}/report/${
              partner_id ? partner_id : "no_related_partner"
            }`
          );
          break;
        case "media_outlets":
          window.open(`${origin}/media/board/${partner_id}`);
          break;
        case "media_contacts":
          window.open(`${origin}/contacts/board/${partner_id}`);
          break;
        default:
          break;
      }
    },
    [origin]
  );

  const initParams = useMemo(
    () => ({
      search_by: searchType,
      search: value ?? "",
    }),
    [value, searchType]
  );

  const { run } = useDebounceFn(
    async () => {
      if (!value?.trim()) {
        setSearchItems(emptySearch);
        return;
      }

      setSearchItems(loadingSearch);
      try {
        const { data } = await getHomeSearchPartners({ ...initParams });
        const items = data
          .slice(0, 50)
          .map(
            ({ partner_id, platform_id, avatar, platform_type, username, user_id, followers }) => {
              return {
                content: (
                  <div style={{ margin: "0 4px 0 4px" }}>
                    <TextContainer>
                      <Text variant="bodyMd" as="span" fontWeight="bold">
                        {username}
                        <Text variant="bodySm" as="p">
                          {user_id}
                          {CreateInlineSpace(5)}
                          {transformNumber(followers)
                            ? transformNumber(followers) + " followers"
                            : null}
                        </Text>
                      </Text>
                    </TextContainer>
                  </div>
                ),
                prefix: (
                  <PrefixBox>
                    <Avatar name="Farrah" size="medium" source={avatar} />
                    {MatchTypeIcon(platform_type)}
                  </PrefixBox>
                ),
                suffix: <Icon source={ChevronRightMinor} />,
                onAction: matchNav2Board(searchType, partner_id, platform_id),
                // onAction() {
                // window.open(
                //   `${origin}/home/platforms/${platform_id ?? "null"}/report/${
                //     partner_id ? partner_id : "no_related_partner"
                //   }`
                // );
                // },
              };
            }
          );

        if (!isEmpty(items)) {
          setSearchItems(items);
        } else {
          setSearchItems(notFoundSearch);
        }
      } catch (error) {
        setSearchItems(notFoundSearch);
      }
    },
    { wait: 600 }
  );
  const renderSearchList = useCallback(run, [run, initParams]);
  //#endregion

  // Discover partners  show all
  const handlePartnerShowAll = useCallback(() => {
    switch (searchType) {
      case "influencers":
        history.push(`/discovery`);
        break;
      case "media_outlets":
        history.push(`/discovery`);
        break;
      case "media_contacts":
        history.push(`/discovery`);
        break;
      default:
        break;
    }
  }, [history, searchType]);
  // Manage collections show all
  const handleCollectionShowAll = useCallback(() => {
    history.push(`/home/group`);
  }, [history]);
  // Manage campaigns show all
  const handleCampaignShowAll = useCallback(() => {
    history.push(`/campaign`);
  }, [history]);

  useEffect(() => {
    renderSearchList();
  }, [value, renderSearchList]);

  const getTotalNumber = useCallback(async () => {
    const totalData = await getHomeTotal({ search_by: searchType });
    setTotalDatabase(totalData.data);
  }, [searchType]);

  useEffect(() => {
    getTotalNumber();
  }, [getTotalNumber]);

  useMount(async () => {
    showTopLoading();
    try {
      const { data } = await getHomeData();
      const { campaigns, groups } = data;
      setCampaigns(campaigns);
      setCollections(groups);
      hiddenTopLoading();
    } catch (error) {
      hiddenTopLoading();
    }
  });

  if (access.LOADING) return <Loading />;
  if (access.ERROR) return <ResponseError />;
  if (!access.ALL) return <NoPermission />;

  return (
    <Page title="Home" fullWidth>
      {TopLoadingJSX}
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <Text>Welcome, {userData?.name} !</Text>
              <Text variant="headingMd" as="h2">
                <Text variant="bodyMd" as="span" fontWeight="bold">
                  {"It's time to make your partnership better!"}
                  <TitlleIcon>
                    <img src={HomeTitlleIcon} alt="icon" height="100%" width="100%" />
                  </TitlleIcon>
                </Text>
              </Text>
            </TextContainer>
            <Stack>
              <ImgBox>
                <img src={HomeResultImg} alt="home" height="100%" width="100%" />
              </ImgBox>
            </Stack>
            <br />
            <TextContainer>
              <Text variant="bodyMd" as="span" fontWeight="bold">
                Discover partners
              </Text>
              {CreateInlineSpace(5)}
              <Button plain removeUnderline onClick={handlePartnerShowAll}>
                Show all
              </Button>
            </TextContainer>
            <div style={{ paddingTop: 6, maxWidth: 840 }}>
              <Stack alignment="trailing" spacing="tight">
                <Stack.Item fill>
                  <Stack spacing="tight">
                    <Stack.Item>
                      <Select
                        options={searchOps}
                        value={searchType}
                        onChange={(value) => {
                          setSearchType(value);
                          setValue("");
                        }}
                      />
                    </Stack.Item>
                    <Stack.Item fill>
                      <Popover
                        active={popoverActive}
                        fullWidth
                        preferredAlignment="left"
                        preferredPosition="mostSpace"
                        activator={
                          <TextField
                            autoComplete="off"
                            placeholder={matchHomeSearchType(searchType)}
                            prefix={<Icon source={SearchMinor} />}
                            preferredAlignment="left"
                            onFocus={popShow}
                            clearButton
                            onClearButtonClick={() => {
                              setValue("");
                            }}
                            label={
                              <div>
                                <Text variant="bodyMd" as="span" fontWeight="bold">
                                  Discover partners
                                </Text>
                                {CreateInlineSpace(5)}
                                <Button plain removeUnderline onClick={handlePartnerShowAll}>
                                  Show all
                                </Button>
                              </div>
                            }
                            value={value}
                            onChange={handleChange}
                            labelHidden
                          />
                        }
                        onClose={togglePopoverActive}
                      >
                        <Popover.Pane>
                          <ActionList actionRole="menuitem" items={searchItems} />
                        </Popover.Pane>
                        <Popover.Pane fixed>
                          <div style={{ padding: 16 }}>
                            <Stack>
                              <Button plain icon={NavigationMajor} onClick={handlePartnerShowAll}>
                                Advanced discovery
                              </Button>
                              <Text variant="bodyMd" as="span" color="subdued">
                                {transformNumber(totalDatabase[0])}+ database
                              </Text>
                            </Stack>
                          </div>
                        </Popover.Pane>
                      </Popover>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
                <Stack.Item>
                  <Button primary icon={ViewMinor} onClick={handlePartnerShowAll}>
                    Advanced discovery
                  </Button>
                </Stack.Item>
              </Stack>
            </div>

            <br />
            <TextContainer>
              <Text variant="bodyMd" as="span" fontWeight="bold">
                Manage collections
              </Text>
              {CreateInlineSpace(5)}
              <Button plain removeUnderline onClick={handleCollectionShowAll}>
                {"Show all"}
              </Button>
            </TextContainer>
            <ManageCollections>
              <Stack>
                {collections.map((collection, i) => (
                  <Stack.Item key={i}>
                    <CollectionsKittyCard collection={collection} />
                  </Stack.Item>
                ))}
                <Stack.Item>
                  <EmptyKittyCard
                    title="Create collection"
                    onClick={() => {
                      if (!access.COLLECTION_WRITE) {
                        hotToast(msg.error403, { error: true });
                        return;
                      }
                      history.push(`/home/group/add/info`);
                    }}
                  />
                </Stack.Item>
              </Stack>
            </ManageCollections>
            <TextContainer>
              <Text variant="bodyMd" as="span" fontWeight="bold">
                Manage campaigns
              </Text>
              {CreateInlineSpace(5)}
              <Button plain removeUnderline onClick={handleCampaignShowAll}>
                Show all
              </Button>
              <ManageCollections>
                <Stack>
                  {campaigns.map((campaign, i) => (
                    <Stack.Item key={i}>
                      <CampaignsKittyCard campaign={campaign} />
                    </Stack.Item>
                  ))}
                  <Stack.Item>
                    <EmptyKittyCard
                      title="Create campaign"
                      onClick={() => {
                        if (!access.CAMPAIGN_WRITE) {
                          hotToast(msg.error403, { error: true });
                          return;
                        }
                        history.push(`/campaign/CampaignDetail`);
                      }}
                    />
                  </Stack.Item>
                </Stack>
              </ManageCollections>
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default Home;
