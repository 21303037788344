import { Filters, Modal, OptionList, Stack } from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react";

import { getMediaContactList } from "@/api/request";
import { AntPagination } from "@/components/AntToShopify/AntPagination";
import { OptionAvatarBox } from "@/components/EnhanceShopify/OptionAvatarBox";
import { useScopeSpinner } from "@/customHooks/useScopeSpinner";
import { useDebounceFn } from "ahooks";
import { Pagination } from "antd";
import { unionBy } from "lodash";
import { useRecoilState } from "recoil";
import { selectedContacts_atom } from "./atom";

export const SelectContacts = ({ active, toggle }) => {
  const [, setContacts] = useRecoilState(selectedContacts_atom); // 展示列表

  const [selectedNewContacts, setSelectedNewContacts] = useState([]);
  const { ScopeSpinner, setIsLoading } = useScopeSpinner();

  const [options, setOptions] = useState([]);

  const [queryValue, setQueryValue] = useState("");
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);

  //#region request
  const [total, setTotal] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const [backupList, setBackupList] = useState([]); // 需要拿这里面的所有数据, 填充进展示列表

  // 首次加载请求的传参:
  const initParams = useMemo(() => {
    return {
      search_by: "name",
      search: queryValue,
      page: currentPage,
      per_page: perPage,
    };
  }, [currentPage, perPage, queryValue]);

  // 在请求展示所有条目的基础上,筛选当前页条目:
  const { run } = useDebounceFn(
    async (params) => {
      setIsLoading(true);
      const { data } = await getMediaContactList({ ...initParams, ...params });
      setBackupList((list) => {
        return unionBy([...list, ...data.list], "id");
      });
      const optionArr = data.list.map(({ id, avatar, name }) => {
        const label = (
          <Stack alignment="center">
            {/* <Stack.Item>
              <Avatar source={avatar} />
            </Stack.Item> */}
            <Stack.Item>{name}</Stack.Item>
          </Stack>
        );
        return { value: String(id), label };
      });
      setOptions(optionArr);
      setTotal(data.meta.pagination.total);
      setPerPage(data.meta.pagination.per_page);
      setCurrentPage(data.meta.pagination.current_page);
      setIsLoading(false);
    },
    { wait: 600 }
  );
  const reqCurrentTable = useCallback(run, [initParams, run]);

  // tabs/搜搜栏/creator/sort/project 变化
  useEffect(() => {
    reqCurrentTable({ page: 1 });
  }, [
    // 不能监听页码和每页数, 会造成挂载执行两次
    reqCurrentTable,
    queryValue,
  ]);

  //#endregion

  const handleCancel = useCallback(() => {
    toggle();
  }, [toggle]);

  const handleSave = useCallback(() => {
    const addSelectContacts = backupList.filter(({ id }) =>
      selectedNewContacts.includes(String(id))
    );
    setContacts((arr) => unionBy(addSelectContacts, arr, "id"));
    setSelectedNewContacts([]);
    setQueryValue("");
    toggle();
  }, [backupList, selectedNewContacts, setContacts, toggle]);

  return (
    <Modal
      title="Select media contact"
      open={active}
      onClose={toggle}
      primaryAction={{ content: "Save", onAction: handleSave }}
      secondaryActions={[{ content: "Cancel", onAction: handleCancel }]}
    >
      <div style={{ padding: "16px 16px 4px", display: "flex" }}>
        <div style={{ flex: 1 }}>
          <Filters
            queryPlaceholder="Search by media contact name"
            queryValue={queryValue}
            filters={[]}
            appliedFilters={[]}
            onQueryChange={setQueryValue}
            onQueryClear={handleQueryValueRemove}
          />
        </div>
      </div>
      <OptionAvatarBox>
        {ScopeSpinner}
        <OptionList
          onChange={setSelectedNewContacts}
          options={options}
          selected={selectedNewContacts}
          allowMultiple
        />
        {options.length ? (
          <Stack distribution="trailing">
            <AntPagination>
              <Pagination
                // showTotal={(total, range) => `${total} items`}
                current={currentPage}
                pageSize={perPage}
                pageSizeOptions={[5, 10]}
                showSizeChanger
                total={total}
                onChange={(page, pageSize) => {
                  reqCurrentTable({ ...initParams, page: page, per_page: pageSize });
                }}
              />
            </AntPagination>
          </Stack>
        ) : null}
      </OptionAvatarBox>
    </Modal>
  );
};
