import { cookieScope } from "@/utils/cookieScope";
import { hotToast } from "@/utils/hotToast";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const ReidrectPage = () => {
  const history = useHistory();

  useEffect(() => {
    const token = cookieScope.getToken();
    const tenantId = cookieScope.getTenantId();

    if (!token || !tenantId) {
      hotToast(
        "Please verify that your login information is correct and try logging in again by closing this page.",
        { type: "error" }
      );
      return;
    }
    history.replace("/dashboard");
  }, [history]);

  return <div>redrecting...</div>;
};
