import { Plat_PrincipalOps_Atom, Plat_tab_init } from "./atom/commonAtom";

import { getPartnerPrincipalOptions } from "@/api/request";
import { useStaleTabs } from "@/customHooks/useStaleTabs";
import { Card } from "@shopify/polaris";
import { useMount } from "ahooks";
import { useRecoilState } from "recoil";
import { Profiles } from "../Profiles";
import { ConfigInit } from "./ConfigInit/ConfigInit";
import { ImportInfluencers } from "./import/ImportInfluencers";
import { InstagramTable } from "./InstagramTable";
import { TikTokTable } from "./TikTokTable";
import { TwitchTable } from "./TwitchTable";
import { TwitterTable } from "./TwitterTable";
import { YoutubeTable } from "./YoutubeTable";

export const Platforms = ({ CollectionID }) => {
  const { tabJSX, selected: tabIndex } = useStaleTabs({
    tabInitAtom: Plat_tab_init,
    titles: ["All", "YouTube", "Instagram", "TikTok", "Twitch", "Twitter"],
  });

  const [, setCreatorOptions] = useRecoilState(Plat_PrincipalOps_Atom);

  useMount(async () => {
    const { data } = await getPartnerPrincipalOptions();
    const ops = data.list.map(({ id, name }) => ({
      label: name,
      value: String(id),
    }));
    setCreatorOptions(ops);
  });

  function MatchTable(tabIndex) {
    switch (tabIndex) {
      case 0:
        return <Profiles CollectionID={CollectionID} />;
      case 1:
        return <YoutubeTable CollectionID={CollectionID} />;
      case 2:
        return <InstagramTable CollectionID={CollectionID} />;
      case 3:
        return <TikTokTable CollectionID={CollectionID} />;
      case 4:
        return <TwitchTable CollectionID={CollectionID} />;
      case 5:
        return <TwitterTable CollectionID={CollectionID} />;
      default:
        break;
    }
  }

  return (
    <Card>
      <ConfigInit />
      <ImportInfluencers />
      {tabJSX}
      {MatchTable(tabIndex)}
    </Card>
  );
};
