import { Loading } from "@shopify/polaris";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const RootPage = () => {
  const history = useHistory();

  useEffect(() => {
    history.replace("/dashboard");
  }, [history]);
  return (
    <div>
      <Loading />
    </div>
  );
};
