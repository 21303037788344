import { useCallback } from "react";
import { GlobalToastAtom, GlobalToastColorAtom, GlobalToastMessageAtom } from "./atom";

import { Toast } from "@shopify/polaris";
import { useRecoilState } from "recoil";

export const GlobalToast = () => {
  const [active, setActive] = useRecoilState(GlobalToastAtom);
  const toggleActive = useCallback(() => setActive((active) => !active), [setActive]);

  const [message] = useRecoilState(GlobalToastMessageAtom);

  const [error] = useRecoilState(GlobalToastColorAtom);

  const toastMarkup = active ? (
    <Toast content={message} onDismiss={toggleActive} error={error} />
  ) : null;

  return <>{toastMarkup}</>;
};
