import { getAnalyseTeamMembers, getTeamMembers, postDeleteTeamMembers } from "@/api/request";
import {
  Button,
  Card,
  ChoiceList,
  Filters,
  Icon,
  IndexTable,
  Loading,
  Page,
  Pagination,
  Popover,
  Stack,
  Text,
  TextContainer,
  useIndexResourceState,
} from "@shopify/polaris";
import { Columns3Minor, SortMinor } from "@shopify/polaris-icons";
import { useDebounceFn, useLocalStorageState, useMount, useToggle, useUpdateEffect } from "ahooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  AddMember,
  teamsMembersColumnsOptions,
  teamsMembersEmptyState,
  teamsMembersResourceName,
  teamsMembersSortOptions,
  teamsMembers_headings,
} from "./TeamMembers.support";

import { BlackBoard } from "@/components/BlackBoard";
import { useChoiceListLogic } from "@/customHooks/useChoiceListLogic";
import { useCustomToast } from "@/customHooks/useCustomToast";
import { useFiltersPagination } from "@/customHooks/useFiltersPagination";
import { usePopoverActive } from "@/customHooks/usePopoverActive";
import { useTabs } from "@/customHooks/useTabs";
import { NoPermission } from "@/error/403";
import { ResponseError } from "@/error/500";
import { loadableAuthAtom } from "@/global/authAtom";
import { ConfirmDeleteModal } from "@/utils/ConfirmDeleteModal";
import { splitChoiceValue } from "@/utils/checkType";
import { datepickToShopifyStyle } from "@/utils/datepickToShopifyStyle";
import { filterDivStyle } from "@/utils/filterDivStyle";
import { DatePicker as AntDatePicker } from "antd";
import { useAtom } from "jotai";
import _ from "lodash";
import moment from "moment";
import { v4 } from "uuid";
const { RangePicker: AntDateRangePicker } = AntDatePicker;

const TeamMembers = () => {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      LOADING: permission.state === "loading",
      ERROR: permission.state === "hasError",
      READ: permission.data?.["pmp/workflows/teams/read"],
      WRITE: permission.data?.["pmp/workflows/teams/write"],
    }),
    [permission]
  );
  const { teamID } = useParams();
  const history = useHistory();
  const { tabJSX } = useTabs({ titles: ["All"] });
  const [indexTableLoading, setIndexTableLoading] = useState(false);
  const { toastJSX, toggleActive: toggleCustomToastActive } =
    useCustomToast("Removed successfully!");
  const [addMemberActive, { toggle: toggleAddMemberActive }] = useToggle();

  // search text
  const [queryValue, setQueryValue] = useState(null);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  // create by

  // pagination state
  const { currentPage, setCurrentPage, totalPages, setTotalPages } = useFiltersPagination();

  // Expect payment date
  const [date, setDate] = useState();

  const onExpectDateChange = useCallback((dates, dateStrings) => {
    setDate(dateStrings);
  }, []);
  const handleExpectDateRemove = useCallback(() => setDate(null), []);

  // clearAll
  const handleClearAll = useCallback(() => {
    setQueryValue("");
    handleExpectDateRemove();
  }, [handleExpectDateRemove]);

  // Comfirm Delete State
  const [deleteComfirmActive, { toggle: toggleDeleteComfirmActive }] = useToggle();
  const [deleteContent] = useState("Are you sure you want to remove the selected member?");

  // black board
  const [dataList, setDataList] = useState([]);

  //#region
  const filters = [
    {
      key: "Date",
      label: "Date",
      filter: (
        <>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <AntDateRangePicker
              size="large"
              style={datepickToShopifyStyle}
              ranges={{
                Today: [moment(), moment()],
                "This Week": [moment().startOf("week"), moment().endOf("week")],
                "This Month": [moment().startOf("month"), moment().endOf("month")],
              }}
              allowClear={false}
              onChange={onExpectDateChange}
              value={date ? [moment(date[0]), moment(date[1])] : null}
            />
          </div>
        </>
      ),
      shortcut: true,
    },
  ];
  const appliedFilters = [];

  // null/undefined   ["",""]
  if (!!date && !!_.compact(date).length) {
    const key = "Date";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, date),
      onRemove: handleExpectDateRemove,
    });
  }
  //  show the filter tags under the Search
  function disambiguateLabel(key, value) {
    switch (key) {
      case "Date":
        return `Date: ${value[0]} ~ ${value[1]}`;
      default:
        return value;
    }
  }

  //#endregion

  //#region Sort
  //  active
  const { popoverActive: sortActive, togglePopoverActive: toggleSortActive } = usePopoverActive();
  // sort selelcted
  const [sortState, setSortState] = useState("new_partners"); // 排序类型:最新时间
  const [sortOrder, setSortOrder] = useState("desc"); // 排序倒序
  const { selected: sortValue, handleSelectChange: handleSortChange } =
    useChoiceListLogic("new_partners|desc");
  // 筛选参数
  useEffect(() => {
    const [updateOrtitle, sort] = splitChoiceValue(sortValue);
    setSortState(updateOrtitle);
    setSortOrder(sort);
  }, [sortValue]);

  const sortActivator = useMemo(
    () => (
      <Button icon={<Icon source={SortMinor} />} onClick={toggleSortActive}>
        Sort
      </Button>
    ),
    [toggleSortActive]
  );
  //#endregion Sort

  const initParams = useMemo(
    () => ({
      team_id: teamID,
      name: queryValue,

      sort_by: sortState,
      sort_type: sortOrder,
      page: 1,
      per_page: 50,
      // start_date: date ? date[0] : "",
      // end_date: date ? date[1] : "",
      created_ats: date,
    }),
    [sortState, sortOrder, queryValue, date, teamID]
  );
  const { run } = useDebounceFn(
    async (params) => {
      setIndexTableLoading(true);
      try {
        // const { data } = await getBudgetAppsList({ ...initParams, ...params });
        const { data } = await getTeamMembers({ ...initParams, ...params });
        setTeamsMemberList(data.list);
        setTotalPages(data.meta.pagination.total_pages);
        setCurrentPage(data.meta.pagination.current_page);

        const { team_id, created_ats } = initParams;
        const { data: analyse } = await getAnalyseTeamMembers({ team_id, created_ats });
        setDataList([
          { label: "Members", value: analyse.member_total },
          { label: "New partner", value: analyse.new_partners_total },
          { label: "Partners reached out", value: analyse.partners_reached_out_total },
          { label: "Partners on board", value: analyse.partners_on_board_total },
          { label: "Response rate", value: analyse.respone_rate_total + "%" },
        ]);
        setIndexTableLoading(false);
      } catch (error) {
        setIndexTableLoading(false);
      }
    },
    { wait: 600 }
  );
  // 渲染当前列表 - useMemo 中已经依赖项 - initParams
  const renderlist = useCallback(run, [run, initParams, setTotalPages, setCurrentPage]);

  //#region Columns
  const [local_team_members_columns, setLocal_team_members_columns] = useLocalStorageState(
    "team_members_columns_1",
    {
      defaultValue: [],
    }
  );
  const {
    selected: columns,
    setSelected: setcolumns,
    handleSelectChange: handleColumnsChange,
  } = useChoiceListLogic([
    "User name",
    // "User code",
    "New partners",
    "Partners reached out",
    "Partners on board",
    "Response rate",
  ]);
  // 挂载时读取记录
  useMount(() => {
    if (local_team_members_columns?.length) {
      setcolumns(local_team_members_columns);
    }
  });
  // 变化时存储记录
  useUpdateEffect(() => {
    setLocal_team_members_columns(columns);
  }, [columns]);

  const { popoverActive: columnsActive, togglePopoverActive: toggleColumnsActive } =
    usePopoverActive();
  const columnsActivator = useMemo(
    () => (
      <Button icon={Columns3Minor} onClick={toggleColumnsActive}>
        Columns
      </Button>
    ),
    [toggleColumnsActive]
  );
  //#endregion Columns

  // title的顺序应该跟columns默认初始值一致
  const [teamsHeading, setTeamsHeading] = useState(teamsMembers_headings);
  const [teamsMemberList, setTeamsMemberList] = useState([]);
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(teamsMemberList);
  // 动态加载列:
  useEffect(() => {
    const headings = teamsMembers_headings.filter(({ title }) => columns.includes(title));
    setTeamsHeading(headings);
    window.dispatchEvent(new Event("resize"));
  }, [columns]);
  // indexTable rows
  const rowMarkup = teamsMemberList.map(
    (
      { id, code, name, new_partners, partners_on_board, partners_reached_out, respone_rate },
      index
    ) => {
      const props = [
        name,
        code,
        new_partners,
        partners_reached_out,
        partners_on_board,
        respone_rate !== "-" ? respone_rate + "%" : respone_rate,
      ];
      let SN = []; // 总的序列对应关系

      // 把每一个heading的索引对应上需要展示的属性: 二维数组
      teamsMembersColumnsOptions.forEach(({ value }, index) => {
        SN.push({ column: value, prop: props[index] });
      });

      let showCell = SN.filter(({ column }) => columns.includes(column));

      return (
        <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
          {showCell.map(({ prop }) => (
            <IndexTable.Cell key={v4()}>{prop}</IndexTable.Cell>
          ))}
        </IndexTable.Row>
      );
    }
  );

  const promotedBulkActions = [
    {
      content: "Remove member",
      onAction() {
        toggleDeleteComfirmActive();
      },
    },
  ];
  // handle comfirm delete function - after the indexTable state
  const handleConfirmDelete = useCallback(async () => {
    toggleDeleteComfirmActive();
    try {
      await postDeleteTeamMembers({ team_id: teamID, ids: selectedResources });
      await renderlist();
      toggleCustomToastActive();
    } catch (error) {
      await renderlist();
    }
    handleSelectionChange("all", false); // 清除indextable选中状态
  }, [
    teamID,
    handleSelectionChange,
    renderlist,
    selectedResources,
    toggleDeleteComfirmActive,
    toggleCustomToastActive,
  ]);

  // 下一页
  const Next = useCallback(async () => {
    setCurrentPage((currentPage) => ++currentPage);
    await renderlist({ page: currentPage + 1 });
  }, [setCurrentPage, renderlist, currentPage]);
  // 上一页
  const Previous = useCallback(async () => {
    setCurrentPage((currentPage) => --currentPage);
    await renderlist({ page: currentPage - 1 });
  }, [setCurrentPage, renderlist, currentPage]);

  // 查询加载
  useUpdateEffect(() => {
    renderlist();
  }, [queryValue, sortValue, date]);

  useMount(() => {
    renderlist();
  });

  if (access.LOADING) return <Loading />;
  if (access.ERROR) return <ResponseError />;
  if (!access.READ) return <NoPermission />;

  return (
    <Page
      fullWidth
      title="Team members"
      breadcrumbs={[{ content: "teams", url: "/Teams" }]}
      primaryAction={
        !access.WRITE
          ? undefined
          : {
              content: "Add member",
              onAction: toggleAddMemberActive,
            }
      }
      secondaryActions={
        !access.WRITE
          ? undefined
          : [
              {
                content: "Team settings",
                onAction() {
                  history.push(`/Teams/${teamID}/TeamSettings`);
                },
              },
            ]
      }
    >
      {!!dataList.length && <BlackBoard dataList={dataList} />}
      <Card>
        {tabJSX}
        <AddMember
          teamID={teamID}
          addMemberActive={addMemberActive}
          toggleAddMemberActive={toggleAddMemberActive}
          renderlist={renderlist}
        />
        <div style={filterDivStyle}>
          <div style={{ flex: 1 }}>
            <Filters
              queryPlaceholder="Search by user name"
              queryValue={queryValue}
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={setQueryValue}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleClearAll}
            />
          </div>
          <div style={{ paddingLeft: "0.4rem" }}>
            <Popover active={sortActive} activator={sortActivator} onClose={toggleSortActive}>
              <Popover.Section>
                <TextContainer>
                  <Text variant="bodyMd" as="span" color="subdued">
                    Sort by
                  </Text>
                </TextContainer>
                <ChoiceList
                  choices={teamsMembersSortOptions}
                  selected={sortValue}
                  onChange={handleSortChange}
                />
              </Popover.Section>
            </Popover>
          </div>
          <div style={{ paddingLeft: "0.4rem" }}>
            <Popover
              active={columnsActive}
              activator={columnsActivator}
              onClose={toggleColumnsActive}
            >
              <Popover.Section>
                <TextContainer>
                  <Text variant="bodyMd" as="span" color="subdued">
                    Columns
                  </Text>
                </TextContainer>
                <ChoiceList
                  allowMultiple
                  choices={teamsMembersColumnsOptions}
                  selected={columns}
                  onChange={handleColumnsChange}
                />
              </Popover.Section>
            </Popover>
          </div>
        </div>
        <IndexTable
          loading={indexTableLoading}
          resourceName={teamsMembersResourceName}
          itemCount={teamsMemberList.length}
          selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
          onSelectionChange={handleSelectionChange}
          promotedBulkActions={!access.WRITE ? undefined : promotedBulkActions}
          headings={teamsHeading}
          emptyState={teamsMembersEmptyState}
        >
          {rowMarkup}
        </IndexTable>
        {teamsMemberList.length ? (
          <Stack distribution="center">
            <div style={{ padding: "2px 0 16px" }}>
              <Pagination
                label={
                  <>
                    {currentPage}/{totalPages}
                  </>
                }
                hasPrevious={currentPage < 2 ? false : true}
                onPrevious={Previous}
                hasNext={currentPage >= totalPages ? false : true}
                onNext={Next}
              />
            </div>
          </Stack>
        ) : null}
      </Card>
      {toastJSX}
      <ConfirmDeleteModal
        title="Confirm remove"
        buttonText="Remove"
        deleteComfirmActive={deleteComfirmActive}
        toggleDeleteComfirmActive={toggleDeleteComfirmActive}
        content={deleteContent}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Page>
  );
};

export default TeamMembers;
