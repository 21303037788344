const CheckBoxes = [
  // "Keyword",
  "Status",
  "Platform",
  "Create by",
  "Creation time",
  "Update by",
  "Update time",
];

const ColumnsOps = CheckBoxes.map((str) => ({
  label: str,
  value: str,
}));

ColumnsOps.unshift({
  label: "Keyword",
  value: "Keyword",
  disabled: true,
});

ColumnsOps.push({
  label: "Operation",
  value: "Operation",
  disabled: true,
});

const Headings = ColumnsOps.map(({ value }) => ({ title: value }));

export { ColumnsOps, Headings };
