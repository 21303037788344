import {
  GlobalToastAtom,
  GlobalToastColorAtom,
  GlobalToastMessageAtom,
} from "@/container/GlobalToast/atom";

import { useCallback } from "react";
import { useRecoilState } from "recoil";

// 为了便于查找, 组件放在 src/container/GlobalToast 中, 与axios全局提示组件 同一级
export const useGlobalToast = () => {
  const [, setActive] = useRecoilState(GlobalToastAtom);
  const toggleActive = useCallback(() => setActive((active) => !active), [setActive]);

  const [, setMessage] = useRecoilState(GlobalToastMessageAtom);

  const [, setError] = useRecoilState(GlobalToastColorAtom);

  const handleGlobalToast = ({ msg = "message", isErr = false }) => {
    setMessage(msg);
    setError(isErr);
    toggleActive();
  };

  return { handleGlobalToast };
};
