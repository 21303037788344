export const searchOps = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "By name",
    value: "name",
  },
  {
    label: "By link",
    value: "link",
  },
  {
    label: "By tag",
    value: "tag",
  },
];
