import { TextClamp } from "@/components/EnhanceShopify/TextClamp";
import { Stack, Text } from "@shopify/polaris";

export const CustomerAvatar = ({ avatar, name, subName, exta }) => {
  return (
    <Stack alignment="center" wrap={false}>
      {avatar}
      <Stack.Item>
        <div style={{ maxWidth: 160 }}>
          <Stack spacing="extraTight">
            <Text variant="bodyLg" as="p" fontWeight="bold">
              <TextClamp>{name}</TextClamp>
            </Text>
            <Stack.Item>{exta}</Stack.Item>
          </Stack>
          <Text variant="bodyMd" as="p" color="subdued">
            <TextClamp>{subName}</TextClamp>
          </Text>
        </div>
      </Stack.Item>
    </Stack>
  );
};
