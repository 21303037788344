import { getPartnerGroups, postAddPlatforms2Collections } from "@/api/request";
import { Filters, Modal, OptionList, Stack } from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react";

import { AntPagination } from "@/components/AntToShopify/AntPagination";
import { useGlobalToast } from "@/customHooks/useGlobalToast";
import { useScopeSpinner } from "@/customHooks/useScopeSpinner";
import { useDebounceFn } from "ahooks";
import { Pagination } from "antd";

export const AddToCollection = ({ active, handleChange, refreshList, platformIDs }) => {
  const { handleGlobalToast } = useGlobalToast();

  const { ScopeSpinner, setIsLoading } = useScopeSpinner();

  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);

  const [queryValue, setQueryValue] = useState("");
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);

  //#region request
  const [total, setTotal] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  // 首次加载请求的传参:
  const initParams = useMemo(() => {
    return {
      title: queryValue,
      page: currentPage,
      per_page: perPage,
    };
  }, [currentPage, perPage, queryValue]);

  // 在请求展示所有条目的基础上,筛选当前页条目:
  const { run } = useDebounceFn(
    async (params) => {
      setIsLoading(true);
      const { data } = await getPartnerGroups({ ...initParams, ...params });
      const optionArr = data.list.map(({ id, title }) => ({ value: String(id), label: title }));
      setOptions(optionArr);
      setTotal(data.meta.pagination.total);
      setPerPage(data.meta.pagination.per_page);
      setCurrentPage(data.meta.pagination.current_page);
      setIsLoading(false);
    },
    { wait: 600 }
  );
  const reqCurrentTable = useCallback(run, [initParams, run]);

  // tabs/搜搜栏/creator/sort/project 变化
  useEffect(() => {
    reqCurrentTable({ page: 1 });
  }, [
    // 不能监听页码和每页数, 会造成挂载执行两次
    reqCurrentTable,
    queryValue,
  ]);

  //#endregion

  return (
    <Modal
      open={active}
      onClose={handleChange}
      title={`Add ${platformIDs?.length ? platformIDs.length + " influencers" : "0 influencer"} to ${
        selected?.length ? selected.length + " collections" : "0 collection"
      } `}
      primaryAction={{
        content: "Save",
        onAction: async () => {
          try {
            if (selected.length) {
              await postAddPlatforms2Collections(platformIDs, selected);
              refreshList();
              handleChange();
              handleGlobalToast({ msg: "Add to collections successfully!" });
            }
          } catch (error) {
            handleGlobalToast({ msg: "Add to collections failed", isErr: true });
          }
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction() {
            handleChange();
          },
        },
      ]}
    >
      <div style={{ padding: "16px 16px 4px", display: "flex" }}>
        <div style={{ flex: 1 }}>
          <Filters
            queryPlaceholder="Search by collection title"
            queryValue={queryValue}
            filters={[]}
            appliedFilters={[]}
            onQueryChange={setQueryValue}
            onQueryClear={handleQueryValueRemove}
          />
        </div>
      </div>
      <div style={{ position: "relative" }}>
        {ScopeSpinner}
        <OptionList onChange={setSelected} options={options} selected={selected} allowMultiple />
        {options.length ? (
          <Stack distribution="trailing">
            <AntPagination>
              <Pagination
                // showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                current={currentPage}
                pageSize={perPage}
                pageSizeOptions={[5, 10]}
                showSizeChanger
                total={total}
                onChange={(page, pageSize) => {
                  reqCurrentTable({ ...initParams, page: page, per_page: pageSize });
                }}
              />
            </AntPagination>
          </Stack>
        ) : null}
      </div>
    </Modal>
  );
};
