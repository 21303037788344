import Amazon_svg from "@/assets/svg/platform/PMP-Amazon.svg";
import Blog_svg from "@/assets/svg/platform/PMP-blog.svg";
import Facebook_svg from "@/assets/svg/platform/PMP-Facebook.svg";
import ins_icon from "@/assets/svg/platform/PMP-Instagram.svg";
import LinkedIn_svg from "@/assets/svg/platform/PMP-LinkedIn.svg";
import Redbook_svg from "@/assets/svg/platform/PMP-redbook.svg";
import Reddit_svg from "@/assets/svg/platform/PMP-Reddit.svg";
import titok_icon from "@/assets/svg/platform/PMP-TikTok.svg";
import twitch_icon from "@/assets/svg/platform/PMP-Twitch.svg";
import twitter_icon from "@/assets/svg/platform/PMP-Twitter.svg";
import Website_svg from "@/assets/svg/platform/PMP-Website.svg";
import youtube_icon from "@/assets/svg/platform/PMP-YouTube.svg";
import website_icon from "@/assets/website_icon.png";
import { formatNumber } from "@/utils/formatNumber";
import { Platag } from "./Platag";

export const PlatagList = (platforms) => {
  let Markup = [];
  pushEle(Markup, "YouTube", youtube_icon);
  pushEle(Markup, "Instagram", ins_icon);
  pushEle(Markup, "TikTok", titok_icon);
  pushEle(Markup, "Twitch", twitch_icon);
  pushEle(Markup, "Twitter", twitter_icon);
  pushEle(Markup, "Xiaohongshu", Redbook_svg);
  pushEle(Markup, "Facebook", Facebook_svg);
  pushEle(Markup, "Blog", Blog_svg);
  pushEle(Markup, "Others", website_icon);
  pushEle(Markup, "Amazon", Amazon_svg);
  pushEle(Markup, "Linkedin", LinkedIn_svg);
  pushEle(Markup, "Reddit", Reddit_svg);
  pushEle(Markup, "Website", Website_svg);

  return Markup; // 每个类型的平台可能有多个需要展示的数量

  // 辅助函数: 数据中每一类平台有多少个.
  function typeArr(title = "") {
    return platforms?.filter((o) => o?.type?.title === title);
  }
  // 辅助函数: 如果数据中存在平台信息,每一类平台遍历出所有项(同类型可能有多个账号),统一加入到总的数组中
  function pushEle(markupArr, title, icon) {
    if (typeArr(title)?.length) {
      const arr = typeArr(title).map((o) => {
        return (
          <Platag
            key={o}
            link={o.link}
            src={icon}
            platname={formatNumber("unit", o.subscribers) || o?.type?.title}
          />
        );
      });
      markupArr.push(...arr);
    }
  }
};
