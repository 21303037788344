import { Icon, Popover, Scrollable, Stack, Tag } from "@shopify/polaris";
import { useState } from "react";

import { DropdownMinor } from "@shopify/polaris-icons";
import { useToggle } from "ahooks";
import { useEffect } from "react";
import { v4 } from "uuid";

export const TagPopover = ({ tags, len = 5 }) => {
  const [active, { toggle }] = useToggle();

  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    setSelectedTags(tags ?? []);
  }, [tags]);

  // const removeTag = useCallback(
  //   (id, title) => (e) => {
  //     e.stopPropagation();
  //     console.log("removeTag");
  //     setSelectedTags((previousTags) => {
  //       const filterTags = previousTags.filter((previousTag) => previousTag.id !== id);
  //       console.log("filterTags: ", filterTags);
  //       return filterTags;
  //     });
  //   },
  //   []
  // );

  const Activator = (
    <div
      style={{ minWidth: 400 }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Stack spacing="extraTight">
        {[...selectedTags].slice(0, len).map(({ title }) => (
          <Tag
            key={v4()}
            //  onRemove={removeTag(id, title)}
          >
            {title}
          </Tag>
        ))}
        <Tag
          onClick={(e) => {
            e.stopPropagation();
            toggle();
          }}
        >
          <Icon source={DropdownMinor} />
        </Tag>
      </Stack>
    </div>
  );

  return selectedTags.length > len ? (
    <Popover active={active} activator={Activator} onClose={toggle}>
      <Popover.Section
        key={v4()}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Scrollable
          shadow
          style={{ maxHeight: "140px" }}
          focusable
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Stack spacing="extraTight">
            {selectedTags.map(({ title }) => (
              <Tag
                key={v4()}
                //  onRemove={removeTag(id, title)}
              >
                {title}
              </Tag>
            ))}
          </Stack>
        </Scrollable>
      </Popover.Section>
    </Popover>
  ) : (
    <div style={{ maxWidth: 300 }}>
      <Stack spacing="extraTight">
        {selectedTags.map(({ title }) => (
          <Tag
            key={v4()}
            // onRemove={removeTag(id, title)}
          >
            {title}
          </Tag>
        ))}
      </Stack>
    </div>
  );
};
