import {
  DollarTwoTone,
  SafetyCertificateTwoTone,
  TagTwoTone,
  ThunderboltTwoTone
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Layout,
  Link,
  Stack,
  Text,
  TextContainer,
  Tooltip
} from "@shopify/polaris";
import { useEffect, useState } from "react";

import { AnchorMark } from "@/components/EnhanceShopify/AnchorMark";
import { InfoCol } from "@/components/EnhanceShopify/InfoCol";
import { InfoRow } from "@/components/EnhanceShopify/InfoRow";
import { LayoutStick } from "@/components/EnhanceShopify/LayoutStick";
import { TextClamp } from "@/components/EnhanceShopify/TextClamp";
import { IconFont } from "@/components/Icon/PMPicon";
import { useCopy } from "@/hooks/useCopy";
import { formatNumber } from "@/utils/formatNumber";
import { DuplicateMinor } from "@shopify/polaris-icons";
import { Anchor, Divider } from "antd";
import { v4 } from "uuid";
import { CustomerAvatar } from "../Common/CustomerAvatar";
import { EmailClamp } from "../Common/EmailClamp";
import { LongTextCollect } from "../Common/LongTextCollect";
import { NoReportUnderLink } from "../Common/NoReport";
import { PlatagList } from "../Common/Platags/PlatagList";
import { ShowTags } from "../Common/ShowTags";

export const Instagram = ({ selectPlatform: platfrom, setUpdateTime, setUpdateBy }) => {
  const { copiedMark, handleCopy } = useCopy();

  // 侧边栏
  const [email, setEmail] = useState([]);

  const [ID, setID] = useState(true);
  const [platformLink, setPlatformLink] = useState("");

  useEffect(() => {
    setEmail(platfrom?.options?.email ?? []);

    setID(platfrom.id);
    setPlatformLink(platfrom.platform_url);
    setUpdateTime(platfrom?.updated_at);
    setUpdateBy(platfrom?.updater?.name);
  }, [platfrom, setUpdateTime, setUpdateBy]);
  return ID ? (
    <Layout>
      {copiedMark}
      <LayoutStick width={300}>
        <Card sectioned>
          <CustomerAvatar
            avatar={<Avatar size="large" source={platfrom?.options?.profilePictureUrl} />}
            name={platfrom?.options?.username}
            subName={`@${window.decodeURIComponent(platfrom?.options?.userId)}`}
            exta={
              <>
                {platfrom?.options?.isProfileVerified && (
                  <Tooltip content="is profile verified" preferredPosition="above">
                    <SafetyCertificateTwoTone twoToneColor="#2E72D2" />
                  </Tooltip>
                )}
                {platfrom?.options?.isMonetizationEnabled && (
                  <Tooltip content="Is monetization enabled" preferredPosition="above">
                    &nbsp;
                    <DollarTwoTone twoToneColor="#FAAD14" />
                  </Tooltip>
                )}
                {platfrom?.options?.isProfilePrivate && (
                  <Tooltip content="is profile private" preferredPosition="above">
                    &nbsp;
                    <TagTwoTone twoToneColor="#eb2f96" />
                  </Tooltip>
                )}
                {platfrom?.options?.joinedRecently && (
                  <Tooltip content="Joined recently" preferredPosition="above">
                    &nbsp;
                    <ThunderboltTwoTone twoToneColor="#52c41a" />
                  </Tooltip>
                )}
              </>
            }
          />
          <br />
          <Stack spacing="tight">
            {PlatagList([
              // 为了统一成general组件中的平台列表tag为以后扩展准备
              {
                subscribers: platfrom?.options?.totalFollowerCount,
                link: platfrom?.options?.profileUrl,
                type: {
                  title: platfrom?.type,
                },
              },
            ])}
          </Stack>
          <br />
          <TextContainer>
            <Text variant="bodyMd" as="span" color="subdued">
              <LongTextCollect longText={platfrom?.options?.profileBioRaw} />
            </Text>
          </TextContainer>
          <br />
          <Stack wrap={false}>
            <div style={{ width: 50, marginTop: -4 }}>
              <IconFont code="&#xe75a;" title="Email" color="#008685" />
            </div>
            <Stack.Item fill>
              <EmailClamp>{email?.join("\n")}</EmailClamp>
            </Stack.Item>
            {!!email?.join("\n") && (
              <Button
                icon={DuplicateMinor}
                plain
                onClick={() => handleCopy({ copyText: email?.join("\n") })}
              />
            )}
          </Stack>
          <Stack wrap={false}>
            <div style={{ width: 50, marginTop: -4 }}>
              <IconFont code="&#xe743;" title="Phone" color="#3171F2" />
            </div>
            <Stack.Item fill>
              <Text variant="bodyMd" as="span" color="subdued">
                {platfrom?.options?.contactPhoneNumber}
              </Text>
            </Stack.Item>
          </Stack>
          <Divider />
          <Anchor offsetTop={60}>
            <Anchor.Link href="#Overview" title="Overview" />
            <Anchor.Link href="#Tag" title="Tag" />
            <Anchor.Link href="#Other_websites" title="Other websites" />
          </Anchor>
        </Card>
      </LayoutStick>
      <Layout.Section>
        <Card title="Overview" sectioned>
          <AnchorMark id="Overview" />
          <Stack distribution="fillEvenly">
            <InfoCol label="Followers">
              <Text variant="bodyMd" as="p" fontWeight="bold">
                {formatNumber("unit", platfrom?.options?.totalFollowerCount)}
              </Text>
            </InfoCol>
            <InfoCol label="Mutual followers">
              <Text variant="bodyMd" as="p" fontWeight="bold">
                {formatNumber("unit", platfrom?.options?.mutualFollowerCount)}
              </Text>
            </InfoCol>
            <InfoCol label="Following">
              <Text variant="bodyMd" as="p" fontWeight="bold">
                {formatNumber("unit", platfrom?.options?.totalFollowingCount)}
              </Text>
            </InfoCol>
            <InfoCol label="Posts">
              <Text variant="bodyMd" as="p" fontWeight="bold">
                {formatNumber("unit", platfrom?.options?.totalPostCount)}
              </Text>
            </InfoCol>
          </Stack>
        </Card>
        <Card title="Tag" sectioned>
          <AnchorMark id="Tag" />
          <InfoRow label="Tag" end>
            <ShowTags tags={[platfrom?.options?.businessCategory]} />
          </InfoRow>
        </Card>
        <Card title="Other websites" sectioned>
          <AnchorMark id="Other_websites" />
          {platfrom?.options?.websiteUrl.map(({ websiteName, websiteUrl }) => {
            return (
              <InfoRow key={v4()} label={websiteName || "-"}>
                <Link url={websiteUrl} removeUnderline>
                  <TextClamp>{websiteUrl}</TextClamp>
                </Link>
              </InfoRow>
            );
          })}
        </Card>
      </Layout.Section>
    </Layout>
  ) : (
    <NoReportUnderLink link={platformLink} />
  );
};
