// antd datepicker高仿复用

// 华强北高仿! 原料: ant design 组件: DatePicker
export const datepickToShopifyStyle = {
  width: "100%", // 自适应撑满 Satck.Item
  padding: "6px 12px", // padding向shopify输入框靠拢,根据实际情况自定义
  borderRadius: 4, // 向shopify靠拢
  border: "1px solid rgba(201, 204, 207, 1)", // shopify颜色变量里面取的
  fontSize: "medium",
};

// error
export const errorMessageStyle = {
  width: "100%",
  padding: "6px 12px",
  fontSize: "medium",
  borderRadius: 4,
  border: "1px solid rgba(215, 44, 13)", // 颜色来自shopiify: criticl颜色
  backgroundColor: "rgba(215, 44, 13,0.07)",
};
