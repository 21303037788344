import { Badge } from "@shopify/polaris";

// ['NOT_START','IN_PROGRESS', 'COMPLETED','EXPIRED'] 根据后端数据来定对应的状态值--用数字表示:1 2 3 4
/**
 * @description: 根据对应状态值, 生成对应颜色的 badge
 * @param {*} status:常量
 * @return {*} <Badge></Badge>组件
 */
export function progressStatusBadge(status) {
  // status:  1 2 3 4
  // 含义 : 'NOT_START','IN_PROGRESS','COMPLETED','EXPIRED'
  status = Number(status);
  const colors = new Map([
    [1, ""], // 灰色
    [2, "info"],// 蓝色
    [3, "success"],// 绿色
    [4, "critical"],// 红色
  ]);
  const currentStatus = new Map([
    [1, "Not start"],
    [2, "In progress"],
    [3, "Completed"],
    [4, "Expired"],
  ]);

  const content = (status) => currentStatus.get(status);
  const badgeColor = (status) => colors.get(status);

  return <Badge status={badgeColor(status)}> <span style={{whiteSpace:"nowrap"}}>{content(status)}</span> </Badge>;
}
