const CheckBox = [
  "Influencer id",
  "Social link",
  "Followers",
  "Likes",
  "Posts",
  "Tag",
  "Source",

  "Create by",
  "Creation time",
  "Update by",
  "Update time",
];

const TikTokColumsOptions = CheckBox.map((str) => ({
  label: str,
  value: str,
}));

TikTokColumsOptions.unshift({
  label: "Influencer name",
  value: "Influencer name",
  disabled: true,
});
TikTokColumsOptions.push({
  label: "Operation",
  value: "Operation",
  disabled: true,
});

const TikTok_headings = TikTokColumsOptions.map(({ value }) => ({ title: value }));

export { TikTokColumsOptions, TikTok_headings };
