import { Button, ButtonGroup } from "@shopify/polaris";
import { useCallback, useMemo } from "react";

import { StopPropagation } from "@/components/EnhanceShopify/StopPropagation";
import { loadableAuthAtom } from "@/global/authAtom";
import { useBomLocation } from "@/hooks/useBomLocation";
import { useAtom } from "jotai";
import { useRecoilState } from "recoil";
import { contactID_atom } from "../MediaContactEditor/atom";

export const Operation = ({ id, handleEdit }) => {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      READ: permission.data?.["pmp/resources/discovery/read"],
      WRITE: permission.data?.["pmp/resources/discovery/write"],
      IMPORT: permission.data?.["pmp/resources/discovery/import"],
      EXPORT: permission.data?.["pmp/resources/discovery/export"],
    }),
    [permission]
  );
  const { origin } = useBomLocation();
  const [, setContactID] = useRecoilState(contactID_atom);

  const navgateReport = useCallback(() => {
    window.open(`${origin}/contacts/board/${id}`);
  }, [origin, id]);

  const navgateEdit = useCallback(() => {
    setContactID(id);
    handleEdit();
  }, [handleEdit, id, setContactID]);

  return (
    <StopPropagation>
      <ButtonGroup segmented>
        <Button plain onClick={navgateReport}>
          View
        </Button>
        {!access.WRITE ? undefined : (
          <Button plain onClick={navgateEdit}>
            Edit
          </Button>
        )}
      </ButtonGroup>
    </StopPropagation>
  );
};
