import { Stack, Text } from "@shopify/polaris";

export const InfoRow = ({ label, titleWidth = 120, children, end = false }) => {
  return (
    <>
      <Stack wrap={false}>
        <div style={{ width: titleWidth }}>
          <Text variant="bodyMd" as="p" color="subdued">
            {label}
          </Text>
        </div>
        <Stack.Item fill>{children}</Stack.Item>
      </Stack>
      {!end && <div style={{ height: 8 }} />}
    </>
  );
};
