import { useCallback, useState } from "react";

// 准备废弃: 使用 ahooks 里面的 useToggle 即可

// 用于触发 popover组件的显示和隐藏
export function usePopoverActive() {
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );
  return { popoverActive, setPopoverActive, togglePopoverActive };
}
