// `qstring.parse.ts` 查询字符串->对象
function parse(queryString) {
  const params = new URLSearchParams(queryString);
  const obj = {};
  Array.from(params.keys()).forEach((key) => {
    const val = params.getAll(key);
    obj[key] = val.length > 1 ? val : val[0];
  });
  return obj;
}

// `qstring.stringify.ts` 对象->查询字符串
function stringify(obj) {
  const parts = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const val = obj[key];
      if (Array.isArray(val)) {
        for (const subVal of val) {
          parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(subVal)}`);
        }
      } else {
        parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
      }
    }
  }
  return parts.join("&");
}

export const qstring = { parse, stringify };
