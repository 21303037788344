import { atom } from "recoil";
import { v4 } from "uuid";

// 平台tabs
const Plat_tab_init = atom({
  key: v4(),
  default: 0,
});

// 平台页签所有的principals 选项
const Plat_PrincipalOps_Atom = atom({
  key: "Plat_PrincipalOps",
  default: [],
});

export { Plat_tab_init, Plat_PrincipalOps_Atom };
