import "./Twitch.scss";

import { DollarTwoTone, SafetyCertificateTwoTone } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Layout,
  Link,
  Stack,
  Text,
  TextContainer,
  Tooltip
} from "@shopify/polaris";
import { useEffect, useState } from "react";

import { AnchorMark } from "@/components/EnhanceShopify/AnchorMark";
import { InfoRow } from "@/components/EnhanceShopify/InfoRow";
import { LayoutStick } from "@/components/EnhanceShopify/LayoutStick";
import { RowStack } from "@/components/EnhanceShopify/RowStack";
import { TextClamp } from "@/components/EnhanceShopify/TextClamp";
import { IconFont } from "@/components/Icon/PMPicon";
import { useCopy } from "@/hooks/useCopy";
import { formatNumber } from "@/utils/formatNumber";
import { DuplicateMinor } from "@shopify/polaris-icons";
import { Anchor, Divider } from "antd";
import { v4 } from "uuid";
import { Region } from "../../Platforms/Common/Region";
import { CustomerAvatar } from "../Common/CustomerAvatar";
import { EmailClamp } from "../Common/EmailClamp";
import { LongTextCollect } from "../Common/LongTextCollect";
import { NoReportUnderLink } from "../Common/NoReport";
import { PlatagList } from "../Common/Platags/PlatagList";
import { ShowTags } from "../Common/ShowTags";
import { SuggestedAccounts } from "./SuggestedAccounts";
import { VideoGallery } from "./VideoGallery";

export const Twitch = ({ selectPlatform: Tw, setUpdateTime, setUpdateBy }) => {
  const { copiedMark, handleCopy } = useCopy();

  const [ID, setID] = useState(null);
  const [platformLink, setPlatformLink] = useState("");

  const [userName, setuserName] = useState("");
  const [userAvatar, setUserAvatar] = useState("");
  const [userID, setUserID] = useState("");
  const [authIcon, setAuthIcon] = useState(0); // 是否认证,认证后颜色为:color="primary"
  const [moneyEnabled, setMoneyEnabled] = useState(false); // 是否开启打赏功能
  const [profile_url, setProfile_url] = useState("");

  const [followers, setFollowers] = useState(0);

  const [recentContents, setRecentContents] = useState([]);

  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [twitchTeam, setTwitchTeam] = useState("");
  const [otherWebsite, setOtherWebsite] = useState([]);
  const [suggestAccounts, setSuggestAccounts] = useState([]);

  useEffect(() => {
    setID(Tw?.id);
    setPlatformLink(Tw.platform_url);

    setUserAvatar(Tw?.options?.profilePictureUrl);

    setuserName(Tw?.options?.username);
    setUserID(Tw?.options?.userId);
    setAuthIcon(Tw?.options?.isProfileVerified);
    setMoneyEnabled(Tw?.options?.isMonetizationEnabled);
    setProfile_url(Tw?.options?.profileUrl);
    // // 次栏
    setFollowers(Tw?.options?.totalFollowerCount);
    // // 视频信息
    setRecentContents(Tw?.options?.latestPost);
    // // 侧边栏
    setDescription(Tw?.options?.profileBioRaw);
    setTags(Tw?.options?.contentHashtagLatestPost);
    setTwitchTeam(Tw?.options?.twitchTeamUrl);
    setOtherWebsite(Tw?.options?.websiteUrl);
    setSuggestAccounts(Tw?.options?.suggestedProfile ?? []);
    setUpdateTime(Tw?.updated_at);
    setUpdateBy(Tw?.updater?.name);
  }, [Tw, setUpdateTime, setUpdateBy]);

  return ID ? (
    <Layout>
      {copiedMark}
      <LayoutStick width={300}>
        <Card sectioned>
          <RowStack>
            <Region
              name={Tw?.region?.name}
              image={Tw?.region?.image}
              languages={[{ name: Tw?.options?.broadcastLanguage }]}
              code={Tw?.region?.code}
            />
          </RowStack>
          <br />
          <CustomerAvatar
            avatar={<Avatar size="large" source={userAvatar} />}
            name={userName}
            subName={`@${window.decodeURIComponent(userID)}`}
            exta={
              <>
                {authIcon && (
                  <Tooltip content="Is profile verified" preferredPosition="above">
                    <SafetyCertificateTwoTone twoToneColor="#2E72D2" />
                  </Tooltip>
                )}
                {moneyEnabled && (
                  <Tooltip content="Is monetization enabled" preferredPosition="above">
                    &nbsp;
                    <DollarTwoTone twoToneColor="#FAAD14" />
                  </Tooltip>
                )}
              </>
            }
          />
          <br />
          <Stack spacing="tight">
            {PlatagList([
              // 为了统一成general组件中的平台列表tag为以后扩展准备
              {
                subscribers: followers,
                link: profile_url,
                type: {
                  title: Tw?.type,
                },
              },
            ])}
          </Stack>
          <br />
          <TextContainer>
            <Text variant="bodyMd" as="span" color="subdued">
              <LongTextCollect longText={description} />
            </Text>
          </TextContainer>
          <br />
          <Stack wrap={false}>
            <div style={{ width: 50, marginTop: -4 }}>
              <IconFont code="&#xe75a;" title="Email" color="#008685" />
            </div>
            <Stack.Item fill>
              <EmailClamp>{Tw?.options?.email?.join("\n")}</EmailClamp>
            </Stack.Item>
            {!!Tw?.options?.email?.join("\n") && (
              <Button
                icon={DuplicateMinor}
                plain
                onClick={() => handleCopy({ copyText: Tw?.options?.email?.join("\n") })}
              />
            )}
          </Stack>
          <Divider />
          <Anchor offsetTop={60}>
            <Anchor.Link href="#Overview" title="Overview" />
            <Anchor.Link href="#Post_contents" title="Post contents" />
            <Anchor.Link href="#Other_websites" title="Other websites" />
            <Anchor.Link href="#Suggested_accounts" title="Suggested accounts" />
          </Anchor>
        </Card>
      </LayoutStick>
      <Layout.Section>
        <Card sectioned title="Overview">
          <AnchorMark id="Overview" />
          <InfoRow label="Followers">{formatNumber("unit", followers)}</InfoRow>
          <InfoRow label="Tag" end>
            <ShowTags tags={tags} />
          </InfoRow>
        </Card>
        <Card title="Post contents">
          <AnchorMark id="Post_contents" />
          <VideoGallery contents={recentContents} title="Post contents" />
        </Card>
        <Card title="Other websites" sectioned>
          <AnchorMark id="Other_websites" />
          <InfoRow label="Twitch team url">
            <Link url={twitchTeam} removeUnderline>
              <TextClamp>{twitchTeam}</TextClamp>
            </Link>
          </InfoRow>
          {Array.isArray(otherWebsite) ? (
            otherWebsite?.map(({ anchorText, websiteUrl }, i) => (
              <InfoRow key={v4()} label={anchorText}>
                <Link url={websiteUrl} removeUnderline>
                  <TextClamp clamp={1}>{websiteUrl}</TextClamp>
                </Link>
              </InfoRow>
            ))
          ) : (
            <Link url={otherWebsite} removeUnderline>
              {otherWebsite}
            </Link>
          )}
          {}
        </Card>
        <>
          <SuggestedAccounts list={suggestAccounts} />
          <AnchorMark id="Suggested_accounts" />
        </>
      </Layout.Section>
    </Layout>
  ) : (
    <NoReportUnderLink link={platformLink} />
  );
};
