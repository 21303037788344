import { Fragment, useCallback, useContext, useState } from "react";

import { LoadingContext } from "@/context/loading-context";
import pubsub from "sweet-pubsub";

export default function AxiosErrorContainer() {
  const loadingContext = useContext(LoadingContext);

  const handleAxiosError = useCallback((err) => {
    loadingContext.loading(false, err ?? "PMP server error", true);
    // eslint-disable-next-line
  }, []);

  useState(() => {
    pubsub.on("AxiosError", handleAxiosError);

    return () => {
      pubsub.off("AxiosError", handleAxiosError);
    };
  }, []);

  return <Fragment />;
}
