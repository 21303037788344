const init_options = [
  "Media outlet name",
  "Country/region",
  "Role",
  "Email",
  "Phone",
  "Media contact homepage URL",
  "Social link",
  "Tag",
  "Create by",
  "Creation time",
  "Update by",
  "Update time",
];

const columnOps = init_options.map((str) => ({
  label: str,
  value: str,
}));

columnOps.unshift({
  label: "Media contact name",
  value: "Media contact name",
  disabled: true,
});
columnOps.push({
  label: "Operation",
  value: "Operation",
  disabled: true,
});

const init_headings = columnOps.map(({ value }) => ({ title: value }));

export { columnOps, init_headings };
