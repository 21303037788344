import { ExportMinor, ImportMinor } from "@shopify/polaris-icons";
import { pubExportActionAtom } from "../PartnersTabs/atom";

import { useRefrehTable } from "@/customHooks/useRefrehTable";
import { useRecoilToggle } from "@/hooks/useRecoilToggle";
import { Page } from "@shopify/polaris";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { toggleImportAtom } from "../Platforms/import/atom";
import { Profiles } from "../Profiles";

export const Partners = () => {
  const history = useHistory();
  const handleAddPartner = useCallback(() => history.push(`/home/fresh/add`), [history]);
  const { handleRefresh: IwantExport } = useRefrehTable(pubExportActionAtom); // 刷新关联的table

  // 导入伙伴弹窗
  const { toggle: toggleActive } = useRecoilToggle(toggleImportAtom);

  return (
    <Page
      title="Partners"
      fullWidth
      primaryAction={{ content: "Create partner", onAction: handleAddPartner }}
      secondaryActions={[
        {
          content: "Export",
          onAction: IwantExport,
          icon: ExportMinor,
        },
        {
          content: "Import",
          onAction: toggleActive,
          icon: ImportMinor,
        },
      ]}
    >
      <Profiles />
    </Page>
  );
};
