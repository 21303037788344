import styled from "styled-components";

export const AvatarGender = styled.div`
  position: relative;
  > .anticon {
    position: absolute;
    padding: 2px;
    border: 1px solid white;
    border-radius: 50%;
    right: 0;
    bottom: 0px;
  }
  > .anticon-man {
    background-color: #2e72d2;
  }
  > .anticon-woman {
    background-color: #ff71ca;
  }
`;
