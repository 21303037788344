import { Stack, Text } from "@shopify/polaris";

import { TextClamp } from "./TextClamp";

export const SiderInfoRow = ({ label, value }) => {
  return (
    <Stack distribution="" wrap={false} alignment="center">
      <Stack.Item>
        <div style={{ width: 100 }}>
          <Text variant="bodyMd" as="span" color="subdued">
            {label}
          </Text>
        </div>
      </Stack.Item>
      <Stack.Item>
        <div style={{ width: 150 }}>
          <TextClamp clamp={3}>{value}</TextClamp>
        </div>
      </Stack.Item>
    </Stack>
  );
};
