import { getPartnerTags, postAddTags2Partners } from "@/api/request";
import { AddTagStyled, TagsOptionStyled } from "@/components/EnhanceShopify/AddTagStyled";
import { Button, Icon, Modal, OptionList, Scrollable, Stack, TextField } from "@shopify/polaris";
import { CirclePlusMinor, SearchMinor } from "@shopify/polaris-icons";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useBottomSpinner } from "@/customHooks/useBottomSpinner";
import { useGlobalToast } from "@/customHooks/useGlobalToast";
import { useTextFieldLogic } from "@/customHooks/useTextFieldLogic";
import { useDebounceFn } from "ahooks";

export const AddTag = ({ active, handleChange, handleSave, partnerIDs }) => {
  const { handleGlobalToast } = useGlobalToast();
  const { BottomSpinner, setIsLoading } = useBottomSpinner();
  const { value, setValue, handleChange: handleSearch } = useTextFieldLogic();
  const handleClearSearch = useCallback(() => setValue(""), [setValue]); // 输入的文案

  const [selected, setSelected] = useState([]);
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (selected?.length) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [selected]);
  // options 分类
  // 本第新增的列表
  const [newlyAddOptions, setNewlyAddOptions] = useState([]);
  // 后端返回的列表
  const [availableOptions, setAvailableOptions] = useState([]);

  // 点击添加 tags 操作
  const handleAdd = useCallback(() => {
    setNewlyAddOptions((oldArr) => [{ label: value, value }, ...oldArr]);
    setSelected((selected) => [...selected, value]);
    handleClearSearch();
  }, [value, handleClearSearch]);

  const handleSelectChange = useCallback((select) => {
    setSelected(select);
    setNewlyAddOptions((oldOptions) => {
      const res = oldOptions.filter(({ value }) => select.includes(value));
      return res;
    });
  }, []);

  // 清除选中状态, 清空新增选项列表 (新增选项列表未提交都是无效的列表, 仅供新增时用户查看, 不作为保留列表)
  const handleClearSelect = useCallback(() => {
    setSelected([]);
    setNewlyAddOptions([]);
  }, []);

  // 校验输入结果
  // 需要查询新增的 tags 和 后端存储的 tags, 输入的是否重复, 重复的则不显示 add 按钮
  const showAddButton = useMemo(() => {
    if (!value?.trim()) {
      return false;
    } else {
      const newlyDuplicate = newlyAddOptions.map(({ value }) => value).includes(value);
      const avaliableDuplicate = availableOptions.map(({ value }) => value).includes(value);
      return !(newlyDuplicate || avaliableDuplicate);
    }
  }, [value, newlyAddOptions, availableOptions]);

  //#region request

  // 首次加载请求的传参:
  const initParams = useMemo(() => {
    return {
      title: value,
      is_not_paged: 1,
    };
  }, [value]);

  // 在请求展示所有条目的基础上, 筛选当前页条目:
  const { run } = useDebounceFn(
    async (params) => {
      setIsLoading(true);
      const { data } = await getPartnerTags({ ...initParams, ...params });

      const optionList = data.list.map(({ title }) => ({ label: title, value: title }));
      setAvailableOptions(optionList);

      setIsLoading(false);
    },
    { wait: 600 }
  );
  const reqCurrentTable = useCallback(run, [initParams, run]);

  // tabs / 搜搜栏 / creator/sort/project 变化
  useEffect(() => {
    reqCurrentTable();
  }, [
    // 不能监听页码和每页数, 会造成挂载执行两次
    reqCurrentTable,
    value,
  ]);

  //#endregion

  return (
    <Modal
      open={active}
      onClose={() => {
        handleClearSelect();
        handleChange();
      }}
      title={`Add ${selected.length} tag${selected.length > 1 ? "s" : ""} to ${
        partnerIDs?.length > 1
          ? partnerIDs.length + " influencers"
          : partnerIDs.length + " influencer"
      } `}
      primaryAction={{
        content: "Save",
        disabled,
        onAction: async () => {
          try {
            await postAddTags2Partners(partnerIDs, selected);
            handleSave();
            handleChange();
            handleClearSelect();
            handleGlobalToast({ msg: "Add tag successfully!" });
          } catch (error) {
            handleGlobalToast({ msg: "Add tag unsuccessfully!", isErr: true });
          }
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction() {
            handleClearSelect();
            handleChange();
          },
        },
      ]}
    >
      <div style={{ position: "relative" }}>
        {BottomSpinner}
        <Scrollable style={{ height: "420px" }} shadow>
          <Modal.Section>
            <Stack vertical>
              <TextField
                value={value}
                onChange={handleSearch}
                autoComplete="off"
                prefix={<Icon source={SearchMinor} />}
                placeholder="Search or create tag"
                clearButton
                onClearButtonClick={handleClearSearch}
              />
              {showAddButton && (
                // 样式外壳
                <AddTagStyled>
                  <Button
                    icon={CirclePlusMinor}
                    plain
                    fullWidth
                    removeUnderline
                    textAlign="left"
                    onClick={handleAdd}
                  >
                    {/* 模仿官方后台的效果: 输入内容会作为按钮的文案的一部分 */}
                    Add {value.trim() && `"` + value + `"`}
                  </Button>
                </AddTagStyled>
              )}
              <TagsOptionStyled>
                <OptionList
                  onChange={handleSelectChange}
                  sections={[
                    newlyAddOptions.length && { title: "newly added", options: newlyAddOptions },
                    availableOptions.length && { title: "available", options: availableOptions },
                  ]}
                  selected={selected}
                  allowMultiple
                />
              </TagsOptionStyled>
            </Stack>
          </Modal.Section>
        </Scrollable>
      </div>
    </Modal>
  );
};
