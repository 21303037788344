import {
  deletePartners,
  deletePartnerToGroup,
  getExportFile,
  getPartners,
  getPartnersFilterInit,
} from "@/api/request";
import {
  filterOptions,
  showOptionLabelsInTextFeild,
  updateTextRegFilter,
} from "@/utils/updateTextRegFilter";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  ChoiceList,
  Filters,
  IndexTable,
  Modal,
  Popover,
  Select,
  Stack,
  Text,
  TextContainer,
  TextField,
  useIndexResourceState,
} from "@shopify/polaris";
import { Columns3Minor, SortMinor } from "@shopify/polaris-icons";
import { useDebounceFn, useMount, useToggle, useUpdateEffect } from "ahooks";
import { DatePicker as AntDatePicker, Pagination, TreeSelect } from "antd";
import { compact, omit } from "lodash";
import { useCallback, useEffect, useState } from "react";
import {
  ptns_current_page,
  ptns_filter_blackListSelect,
  ptns_filter_companyDesOps,
  ptns_filter_companyOps,
  ptns_filter_companySelect,
  ptns_filter_contactSelect,
  ptns_filter_countryDesOps,
  ptns_filter_countryOps,
  ptns_filter_countrySelect,
  ptns_filter_creatimeSelect,
  ptns_filter_creatorDesOps,
  ptns_filter_creatorOps,
  ptns_filter_creatorSelect,
  ptns_filter_currency,
  ptns_filter_currencyLabel,
  ptns_filter_currencyOps,
  ptns_filter_gender,
  ptns_filter_groupDesOps,
  ptns_filter_groupOps,
  ptns_filter_groupSelect,
  ptns_filter_langDesOps,
  ptns_filter_langOps,
  ptns_filter_langSelect,
  ptns_filter_plaTypeDesOps,
  ptns_filter_plaTypeOps,
  ptns_filter_plaTypeSelect,
  ptns_filter_popDesOps,
  ptns_filter_popOps,
  ptns_filter_popSelect,
  ptns_filter_priceMax,
  ptns_filter_priceMin,
  ptns_filter_principalDesOps,
  ptns_filter_principalOps,
  ptns_filter_principalSelect,
  ptns_filter_sort,
  ptns_filter_subsMax,
  ptns_filter_subsMin,
  ptns_filter_subsSelect,
  ptns_per_page,
  ptns_search_text,
  ptns_search_type,
} from "./atom";
import { profiles_headings, profilesColumsOptions } from "./helper";

import { AntPagination } from "@/components/AntToShopify/AntPagination";
import { AntTreeSelect } from "@/components/AntToShopify/AntTreeSelect";
import { CountryEmoji } from "@/components/EnhanceShopify/CountryEmoji";
import { FilterBox } from "@/components/EnhanceShopify/FilterBox";
import { StopPropagation } from "@/components/EnhanceShopify/StopPropagation";
import { CountryRegionListAtom } from "@/components/InitCountryRegions";
import { useGlobalToast } from "@/customHooks/useGlobalToast";
import { useRefrehTable } from "@/customHooks/useRefrehTable";
import { useTopLoading } from "@/customHooks/useTopLoading";
import { loadableAuthAtom } from "@/global/authAtom";
import { useDispatchResize } from "@/hooks/useDispatchResize";
import { useDynamicHeadings } from "@/hooks/useDynamicHeadings";
import { useRecoilToggle } from "@/hooks/useRecoilToggle";
import { useStoreLocalState } from "@/hooks/useStoreLocalState";
import { s_to_ms } from "@/utils/accurateTimeTrans";
import { splitChoiceValue } from "@/utils/checkType";
import { ConfirmDeleteModal } from "@/utils/ConfirmDeleteModal";
import { datepickToShopifyStyle } from "@/utils/datepickToShopifyStyle";
import { treeData } from "@/utils/frontEndData/_treeTestData";
import { searchOps } from "@/utils/frontEndData/searchBy";
import { isEmpty } from "@/utils/isEmpty";
import { moment2Date } from "@/utils/moment2Date";
import { openNewWindow } from "@/utils/openNewWindow";
import { transformNumber } from "@/utils/tools";
import { wait } from "@/utils/wait";
import { useAtom } from "jotai";
import moment from "moment";
import { useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { v4 } from "uuid";
import { refrehCollectionAtom } from "../Collections/Common/atom/atom";
import { influencer_refresh } from "../Influencers/atom";
import { editorActive_atom } from "../Influencers/Eidtor/atom";
import { Eidtor } from "../Influencers/Eidtor/Eidtor";
import { pubExportActionAtom } from "../PartnersTabs/atom";
import { matchSearchType } from "../Platforms/Common/matchSearchType";
import { AddTag } from "./Common/AddTag";
import { AddToCampaign } from "./Common/AddToCampaign";
import { AddToCollection } from "./Common/AddToCollection";
import { ContactStatus } from "./Common/ContactStatus";
import { Operation } from "./Common/Operation";
import { RowBlckList } from "./Common/RowBlckList";
import { RowPlatforms } from "./Common/RowPlatforms";
import { RowTextList } from "./Common/RowTextList";
import { TagPopover } from "./Common/TagPopover";
import { PartnerNav } from "./PartnerNav";

const { RangePicker: AntDateRangePicker } = AntDatePicker;
const { SHOW_PARENT } = TreeSelect;

export const Profiles = ({ CollectionID }) => {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      WRITE: permission.data?.["pmp/resources/discovery/write"],
      READ: permission.data?.["pmp/resources/discovery/read"],
    }),
    [permission]
  );
  const regions = useRecoilValue(CountryRegionListAtom);

  const { active: refreshInfluencer } = useRecoilToggle(influencer_refresh);
  const { handleGlobalToast } = useGlobalToast();
  const { refreshMark } = useRefrehTable(refrehCollectionAtom); // 翻越千山万水从collection add partner 成功时, 让该列表刷新
  const [isExport] = useRecoilState(pubExportActionAtom);
  const { TopLoadingJSX, hiddenTopLoading, showTopLoading } = useTopLoading();
  const { active: EditorActive } = useRecoilToggle(editorActive_atom);

  //#region Add Tag / to Campaign / Collection modal state / delete modal state
  const [addTagModalActive, { toggle: toggleAddTagModal }] = useToggle();
  const [add2CampaignModalActive, { toggle: toggleadd2CampaignModal }] = useToggle();
  const [add2CollectionModalActive, { toggle: toggleadd2CollectionModal }] = useToggle();
  const [deletePartnerActive, { toggle: toggleDeletePartnerModal }] = useToggle();

  //#endregion

  // search type
  const [searchType, setSearchType] = useRecoilState(ptns_search_type);

  // sort
  const sortOptions = useMemo(
    () => [
      { label: "Total followers (Max-Min)", value: "subscriber|desc" },
      { label: "Total followers (Min-Max)", value: "subscriber|asc" },
      { label: "Creation time (oldest first)", value: "created_at|asc" },
      { label: "Creation time (newest first)", value: "created_at|desc" },
      { label: "Update time (oldest first)", value: "updated_at|asc" },
      {
        label: "Update time (newest first)",
        value: "updated_at|desc",
      },
    ],
    []
  );
  const [sortState, setSortState] = useState("updated_at"); // 排序类型:最新时间
  const [sortOrder, setSortOrder] = useState("desc"); // 排序倒序

  const [sortValue, setSortValue] = useRecoilState(ptns_filter_sort);
  const handleSortChange = useCallback((value) => setSortValue(value), [setSortValue]);

  // 筛选参数
  useEffect(() => {
    const [update, sort] = splitChoiceValue(sortValue);
    setSortState(update);
    setSortOrder(sort);
  }, [sortValue]);

  const [columnsActive, { toggle: toggleColumnsActive }] = useToggle();

  // popover
  const [popoverSortActive, { toggle: togglePopoverSortActive }] = useToggle();
  // indextable 上方的筛选框和搜索框
  const [queryValue, setQueryValue] = useRecoilState(ptns_search_text);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), [setQueryValue]);

  //#region Gender
  const [selectedGender, setSelectedGender] = useRecoilState(ptns_filter_gender);
  const handleGenderSelectChange = useCallback(
    (value) => setSelectedGender(value),
    [setSelectedGender]
  );
  const genderOptions = [
    { label: "Male", value: "1" },
    { label: "Female", value: "2" },
  ];

  const handleGenderRemove = useCallback(() => {
    setSelectedGender("");
  }, [setSelectedGender]);
  //#endregion

  //#region language
  const [deselectedLanguageOption, setDeselectedLanguageOption] =
    useRecoilState(ptns_filter_langDesOps);
  const [languageOptions, setLanguageOptions] = useRecoilState(ptns_filter_langOps);
  const [selectedLanguage, setSelectedLanguage] = useRecoilState(ptns_filter_langSelect);
  const [languageInput, setLanguageInput] = useState("");
  const handleLanguageOnSelect = useCallback(
    (selected) => {
      setSelectedLanguage(selected);
    },
    [setSelectedLanguage]
  );
  const updateLanguageText = useCallback(
    (value) => {
      setLanguageInput(value);
      if (value === "") {
        setLanguageOptions(deselectedLanguageOption);
        return;
      }
      const resultOptions = updateTextRegFilter(value, deselectedLanguageOption);
      setLanguageOptions(resultOptions);
    },
    [deselectedLanguageOption, setLanguageOptions]
  );
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(selectedLanguage, deselectedLanguageOption);
    setLanguageInput(showtext);
  }, [selectedLanguage, deselectedLanguageOption]);

  const handleLanguageRemove = useCallback(() => setSelectedLanguage([]), [setSelectedLanguage]);
  //#endregion

  //#region Tier
  const [deselectedTierOptions, setDeselectedTierOptions] = useRecoilState(ptns_filter_popDesOps);
  const [tierOptions, setTierOptions] = useRecoilState(ptns_filter_popOps);
  const [selectTier, setSelectTier] = useRecoilState(ptns_filter_popSelect);
  const [tierInput, setTierInput] = useState("");
  const handleTierOnSelect = useCallback(
    (selected) => {
      setSelectTier(selected);
    },
    [setSelectTier]
  );
  const updatePopularityText = useCallback(
    (value) => {
      setTierInput(value);
      if (value === "") {
        setTierOptions(deselectedTierOptions);
        return;
      }
      const resultOptions = updateTextRegFilter(value, deselectedTierOptions);
      setTierOptions(resultOptions);
    },
    [deselectedTierOptions, setTierOptions]
  );
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(selectTier, deselectedTierOptions);
    setTierInput(showtext);
  }, [selectTier, deselectedTierOptions]);

  const handlePopularityRemove = useCallback(() => setSelectTier([]), [setSelectTier]);
  //#endregion

  //#region Owner
  const [deselectedOwnerOptions, setDeselectedOwnerOptions] = useRecoilState(
    ptns_filter_principalDesOps
  );
  const [ownerOptions, setOwnerOptions] = useRecoilState(ptns_filter_principalOps);
  const [selectOwner, setSelectOwner] = useRecoilState(ptns_filter_principalSelect);
  const [ownerInput, setOwnerInput] = useState("");
  const handleOwnerOnSelect = useCallback(
    (selected) => {
      setSelectOwner(selected);
    },
    [setSelectOwner]
  );
  const updatePrincipalText = useCallback(
    (value) => {
      setOwnerInput(value);
      if (value === "") {
        setOwnerOptions(deselectedOwnerOptions);
        return;
      }
      const resultOptions = updateTextRegFilter(value, deselectedOwnerOptions);
      setOwnerOptions(resultOptions);
    },
    [deselectedOwnerOptions, setOwnerOptions]
  );
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(selectOwner, deselectedOwnerOptions);
    setOwnerInput(showtext);
  }, [selectOwner, deselectedOwnerOptions]);

  const handlePrincipalRemove = useCallback(() => setSelectOwner([]), [setSelectOwner]);
  //#endregion

  //#region Platform type
  const [deselectedPlatformTypeOptions, setDeselectedPlatformTypeOptions] =
    useRecoilState(ptns_filter_plaTypeDesOps);
  const [platformTypeOptions, setPlatformTypeOptions] = useRecoilState(ptns_filter_plaTypeOps);
  const [selectedPlatformType, setSelectedPlatformType] = useRecoilState(ptns_filter_plaTypeSelect);
  const [platformTypeInput, setPlatformTypeInput] = useState("");
  const handlePlatformTypeOnSelect = useCallback(
    (selected) => {
      setSelectedPlatformType(selected);
    },
    [setSelectedPlatformType]
  );

  const updatePlatformTypeText = useCallback(
    (value) => {
      setPlatformTypeInput(value);
      if (value === "") {
        setPlatformTypeOptions(deselectedPlatformTypeOptions);
        return;
      }
      const resultOptions = updateTextRegFilter(value, deselectedPlatformTypeOptions);
      setPlatformTypeOptions(resultOptions);
    },
    [deselectedPlatformTypeOptions, setPlatformTypeOptions]
  );
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(
      selectedPlatformType,
      deselectedPlatformTypeOptions
    );
    setPlatformTypeInput(showtext);
  }, [selectedPlatformType, deselectedPlatformTypeOptions]);

  const handlePlatformTypeRemove = useCallback(
    () => setSelectedPlatformType([]),
    [setSelectedPlatformType]
  );
  //#endregion

  //#region Platform subscribers
  let subscribersChoose = [
    { label: "0-50K", value: "0-50000" },
    { label: "50-100K", value: "50000-100000" },
    { label: "100-200K", value: "100000-200000" },
    { label: "200-500K", value: "200000-500000" },
    { label: "500K-1M", value: "500000-1000000" },
    { label: "1M+", value: "1M+" },
  ];
  const [subscribersSelected, setSubscribersSelected] = useRecoilState(ptns_filter_subsSelect);
  const [minnum, setminnumValue] = useRecoilState(ptns_filter_subsMin);
  const [maxnum, setmaxnumValue] = useRecoilState(ptns_filter_subsMax);

  const subscribershandleChange = useCallback(
    (value) => {
      setSubscribersSelected(value);
      if (value[0] === "1M+") {
        setminnumValue(1);
        setmaxnumValue(2);
      } else if (value[0] === "") {
        setminnumValue("");
        setmaxnumValue("");
      } else {
        setminnumValue(+value[0].split("-")[0]);
        setmaxnumValue(+value[0].split("-")[1]);
      }
    },
    [setSubscribersSelected, setminnumValue, setmaxnumValue]
  );

  const handleminChange = useCallback(
    (newValue) => {
      setminnumValue(newValue);
    },
    [setminnumValue]
  );
  const handlemaxChange = useCallback(
    (newValue) => {
      setmaxnumValue(newValue);
    },
    [setmaxnumValue]
  );

  const handleSubscribersRemove = useCallback(() => {
    setSubscribersSelected(null);
    setminnumValue("");
    setmaxnumValue("");
  }, [setSubscribersSelected, setminnumValue, setmaxnumValue]);
  //#endregion

  //#region  Price
  const [minPrice, setMinPrice] = useRecoilState(ptns_filter_priceMin);
  const [maxPrice, setMaxPrice] = useRecoilState(ptns_filter_priceMax);
  const handleMinPriceChange = useCallback(
    (newValue) => {
      setMinPrice(newValue.replace(/-/gi, ""));
    },
    [setMinPrice]
  );
  const handleMaxPriceChange = useCallback(
    (newValue) => {
      setMaxPrice(newValue.replace(/-/gi, ""));
    },
    [setMaxPrice]
  );
  const [currencyOptions, setCurrencyOptions] = useRecoilState(ptns_filter_currencyOps);
  const [inputCur, setInputCur] = useRecoilState(ptns_filter_currencyLabel);
  const [currency, setCurrency] = useRecoilState(ptns_filter_currency);
  const handleCurrencyChange = useCallback(
    (newValue) => {
      setCurrency(newValue);
      currencyOptions.forEach((item) => {
        if (item.value === newValue) {
          setInputCur(item.label);
        }
      });
    },
    [currencyOptions, setCurrency, setInputCur]
  );
  const handlePriceRemove = useCallback(() => {
    setCurrency("");
    setMinPrice("");
    setMaxPrice("");
  }, [setCurrency, setMinPrice, setMaxPrice]);
  //#endregion

  //#region  Contact status
  const _status = [
    { label: "Not contacted", value: "0" },
    { label: "In contact", value: "1" },
    { label: "Rejected", value: "2" },
    { label: "Accepted", value: "3" },
  ];
  const [deselectedStatusOptions] = useState(_status);
  const [statusOptions, setStatusOptions] = useState(_status);
  const [selectedStatus, setSelectedStatus] = useRecoilState(ptns_filter_contactSelect);
  const [statusInput, setStatusInput] = useState("");
  const handleStatusOnSelect = useCallback(
    (selected) => {
      setSelectedStatus(selected);
    },
    [setSelectedStatus]
  );

  const updatStatusText = useCallback(
    (value) => {
      setStatusInput(value);
      if (value === "") {
        setStatusOptions(deselectedStatusOptions);
        return;
      }
      const resultOptions = updateTextRegFilter(value, deselectedStatusOptions);
      setStatusOptions(resultOptions);
    },
    [deselectedStatusOptions]
  );
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(selectedStatus, deselectedStatusOptions);
    setStatusInput(showtext);
  }, [selectedStatus, deselectedStatusOptions]);

  const handleStatusRemove = useCallback(() => setSelectedStatus([]), [setSelectedStatus]);

  //#endregion

  //#region Blacklist
  const [blacklistSelected, setBlacklistSelected] = useRecoilState(ptns_filter_blackListSelect);
  const handleBlacklistSelectChange = useCallback(
    (value) => setBlacklistSelected(value),
    [setBlacklistSelected]
  );
  const blacklistOptions = [
    { label: "Unblock", value: "0" },
    { label: "Block", value: "1" },
  ];

  const handleBlacklistRemove = useCallback(() => {
    setBlacklistSelected(null);
  }, [setBlacklistSelected]);
  //#endregion

  //#region Country filter
  const [deselectedCountryOptions, setDeselectedCountryOptions] =
    useRecoilState(ptns_filter_countryDesOps);
  const [countryOptions, setCountryOptions] = useRecoilState(ptns_filter_countryOps);
  const [selectedCountry, setSelectedCountry] = useRecoilState(ptns_filter_countrySelect);
  const [countryInputValue, setCountryInputValue] = useState("");
  const handleCountryOnSelect = useCallback(
    (selectList) => {
      setSelectedCountry(selectList);
    },
    [setSelectedCountry]
  );
  const updateCountryText = useCallback(
    (value) => {
      setCountryInputValue(value);
      if (value === "") {
        setCountryOptions(deselectedCountryOptions);
        return;
      }
      const resultOptions = updateTextRegFilter(value, deselectedCountryOptions);
      setCountryOptions(resultOptions);
    },
    [deselectedCountryOptions, setCountryOptions]
  );
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(selectedCountry, deselectedCountryOptions);
    setCountryInputValue(showtext);
  }, [selectedCountry, deselectedCountryOptions]);

  const handleCountryRemove = useCallback(() => setSelectedCountry([]), [setSelectedCountry]);
  //#endregion

  //#region Company
  const [deselectedCompanyOptions, setDeselectedCompanyOptions] =
    useRecoilState(ptns_filter_companyDesOps);
  const [companyOptions, setCompanyOptions] = useRecoilState(ptns_filter_companyOps);
  const [selectedCompany, setSelectedCompany] = useRecoilState(ptns_filter_companySelect);
  const [companyInputValue, setCompanyInputValue] = useState("");
  const handleCompanyOnSelect = useCallback(
    (selectList) => {
      setSelectedCompany(selectList);
    },
    [setSelectedCompany]
  );
  const updateCompanyText = useCallback(
    (value) => {
      setCompanyInputValue(value);
      if (value === "") {
        setCompanyOptions(deselectedCompanyOptions);
        return;
      }
      const resultOptions = updateTextRegFilter(value, deselectedCompanyOptions);
      setCompanyOptions(resultOptions);
    },
    [deselectedCompanyOptions, setCompanyOptions]
  );
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(selectedCompany, deselectedCompanyOptions);
    setCompanyInputValue(showtext);
  }, [selectedCompany, deselectedCompanyOptions]);

  const handleCompanyRemove = useCallback(() => setSelectedCompany([]), [setSelectedCompany]);
  //#endregion

  //#region Collection
  const [deselectedCollectionOptions, setDeselectedCollectionOptions] =
    useRecoilState(ptns_filter_groupDesOps);
  const [collectionOptions, setCollectionOptions] = useRecoilState(ptns_filter_groupOps);
  const [selectedCollection, setSelectedCollection] = useRecoilState(ptns_filter_groupSelect);
  const [collectionInputValue, setCollectionInputValue] = useState("");
  const handleCollectionOnSelect = useCallback(
    (selectList) => {
      setSelectedCollection(selectList);
    },
    [setSelectedCollection]
  );
  const updateCollectionText = useCallback(
    (value) => {
      setCollectionInputValue(value);
      if (value === "") {
        setCollectionOptions(deselectedCollectionOptions);
        return;
      }
      const resultOptions = updateTextRegFilter(value, deselectedCollectionOptions);
      setCollectionOptions(resultOptions);
    },
    [deselectedCollectionOptions, setCollectionOptions]
  );
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(selectedCollection, deselectedCollectionOptions);
    setCollectionInputValue(showtext);
  }, [selectedCollection, deselectedCollectionOptions]);

  const handleCollectionRemove = useCallback(
    () => setSelectedCollection([]),
    [setSelectedCollection]
  );
  //#endregion

  //#region Tags
  const [selectTreeValue, setSelectTreeValue] = useState([]);

  useEffect(() => {
    console.log("selectTreeValue: ", selectTreeValue);
  }, [selectTreeValue]);

  const onSlelectTreeChange = (newValue) => {
    setSelectTreeValue(newValue);
  };

  const handleTagRemove = useCallback(() => {
    setSelectTreeValue([]);
  }, []);

  const tProps = {
    treeData: treeData,
    value: selectTreeValue,
    onChange: onSlelectTreeChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT, // 若全选只选择上一级
    placeholder: "",
    style: {
      width: "100%",
    },
    dropdownStyle: {
      // 下拉框的样式
    },
    listHeight: 400, // 滚动高度
  };
  //#endregion

  //#region Create by
  const [deselectedCreatorOptions, setDeselectedCreatorOptions] =
    useRecoilState(ptns_filter_creatorDesOps);
  const [creatorOptions, setCreatorOptions] = useRecoilState(ptns_filter_creatorOps);
  const [selectedCreator, setSelectedCreator] = useRecoilState(ptns_filter_creatorSelect);
  const [creatorInputValue, setCreatorInputValue] = useState("");
  const handleCreatorOnSelect = useCallback(
    (selectList) => {
      setSelectedCreator(selectList);
    },
    [setSelectedCreator]
  );
  const updateCreatorText = useCallback(
    (value) => {
      setCreatorInputValue(value);
      if (value === "") {
        setCreatorOptions(deselectedCreatorOptions);
        return;
      }
      const resultOptions = updateTextRegFilter(value, deselectedCreatorOptions);
      setCreatorOptions(resultOptions);
    },
    [deselectedCreatorOptions, setCreatorOptions]
  );
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(selectedCreator, deselectedCreatorOptions);
    setCreatorInputValue(showtext);
  }, [selectedCreator, deselectedCreatorOptions]);

  const handleCreatorRemove = useCallback(() => setSelectedCreator([]), [setSelectedCreator]);
  //#endregion

  //#region Creation time
  const [creationTime, setCreationTime] = useRecoilState(ptns_filter_creatimeSelect);
  const onCreationTimeChange = useCallback(
    (dates, dateStrings) => {
      setCreationTime(dateStrings);
    },
    [setCreationTime]
  );

  const handleCreationTimeRemove = useCallback(() => setCreationTime(null), [setCreationTime]);
  //#endregion

  // remove all
  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
    handleGenderRemove();
    handleLanguageRemove();
    handlePopularityRemove();
    handlePrincipalRemove();
    handlePlatformTypeRemove();
    handleSubscribersRemove();
    handlePriceRemove();
    handleStatusRemove();
    handleBlacklistRemove();
    handleCountryRemove();
    handleCompanyRemove();
    handleCollectionRemove();
    handleTagRemove();
    handleCreatorRemove();
    handleCreationTimeRemove();
  }, [
    handleQueryValueRemove,
    handleGenderRemove,
    handleLanguageRemove,
    handlePopularityRemove,
    handlePrincipalRemove,
    handlePlatformTypeRemove,
    handleSubscribersRemove,
    handlePriceRemove,
    handleStatusRemove,
    handleBlacklistRemove,
    handleCountryRemove,
    handleCompanyRemove,
    handleCollectionRemove,
    handleTagRemove,
    handleCreatorRemove,
    handleCreationTimeRemove,
  ]);

  const filters = [
    // Gender
    {
      key: "Gender",
      label: "Gender",
      filter: (
        <ChoiceList
          title="Gender:"
          titleHidden
          choices={genderOptions}
          selected={selectedGender}
          onChange={handleGenderSelectChange}
        />
      ),
    },
    // Language
    {
      key: "Language",
      label: "Language",
      filter: (
        <>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            options={languageOptions}
            selected={selectedLanguage}
            textField={
              <Autocomplete.TextField
                autoComplete="off"
                onChange={updateLanguageText}
                // label="Tags"
                value={languageInput}
              />
            }
            onSelect={handleLanguageOnSelect}
            listTitle="Language"
          />
        </>
      ),
    },
    // Tier
    {
      key: "Tier",
      label: "Tier",
      filter: (
        <>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            options={tierOptions}
            selected={selectTier}
            textField={
              <Autocomplete.TextField
                onChange={updatePopularityText}
                label="Tier"
                labelHidden
                value={tierInput}
                // placeholder="All"
                autoComplete="off"
              />
            }
            onSelect={handleTierOnSelect}
            listTitle="Tier"
          />
        </>
      ),
      // hideClearButton: true,
    },
    // Owner
    {
      key: "Owner",
      label: "Owner",
      filter: (
        <>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            options={ownerOptions}
            selected={selectOwner}
            textField={
              <Autocomplete.TextField
                onChange={updatePrincipalText}
                label="Owner"
                labelHidden
                value={ownerInput}
                // placeholder="All"
                autoComplete="off"
              />
            }
            onSelect={handleOwnerOnSelect}
            listTitle="Owner"
          />
        </>
      ),
      // hideClearButton: true,
    },
    // Platform type
    {
      key: "Platform_type",
      label: "Platform",
      filter: (
        <div onClick={(e) => e.stopPropagation()}>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            options={platformTypeOptions}
            selected={selectedPlatformType}
            textField={
              <Autocomplete.TextField
                autoComplete="off"
                onChange={updatePlatformTypeText}
                // label="Tags"
                value={platformTypeInput}
              />
            }
            onSelect={handlePlatformTypeOnSelect}
            listTitle="Platform"
          />
        </div>
      ),
      shortcut: true,
    },
    // Platform subscriber
    {
      key: "subscribers",
      label: "Total followers",
      filter: (
        <>
          <ChoiceList
            title="Followers:"
            titleHidden
            choices={subscribersChoose}
            selected={subscribersSelected || []}
            onChange={subscribershandleChange}
          />
          <TextField
            label="Minimum"
            type="number"
            value={minnum}
            disabled={subscribersSelected === null || subscribersSelected[0] !== "1M+"}
            onChange={handleminChange}
            placeholder="Minimum"
            min={1}
            suffix="M"
            autoComplete="off"
          />
          <TextField
            label="Maximum"
            type="number"
            value={maxnum}
            disabled={subscribersSelected === null || subscribersSelected[0] !== "1M+"}
            onChange={handlemaxChange}
            placeholder="Maximum"
            min={Number(minnum) + 1}
            suffix="M"
            autoComplete="off"
          />
        </>
      ),
      shortcut: true,
    },
    // Price
    {
      key: "price",
      label: "Price",
      filter: (
        <>
          <Stack vertical>
            <TextField
              label="Price"
              labelHidden
              type="number"
              value={minPrice}
              onChange={handleMinPriceChange}
              placeholder="Minimum"
              min={0}
              autoComplete="off"
            />
            <TextField
              label="Maximum"
              type="number"
              labelHidden
              value={maxPrice}
              onChange={handleMaxPriceChange}
              placeholder="Maximum"
              min={0}
              autoComplete="off"
            />
            <Select
              label="Currency"
              options={currencyOptions}
              onChange={handleCurrencyChange}
              value={currency}
            />
          </Stack>
        </>
      ),
    },
    // Contact status
    {
      key: "Contact_status",
      label: "Contact status",
      filter: (
        <div onClick={(e) => e.stopPropagation()}>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            options={statusOptions}
            selected={selectedStatus}
            textField={
              <Autocomplete.TextField
                autoComplete="off"
                onChange={updatStatusText}
                value={statusInput}
              />
            }
            onSelect={handleStatusOnSelect}
            listTitle="Contact status"
          />
        </div>
      ),
      shortcut: true,
    },
    // Blacklist
    {
      key: "blacklist",
      label: "Blacklist",
      filter: (
        <ChoiceList
          title="BLACKLIST:"
          titleHidden
          choices={blacklistOptions}
          selected={blacklistSelected || []}
          onChange={handleBlacklistSelectChange}
        />
      ),
    },
    // Country/region
    {
      key: "Country",
      label: "Country/region",
      filter: (
        <div onClick={(e) => e.stopPropagation()}>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            options={countryOptions}
            selected={selectedCountry}
            textField={
              <Autocomplete.TextField
                autoComplete="off"
                onChange={updateCountryText}
                // label="Tags"
                value={countryInputValue}
              />
            }
            onSelect={handleCountryOnSelect}
            listTitle="Country/region"
          />
        </div>
      ),
      shortcut: true,
    },
    // Company
    {
      key: "Company",
      label: "Company",
      filter: (
        <div>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            options={companyOptions}
            selected={selectedCompany}
            textField={
              <Autocomplete.TextField
                autoComplete="off"
                onChange={updateCompanyText}
                // label="Tags"
                value={companyInputValue}
              />
            }
            onSelect={handleCompanyOnSelect}
            listTitle="Company"
          />
        </div>
      ),
    },
    // Collection
    {
      key: "Collection",
      label: "Collection",
      filter: (
        <div>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            options={collectionOptions}
            selected={selectedCollection}
            textField={
              <Autocomplete.TextField
                autoComplete="off"
                onChange={updateCollectionText}
                // label="Tags"
                value={collectionInputValue}
              />
            }
            onSelect={handleCollectionOnSelect}
            listTitle="Collection"
          />
        </div>
      ),
    },
    // // Tags
    {
      key: "Tags",
      label: "Tag",
      filter: (
        <StopPropagation>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <AntTreeSelect>
            <TreeSelect {...tProps} />
          </AntTreeSelect>
        </StopPropagation>
      ),
      shortcut: true,
    },
    // Create by
    {
      key: "Creator",
      label: "Create by",
      filter: (
        <div>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            options={creatorOptions}
            selected={selectedCreator}
            textField={
              <Autocomplete.TextField
                autoComplete="off"
                onChange={updateCreatorText}
                // label="Tags"
                value={creatorInputValue}
              />
            }
            onSelect={handleCreatorOnSelect}
            listTitle="Create by"
          />
        </div>
      ),
      shortcut: true,
    },
    // Creation time
    {
      key: "Creation_time",
      label: "Creation time",
      filter: (
        <>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <AntDateRangePicker
            style={datepickToShopifyStyle}
            // showTime
            ranges={{
              Today: [moment(), moment()],
              "This Week": [moment().startOf("week"), moment().endOf("week")],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
            }}
            allowClear={false}
            onChange={onCreationTimeChange}
            value={creationTime ? [moment(creationTime[0]), moment(creationTime[1])] : null}
          />
        </>
      ),
    },
    // 这里在区分是不是分组下面的伙伴列表.如果是,则不需要collection的筛选
  ].filter(({ key }) => {
    if (!CollectionID) {
      return true;
    } else {
      return key !== "Collection";
    }
  });

  const appliedFilters = [];
  //#region appliedFilters

  // Gender
  if (selectedGender !== "") {
    const key = "Gender";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedGender),
      onRemove: handleGenderRemove,
    });
  }
  // Language
  if (selectedLanguage.length !== 0) {
    const key = "Language";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedLanguage),
      onRemove: handleLanguageRemove,
    });
  }
  // Tier
  if (selectTier.length !== 0) {
    const key = "Tier";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectTier),
      onRemove: handlePopularityRemove,
    });
  }
  // Owner
  if (selectOwner.length !== 0) {
    const key = "Owner";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectOwner),
      onRemove: handlePrincipalRemove,
    });
  }
  // Platform type
  if (!isEmpty(selectedPlatformType)) {
    const key = "Platform_type";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedPlatformType),
      onRemove: handlePlatformTypeRemove,
    });
  }
  // Platform subscriber
  if (!isEmpty(subscribersSelected)) {
    const key = "subscribers";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, subscribersSelected),
      onRemove: handleSubscribersRemove,
    });
  }
  // Price
  if (minPrice !== "" || maxPrice !== "" || currency !== "") {
    const key = "price";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, {
        min: minPrice,
        max: maxPrice,
        cur: currency,
        textCurrency: inputCur,
      }),
      onRemove: handlePriceRemove,
    });
  }
  // Contact status
  if (!isEmpty(selectedStatus)) {
    const key = "Contact_status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedStatus),
      onRemove: handleStatusRemove,
    });
  }
  // Blacklist
  if (!isEmpty(blacklistSelected)) {
    const key = "blacklist";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, blacklistSelected),
      onRemove: handleBlacklistRemove,
    });
  }
  // Country/region
  if (!isEmpty(selectedCountry)) {
    const key = "Country";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedCountry),
      onRemove: handleCountryRemove,
    });
  }
  // Company
  if (!isEmpty(selectedCompany)) {
    const key = "Company";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedCompany),
      onRemove: handleCompanyRemove,
    });
  }
  // Collection
  if (!isEmpty(selectedCollection)) {
    const key = "Collection";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedCollection),
      onRemove: handleCollectionRemove,
    });
  }
  // // Tag
  if (!isEmpty(selectTreeValue)) {
    const key = "Tags";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectTreeValue),
      onRemove: handleTagRemove,
    });
  }
  // Create by
  if (!isEmpty(selectedCreator)) {
    const key = "Creator";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedCreator),
      onRemove: handleCreatorRemove,
    });
  }
  if (!!creationTime && !!compact(creationTime).length) {
    const key = "Creation_time";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, creationTime),
      onRemove: handleCreationTimeRemove,
    });
  }
  //#endregion

  function disambiguateLabel(key, value) {
    switch (key) {
      case "Gender":
        return `Gender: ${selectedGender[0] === "1" ? "Male" : "Female"}`;
      case "Language": {
        const checkedLanguage = filterOptions(value, deselectedLanguageOption);
        return "Language: " + checkedLanguage.map((o) => `${o.label}`).join(", ");
      }
      case "Tier": {
        const checkedPopularity = filterOptions(value, deselectedTierOptions);
        return "Tier: " + checkedPopularity.map((o) => `${o.label}`).join(", ");
      }
      case "Owner": {
        const checkedPrincipal = filterOptions(value, deselectedOwnerOptions);
        return "Owner: " + checkedPrincipal.map((o) => `${o.label}`).join(", ");
      }
      case "Platform_type": {
        const checkedPlatformType = filterOptions(value, deselectedPlatformTypeOptions);
        return "Platform: " + checkedPlatformType.map((o) => `${o.label}`).join(", ");
      }
      case "subscribers":
        return `Total followers: ${subscribersSelected[0] === "1M+" ? minnum * 1000000 : minnum}-${
          subscribersSelected[0] === "1M+" ? maxnum * 1000000 : maxnum
        }`;
      case "price":
        return `Price: ${value?.min || ""} ${value?.max ? ` - ${value?.max}` : ""} ${
          value.textCurrency === "" || value.textCurrency === "All" ? "" : value.textCurrency
        }`;
      case "Contact_status": {
        const checkedContact_status = filterOptions(value, deselectedStatusOptions);
        return "Contact status: " + checkedContact_status.map((o) => `${o.label}`).join(", ");
      }
      case "blacklist":
        return `Blacklist: ${blacklistSelected[0] === "0" ? "Unblock" : "Block"}`;
      case "Country": {
        const checkedCountry = filterOptions(value, deselectedCountryOptions);
        return "Country/region: " + checkedCountry.map((o) => `${o.label}`).join(", ");
      }
      case "Company": {
        const checkedCompany = filterOptions(value, deselectedCompanyOptions);
        return "Company: " + checkedCompany.map((o) => `${o.label}`).join(", ");
      }
      case "Collection": {
        const checkedCollection = filterOptions(value, deselectedCollectionOptions);
        return "Collection: " + checkedCollection.map((o) => `${o.label}`).join(", ");
      }
      case "Tags":
        return "Tags: " + selectTreeValue.map((s) => s).join(", ");
      case "Creator": {
        const checkedCreator = filterOptions(value, deselectedCreatorOptions);
        return "Create by: " + checkedCreator.map((o) => `${o.label}`).join(", ");
      }
      case "Creation_time":
        return `Creation time: ${value[0]} ~ ${value[1]}`;
      default:
        return value;
    }
  }

  //#region request

  const [total, setTotal] = useState(1);
  const [currentPage, setCurrentPage] = useRecoilState(ptns_current_page);
  const [perPage, setPerPage] = useRecoilState(ptns_per_page);
  // 首次加载请求的传参:
  const initParams = useMemo(() => {
    return {
      search_by: searchType,
      search: queryValue ?? "",
      page: currentPage,
      per_page: perPage,
      contact_status: selectedStatus,
      platform_type_ids: selectedPlatformType,
      subscribers_min:
        subscribersSelected === null || subscribersSelected[0] !== "1M+"
          ? minnum
          : minnum * 1000000,
      subscribers_max:
        subscribersSelected === null || subscribersSelected[0] !== "1M+"
          ? maxnum
          : maxnum * 1000000,
      currency_id: currency,
      price_min: minPrice,
      price_max: maxPrice,
      country_ids: selectedCountry,
      language_ids: selectedLanguage,
      company_ids: selectedCompany,
      // 这里在区分是不是分组下面的伙伴列表.如果是,则固定分组id, 去掉collection筛选项
      group_ids: CollectionID ? [CollectionID] : selectedCollection,
      type: "0",
      gender: selectedGender ? selectedGender : "",
      is_blacklisted: !blacklistSelected ? "" : +blacklistSelected[0],
      account_level_ids: selectTier,
      principal_ids: selectOwner,
      creator_ids: selectedCreator,
      created_ats: creationTime,
      sort_by: sortState,
      sort_type: sortOrder,
      tags: selectTreeValue,
    };
  }, [
    searchType,
    CollectionID,
    currentPage,
    perPage,
    queryValue,
    selectedStatus,
    selectedPlatformType,
    subscribersSelected,
    minnum,
    maxnum,
    currency,
    minPrice,
    maxPrice,
    selectedCountry,
    selectedLanguage,
    // selectedTag,
    selectedCompany,
    selectedCollection,
    // selectedTab,
    selectedGender,
    blacklistSelected,
    selectTier,
    selectOwner,
    selectedCreator,
    creationTime,
    sortState,
    sortOrder,
    selectTreeValue,
  ]);

  // 在请求展示所有条目的基础上,筛选当前页条目:
  const { run } = useDebounceFn(
    async (params) => {
      setIndexTableLoading(true);

      const { data } = await getPartners({ ...initParams, ...params });

      setPartnerProfiles(data.list);
      setTotal(data.meta.pagination.total);
      setPerPage(data.meta.pagination.per_page);
      setCurrentPage(data.meta.pagination.current_page);
      window.dispatchEvent(new Event("resize"));

      setIndexTableLoading(false);
    },
    { wait: 600 }
  );
  const reqCurrentTable = useCallback(run, [initParams, run]);

  // tabs/搜搜栏/creator/sort/project 变化
  useEffect(() => {
    reqCurrentTable({ page: 1 });
  }, [
    reqCurrentTable,
    queryValue,
    // selectedTab,
    refreshInfluencer, // 导入成功之后,获取最新的列表 (其实也可以把上面的jsx全部挪到此请求函数的下方,少一变量. 不想移动位置了)
    refreshMark, // 来自collection add partner 添加成功的刷新
    selectedStatus,
    selectedPlatformType,
    subscribersSelected,
    minnum,
    maxnum,
    currency,
    minPrice,
    maxPrice,
    selectedCountry,
    selectedLanguage,
    selectTreeValue,
    selectedCompany,
    selectedCollection,
    selectedGender,
    blacklistSelected,
    selectTier,
    selectOwner,
    selectedCreator,
    creationTime,
  ]);
  useEffect(() => {
    reqCurrentTable();
  }, [
    // 不能监听页码和每页数, 会造成挂载执行两次
    reqCurrentTable,
    sortValue,
  ]);

  //#endregion

  //#region Columns
  const [profilesHeading, setProfilesHeadings] = useState(profiles_headings);
  const [columns, setColumns] = useState([
    "Influencer name",
    "Country/region",
    "Social link",
    "Total followers",
    "Tag",
    "Operation",
  ]);

  useStoreLocalState("profiles_columns_4", {
    state: columns,
    setState: setColumns,
  });
  useDispatchResize(columns);
  useDynamicHeadings({
    staticHeadings: profiles_headings,
    columns: columns,
    setTableHeadings: setProfilesHeadings,
  });
  //#endregion Columns

  //#region IndexTable
  // index table 配置项
  const resourceName = {
    singular: "influencer",
    plural: "influencers",
  };
  const [indexTableLoading, setIndexTableLoading] = useState(false); // indexTable 加载状态显示

  const [partnerProfiles, setPartnerProfiles] = useState([]);
  // index Table 选中处理函数
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(partnerProfiles);

  //TODO -  export
  const [exportModalActive, { toggle: toggleExportModal, setLeft: closeExportModal }] = useToggle();
  const tableToExcel = useCallback(async () => {
    try {
      showTopLoading();
      // const params = pick(initParams, ["search", "search_by"]);
      const params = omit(initParams, ["page", "per_page"]);

      // const { data } = await exportPartnerFile(params);
      await getExportFile({ ...params, type: "influencer" });

      // history.push(`/account/export`);
      openNewWindow("/account/export");
      closeExportModal();

      // downLoadFile(data.file_link, "PartnerList", false);
      hiddenTopLoading();
    } catch (error) {
      hiddenTopLoading();
    }
  }, [initParams, showTopLoading, hiddenTopLoading, closeExportModal]);

  const checkExport = useCallback(() => {
    // if (total > 10000) {
    //   toggleExportModal(); // 多余一千要提示
    // } else {
    tableToExcel();
    // }
  }, [tableToExcel]);

  const ConfirmExportModal = (
    <Modal title="Confirm export" open={exportModalActive} onClose={toggleExportModal}>
      <Modal.Section>
        <p>
          Export up to 10000 items of data at a time, if more than 10000 items, the first 10000
          items will be exported in order of the current list.
        </p>
      </Modal.Section>

      <Modal.Section>
        <Stack distribution="trailing">
          <ButtonGroup>
            <Button onClick={toggleExportModal}>Cancel</Button>
            <Button destructive onClick={tableToExcel}>
              Export
            </Button>
          </ButtonGroup>
        </Stack>
      </Modal.Section>
    </Modal>
  );

  // 跨级通知导出 现在导出按钮处于整个 partnersTabs外层page组件上
  useUpdateEffect(() => {
    // tableToExcel();
    checkExport();
  }, [isExport]);

  const promotedBulkActions = [
    {
      content: "Add tag",
      onAction: toggleAddTagModal,
    },
    {
      content: "Add to collection",
      onAction: toggleadd2CollectionModal,
    },
    {
      content: "Add to campaign",
      onAction: toggleadd2CampaignModal,
    },
    {
      content: CollectionID ? "Remove influencer" : "Delete influencer",
      onAction: toggleDeletePartnerModal,
    },
  ];
  // handle delete influencer
  const handleDeletePartner = useCallback(async () => {
    try {
      if (!CollectionID) {
        await deletePartners(selectedResources);
        await wait(1000);
        toggleDeletePartnerModal();
        handleSelectionChange("all", false); // 清除indextable选中状态
        reqCurrentTable();
        handleGlobalToast({ msg: "Delete successfully!" });
      } else {
        await deletePartnerToGroup([CollectionID], selectedResources);
        await wait(1000);

        toggleDeletePartnerModal();
        handleSelectionChange("all", false); // 清除indextable选中状态
        reqCurrentTable();
        handleGlobalToast({ msg: "Remove successfully!" });
      }
    } catch (error) {
      handleGlobalToast({ msg: "Delete unsuccessfully!", isErr: true });
    }
  }, [
    toggleDeletePartnerModal,
    selectedResources,
    reqCurrentTable,
    handleSelectionChange,
    handleGlobalToast,
    CollectionID,
  ]);

  // 在传递了请求刷新函数, 关联接口在组件内部
  // handle add tag
  const handleAddTags = useCallback(() => {
    reqCurrentTable();
  }, [reqCurrentTable]);
  // handle Add to campaign
  const handleAddToCampaign = useCallback(() => {
    reqCurrentTable();
  }, [reqCurrentTable]);
  // handle Add to collection
  const handleAddToCollection = useCallback(() => {
    reqCurrentTable();
  }, [reqCurrentTable]);

  const rowMarkup = partnerProfiles.map(
    (
      {
        id,
        first_name,
        avatar,
        gender,
        languages,
        principal, // null
        tags,
        account_level, // {}
        is_blacklisted,
        email,
        contacts, // [] {id, account, type :{ title } }
        groups, // [] {id, title}
        prices,
        platforms,
        total_subscribers,
        company,
        contact_status,
        address,
        creator,
        updater,
        created_at,
        updated_at,
      },
      index
    ) => {
      const code = address?.country?.code;
      const regionObj = regions.filter(({ iso2 }) => iso2 === code)?.[0];
      const renderRegion = (
        <div style={{ display: "flex", alignItems: "center" }}>
          <CountryEmoji>{regionObj?.emoji ?? ""}</CountryEmoji>
          <span>{regionObj?.name ?? ""}</span>
        </div>
      );

      // 字段展示
      const props = [
        // "Partner name",
        <PartnerNav
          key={v4()}
          name={first_name}
          avatar={avatar}
          partnerID={id}
          is_blacklisted={is_blacklisted}
        />,
        // "Country/region",
        // <Region key={v4()} name={address?.country?.name} image={address?.country?.image} />,
        renderRegion,

        // "Language",
        <RowBlckList is_blacklisted={is_blacklisted} key={v4()}>
          {languages?.map(({ name }) => name + "; ")}
        </RowBlckList>,

        // "Gender",
        <RowBlckList is_blacklisted={is_blacklisted} key={v4()}>
          {gender === 1 ? "Male" : gender === 2 ? "Female" : ""}
        </RowBlckList>,

        // "Social link",
        <RowPlatforms platforms={platforms} key={v4()} />,

        // "Total followers",
        <RowBlckList is_blacklisted={is_blacklisted} key={v4()}>
          {total_subscribers ? transformNumber(total_subscribers) : ""}
        </RowBlckList>,

        // "Tier",
        <RowBlckList is_blacklisted={is_blacklisted} key={v4()}>
          <Text>{account_level?.title}</Text>
        </RowBlckList>,

        // "Owner",
        <RowBlckList key={v4()} is_blacklisted={is_blacklisted}>
          {principal?.name}
        </RowBlckList>,

        // "Price",
        <RowTextList
          key={v4()}
          textList={prices.map(({ currency, type, value }) => {
            return (
              <div style={{ width: 200 }}>
                <Text variant="bodyMd" as="span" color="subdued">
                  {type?.title}{" "}
                </Text>
                <Text as="span">{currency?.symbol} </Text>
                <Text as="span">{value}</Text>
              </div>
            );
          })}
        />,

        // "Collection",
        <TagPopover key={v4()} tags={groups} />,

        // "Tag",
        <TagPopover key={v4()} tags={tags} />,

        // "Contact status",
        <ContactStatus contact_status={contact_status} key={v4()} />,

        // "Blacklist",
        is_blacklisted ? "Block" : "Unblock",

        // "Email",
        <RowBlckList key={v4()} is_blacklisted={is_blacklisted}>
          {email}
        </RowBlckList>,

        // "Other contact",
        <RowTextList
          key={v4()}
          textList={contacts.map(({ account, type }) => {
            return (
              <>
                <Text variant="bodyMd" as="span" color="subdued">
                  {type?.title}{" "}
                </Text>
                <Text>{account}</Text>
              </>
            );
          })}
        />,

        // "Company name",
        company?.name,

        // "Create by",
        creator?.name,
        // "Creation time",
        moment2Date(s_to_ms(created_at)),
        // "Update by",
        updater?.name,
        // "Update time",
        moment2Date(s_to_ms(updated_at)),
        // operation
        <StopPropagation key={v4()}>
          <Operation partnerID={id} />
        </StopPropagation>,
      ];
      let SN = []; // 总的序列对应关系
      profilesColumsOptions.forEach(({ value }, index) => {
        SN.push({ column: value, prop: props[index] });
      });

      let showCell = SN.filter(({ column }) => columns.includes(column));

      return (
        <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
          {showCell.map(({ prop }) => (
            <IndexTable.Cell key={v4()}>{prop}</IndexTable.Cell>
          ))}
        </IndexTable.Row>
      );
    }
  );
  //#endregion

  useMount(async () => {
    // 筛选项列表 --> 提升到 influencers 组件去了, 因为需要模板链接
    const options = await getPartnersFilterInit();

    // 导入模板下载链接
    // setTemplateFileUrl(options.data.partner_template);
    // 语言
    const languageList = options?.data?.languages?.map(({ id, name }) => ({
      label: name,
      value: `${id}`,
    }));
    setDeselectedLanguageOption(languageList);
    setLanguageOptions(languageList);
    // Tier
    const tierList = options?.data?.account_levels?.map(({ id, title }) => ({
      label: title,
      value: `${id}`,
    }));
    setDeselectedTierOptions(tierList);
    setTierOptions(tierList);
    // Owner
    const PrincipalList = options?.data?.principals?.map(({ id, name }) => ({
      label: name,
      value: `${id}`,
    }));
    setDeselectedOwnerOptions(PrincipalList);
    setOwnerOptions(PrincipalList);
    // Platform type
    const platformTypes = options?.data?.platform_types?.map(({ id, title }) => ({
      label: title,
      value: String(id),
    }));
    setDeselectedPlatformTypeOptions(platformTypes);
    setPlatformTypeOptions(platformTypes);
    // price
    const prices = options?.data?.currencys?.map(({ id, code }) => ({
      label: code,
      value: String(id),
    }));
    prices?.unshift({ label: "All", value: "" });
    setCurrencyOptions(prices);
    setCurrency(prices?.[0]?.value);
    // 国家
    const countryList = options?.data?.countrys?.map(({ id, name }) => ({
      label: name,
      value: `${id}`,
    }));
    setDeselectedCountryOptions(countryList);
    setCountryOptions(countryList);
    // Company
    const Company = options?.data?.companys?.map(({ id, name }) => ({
      label: name,
      value: String(id),
    }));
    setDeselectedCompanyOptions(Company);
    setCompanyOptions(Company);
    // Collection
    const Collection = options?.data?.groups?.map(({ id, title }) => ({
      label: title,
      value: String(id),
    }));
    setDeselectedCollectionOptions(Collection);
    setCollectionOptions(Collection);

    // Creator
    const Creator = options?.data?.principals?.map(({ id, name }) => ({
      label: name,
      value: String(id),
    }));
    setDeselectedCreatorOptions(Creator);
    setCreatorOptions(Creator);
    // eslint-disable-next-line
    window.dispatchEvent(new Event("resize"));
  });

  return (
    <>
      <Eidtor title="Edit influencer basic profile" visible={EditorActive} />
      {TopLoadingJSX}
      {ConfirmExportModal}
      <ConfirmDeleteModal
        title={`Confirm ${CollectionID ? "remove" : "delete"}`}
        content={`Are you sure you want to ${
          CollectionID ? "remove" : "delete"
        } the selected influencer ?`}
        buttonText={CollectionID ? "Remove" : "Delete"}
        deleteComfirmActive={deletePartnerActive}
        toggleDeleteComfirmActive={toggleDeletePartnerModal}
        handleConfirmDelete={handleDeletePartner}
      />
      <AddTag
        active={addTagModalActive}
        handleChange={toggleAddTagModal}
        handleSave={handleAddTags}
        partnerIDs={selectedResources}
      />
      <AddToCampaign
        active={add2CampaignModalActive}
        handleChange={toggleadd2CampaignModal}
        handleSave={handleAddToCampaign}
        partnerIDs={selectedResources}
      />
      <AddToCollection
        active={add2CollectionModalActive}
        handleChange={toggleadd2CollectionModal}
        handleSave={handleAddToCollection}
        partnerIDs={selectedResources}
      />
      <Card>
        {/* filters */}
        <FilterBox>
          <Select
            options={searchOps}
            value={searchType}
            onChange={(value) => {
              setSearchType(value);
              setQueryValue("");
            }}
          />

          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={setQueryValue}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleClearAll}
            queryPlaceholder={`Search ${matchSearchType(searchOps, searchType)}`}
          />
          <div style={{ paddingLeft: "0.4rem" }}>
            <Popover
              active={popoverSortActive}
              activator={
                <Button onClick={togglePopoverSortActive} icon={SortMinor}>
                  Sort
                </Button>
              }
              onClose={togglePopoverSortActive}
              preferredAlignment="right"
            >
              <Popover.Pane>
                <Popover.Section>
                  <TextContainer>
                    <Text variant="bodyMd" as="span" color="subdued">
                      Sort by
                    </Text>
                    <ChoiceList
                      choices={sortOptions}
                      selected={sortValue}
                      onChange={handleSortChange}
                    />
                  </TextContainer>
                </Popover.Section>
              </Popover.Pane>
            </Popover>
          </div>
          <div style={{ paddingLeft: "0.4rem" }}>
            <Popover
              active={columnsActive}
              activator={
                <Button icon={Columns3Minor} onClick={toggleColumnsActive}>
                  Columns
                </Button>
              }
              onClose={toggleColumnsActive}
            >
              <Popover.Section>
                <TextContainer>
                  <Text variant="bodyMd" as="span" color="subdued">
                    Columns
                  </Text>
                </TextContainer>
                <ChoiceList
                  allowMultiple
                  choices={profilesColumsOptions}
                  selected={columns}
                  onChange={setColumns}
                />
              </Popover.Section>
            </Popover>
          </div>
        </FilterBox>
        <IndexTable
          loading={indexTableLoading}
          resourceName={resourceName}
          itemCount={partnerProfiles.length}
          selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
          onSelectionChange={handleSelectionChange}
          headings={profilesHeading}
          promotedBulkActions={!access.WRITE ? undefined : promotedBulkActions}
          // emptyState={emptyStateMarkup}
          lastColumnSticky
        >
          {rowMarkup}
        </IndexTable>
        {partnerProfiles.length ? (
          <Stack distribution="trailing">
            <AntPagination>
              <Pagination
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                current={currentPage}
                pageSize={perPage}
                pageSizeOptions={[10, 20, 50, 100]}
                showSizeChanger
                total={total}
                onChange={(page, pageSize) => {
                  reqCurrentTable({ ...initParams, page: page, per_page: pageSize });
                }}
              />
            </AntPagination>
          </Stack>
        ) : null}
      </Card>
    </>
  );
};
