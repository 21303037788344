import styled from "styled-components";

export const AddTagStyled = styled.div`
  border-radius: 4px;
  /* padding-top: 6px; */
  /* margin: 0 8px; */
  &:hover {
    background-color: rgba(241, 242, 243, 1);
  }
`;
export const TagsOptionStyled = styled.div`
  .Polaris-OptionList {
    padding: 0px;
  }
`;
