import { Budget } from "./Budget";
import { Cooperation } from "./Cooperation";
import { Delivery } from "./Delivery";
import { Payment } from "./Payment";
import { Promotion } from "./Promotion";

// 合作信息看板
export const BusinessBoard = ({ tabIndex }) => {
  // 根据当前选中的那个 tab 切换不同的组件
  const MatchTab = (i) => {
    switch (i) {
      case 0:
        return <Cooperation />;
      case 1:
        return <Budget />;
      case 2:
        return <Promotion />;
      case 3:
        return <Delivery />;
      case 4:
        return <Payment />;
      default:
        return <Cooperation />;
    }
  };

  return <>{MatchTab(tabIndex)}</>;
};
