import { atom } from "recoil";
import { v4 } from "uuid";

export const exportContactAtom = atom({
  key: v4(),
  default: false,
});

export const contactsRefresh_atom = atom({
  key: v4(),
  default: false,
});

export const contacts_search_type = atom({
  key: v4(),
  default: "all",
});

export const sort_atom = atom({
  key: v4(),
  default: "updated_at|desc",
});

export const current_page_atom = atom({
  key: v4(),
  default: 1,
});

export const per_page_atom = atom({
  key: v4(),
  default: 10,
});

export const contacts_filter_countryDesOps = atom({
  key: v4(),
  default: [],
});

export const contacts_filter_countryOps = atom({
  key: v4(),
  default: [],
});

export const contacts_filter_countrySelect = atom({
  key: v4(),
  default: [],
});

export const contacts_filter_outletsDesOps = atom({
  key: v4(),
  default: [],
});

export const contacts_filter_outletsOps = atom({
  key: v4(),
  default: [],
});

export const contacts_filter_outletsSelect = atom({
  key: v4(),
  default: [],
});

export const contacts_filter_creatorDesOps = atom({
  key: v4(),
  default: [],
});

export const contacts_filter_creatorOps = atom({
  key: v4(),
  default: [],
});

export const contacts_filter_creatorSelect = atom({
  key: v4(),
  default: [],
});

export const contacts_filter_creatimeSelect = atom({
  key: v4(),
  default: null,
});
