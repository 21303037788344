import svg500 from "@/assets/svg/500.svg";
import { Card, EmptyState, Page } from "@shopify/polaris";
import { useHistory } from "react-router-dom";

/**
 * Creates a ResponseError component.
 *
 * @param {Object} props - The properties of the component.
 * @param {boolean} props.isPage - Indicates whether the error should be displayed as a full page or not. Defaults to true.
 * @return {JSX.Element} The rendered ResponseError component.
 */
export const ResponseError = ({ isPage = true }) => {
  const history = useHistory();

  const CardMark = (
    <Card sectioned>
      <EmptyState
        heading="Sorry, there seems to be an issue with the server."
        action={{
          content: "Back to home page",
          onAction: () => history.replace("/"),
        }}
        image={svg500}
      ></EmptyState>
    </Card>
  );
  if (isPage) {
    return <Page>{CardMark}</Page>;
  } else {
    return <>{CardMark}</>;
  }
};
