import { getFile, postImportFile } from "@/api/request";
import {
  ErrorProgress,
  FakeProgress,
  FillProgress
} from "@/components/EnhanceShopify/UploadProgress";
import { useRecoilToggle } from "@/hooks/useRecoilToggle";
import { calculateSecond } from "@/utils/calculateSecond";
import { downLoadFile } from "@/utils/downLoadFile";
import { isUrl } from "@/utils/isUrl";
import {
  Banner,
  Button,
  ButtonGroup,
  DropZone,
  List,
  Modal,
  Stack,
  Text,
  Thumbnail
} from "@shopify/polaris";
import { DeleteMinor, NoteMinor } from "@shopify/polaris-icons";
import { useToggle, useUnmount } from "ahooks";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { outletsRefresh_atom } from "../atom";
import { importOutletsUrl_atom, toggleImportOutlets_atom } from "./atom";

export const ImportMediaOutlets = () => {
  //#region 导入伙伴弹窗
  const { active: importActive, toggle: toggleImportActive } =
    useRecoilToggle(toggleImportOutlets_atom);

  const { toggle: handleRefresh } = useRecoilToggle(outletsRefresh_atom);
  const [templateFileUrl] = useRecoilState(importOutletsUrl_atom); // 下载模版连接初始化

  const [isUploaded, setIsUploaded] = useState(false); // 是否上传完毕
  const [isUploadedErr, setIsUploadedErr] = useState(false); // 是否上传错误
  const [successInfo, setSuccessInfo] = useState({ success: 0, fail: 0, link: "" });

  const [file, setFile] = useState(); // 上传框里是否有文件
  const [disableNextButton, setDisableNextButton] = useState(true); // 没有文件则禁用next按钮无法进入下一步
  useEffect(() => {
    if (file) {
      setDisableNextButton(false);
    } else {
      setDisableNextButton(true);
    }
  }, [file]);
  // eslint-disable-next-line
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const hasError = rejectedFiles.length > 0;
  const handleDropZoneDrop = useCallback((_dropFiles, acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const fileUpload = !file && <DropZone.FileUpload actionTitle="Add file" />;
  const uploadedFile = file && (
    <Stack alignment="center">
      <Stack.Item fill>
        <Stack>
          <Thumbnail
            size="small"
            alt={file.name}
            source={
              validImageTypes.includes(file.type) ? window.URL.createObjectURL(file) : NoteMinor
            }
          />
          <div>
            {file.name}
            <Text variant="bodySm" as="p">
              {file.size} bytes
            </Text>
          </div>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <div
          style={{ paddingRight: 16 }}
          onClick={(e) => {
            e.stopPropagation();
            setFile();
          }}
        >
          <Button icon={DeleteMinor} plain destructive />
        </div>
      </Stack.Item>
    </Stack>
  );
  const errorMessage = hasError && (
    <Banner title="The following images couldn't be uploaded:" status="critical">
      <List type="bullet">
        {rejectedFiles.map((file, index) => (
          <List.Item key={index}>
            {`"${file.name}" is not supported. File type must be .gif, .jpg, .png or .svg.`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );
  //#endregion

  //#region 上传伙伴模板文件弹窗
  const [uploadActive, { toggle: toggleUploadActive }] = useToggle();

  const handleClearUploadStatus = useCallback(() => {
    setIsUploaded(false);
    setIsUploadedErr(false);
    setFile();
  }, []);

  //TODO - outlet 导入
  let timer;
  const loopRequest = (intervalTime, id) => {
    timer = setTimeout(async () => {
      try {
        // 请求数据
        const { data } = await getFile(id);

        switch (data.status) {
          case "success":
          case "partial_fail": {
            clearTimeout(timer);

            setSuccessInfo({
              success: data.succeeded_qty,
              fail: data.failed_qty,
              link: data.file_url,
            });
            handleRefresh();
            setIsUploaded(true);
            break;
          }
          case "not_start":
          case "in_progress": {
            clearTimeout(timer);
            loopRequest(intervalTime + 1, id);
            break;
          }
          case "fail": {
            clearTimeout(timer);
            setIsUploaded(true);
            setIsUploadedErr(true);
            break;
          }
          default:
            clearTimeout(timer);
            break;
        }
      } catch (error) {}
    }, calculateSecond(intervalTime) * 1000);
  };

  useUnmount(() => {
    clearTimeout(timer);
  });

  const handleNext = async () => {
    toggleImportActive();
    toggleUploadActive();
    let formData = new FormData();
    formData.append("file", file);
    formData.append("type", "media_outlet"); // media_outlet  media_contact
    try {
      // 上传文件
      const { data } = await postImportFile(formData);
      // 上传完成后, 需要拿着上传成功的id调用getFile接口
      loopRequest(1, data.id);
    } catch (error) {
      setIsUploadedErr(true);
    } finally {
    }
  };

  // Drop 添加文件modal
  const ImportModal = (
    <Modal
      open={importActive}
      onClose={toggleImportActive}
      title="Import media outlet"
      primaryAction={{
        content: "Next",
        disabled: disableNextButton,
        onAction: handleNext,
        //   async () => {
        //     toggleImportActive();
        //     toggleUploadActive();
        //     let formData = new FormData();
        //     formData.append("file", file);
        //     try {
        //       const { data } = await postImportOutlets(formData);
        //       setSuccessInfo({ success: data.succeeded, fail: data.failed, link: data.file_link });

        //       handleRefresh();
        //       setIsUploaded(true);
        //     } catch (error) {
        //       setIsUploadedErr(true);
        //     }
        //   },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction() {
            setFile();
            toggleImportActive();
          },
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical>
          {errorMessage}
          <DropZone
            allowMultiple={false}
            onDrop={handleDropZoneDrop}
            accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            type="file"
          >
            {uploadedFile}
            {fileUpload}
          </DropZone>
          <Text variant="bodyMd" as="span" color="subdued">
            <List type="bullet">
              <List.Item>
                You can&nbsp;
                <Button
                  plain
                  onClick={() => {
                    if (!isUrl(templateFileUrl)) return;
                    downLoadFile(templateFileUrl, "Template", false);
                  }}
                >
                  click here
                </Button>
                &nbsp;to download the template file!
              </List.Item>
              <List.Item>The size of the uploaded file should not exceed 50M.</List.Item>
            </List>
          </Text>
        </Stack>
      </Modal.Section>
    </Modal>
  );
  const MatchProgressStatus = (isUploadedErr, isUploaded) => {
    // 上传错误
    if (isUploadedErr) {
      return <ErrorProgress />;
    }
    // 上传成功 or 上传中
    if (isUploaded) {
      return <FillProgress successInfo={successInfo} />;
    } else {
      return <FakeProgress />;
    }
  };
  // Progress 上传modal 查看进度
  const UploadInfoModal = (
    <Modal
      open={uploadActive}
      onClose={() => {
        toggleUploadActive();
        handleClearUploadStatus();
      }}
      title="Import media outlet"
    >
      <Modal.Section>{MatchProgressStatus(isUploadedErr, isUploaded)}</Modal.Section>
      <Modal.Section>
        <Stack distribution="trailing">
          <ButtonGroup>
            <Button
              onClick={() => {
                toggleUploadActive();
                handleClearUploadStatus();
              }}
            >
              Cancel
            </Button>
            {isUploadedErr ? null : (
              <Button
                primary
                disabled={!isUploaded}
                loading={!isUploaded}
                onClick={() => {
                  toggleUploadActive();
                  handleClearUploadStatus();
                }}
              >
                Done
              </Button>
            )}
          </ButtonGroup>
        </Stack>
      </Modal.Section>
    </Modal>
  );
  //#endregion

  return (
    <>
      {ImportModal}
      {UploadInfoModal}
    </>
  );
};
