import { useEffect } from "react";
import styled from "styled-components";
export const TestPage = () => {
  useEffect(() => {
    throw new Error("测试错误");
  }, []);

  return <Center>test page</Center>;
};

const Center = styled.div`
  height: 100%;
  display: grid;
  place-items: center;
`;
