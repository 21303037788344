import styled from "styled-components";

const Span = styled.span`
  display: inline-flex;
  align-items: ${(props) => (props.setCenter ? "center" : "flex-start")};
  .iconfont {
    padding-top: 1px;
  }
`;

export const IconFont = ({ code, title, color, setCenter = true }) => {
  return (
    <Span setCenter={setCenter}>
      <i className="iconfont" style={{ color }}>
        {code}
      </i>
      &nbsp;
      <span style={{ display: "inline-block" }}>{title}</span>
    </Span>
  );
};
