// 34  22 -6

import { CountryEmoji } from "@/components/EnhanceShopify/CountryEmoji";
import { TextClamp } from "@/components/EnhanceShopify/TextClamp";
import { CountryRegionListAtom } from "@/components/InitCountryRegions";
import { Stack, Text, Tooltip } from "@shopify/polaris";
import { Divider } from "antd";
import { useRecoilValue } from "recoil";

export const Region = ({ name, image, languages, code }) => {
  const regions = useRecoilValue(CountryRegionListAtom);

  const regionObj = regions.filter(({ iso2 }) => iso2 === code)?.[0];
  const lang = languages?.map((o) => `${o.name}; `);

  return (
    <Stack alignment="center" wrap={false} spacing="extraTight">
      <CountryEmoji>{regionObj?.emoji}</CountryEmoji>
      <Stack wrap={false}>
        <Stack.Item>
          <Text variant="bodyMd" as="span">
            {name}
          </Text>
          <Divider type="vertical" />
        </Stack.Item>
        {!!languages && (
          <Stack.Item>
            <Tooltip content={lang}>
              <div style={{ maxWidth: 116, marginLeft: -14 }}>
                <TextClamp clamp={1}>{lang}</TextClamp>
              </div>
            </Tooltip>
          </Stack.Item>
        )}
      </Stack>
    </Stack>
  );
};
