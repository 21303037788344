import {
  Button,
  Card,
  ChoiceList,
  IndexTable,
  Popover,
  Stack,
  Text,
  TextContainer
} from "@shopify/polaris";
import { useState } from "react";

import { AntPagination } from "@/components/AntToShopify/AntPagination";
import { ButtonLink } from "@/components/EnhanceShopify/ButtonLink";
import { useChoiceListLogic } from "@/customHooks/useChoiceListLogic";
import { transformNumber } from "@/utils/tools";
import { Columns3Minor } from "@shopify/polaris-icons";
import { useToggle } from "ahooks";
import { Pagination } from "antd";
import _ from "lodash";
import { useEffect } from "react";
import styled from "styled-components";
import { v4 } from "uuid";

const ColumnStyled = styled.span`
  .Polaris-Button__Content {
    color: rgba(92, 95, 98, 1);
    .Polaris-Button__Icon {
      .Polaris-Icon {
        .Polaris-Icon__Svg {
          fill: rgba(92, 95, 98, 1);
        }
      }
    }
  }
`;

const CheckBox = ["Views", "Social link"];
const columsOptions = CheckBox.map((str) => ({
  label: str,
  value: str,
}));
columsOptions.unshift({
  label: "Influencer name",
  value: "Influencer name",
  disabled: true,
});

const table_headings = columsOptions.map(({ value }) => ({ title: value }));

export const SimilarChannels = ({ list, mark }) => {
  const [tableList, setTableList] = useState([]);
  const [listCunk, setListCunk] = useState([]);
  const [tableHeading, setTableHeading] = useState(table_headings);
  const { selected: columns, handleSelectChange: handleColumnsChange } = useChoiceListLogic([
    "Influencer name",
    "Views",
    "Social link",
  ]);
  const [columnsActive, { toggle: toggleColumnsActive }] = useToggle();

  const [total, setTotal] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  // 动态加载列:
  useEffect(() => {
    const headings = table_headings.filter(({ title }) => columns.includes(title));
    setTableHeading(headings);
    window.dispatchEvent(new Event("resize"));
  }, [columns]);

  const rowMarkup = tableList?.map(({ userId, username, followerCount, profileUrl }, index) => {
    // 字段展示
    const props = [
      // "Influencer name",
      username,
      // "views",
      transformNumber(followerCount),
      <ButtonLink link={profileUrl} key={v4()} />,
    ];
    let SN = []; // 总的序列对应关系
    columsOptions.forEach(({ value }, index) => {
      SN.push({ column: value, prop: props[index] });
    });
    let showCell = SN.filter(({ column }) => columns.includes(column));

    return (
      <IndexTable.Row id={userId} key={v4()} position={index}>
        {showCell.map(({ prop }) => (
          <IndexTable.Cell key={v4()}>{prop}</IndexTable.Cell>
        ))}
      </IndexTable.Row>
    );
  });

  useEffect(() => {
    const pages = _.chunk(list, pageSize);
    setTableList(pages[0]); // 第一页
    setListCunk(pages); // 各个页数
    setTotal(list.length); // 总数
  }, [list, pageSize]);

  return (
    <Card
      title="Suggested accounts"
      actions={[
        {
          content: (
            <Popover
              active={columnsActive}
              activator={
                <ColumnStyled>
                  <Button
                    icon={Columns3Minor}
                    size="slim"
                    onClick={toggleColumnsActive}
                    // plain
                    // removeUnderline
                  >
                    Columns
                  </Button>
                </ColumnStyled>
              }
              onClose={toggleColumnsActive}
            >
              <Popover.Section>
                <TextContainer>
                  <Text variant="bodyMd" as="span" color="subdued">
                    Columns
                  </Text>
                </TextContainer>
                <ChoiceList
                  allowMultiple
                  choices={columsOptions}
                  selected={columns}
                  onChange={handleColumnsChange}
                />
              </Popover.Section>
            </Popover>
          ),
        },
      ]}
    >
      {mark}
      {tableList?.length && (
        <IndexTable
          resourceName={{ singular: "item", plural: "items" }}
          itemCount={tableList.length}
          headings={tableHeading}
          selectable={false}
        >
          {rowMarkup}
        </IndexTable>
      )}
      {tableList?.length ? (
        <Stack distribution="trailing" alignment="center">
          <AntPagination>
            <Pagination
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              // current={currentPage}
              pageSize={pageSize}
              pageSizeOptions={[5, 10, 20, 50]}
              showSizeChanger
              total={total}
              onChange={(page, pageSize) => {
                setPageSize(pageSize);
                setTableList(listCunk[page - 1]);
              }}
            />
          </AntPagination>
        </Stack>
      ) : null}
    </Card>
  );
};
