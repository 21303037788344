import _ from "lodash";

// 傻人有傻福系列函数
export const CreateInlineSpace = (num) => {
  if (isNaN(num)) return;

  const array = new Array(num);

  const targetArr = _.fill(array, num);

  return targetArr.map((s, i) => <span key={i}>&nbsp;</span>);
};
