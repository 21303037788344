const CheckBox = [
  "Country/region",
  "Media website",
  "Monthly visits",
  "Traffic in the main country",
  "Tag",
];
const columsOptions = CheckBox.map((str) => ({
  label: str,
  value: str,
}));
columsOptions.unshift({
  label: "Media outlet name",
  value: "Media outlet name",
  disabled: true,
});
columsOptions.push({
  label: "Operation",
  value: "Operation",
  disabled: true,
});
const table_headings = columsOptions.map(({ value }) => ({ title: value }));

export { columsOptions, table_headings };
