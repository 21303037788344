import { Button, ButtonGroup } from "@shopify/polaris";
import { useCallback, useMemo } from "react";

import { StopPropagation } from "@/components/EnhanceShopify/StopPropagation";
import { loadableAuthAtom } from "@/global/authAtom";
import { useBomLocation } from "@/hooks/useBomLocation";
import { useAtom } from "jotai";
import PropTypes from "prop-types";
import { useRecoilState } from "recoil";
import { id_atom } from "../MediaOutletsEditor/atom";

Operation.propTypes = {
  handleEdit: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export function Operation({ handleEdit, id }) {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      READ: permission.data?.["pmp/resources/discovery/read"],
      WRITE: permission.data?.["pmp/resources/discovery/write"],
      IMPORT: permission.data?.["pmp/resources/discovery/import"],
      EXPORT: permission.data?.["pmp/resources/discovery/export"],
    }),
    [permission]
  );
  const [, setOutletID] = useRecoilState(id_atom);

  // const history = useHistory();
  const { origin } = useBomLocation();

  const navgateReport = useCallback(() => {
    window.open(`${origin}/media/board/${id}`);
  }, [origin, id]);

  return (
    <StopPropagation>
      <ButtonGroup segmented>
        <Button plain onClick={navgateReport}>
          View
        </Button>
        {!access.WRITE ? undefined : (
          <Button
            plain
            onClick={() => {
              handleEdit();
              setOutletID(id);
            }}
          >
            Edit
          </Button>
        )}
      </ButtonGroup>
    </StopPropagation>
  );
}
