import { atom } from "recoil";
import { v4 } from "uuid";

export const exportYTB_atom = atom({
  key: v4(),
  default: false,
});

const ytb_search_type = atom({
  key: v4(),
  default: "all",
});

const ytb_current_page = atom({
  key: v4(),
  default: 1,
});
const ytb_per_page = atom({
  key: v4(),
  default: 10,
});

const ytb_filter_sort = atom({
  key: v4(),
  default: "updated_at|desc",
});

const ytb_filter_search = atom({
  key: v4(),
  default: "",
});

const ytb_filter_platsrDesOps = atom({
  key: v4(),
  default: [],
});

const ytb_filter_platsrcOps = atom({
  key: v4(),
  default: [],
});

const ytb_filter_platsrcSelect = atom({
  key: v4(),
  default: [],
});

const ytb_filter_relatedPtnsDesOps = atom({
  key: v4(),
  default: [],
});

const ytb_filter_relatedPtnsOps = atom({
  key: v4(),
  default: [],
});

const ytb_filter_relatedPtnsSelect = atom({
  key: v4(),
  default: [],
});

const ytb_filter_locationDesOps = atom({
  key: v4(),
  default: [],
});

const ytb_filter_locationOps = atom({
  key: v4(),
  default: [],
});

const ytb_filter_locationSelect = atom({
  key: v4(),
  default: [],
});

const ytb_filter_followerMin = atom({
  key: v4(),
  default: "",
});

const ytb_filter_followerMax = atom({
  key: v4(),
  default: "",
});

const ytb_filter_viewMin = atom({
  key: v4(),
  default: "",
});

const ytb_filter_viewMax = atom({
  key: v4(),
  default: "",
});

const ytb_filter_keywordbackup = atom({
  key: v4(),
  default: [],
});

const ytb_filter_keywordDesOps = atom({
  key: v4(),
  default: [],
});

const ytb_filter_keywordOps = atom({
  key: v4(),
  default: [],
});

const ytb_filter_keywordSelect = atom({
  key: v4(),
  default: [],
});

const ytb_filter_hashTagBackup = atom({
  key: v4(),
  default: [],
});

const ytb_filter_hashTagDesOps = atom({
  key: v4(),
  default: [],
});

const ytb_filter_hashTagOps = atom({
  key: v4(),
  default: [],
});

const ytb_filter_hashTagSelect = atom({
  key: v4(),
  default: [],
});

const ytb_filter_creatorDesOps = atom({
  key: v4(),
  default: [],
});

const ytb_filter_creatorOps = atom({
  key: v4(),
  default: [],
});

const ytb_filter_creatorSelect = atom({
  key: v4(),
  default: [],
});

const ytb_filter_creatime = atom({
  key: v4(),
  default: null,
});

// const ytb_filter_xxDesOps = atom({
//   key: v4(),
//   default: [],
// });

// const ytb_filter_xxOps = atom({
//   key: v4(),
//   default: [],
// });

// const ytb_filter_xxSelect = atom({
//   key: v4(),
//   default: [],
// });

export {
  ytb_search_type,
  ytb_current_page,
  ytb_per_page,
  ytb_filter_sort,
  ytb_filter_search,
  ytb_filter_platsrDesOps,
  ytb_filter_platsrcOps,
  ytb_filter_platsrcSelect,
  ytb_filter_relatedPtnsDesOps,
  ytb_filter_relatedPtnsOps,
  ytb_filter_relatedPtnsSelect,
  ytb_filter_locationDesOps,
  ytb_filter_locationOps,
  ytb_filter_locationSelect,
  ytb_filter_followerMin,
  ytb_filter_followerMax,
  ytb_filter_viewMin,
  ytb_filter_viewMax,
  ytb_filter_keywordbackup, // 数据量过于庞大, 这是所有数据, 并不参与渲染
  ytb_filter_keywordDesOps,
  ytb_filter_keywordOps,
  ytb_filter_keywordSelect,
  ytb_filter_hashTagBackup, // 数据量过于庞大, 这是所有数据, 并不参与渲染
  ytb_filter_hashTagDesOps,
  ytb_filter_hashTagOps,
  ytb_filter_hashTagSelect,
  ytb_filter_creatorDesOps,
  ytb_filter_creatorOps,
  ytb_filter_creatorSelect,
  ytb_filter_creatime,
};
