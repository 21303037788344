export const showErrInfo = (checkState, setState, errInfo) => {
  if (checkState) {
    setState(errInfo);
  } else {
    setState();
  }
};

export function checkType(o) {
  // 判断o是什么具体类型?   Array | Object | String
  return Object.prototype.toString.call(o).slice(8, -1);
}

// useChoiceListLogic初始值为字符串 'str', 选择为 ['str'],
// 用于筛选时候拆分选择的值: 形如:"updated_at|desc", 拆为'updated_at' 和'desc'
export function splitChoiceValue(Value) {
  return checkType(Value) === "Array" ? Value[0].split("|") : Value.split("|");
}

// 校验字符串是不是形如 0 1200 12K 3M 5B
export const checkAbbNumerString = (str) => {
  if (str === "0" || str === "") return true;
  if (str.length === 1 && !isNaN(str)) return true;

  let tailVal = str.trim().split("").slice(-1)[0].toLowerCase();
  let headVal = str.trim().split("").slice(0, -1).join("");
  let head = headVal[0];

  if (
    !isNaN(headVal) &&
    !isNaN(head) &&
    (tailVal === "k" || tailVal === "m" || tailVal === "b" || !isNaN(tailVal))
  ) {
    return true;
  } else {
    return false;
  }
};
