import { atom } from "recoil";

/**
 * @description: 全局state初始值管理
 *
 * 此原子状态在各个页面个通过三种方式自定义获取状态/ 修改状态的方法
 * const [state , setState] = useRecoilState( atom ) 用法同useState,传参为此处导出的对应原状态
 * 单独使用state或者setSate: [state, ] , [ , setSate], 用法跟useSate一模一样
 * 
 * 以下是额外的方法:
 * const state    = useRecoilValue( atom ) 获取此处的状态依赖
 * const setState = useSetRecoilState( atom ) 获去此处状态修改的set方法
 * 命名可自定 const xxx / set Xxx
 */

// topBarSave按钮的初始状态
export const topBarSaveDisplay = atom({
  key: "topBarSaveDisplay",
  default: false,
});

// topBarSave按钮传入的信号
export const topBarSignal = atom({
  key: "topBarSignal",
  default: "",
});

// AccountSetting:修改后传递的信息
export const accountInfos = atom({
  key: "accountInfos",
  default: {},
});

// 伙伴排序
export const RECOIL_SORT_BY = atom({
  key: "sortBy",
  default: ["updated_at#desc"],
});

// 全局上方的加载进度loading条
export const globalLoadingStatus = atom({
  key: "globalLoadingStatus",
  default: false,
});

// topbar save按钮是否禁用
export const isTopBarSaveDisabled = atom({
  key: "isTopBarSaveDisabled",
  default: false,
});

// 伙伴列表总个数显示
export const partnerTotalNumber = atom({
  key: "totalPartnerList",
  default: 0
});

// 伙伴列表选择个数显示
export const partnerSelectNumber = atom({
  key: "totalPartnerSelect",
  default: 0
});
