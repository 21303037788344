import { getCampaignList, getCurrentCooperations } from "@/api/request";
import { Card, Loading, Page } from "@shopify/polaris";
import { useLocalStorageState, useMount, useUpdateEffect } from "ahooks";
import { useMemo, useState } from "react";

import { useTabs } from "@/customHooks/useTabs";
import { NoPermission } from "@/error/403";
import { ResponseError } from "@/error/500";
import { loadableAuthAtom } from "@/global/authAtom";
import { compactParnterName } from "@/utils/compactParnterName";
import { useAtom } from "jotai";
import { CampaignSchedule } from "./Campaigns";
import { CooperationSchedule } from "./Cooperations";
import { PromotionSchedle } from "./Promotions";

const Schedules = () => {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      LOADING: permission.state === "loading",
      ERROR: permission.state === "hasError",
      ALL: permission.data?.["pmp/workflows/calendar/all"],
    }),
    [permission]
  );

  const [SchedulesTabIndex, setSchedulesTabIndex] = useLocalStorageState("Schedules_Tab_Index", {
    defaultValue: 0,
  });
  const {
    tabJSX,
    selected: tabIndex,
    setSelected: setTabIndex,
  } = useTabs({
    titles: ["Campaign", "Cooperation", "Promotion"],
  });
  const [campaignList, setCampaignList] = useState([]);
  const [cooperList, setCooperList] = useState([]);

  useUpdateEffect(() => {
    setSchedulesTabIndex(tabIndex);
  }, [tabIndex]);

  useMount(async () => {
    // setTabIndex(2); // 初始化显示哪个
    if (SchedulesTabIndex) {
      setTabIndex(SchedulesTabIndex);
    }
    try {
      const campaignData = await getCampaignList({ is_not_paged: 1 });
      const campaignListOptions = campaignData.data.list.map(({ id, title }) => ({
        label: title,
        value: `${id}`,
      }));
      setCampaignList(campaignListOptions);
      const cooperData = await getCurrentCooperations({ is_not_paged: 1 });
      const cooperListOptions = cooperData.data.list.map(
        ({ id, partner: { first_name, last_name }, campaign: { title } }) => {
          const label1 = compactParnterName(first_name, last_name);
          return { label: `${" [ " + title + " ] " + label1}`, value: `${id}` };
        }
      );
      setCooperList(cooperListOptions);
    } catch (error) {
      //
    }
  });

  if (access.LOADING) return <Loading />;
  if (access.ERROR) return <ResponseError />;
  if (!access.ALL) return <NoPermission />;

  return (
    <Page title="Schedules" fullWidth>
      <div style={{ marginTop: -16, marginBottom: 16 }}>{tabJSX}</div>

      <Card sectioned>
        {tabIndex === 0 && <CampaignSchedule />}
        {tabIndex === 1 && <CooperationSchedule campaignList={campaignList} />}
        {tabIndex === 2 && <PromotionSchedle campaignList={campaignList} cooperList={cooperList} />}
      </Card>
    </Page>
  );
};

export default Schedules;

// export default Schedules
