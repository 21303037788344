import { Badge } from "@shopify/polaris";
import { useEffect, useState } from "react";

export const ContactStatus = ({ contact_status }) => {
  const [status, setStatus] = useState(0);

  const MapBadge = {
    0: {
      status: "",
      message: "Not contacted",
    },
    1: {
      status: "info",
      message: "In contact",
    },
    2: {
      status: "critical",
      message: "Rejected",
    },
    3: {
      status: "success",
      message: "Accepted",
    },
  };

  useEffect(() => {
    setStatus(contact_status);
  }, [contact_status]);

  return <Badge status={MapBadge[status]?.status}>{MapBadge[status]?.message}</Badge>;
};
