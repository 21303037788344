import { Avatar, Button, Card, Layout, Stack, Text } from "@shopify/polaris";
import { useEffect, useMemo, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";

import { AnchorMark } from "@/components/EnhanceShopify/AnchorMark";
import { ButtonLink } from "@/components/EnhanceShopify/ButtonLink";
import { InfoRow } from "@/components/EnhanceShopify/InfoRow";
import { NoStickyTableHeader } from "@/components/EnhanceShopify/NoStickyTableHeader";
import { RowStack } from "@/components/EnhanceShopify/RowStack";
import { TextClamp } from "@/components/EnhanceShopify/TextClamp";
import { UpdateInfo } from "@/components/EnhanceShopify/UpdateInfo";
import { IconFont } from "@/components/Icon/PMPicon";
import { useTabs } from "@/customHooks/useTabs";
import { useCopy } from "@/hooks/useCopy";
import { EmailClamp } from "@/pages/Partner/PartnerReport/Common/EmailClamp";
import { LongTextCollect } from "@/pages/Partner/PartnerReport/Common/LongTextCollect";
import { ShowTags } from "@/pages/Partner/PartnerReport/Common/ShowTags";
import { PureTimeLine } from "@/pages/Partner/PartnerReport/General/PureTimeLine";
import { Region } from "@/pages/Partner/Platforms/Common/Region";
import { s_to_ms } from "@/utils/accurateTimeTrans";
import { moment2Date } from "@/utils/moment2Date";
import { DuplicateMinor } from "@shopify/polaris-icons";
import { Anchor, Divider } from "antd";
import { useParams } from "react-router-dom";
import { BusinessBoard } from "../../Common/BusinessBoard";
import { BoardBudgets_atom } from "../../Common/BusinessBoard/Budget";
import { BoardCoopers_atom } from "../../Common/BusinessBoard/Cooperation";
import { BoardDeliveries_atom } from "../../Common/BusinessBoard/Delivery";
import { BoardPayments_atom } from "../../Common/BusinessBoard/Payment";
import { BoardPromotions_atom } from "../../Common/BusinessBoard/Promotion";
import { MediaOutlets, isUpdateOutletsDataAtom } from "../../MediaOutlets";
import { CustomerAvatar } from "../../MediaOutlets/Common/CustomerAvatar";
import { PlatagList } from "../../MediaOutlets/Common/Platags/PlatagList";
import { Articles, isUpdateArticlesAtom } from "../../MediaOutlets/OutletBoard/Articles/Articles";
import { SideAttachment } from "../../MediaOutlets/OutletBoard/SideAttachment/SideAttachment";
import { MediaOutlet_atom } from "./MediaOutletBoard/atom";

// 这是 联系人 看板页面
export default function ContactBoard({
  data,
  setUpdateTime,
  setUpdateBy,
  setContactStatus,
  setIsBlack,
}) {
  console.log("ContactBoard data: ", data);
  const { id } = useParams();

  const { copiedMark, handleCopy } = useCopy();

  const [avatar, setAvatar] = useState("");
  const [contactName, setContactName] = useState("");
  const [role, setRole] = useState("");
  const [languages, setLanguages] = useState([]);
  const [region, setRegion] = useState({});
  const [platforms, setPlatforms] = useState([]);
  const [homepage, setHomepage] = useState("");
  const [note, setNote] = useState("");
  const [collections, setCollections] = useState([]);
  const [tags, setTags] = useState([]);

  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  // const [address, setAddress] = useState({});

  const [attachments, setAttachments] = useState([]);
  const [events, setEvents] = useState([]);
  const [otherInfo, setOtherInfo] = useState({
    creatorAvatar: "",
    creator: "",
    createTime: "",
    updaterAvatar: "",
    updater: "",
    updateTime: "",
  });

  // tabs
  const { tabJSX, selected: tabIndex } = useTabs({
    titles: ["Cooperation", "Budget", "Promotion", "Delivery"],
  });

  // Media contact
  const [, setMediaOutlets] = useRecoilState(MediaOutlet_atom);

  // cooperations
  const [, setCooperations] = useRecoilState(BoardCoopers_atom);
  const resetCooperations = useResetRecoilState(BoardCoopers_atom);
  // budgets
  const [, setBudgets] = useRecoilState(BoardBudgets_atom);
  const resetBudgets = useResetRecoilState(BoardBudgets_atom);
  // promotion
  const [, setPromotions] = useRecoilState(BoardPromotions_atom);
  const resetPromotions = useResetRecoilState(BoardPromotions_atom);
  // delivery
  const [, setDeliveries] = useRecoilState(BoardDeliveries_atom);
  const resetDeliveries = useResetRecoilState(BoardDeliveries_atom);
  // payment
  const [, setPayments] = useRecoilState(BoardPayments_atom);
  const resetPayments = useResetRecoilState(BoardPayments_atom);

  useEffect(() => {
    setAvatar(data?.avatar);
    setContactName(data?.name);
    setRole(data?.role);
    setLanguages(data?.languages);
    setHomepage(data?.homepage_url);
    setRegion(data?.address?.country);
    setPlatforms(data.platforms);
    setNote(data?.brief_description);
    setCollections(data?.groups);
    setTags(data?.tags);
    setContactStatus(data?.contact_status || 0);
    setIsBlack(data?.is_blacklisted);
    setEmail(data?.email);
    setMobile(data?.mobile_phone);
    setEvents(data?.events);
    // setAddress(data?.address);
    setAttachments(data?.attachments);
    setUpdateTime(data?.updated_at);
    setUpdateBy(data?.updater?.name);
    setOtherInfo({
      creatorAvatar: data?.creator?.avatar,
      creator: data?.creator?.name,
      createTime: moment2Date(s_to_ms(data?.created_at)),
      updaterAvatar: data?.updater?.avatar,
      updater: data?.updater?.name,
      updateTime: moment2Date(s_to_ms(data?.updated_at)),
    });

    setMediaOutlets(data?.media_outlets);

    setCooperations(data?.cooperation);
    setBudgets(data?.budget);
    setPromotions(data?.promotion);
    setDeliveries(data?.delivery);
    setPayments(data?.paymentApplication);
  }, [
    data,
    setBudgets,
    setCooperations,
    setDeliveries,
    setMediaOutlets,
    setPayments,
    setPromotions,
    setUpdateTime,
    setContactStatus,
    setIsBlack,
    setUpdateBy,
  ]);

  useEffect(
    () => () => {
      resetCooperations();
      resetBudgets();
      resetPromotions();
      resetDeliveries();
      resetPayments();
    },
    [resetCooperations, resetBudgets, resetPromotions, resetDeliveries, resetPayments]
  );

  // 跟新数据时候, 需要重新设置新的高度
  const [isUpdateOutlets] = useRecoilState(isUpdateOutletsDataAtom);
  const [isUpdateArticles] = useRecoilState(isUpdateArticlesAtom);
  const H = useMemo(
    () => ({ height: document.body.scrollHeight - 306, minHeight: 500 }),
    // eslint-disable-next-line
    [isUpdateOutlets, isUpdateArticles]
  );

  return (
    <Layout>
      {copiedMark}
      <div style={{ width: 300 }}>
        <Layout.Section secondary>
          <Card sectioned>
            <div style={H}>
              <RowStack>
                <Region
                  name={region?.name}
                  image={region?.image}
                  code={region?.code}
                  languages={languages}
                />
              </RowStack>
              <br />
              <CustomerAvatar
                avatar={<Avatar size="large" source={avatar} />}
                name={contactName}
                subName={role}
              />
              <br />
              <Stack spacing="tight">{PlatagList(platforms)}</Stack>
              <br />
              <Text variant="bodyMd" as="span" color="subdued">
                <LongTextCollect longText={note} />
              </Text>
              <br />
              <Stack wrap={false} alignment="center">
                <div style={{ width: 50 }}>
                  <IconFont code="&#xe760;" title="Home" color="#AC1D2B" />
                </div>
                <Stack.Item fill>
                  <ButtonLink link={homepage} len={25} />
                </Stack.Item>
              </Stack>
              <Stack wrap={false} alignment="center">
                <div style={{ width: 50 }}>
                  <IconFont code="&#xe75a;" title="Email" color="#008685" />
                </div>
                <Stack.Item fill>
                  <EmailClamp>{email}</EmailClamp>
                </Stack.Item>
                {email && (
                  <Button
                    icon={DuplicateMinor}
                    plain
                    onClick={() => handleCopy({ copyText: email })}
                  />
                )}
              </Stack>
              <Stack wrap={false} alignment="center">
                <div style={{ width: 50 }}>
                  <IconFont code="&#xe743;" title="Phone" color="#3171F2" />
                </div>
                <Stack.Item fill>
                  <Text variant="bodyMd" as="span" color="subdued">
                    <TextClamp>{mobile}</TextClamp>
                  </Text>
                </Stack.Item>
              </Stack>
              <Divider />
              <Anchor offsetTop={60}>
                <Anchor.Link href="#Overview" title="Overview" />
                <Anchor.Link href="#Media_outlets" title="Media outlets" />
                <Anchor.Link href="#Media_contact_articles" title="Media contact articles" />
                <Anchor.Link href="#Historical_activities" title="Historical activities" />
                <Anchor.Link href="#Other_information" title="Other information" />
                <Anchor.Link href="#Attachments" title="Attachments" />
                <Anchor.Link href="#Timeline" title="Timeline" />
              </Anchor>
            </div>
          </Card>
        </Layout.Section>
      </div>

      <Layout.Section>
        <Card title="Overview" sectioned>
          <AnchorMark id="Overview" />
          <InfoRow label="Collection">
            <ShowTags tags={collections} isProfile />
          </InfoRow>
          <InfoRow label="Tag">
            <ShowTags tags={tags} isProfile />
          </InfoRow>
        </Card>
        <Card title="Media outlets">
          <AnchorMark id="Media_outlets" />
          <NoStickyTableHeader>
            <MediaOutlets contactID={id} />
          </NoStickyTableHeader>
        </Card>
        <Card title="Media contact articles">
          <AnchorMark id="Media_contact_articles" />
          <Articles contactID={id} />
        </Card>
        <Card title="Historical activities">
          <AnchorMark id="Historical_activities" />
          {tabJSX}
          <NoStickyTableHeader>
            <BusinessBoard tabIndex={tabIndex} />
          </NoStickyTableHeader>
        </Card>
        <Card title="Other information" sectioned>
          <AnchorMark id="Other_information" />
          <UpdateInfo
            creatorAvatar={otherInfo.creatorAvatar}
            creator={otherInfo.creator}
            createTime={otherInfo.createTime}
            updaterAvatar={otherInfo.updaterAvatar}
            updater={otherInfo.updater}
            updateTime={otherInfo.updateTime}
          />
        </Card>
        <Card title="Attachments" sectioned>
          <AnchorMark id="Attachments" />
          <SideAttachment attachments={attachments} />
        </Card>
        <Card title="Timeline" sectioned>
          <AnchorMark id="Timeline" />
          <PureTimeLine events={events} />
        </Card>
      </Layout.Section>
    </Layout>
  );
}
