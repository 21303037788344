import { Avatar, Button, ProgressBar, Stack, Text, Tooltip } from "@shopify/polaris";

import { TextClamp } from "@/components/EnhanceShopify/TextClamp";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const KitCard = styled.div`
  width: 256px;
  height: 132px;
  padding: 16px 16px 0 16px;
  margin: 0px 0px 8px 0px;
  border-radius: 8px;
  box-sizing: border-box;
  background: linear-gradient(135deg, #f4f9f6, #ffffff 60%);
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  transition: box-shadow 0.2s ease-in, background-color 0.2s ease-in;
  &:hover {
    box-shadow: 2px 2px 8px #00000020, -2px -2px 8px #00000020;
    background: #ffffff;
  }
`;

export const CampaignsKittyCard = ({ campaign }) => {
  const history = useHistory();
  // eslint-disable-next-line
  const { id, title, cooperations_completed_total, cooperations_total, creator, progress_rate } =
    campaign;
  return (
    <KitCard>
      <Stack wrap={false}>
        <Stack.Item fill>
          <Stack vertical distribution="fillEvenly">
            <Stack.Item>
              {/* <Text variant="bodyMd" as="span" fontWeight="bold"> ttitle title title</Text> */}
              <Tooltip content={title}>
                <div>
                  <Text variant="bodyMd" as="span" fontWeight="bold">
                    <TextClamp clamp={1}>{title}</TextClamp>
                  </Text>
                </div>
              </Tooltip>
            </Stack.Item>
            <Stack.Item>
              <Stack vertical spacing="extraTight">
                <Stack.Item>
                  <Text variant="bodyMd" as="span" fontWeight="bold">
                    {progress_rate !== "-" ? progress_rate + "%" : "0%"}
                  </Text>
                </Stack.Item>
                <Stack.Item alignment="leading">
                  <ProgressBar progress={progress_rate} size="small" color="primary" />
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Button
                plain
                removeUnderline
                onClick={() => {
                  history.push(`/campaign/cooperation/${id}`);
                }}
              >
                View details
              </Button>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Avatar source={creator?.avatar}  />
        </Stack.Item>
      </Stack>
    </KitCard>
  );
};
