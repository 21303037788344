import axios from "axios";

import {
  requsetErrorHandler,
  requsetHandler,
  responseErrorHandler,
  responseHandler,
} from "./interceptor.handler";

// 创建一个新的 axios 实例
const api = axios.create({
  timeout: 10000,
  baseURL: process.env.REACT_APP_API_ORIGIN,
});

// 请求拦截器
api.interceptors.request.use(requsetHandler, requsetErrorHandler);

// 响应拦截器
api.interceptors.response.use(responseHandler, responseErrorHandler);

export { api };
