import { getTeamList, postDeleteTeams } from "@/api/request";
import { moment2Date } from "@/utils/moment2Date";
import {
  Button,
  Card,
  ChoiceList,
  Filters,
  Icon,
  IndexTable,
  Loading,
  Page,
  Pagination,
  Popover,
  Stack,
  Text,
  TextContainer,
  useIndexResourceState,
} from "@shopify/polaris";
import { Columns3Minor, SortMinor } from "@shopify/polaris-icons";
import { useDebounceFn, useLocalStorageState, useMount, useToggle, useUpdateEffect } from "ahooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  teamsColumnsOptions,
  teamsEmptyStateMarkup,
  teamsResourceName,
  teamsSortOptions,
  teams_headings,
} from "./Teams.support";

import { useChoiceListLogic } from "@/customHooks/useChoiceListLogic";
import { useCustomToast } from "@/customHooks/useCustomToast";
import { useFiltersPagination } from "@/customHooks/useFiltersPagination";
import { usePopoverActive } from "@/customHooks/usePopoverActive";
import { useTabs } from "@/customHooks/useTabs";
import { NoPermission } from "@/error/403";
import { ResponseError } from "@/error/500";
import { loadableAuthAtom } from "@/global/authAtom";
import { ConfirmDeleteModal } from "@/utils/ConfirmDeleteModal";
import { s_to_ms } from "@/utils/accurateTimeTrans";
import { splitChoiceValue } from "@/utils/checkType";
import { filterDivStyle } from "@/utils/filterDivStyle";
import { creatorOptions } from "@/utils/frontEndData/common.option";
import { filterOptions } from "@/utils/updateTextRegFilter";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";
import { v4 } from "uuid";

const Teams = () => {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      LOADING: permission.state === "loading",
      ERROR: permission.state === "hasError",
      READ: permission.data?.["pmp/workflows/teams/read"],
      WRITE: permission.data?.["pmp/workflows/teams/write"],
    }),
    [permission]
  );
  const history = useHistory();
  const { tabJSX } = useTabs({ titles: ["All"] });
  const [indexTableLoading, setIndexTableLoading] = useState(false);

  const { toastJSX, toggleActive: toggleCustomToastActive } =
    useCustomToast("Deleted successfully!");
  const [Teams, setTeams] = useState([]);

  // search text
  const [queryValue, setQueryValue] = useState(null);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  // create by
  const [selectedCreatorOptions, setSelectedCreatorOptions] = useState(["0"]);
  const handleCreatorOnSelect = useCallback((value) => setSelectedCreatorOptions(value), []);
  const handleCreatorRemove = useCallback(() => setSelectedCreatorOptions(["0"]), []);

  // clearAll
  const handleClearAll = useCallback(() => {
    setQueryValue("");
    handleCreatorRemove();
  }, [handleCreatorRemove]);

  // pagination state
  const { currentPage, setCurrentPage, totalPages, setTotalPages } = useFiltersPagination();

  const [deleteComfirmActive, { toggle: toggleDeleteComfirmActive }] = useToggle();
  const [deleteContent] = useState("Are you sure you want to delete the selected team?");

  //#region
  const filters = [
    {
      key: "Creator",
      label: "Create by",
      filter: (
        <ChoiceList
          title="Account status"
          titleHidden
          choices={creatorOptions}
          selected={selectedCreatorOptions || []}
          onChange={handleCreatorOnSelect}
        />
      ),
      shortcut: true,
    },
  ];
  const appliedFilters = [];
  if (selectedCreatorOptions["0"] !== "0") {
    const key = "Creator";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedCreatorOptions),
      onRemove: handleCreatorRemove,
    });
  }
  //  show the filter tags under the Search
  function disambiguateLabel(key, value) {
    switch (key) {
      case "Creator": {
        const checkedCreator = filterOptions(value, creatorOptions);
        return "Create by: " + checkedCreator.map((o) => `${o.label}`).join(", ");
      }
      default:
        return value;
    }
  }
  //#endregion

  //#region Sort
  //  active
  // const { popoverActive: sortActive, togglePopoverActive: toggleSortActive } = usePopoverActive();
  const [sortActive, { toggle: toggleSortActive }] = useToggle();
  // sort selelcted
  const [sortState, setSortState] = useState("updated_at"); // 排序类型:最新时间
  const [sortOrder, setSortOrder] = useState("desc"); // 排序倒序
  const { selected: sortValue, handleSelectChange: handleSortChange } =
    useChoiceListLogic("updated_at|desc");
  // 筛选参数
  useEffect(() => {
    const [updateOrtitle, sort] = splitChoiceValue(sortValue);
    setSortState(updateOrtitle);
    setSortOrder(sort);
  }, [sortValue]);

  const sortActivator = useMemo(
    () => (
      <Button icon={<Icon source={SortMinor} />} onClick={toggleSortActive}>
        Sort
      </Button>
    ),
    [toggleSortActive]
  );
  //#endregion Sort

  const initParams = useMemo(
    () => ({
      created_by_current_user: selectedCreatorOptions[0],
      sort_by: sortState,
      sort_type: sortOrder,
      page: 1,
      per_page: 50,
      name: queryValue,
    }),
    [selectedCreatorOptions, sortState, sortOrder, queryValue]
  );
  const { run } = useDebounceFn(
    async (params) => {
      if (!access.READ) return;
      setIndexTableLoading(true);
      try {
        // const { data } = await getBudgetAppsList({ ...initParams, ...params });
        const { data } = await getTeamList({ ...initParams, ...params });
        console.log("data: ", data);

        setTeams(data.list);
        setTotalPages(data.meta.pagination.total_pages ?? 0);
        setCurrentPage(data.meta.pagination.current_page ?? 0);
        setIndexTableLoading(false);
      } catch (error) {
        setIndexTableLoading(false);
      }
    },
    { wait: 600 }
  );
  // 渲染当前列表 - useMemo 中已经依赖项 - initParams
  const renderlist = useCallback(run, [run, initParams, setTotalPages, setCurrentPage]);

  //#region Columns
  const [local_teams_colums, set_local_teams_colums] = useLocalStorageState("teams_columns", {
    defaultValue: [],
  });
  const {
    selected: columns,
    setSelected: setcolumns,
    handleSelectChange: handleColumnsChange,
  } = useChoiceListLogic(["Team title", "Team code"]);
  // 挂载时读取记录
  useMount(() => {
    if (local_teams_colums?.length) {
      setcolumns(local_teams_colums);
    }
  });
  // 变化时存储记录
  useUpdateEffect(() => {
    set_local_teams_colums(columns);
  }, [columns]);

  const { popoverActive: columnsActive, togglePopoverActive: toggleColumnsActive } =
    usePopoverActive();
  const columnsActivator = useMemo(
    () => (
      <Button icon={Columns3Minor} onClick={toggleColumnsActive}>
        Columns
      </Button>
    ),
    [toggleColumnsActive]
  );
  //#endregion Columns

  // title的顺序应该跟columns默认初始值一致
  const [teamsHeading, setTeamsHeading] = useState(teams_headings);
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(Teams);
  // 动态加载列:
  useEffect(() => {
    const headings = teams_headings.filter(({ title }) => columns.includes(title));
    setTeamsHeading(headings);
    window.dispatchEvent(new Event("resize"));
  }, [columns]);
  // indexTable rows
  const rowMarkup = Teams.map(
    ({ id, code, title, note, creator, created_at, updater, updated_at }, index) => {
      const navigateToMembers = (
        <div onClick={() => history.push(`/Teams/${id}/TeamMembers`)}>{title}</div>
      );
      const creation_time_wrap = moment2Date(s_to_ms(created_at));
      const update_time_wrap = moment2Date(s_to_ms(updated_at));
      const props = [
        navigateToMembers,
        code,
        note,
        creator?.name,
        creation_time_wrap,
        updater?.name,
        update_time_wrap,
      ];
      let SN = []; // 总的序列对应关系

      // 把每一个heading的索引对应上需要展示的属性: 二维数组
      teamsColumnsOptions.forEach(({ value }, index) => {
        SN.push({ column: value, prop: props[index] });
      });

      let showCell = SN.filter(({ column }) => columns.includes(column));

      return (
        <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
          {showCell.map(({ prop }) => (
            <IndexTable.Cell key={v4()}>{prop}</IndexTable.Cell>
          ))}
        </IndexTable.Row>
      );
    }
  );

  const promotedBulkActions = [
    {
      content: "Delete team",
      onAction() {
        toggleDeleteComfirmActive();
      },
    },
  ];
  // handle comfirm delete function - after the indexTable state
  const handleConfirmDelete = useCallback(async () => {
    toggleDeleteComfirmActive();
    try {
      await postDeleteTeams({ ids: selectedResources });
      await renderlist();
      toggleCustomToastActive();
    } catch (error) {
      await renderlist();
    }
    handleSelectionChange("all", false); // 清除indextable选中状态
  }, [
    handleSelectionChange,
    renderlist,
    selectedResources,
    toggleDeleteComfirmActive,
    toggleCustomToastActive,
  ]);

  // 下一页
  const Next = useCallback(async () => {
    setCurrentPage((currentPage) => ++currentPage);
    await renderlist({ page: currentPage + 1 });
  }, [setCurrentPage, renderlist, currentPage]);
  // 上一页
  const Previous = useCallback(async () => {
    setCurrentPage((currentPage) => --currentPage);
    await renderlist({ page: currentPage - 1 });
  }, [setCurrentPage, renderlist, currentPage]);

  // 查询加载
  useUpdateEffect(() => {
    renderlist();
  }, [queryValue, selectedCreatorOptions, sortValue]);

  useMount(() => {
    renderlist();
  });

  if (access.LOADING) return <Loading />;
  if (access.ERROR) return <ResponseError />;
  if (!access.READ) return <NoPermission />;

  return (
    <Page
      fullWidth
      title="Teams"
      primaryAction={
        !access.WRITE
          ? undefined
          : {
              content: "Create team",
              onAction() {
                history.push(`/Teams/TeamSettings`);
              },
            }
      }
    >
      <Card>
        {tabJSX}
        <div style={filterDivStyle}>
          <div style={{ flex: 1 }}>
            <Filters
              queryPlaceholder="Search by team title"
              queryValue={queryValue}
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={setQueryValue}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleClearAll}
            />
          </div>
          <div style={{ paddingLeft: "0.4rem" }}>
            <Popover active={sortActive} activator={sortActivator} onClose={toggleSortActive}>
              <Popover.Section>
                <TextContainer>
                  <Text variant="bodyMd" as="span" color="subdued">
                    Sort by
                  </Text>
                </TextContainer>
                <ChoiceList
                  choices={teamsSortOptions}
                  selected={sortValue}
                  onChange={handleSortChange}
                />
              </Popover.Section>
            </Popover>
          </div>
          <div style={{ paddingLeft: "0.4rem" }}>
            <Popover
              active={columnsActive}
              activator={columnsActivator}
              onClose={toggleColumnsActive}
            >
              <Popover.Section>
                <TextContainer>
                  <Text variant="bodyMd" as="span" color="subdued">
                    Columns
                  </Text>
                </TextContainer>
                <ChoiceList
                  allowMultiple
                  choices={teamsColumnsOptions}
                  selected={columns}
                  onChange={handleColumnsChange}
                />
              </Popover.Section>
            </Popover>
          </div>
        </div>
        <IndexTable
          loading={indexTableLoading}
          resourceName={teamsResourceName}
          itemCount={Teams.length}
          selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
          onSelectionChange={handleSelectionChange}
          promotedBulkActions={!access.WRITE ? undefined : promotedBulkActions}
          headings={teamsHeading}
          emptyState={teamsEmptyStateMarkup}
        >
          {rowMarkup}
        </IndexTable>
        {Teams.length ? (
          <Stack distribution="center">
            <div style={{ padding: "2px 0 16px" }}>
              <Pagination
                label={
                  <>
                    {currentPage}/{totalPages}
                  </>
                }
                hasPrevious={currentPage < 2 ? false : true}
                onPrevious={Previous}
                hasNext={currentPage >= totalPages ? false : true}
                onNext={Next}
              />
            </div>
          </Stack>
        ) : null}
      </Card>
      {toastJSX}
      <ConfirmDeleteModal
        deleteComfirmActive={deleteComfirmActive}
        toggleDeleteComfirmActive={toggleDeleteComfirmActive}
        content={deleteContent}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Page>
  );
};

export default Teams;
