import "./leftbar.scss";

import {
  AppsMajor,
  ChannelsMajor,
  CustomersMajor,
  FavoriteMajor,
  HomeMajor,
  MarketingMajor,
  PaymentsMajor,
  SearchMajor,
  SettingsMajor,
} from "@shopify/polaris-icons";
import { useCallback } from "react";

import { cookieScope } from "@/utils/cookieScope";
import { qstring } from "@/utils/qstring";
import { Navigation } from "@shopify/polaris";
import { useResponsive } from "ahooks";
import { useHistory } from "react-router-dom";

const Leftbar = (props) => {
  const history = useHistory();
  const { md } = useResponsive();

  const adaptMobile = useCallback(
    (url) => () => {
      if (!md) history.push(url);
    },
    [history, md]
  );
  const HomeMenu = [
    {
      url: "/dashboard",
      label: "Home",
      icon: HomeMajor,
    },
  ];
  const PartnerMenu = [
    {
      url: "/discovery",
      label: "Discovery",
      icon: SearchMajor,
    },
    {
      url: "/home/group",
      label: "Collections",
      icon: FavoriteMajor,
      onClick: adaptMobile("/home/group"),
    },
  ];

  const CampaignMenu = [
    {
      file: true,
      url: "/campaign",
      label: "Campaigns",
      onClick: adaptMobile("/campaign"),
      icon: MarketingMajor,
      subNavigationItems: [
        {
          url: "/campaign/Cooperations",
          label: "Cooperations",
        },
        {
          url: "/orders/BudgetApps",
          label: "Budgets",
        },
        {
          url: "/orders/Deliveries",
          label: "Deliveries",
        },
        {
          url: "/campaign/Promotions",
          label: "Promotions",
        },
        // {
        //   url: "/orders/PaymentOrders",
        //   label: "Payments",
        // },
        {
          url: "/Schedules",
          label: "Schedules",
          subNavigationItems: [],
        },
        {
          url: "/Teams",
          label: "Teams",
          // icon: TeamMajor,
          subNavigationItems: [],
        },
      ],
    },
    {
      url: `${process.env.REACT_APP_UPP_URL}/redirect?${qstring.stringify({
        token: cookieScope.getToken(),
        id: cookieScope.getTenantId(),
      })}`,
      label: "Payments",
      icon: PaymentsMajor,
    },
  ];
  const AppsMenu = [
    {
      url: "/tools",
      label: "Automation tools",
      icon: AppsMajor,
    },
  ];

  // eslint-disable-next-line
  const items = [
    {
      url: "/influencers",
      label: "Influencers",
      onClick: adaptMobile("/influencers"),
      icon: CustomersMajor,
      subNavigationItems: [
        {
          url: "/leads",
          label: "Social links",
        },
      ],
    },
    {
      url: "/media",
      label: "Media outlets",
      onClick: adaptMobile("/media"),
      icon: ChannelsMajor,
      subNavigationItems: [
        {
          url: "/contacts",
          label: "Media contacts",
        },
      ],
    },
  ];
  const settingItem = [
    {
      url: "/account/company",
      label: "System settings",
      icon: SettingsMajor,
    },
  ];
  return (
    <Navigation location={props.location.pathname}>
      <Navigation.Section items={HomeMenu} />
      <Navigation.Section title="Resources" items={PartnerMenu} />
      <Navigation.Section title="Workflows" items={CampaignMenu} />
      <Navigation.Section title="Apps" items={AppsMenu} fill />
      <Navigation.Section items={settingItem} />
    </Navigation>
  );
};

export default Leftbar;
