import styled from "styled-components";

export const AntTreeSelect = styled.div`
  min-width: 240px;
  .ant-select-multiple .ant-select-selector {
    /* 选择框样式 */
    border-radius: 4px;
    border-color: rgba(186, 191, 195, 1);
    padding: 3px 4px;
    .ant-select-selection-overflow .ant-select-selection-overflow-item .ant-select-selection-item {
      /* tag样式 */
      background-color: rgba(228, 229, 231, 1);
      border-radius: 4px;
    }
  }
`;
