export const wait = (timeout = 300, isError = false) => {
  if (!isError) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, timeout);
    });
  } else {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject("wait error");
      }, timeout);
    });
  }
};
