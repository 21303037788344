import { atom } from "recoil";
import { v4 } from "uuid";

export const influencer_Options = atom({
  key: v4(),
  default: [],
});

export const influencer_refresh = atom({
  key: v4(),
  default: false,
});
