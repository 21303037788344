import {
  getFiltersCountrys,
  getPartnerCompanysById,
  postPartnerUserCompany,
  putPartnerCompanyInfo
} from "@/api/request";
import { Card, ContextualSaveBar, FormLayout, Select, TextField } from "@shopify/polaris";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { LoadingContext } from "@/context/loading-context";
import { regEmail } from "@/utils/valid";
import { useHistory, useParams } from "react-router";

export default function CompanyItemDetail() {
  const history = useHistory();
  const { ID } = useParams();
  const diffCount = useRef(0);
  const originInfo = useRef({});
  const memoObject = useRef({});
  const loadingContext = useContext(LoadingContext);
  const [addDisable, setAddDisable] = useState(false);

  const [isShowContextSaveBar, setIsShowContextSaveBar] = useState(false);
  const handleCompareTwoObject = useCallback(
    (prop, value) => {
      if (ID !== "add") {
        if (originInfo?.current?.[prop] !== value) {
          setIsShowContextSaveBar(true);
          if (memoObject.current[prop] === 1) {
            memoObject.current[prop]++;
          } else {
            memoObject.current[prop] = 1;
          }
        } else {
          memoObject.current[prop] = 0;
        }
        if (memoObject.current[prop] === 1) {
          diffCount.current++;
        } else if (memoObject.current[prop] === 0) {
          diffCount.current--;
        }
        if (diffCount.current === 0) setIsShowContextSaveBar(false);
      } else {
        if (prop === "name") {
          if (originInfo?.current?.[prop] !== value) {
            setAddDisable(false);
          } else {
            setAddDisable(true);
          }
        }
      }
    },
    [ID, originInfo, memoObject, diffCount]
  );

  const handleSaveClick = () => {
    if (companyName === "") {
      loadingContext.loading(false, "Please input the company name!", true);
      return;
    }
    if (ID !== "add") {
      loadingContext.loading(true);
      putPartnerCompanyInfo(ID, {
        name: companyName,
        contact_person: contactPerson,
        mobile_phone: companyMobilePhone,
        email: companyEmail,
        telephone: companyTelePhone,
        fax: companyFax,
        official_website: officeWebsite,
        country_id: countrySelected,
        state: stateValue,
        city: cityValue,
        postal_code: postalCodeValue,
        street: addressValue,
      }).then((res) => {
        let temp = {
          name: res?.data?.name ?? "",
          contact_person: res?.data?.contact_person ?? "",
          mobile_phone: res?.data?.mobile_phone ?? "",
          email: res?.data?.email ?? "",
          telephone: res?.data?.telephone ?? "",
          fax: res?.data?.fax ?? "",
          official_website: res?.data?.official_website ?? "",
          country: (res?.data?.address?.country?.id ?? "") + "",
          state: res?.data?.address?.state ?? "",
          city: res?.data?.address?.city ?? "",
          postal_code: res?.data?.address?.postal_code ?? "",
          street: res?.data?.address?.street ?? "",
        };
        originInfo.current = temp;
        initData(temp);
        diffCount.current = 0;
        memoObject.current = {};
        setIsShowContextSaveBar(false);
        loadingContext.loading(false, "Saved successfully !");
      });
    } else {
      loadingContext.loading(true);
      postPartnerUserCompany({
        name: companyName,
        contact_person: contactPerson,
        mobile_phone: companyMobilePhone,
        email: companyEmail,
        telephone: companyTelePhone,
        fax: companyFax,
        official_website: officeWebsite,
        country_id: countrySelected,
        state: stateValue,
        city: cityValue,
        postal_code: postalCodeValue,
        street: addressValue,
      }).then((res) => {
        let temp = {
          name: res?.data?.name ?? "",
          contact_person: res?.data?.contact_person ?? "",
          mobile_phone: res?.data?.mobile_phone ?? "",
          email: res?.data?.email ?? "",
          telephone: res?.data?.telephone ?? "",
          fax: res?.data?.fax ?? "",
          official_website: res?.data?.official_website ?? "",
          country: (res?.data?.address?.country?.id ?? "") + "",
          state: res?.data?.address?.state ?? "",
          city: res?.data?.address?.city ?? "",
          postal_code: res?.data?.address?.postal_code ?? "",
          street: res?.data?.address?.street ?? "",
        };
        originInfo.current = temp;
        initData(temp);
        diffCount.current = 0;
        memoObject.current = {};
        setIsShowContextSaveBar(false);
        loadingContext.loading(false, "Saved successfully !");
        history.goBack();
      });
    }
  };

  let contextSaveBar = isShowContextSaveBar ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        onAction: handleSaveClick,
        loading: false,
        disabled: addDisable,
      }}
      discardAction={{
        onAction: () => {
          if (ID !== "add") {
            diffCount.current = 0;
            memoObject.current = {};
            initData(originInfo.current);
            setIsShowContextSaveBar(false);
          } else {
            history.goBack();
          }
        },
      }}
    />
  ) : null;

  useEffect(() => {
    if (ID !== "add") {
      // loadingContext.loading(true);
      getPartnerCompanysById(ID).then((res) => {
        // loadingContext.loading(false);
        let temp = {
          name: res.data?.name ?? "",
          contact_person: res.data?.contact_person ?? "",
          mobile_phone: res.data?.mobile_phone ?? "",
          email: res.data?.email ?? "",
          telephone: res.data?.telephone ?? "",
          fax: res.data?.fax ?? "",
          official_website: res.data?.official_website ?? "",
          country: (res.data?.address?.country?.id ?? "") + "",
          state: res.data?.address?.state ?? "",
          city: res.data?.address?.city ?? "",
          postal_code: res.data?.address?.postal_code ?? "",
          street: res.data?.address?.street ?? "",
        };
        originInfo.current = temp;
        initData(temp);
      });
    } else {
      let temp = {
        name: "",
        contact_person: "",
        mobile_phone: "",
        email: "",
        telephone: "",
        fax: "",
        official_website: "",
        country: "",
        state: "",
        city: "",
        postal_code: "",
        street: "",
      };
      originInfo.current = temp;
      initData(temp);
      setIsShowContextSaveBar(true);
      setAddDisable(true);
    }
  }, [ID]);

  const initData = (data) => {
    setCompanyName(data?.name ?? "");
    setContactPerson(data?.contact_person ?? "");
    setCompanyMobilePhone(data?.mobile_phone);
    setCompanyEmail(data?.email);
    setCompanyTelePhone(data?.telephone);
    setCompanyFax(data?.fax);
    setOfficeWebsite(data?.official_website);
    setCountrySelected(data?.country);
    setStateValue(data?.state);
    setCityValue(data?.city);
    setPostalCodeValue(data?.postal_code);
    setAddressValue(data?.street);
  };

  // company name
  const [companyName, setCompanyName] = useState("");
  const handleCompanyNameChange = useCallback(
    (newValue) => {
      setCompanyName(newValue);
      handleCompareTwoObject("name", newValue);
    },
    [handleCompareTwoObject]
  );

  // contact person
  const [contactPerson, setContactPerson] = useState("");
  const handleContactPersonChange = useCallback(
    (newValue) => {
      setContactPerson(newValue);
      handleCompareTwoObject("contact_person", newValue);
    },
    [handleCompareTwoObject]
  );

  // Mobile phone
  const [companyMobilePhone, setCompanyMobilePhone] = useState("");
  const handleCompanyMobilePhoneChange = useCallback(
    (newValue) => {
      setCompanyMobilePhone(newValue);
      handleCompareTwoObject("mobile_phone", newValue);
    },
    [handleCompareTwoObject]
  );

  // email
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyEmailError, setCompanyEmailError] = useState("");
  const handleCompanyEmailChange = useCallback(
    (newValue) => {
      setCompanyEmail(newValue);
      handleCompareTwoObject("email", newValue);
      if (newValue && !regEmail(newValue)) {
        setCompanyEmailError("please enter the right email address");
      } else {
        setCompanyEmailError("");
      }
    },
    [handleCompareTwoObject]
  );

  // telephone
  const [companyTelePhone, setCompanyTelePhone] = useState("");
  const handleCompanyTelePhoneChange = useCallback(
    (newValue) => {
      setCompanyTelePhone(newValue);
      handleCompareTwoObject("telephone", newValue);
    },
    [handleCompareTwoObject]
  );

  // fax
  const [companyFax, setCompanyFax] = useState("");
  const handleCompanyFaxChange = useCallback(
    (newValue) => {
      setCompanyFax(newValue);
      handleCompareTwoObject("fax", newValue);
    },
    [handleCompareTwoObject]
  );

  // office website
  const [officeWebsite, setOfficeWebsite] = useState("");
  const handleOfficeWebsiteChange = useCallback(
    (newValue) => {
      setOfficeWebsite(newValue);
      handleCompareTwoObject("official_website", newValue);
    },
    [handleCompareTwoObject]
  );

  // 处理公司地址信息
  useEffect(() => {
    getFiltersCountrys().then((res) => {
      let tempArr = res.data.list.map((item) => ({
        label: item.name,
        value: item.id + "",
      }));
      tempArr.unshift({ label: "", value: "" });
      setCountryOptions(tempArr);
    });
  }, []);
  const [countryOptions, setCountryOptions] = useState([]);
  const [countrySelected, setCountrySelected] = useState("");
  const handleCountrySelectChange = useCallback(
    (newValue) => {
      setCountrySelected(newValue);
      handleCompareTwoObject("country", newValue);
    },
    [handleCompareTwoObject]
  );

  const [stateValue, setStateValue] = useState("");
  const handleStateValueChange = useCallback(
    (newValue) => {
      setStateValue(newValue);
      handleCompareTwoObject("state", newValue);
    },
    [handleCompareTwoObject]
  );

  const [cityValue, setCityValue] = useState("");
  const handleCityValueChange = useCallback(
    (newValue) => {
      setCityValue(newValue);
      handleCompareTwoObject("city", newValue);
    },
    [handleCompareTwoObject]
  );

  const [postalCodeValue, setPostalCodeValue] = useState("");
  const handlePostalCodeValueChange = useCallback(
    (newValue) => {
      setPostalCodeValue(newValue);
      handleCompareTwoObject("postal_code", newValue);
    },
    [handleCompareTwoObject]
  );

  const [addressValue, setAddressValue] = useState("");
  const handleAddressValueChange = useCallback(
    (newValue) => {
      setAddressValue(newValue);
      handleCompareTwoObject("street", newValue);
    },
    [handleCompareTwoObject]
  );
  return (
    <Card title="">
      {contextSaveBar}
      <Card.Section>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              label="Company name"
              value={companyName}
              maxLength={50}
              onChange={handleCompanyNameChange}
            />
            <TextField
              label="Contact person"
              value={contactPerson}
              onChange={handleContactPersonChange}
              maxLength={50}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              label="Mobile phone"
              type="tel"
              value={companyMobilePhone}
              onChange={handleCompanyMobilePhoneChange}
              maxLength={50}
            />
            <TextField
              label="E-mail"
              type="email"
              value={companyEmail}
              onChange={handleCompanyEmailChange}
              maxLength={50}
              error={companyEmailError}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              label="Telephone"
              value={companyTelePhone}
              onChange={handleCompanyTelePhoneChange}
              maxLength={50}
            />
            <TextField
              label="Fax"
              value={companyFax}
              onChange={handleCompanyFaxChange}
              maxLength={50}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Select
              label="Country/region"
              options={countryOptions}
              onChange={handleCountrySelectChange}
              value={countrySelected}
            />
            <TextField label="State" value={stateValue} onChange={handleStateValueChange} />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField label="City" value={cityValue} onChange={handleCityValueChange} />
            <TextField
              label="Postal code"
              value={postalCodeValue}
              onChange={handlePostalCodeValueChange}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              label="Address"
              onChange={handleAddressValueChange}
              multiline={2}
              placeholder=""
              value={addressValue}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              label="Official website"
              onChange={handleOfficeWebsiteChange}
              multiline={2}
              value={officeWebsite}
              placeholder=""
              maxLength={255}
            />
          </FormLayout.Group>
        </FormLayout>
      </Card.Section>
    </Card>
  );
}
