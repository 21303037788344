import { Modal } from "@shopify/polaris";

export const ConfirmDeleteModal = ({
  title = "Confirm delete",
  deleteComfirmActive,
  toggleDeleteComfirmActive,
  content = "Are you sure you want to delete the selected items?",
  handleConfirmDelete,
  buttonText = "Delete",
  disabled = false,
}) => {
  return (
    <Modal
      open={deleteComfirmActive}
      onClose={toggleDeleteComfirmActive}
      title={title}
      primaryAction={{
        destructive: true,
        content: buttonText,
        onAction: handleConfirmDelete,
        disabled,
      }}
      secondaryActions={[{ content: "Cancel", onAction: toggleDeleteComfirmActive }]}
    >
      <Modal.Section>{content}</Modal.Section>
    </Modal>
  );
};
