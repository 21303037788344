import { IndexTable, Stack } from "@shopify/polaris";
import { useEffect, useMemo, useState } from "react";
import { atom, useRecoilState } from "recoil";

import { AntPagination } from "@/components/AntToShopify/AntPagination";
import { ButtonLink } from "@/components/EnhanceShopify/ButtonLink";
import { loadableAuthAtom } from "@/global/authAtom";
import { EmptyStateCard } from "@/pages/Partner/PartnerReport/General/General.support";
import { promotionStatusBadge } from "@/utils/statusMapBadge";
import { Pagination } from "antd";
import { useAtom } from "jotai";
import { chunk } from "lodash";
import { useHistory } from "react-router-dom";
import { v4 } from "uuid";

export const BoardPromotions_atom = atom({
  key: v4(),
  default: [],
});

const emptyNavto = `/campaign/Promotions/PromotionDetail`;

const headings = ["Promotion code", "Campaign", "Status", "Link"];
const tableHeading = headings.map((str) => ({ title: str }));

export const Promotion = () => {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      READ: permission.data?.["pmp/workflows/promotions/read"],
      WRITE: permission.data?.["pmp/workflows/promotions/write"],
    }),
    [permission]
  );
  const history = useHistory();
  const [promotions] = useRecoilState(BoardPromotions_atom);

  const [tableList, setTableList] = useState([]);
  const [listCunk, setListCunk] = useState([]);

  const [total, setTotal] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const rowMarkup = tableList?.map(({ id, code, campaign, status, link }, index) => {
    return (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>{code}</IndexTable.Cell>
        <IndexTable.Cell>{campaign}</IndexTable.Cell>
        <IndexTable.Cell>{promotionStatusBadge(status)}</IndexTable.Cell>
        <IndexTable.Cell>{<ButtonLink link={link}>{link}</ButtonLink>}</IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  useEffect(() => {
    const pages = chunk(promotions, pageSize);
    setTableList(pages[0]); // 第一页
    setListCunk(pages); // 各个页数
    setCurrentPage(1);
    setTotal(promotions.length); // 总数
  }, [promotions, pageSize]);

  return (
    <>
      {tableList?.length ? (
        <IndexTable
          resourceName={{ singular: "item", plural: "items" }}
          itemCount={tableList.length}
          headings={tableHeading}
          selectable={false}
        >
          {rowMarkup}
        </IndexTable>
      ) : (
        <EmptyStateCard
          btnTitle="promotion"
          content="promotions"
          handleCreate={() => history.push(emptyNavto)}
          access={access.WRITE}
        />
      )}
      {tableList?.length ? (
        <Stack distribution="trailing" alignment="center">
          <AntPagination>
            <Pagination
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              pageSize={pageSize}
              current={currentPage}
              pageSizeOptions={[5, 10, 15, 20]}
              showSizeChanger={true}
              total={total}
              onChange={(page, pageSize) => {
                setPageSize(pageSize);
                setCurrentPage(page);
                setTableList(listCunk[page - 1]);
              }}
            />
          </AntPagination>
        </Stack>
      ) : null}
    </>
  );
};
