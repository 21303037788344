import { atom } from "recoil";
import { v4 } from "uuid";

export const epxortINS_atom = atom({
  key: v4(),
  default: false,
});

const ins_search_type = atom({
  key: v4(),
  default: "all",
});

const ins_current_page = atom({
  key: v4(),
  default: 1,
});
const ins_per_page = atom({
  key: v4(),
  default: 10,
});

const ins_filter_sort = atom({
  key: v4(),
  default: "updated_at|desc",
});

const ins_filter_search = atom({
  key: v4(),
  default: "",
});

const ins_filter_platsrDesOps = atom({
  key: v4(),
  default: [],
});

const ins_filter_platsrOps = atom({
  key: v4(),
  default: [],
});

const ins_filter_platsrSelect = atom({
  key: v4(),
  default: [],
});

const ins_filter_keywordDesOps = atom({
  key: v4(),
  default: [],
});

const ins_filter_keywordOps = atom({
  key: v4(),
  default: [],
});

const ins_filter_keywordSelect = atom({
  key: v4(),
  default: [],
});

const ins_filter_relatedPtnsDesOps = atom({
  key: v4(),
  default: [],
});

const ins_filter_relatedPtnsOps = atom({
  key: v4(),
  default: [],
});

const ins_filter_relatedPtnsSelect = atom({
  key: v4(),
  default: [],
});

const ins_filter_postMin = atom({
  key: v4(),
  default: "",
});

const ins_filter_postMax = atom({
  key: v4(),
  default: "",
});

const ins_filter_followerMin = atom({
  key: v4(),
  default: "",
});

const ins_filter_followerMax = atom({
  key: v4(),
  default: "",
});

const ins_filter_categoryBackup = atom({
  key: v4(),
  default: [],
});

const ins_filter_categoryDesOps = atom({
  key: v4(),
  default: [],
});

const ins_filter_categoryOps = atom({
  key: v4(),
  default: [],
});

const ins_filter_categorySelect = atom({
  key: v4(),
  default: [],
});

const ins_filter_creatorDesOps = atom({
  key: v4(),
  default: [],
});

const ins_filter_creatorOps = atom({
  key: v4(),
  default: [],
});

const ins_filter_creatorSelect = atom({
  key: v4(),
  default: [],
});

const ins_filter_creatime = atom({
  key: v4(),
  default: null,
});

// const ins_filter_xxDesOps = atom({
//   key: v4(),
//   default: [],
// });

// const ins_filter_xxOps = atom({
//   key: v4(),
//   default: [],
// });

// const ins_filter_xxSelect = atom({
//   key: v4(),
//   default: [],
// });

export {
  ins_search_type,
  ins_current_page,
  ins_per_page,
  ins_filter_sort,
  ins_filter_search,
  ins_filter_platsrDesOps,
  ins_filter_platsrOps,
  ins_filter_platsrSelect,
  ins_filter_keywordDesOps,
  ins_filter_keywordOps,
  ins_filter_keywordSelect,
  ins_filter_relatedPtnsDesOps,
  ins_filter_relatedPtnsOps,
  ins_filter_relatedPtnsSelect,
  ins_filter_postMin,
  ins_filter_postMax,
  ins_filter_followerMin,
  ins_filter_followerMax,
  ins_filter_categoryBackup,
  ins_filter_categoryDesOps,
  ins_filter_categoryOps,
  ins_filter_categorySelect,
  ins_filter_creatorDesOps,
  ins_filter_creatorOps,
  ins_filter_creatorSelect,
  ins_filter_creatime,
};
