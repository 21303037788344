import { v4 } from "uuid";

const { atom } = require("recoil");

// 通过分组里面 add partner 选择框, 点击save 新增成功后, 要通知列表组件刷新当前列表以便展示最新的列表状态
export const refrehCollectionAtom = atom({
  key: v4(),
  default: false,
});

export const refrehCollectionPlatformAtom = atom({
  key: v4(),
  default: false,
});
