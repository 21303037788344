import { useCustomToast } from "@/customHooks/useCustomToast";
import copy from "copy-to-clipboard";
import { useCallback, useState } from "react";

export const useCopy = () => {
  const [copiedMsg, setCopiedMsg] = useState("Copied!");
  const { toastJSX: copiedMark, toggleActive } = useCustomToast(copiedMsg);

  const handleCopy = useCallback(
    ({ copyText, msg }) => {
      if (!copyText) return;
      msg && setCopiedMsg(msg);
      copy(copyText);
      toggleActive();
    },
    [toggleActive]
  );

  return { copiedMark, handleCopy };
};
