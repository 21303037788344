import { getTeamDetail, postAddTeam, postUpdateTeam } from "@/api/request";
import { Card, Layout, Loading, Page, Text, TextField } from "@shopify/polaris";
import { useMount, useUpdateEffect } from "ahooks";
import { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { msg } from "@/configs";
import { useBanner } from "@/customHooks/useBanner";
import { useTextFieldLogic } from "@/customHooks/useTextFieldLogic";
import { useTopLoading } from "@/customHooks/useTopLoading";
import { NoPermission } from "@/error/403";
import { ResponseError } from "@/error/500";
import { loadableAuthAtom } from "@/global/authAtom";
import { TopSaveBar } from "@/utils/TopSaveBar";
import { s_to_ms } from "@/utils/accurateTimeTrans";
import { hotToast } from "@/utils/hotToast";
import { moment2Date } from "@/utils/moment2Date";
import { toJson } from "@/utils/toValue";
import { useAtom } from "jotai";

const TeamSettings = () => {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      LOADING: permission.state === "loading",
      ERROR: permission.state === "hasError",
      READ: permission.data?.["pmp/workflows/teams/read"],
      WRITE: permission.data?.["pmp/workflows/teams/write"],
    }),
    [permission]
  );

  const history = useHistory();
  const { teamID } = useParams();
  const AddErrBanner = useBanner({ content: "failed", status: "critical" });
  const SuccessBanner = useBanner({ content: "successfully!" });
  // 顶部栏
  const [showTopBar, setShowTopBar] = useState(false); // 顶部栏
  const [disabled, disabledTopBar] = useState(false); // 禁用顶部栏save按钮
  const { TopLoadingJSX } = useTopLoading();
  const [backupData, setBackupData] = useState({});

  // 输入框
  const {
    value: title,
    setValue: setTtile,
    handleChange: handleTtileChange, // 做了去空格处理
  } = useTextFieldLogic();

  const {
    value: note,
    setValue: setNote,
    handleChange: handleNoteChange, // 做了去空格处理
  } = useTextFieldLogic();

  // 其他信息-侧边栏
  const otherInfoTitleList = ["CREATE BY", "CREATION TIME", "UPDATE BY", "UPDATE TIME"];
  const [otherInfo, setOtherInfo] = useState([]);

  const params = useMemo(() => {
    return {
      title,
      note,
    };
  }, [title, note]);
  const backup = useMemo(() => {
    return {
      title: backupData.title,
      note: backupData.note,
    };
  }, [backupData]);
  useUpdateEffect(() => {
    // console.log("toJson(backup): ", toJson(backup));
    // console.log("toJson(params): ", toJson(params));
    if (teamID) {
      if (toJson(backup) !== toJson(params)) {
        setShowTopBar(true);
      } else {
        setShowTopBar(false);
      }
    }
  }, [backup, params]);

  const isTitleFill = useMemo(() => !!title?.trim(), [title]);
  useUpdateEffect(() => {
    console.log("isTitleFill: ", isTitleFill);
    if (!isTitleFill) {
      disabledTopBar(true);
    } else {
      disabledTopBar(false);
    }
  }, [isTitleFill]);

  const initInfo = useCallback(
    (data) => {
      setTtile(data.title);
      setNote(data.note);
      setOtherInfo([
        data.creator.name,
        moment2Date(s_to_ms(data.created_at)),
        data.updater.name,
        moment2Date(s_to_ms(data.updated_at)),
      ]);
    },
    [setTtile, setNote]
  );
  // 取消
  const handleDiscard = useCallback(() => {
    if (!teamID) {
      history.goBack();
    } else {
      initInfo(backupData);
    }
  }, [history, backupData, initInfo, teamID]);

  // 保存
  const handleSave = useCallback(async () => {
    if (!access.WRITE) {
      hotToast(msg.error403, { error: true });
      return;
    }
    if (!teamID) {
      try {
        await postAddTeam(params);
        history.goBack();
      } catch (error) {
        AddErrBanner.setBannerShow();
      }
    } else {
      const { data } = await postUpdateTeam(teamID, params);
      initInfo(data);
      SuccessBanner.setBannerShow();
      setShowTopBar(false);
    }
  }, [access, teamID, params, history, AddErrBanner, initInfo, SuccessBanner]);
  // 挂载时
  useMount(async () => {
    if (!teamID) {
      setShowTopBar(true);
      disabledTopBar(true);
    } else {
      const { data } = await getTeamDetail(teamID);
      setBackupData(data); // 备份数据
      initInfo(data);
    }
  });

  if (access.LOADING) return <Loading />;
  if (access.ERROR) return <ResponseError />;
  if (!access.READ) return <NoPermission />;

  return (
    <Page
      title="Team settings"
      breadcrumbs={[
        {
          content: "teams",
          onAction: () => {
            history.goBack();
          },
        },
      ]}
    >
      {TopLoadingJSX}
      <TopSaveBar
        isDirty={showTopBar}
        handleSave={handleSave}
        handleDiscard={handleDiscard}
        disabled={disabled}
      />
      <Layout>
        <Layout.Section>
          {AddErrBanner.BannerJSX}
          {SuccessBanner.BannerJSX}
          <Card sectioned title="Basic information">
            <TextField
              autoComplete="off"
              label="Team title"
              value={title}
              requiredIndicator
              onChange={handleTtileChange}
            />
            <br />
            <TextField
              autoComplete="off"
              label="Note"
              multiline={2}
              value={note}
              onChange={handleNoteChange}
            />
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card title="Other information">
            {otherInfoTitleList.map((title, index) => (
              <Card.Section key={title} title={title}>
                <Text variant="bodyMd" as="span" color="subdued">
                  {otherInfo[index]}
                </Text>
              </Card.Section>
            ))}
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default TeamSettings;
