import { atom } from "recoil";
import { v4 } from "uuid";

export const partnerID_atom = atom({
  key: v4(),
  default: "",
});

export const editorActive_atom = atom({
  key: v4(),
  default: false,
});

export const regionOps_atom = atom({
  key: v4(),
  default: [],
});

export const languageOps_atom = atom({
  key: v4(),
  default: [],
});

export const contactTypeOps_atom = atom({
  key: v4(),
  default: [],
});

export const priceTypeOps_atom = atom({
  key: v4(),
  default: [],
});

export const currecyOps_atom = atom({
  key: v4(),
  default: [],
});
