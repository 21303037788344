import "./CooperationList.scss";

import { deleteMultiCooperations, getCampaignCooperations } from "@/api/request";
import { checkType, splitChoiceValue } from "@/utils/checkType";
import { moment2Date, moment2DateWithoutTime } from "@/utils/moment2Date";
import { deliveryStatusBadge, promotionStatusBadge } from "@/utils/statusMapBadge";
import {
  Avatar,
  Button,
  Card,
  ChoiceList,
  Filters,
  Icon,
  IndexTable,
  Modal,
  Pagination,
  Popover,
  Stack,
  Tabs,
  Text,
  TextContainer,
  useIndexResourceState,
} from "@shopify/polaris";
import { Columns3Minor, DropdownMinor, SortMinor } from "@shopify/polaris-icons";
import {
  useDebounceFn,
  useHover,
  useLocalStorageState,
  useMount,
  useToggle,
  useUpdateEffect,
} from "ahooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  cooperListColumnsOptions,
  cooperListEmptyStateMarkup,
  cooperList_headings,
  cooperSortOptions,
} from "./helper";

import { useChoiceListLogic } from "@/customHooks/useChoiceListLogic";
import { useFiltersPagination } from "@/customHooks/useFiltersPagination";
import { usePopoverActive } from "@/customHooks/usePopoverActive";
import { useSelectLogic } from "@/customHooks/useSelectLogic";
import { useTabsChangeLogic } from "@/customHooks/useTabsChangeLogic";
import { useTopLoading } from "@/customHooks/useTopLoading";
import { loadableAuthAtom } from "@/global/authAtom";
import { CustomToast } from "@/utils/CustomToast";
import { s_to_ms } from "@/utils/accurateTimeTrans";
import { approvalStatusBadge } from "@/utils/approvalStatusBadge";
import { compactParnterName } from "@/utils/compactParnterName";
import { datepickToShopifyStyle } from "@/utils/datepickToShopifyStyle";
import { filterDivStyle } from "@/utils/filterDivStyle";
import { creatorOptions } from "@/utils/frontEndData/common.option";
import { paymentStatusBadge } from "@/utils/paymentStatusBadge";
import { progressStatusBadge } from "@/utils/progressStatusBadge";
import { filterOptions } from "@/utils/updateTextRegFilter";
import { DatePicker as AntDatePicker } from "antd";
import { useAtom } from "jotai";
import _ from "lodash";
import moment from "moment";
import { v4 } from "uuid";
const { RangePicker: AntDateRangePicker } = AntDatePicker;

export default function CampaignCooperations() {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      LOADING: permission.state === "loading",
      ERROR: permission.state === "hasError",
      READ: permission.data?.["pmp/workflows/campaigns/read"],
      WRITE: permission.data?.["pmp/workflows/campaigns/write"],
    }),
    [permission]
  );
  const history = useHistory();
  const { campaignID } = useParams();
  // const [blackBoaodDataList, setBlackBoaodDataList] = useState([]);

  const { TopLoadingJSX, hiddenTopLoading, showTopLoading } = useTopLoading();

  const [indexTableLoading, setIndexTableLoading] = useState(false); // indexTable 加载状态显示
  const [sortState, setSortState] = useState("updated_at"); // 排序类型:最新时间
  const [sortOrder, setSortOrder] = useState("desc"); // 排序正序

  const { popoverActive: customToastActive, togglePopoverActive: toggleCustomToastActive } =
    usePopoverActive();
  const [toastMessage, setToastMessage] = useState("");

  // tabs: 顺序决定位置
  const tabContents = ["All", "Not start", "In progress", "Completed", "Expired"];
  const { tabs, tabSelected, handleTabChange } = useTabsChangeLogic(tabContents);

  // Filters
  const [queryValue, setQueryValue] = useState("");
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  // Start date
  const [startDate, setStartDate] = useState();
  const onStartDateChange = useCallback((dates, dateStrings) => {
    setStartDate(dateStrings);
  }, []);
  const handleStartDateRemove = useCallback(() => setStartDate(null), []);
  // End date
  const [endDate, setEndDate] = useState();
  const handleEndDateChange = useCallback((dates, dateStrings) => {
    setEndDate(dateStrings);
  }, []);
  const handleEndDateRemove = useCallback(() => setEndDate(null), []);
  // Creator
  const {
    selected: creatorValue,
    setSelected: setCreatorValue,
    handleSelectChange: handleCreatorChange,
  } = useSelectLogic(creatorOptions);
  const handleCreatorRemove = useCallback(() => setCreatorValue("0"), [setCreatorValue]);

  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
    handleStartDateRemove();
    handleEndDateRemove();
    handleCreatorRemove();
  }, [handleQueryValueRemove, handleStartDateRemove, handleEndDateRemove, handleCreatorRemove]);

  const filters = [
    {
      key: "Start date",
      label: "Start date",
      filter: (
        <>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <AntDateRangePicker
            size="large"
            style={datepickToShopifyStyle}
            ranges={{
              Today: [moment(), moment()],
              "This Week": [moment().startOf("week"), moment().endOf("week")],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
            }}
            allowClear={false}
            onChange={onStartDateChange}
            value={startDate ? [moment(startDate[0]), moment(startDate[1])] : null}
          />
        </>
      ),
    },
    {
      key: "End date",
      label: "End date",
      filter: (
        <>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <AntDateRangePicker
            size="large"
            style={datepickToShopifyStyle}
            ranges={{
              Today: [moment(), moment()],
              "This Week": [moment().startOf("week"), moment().endOf("week")],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
            }}
            allowClear={false}
            onChange={handleEndDateChange}
            value={endDate ? [moment(endDate[0]), moment(endDate[1])] : null}
          />
        </>
      ),
    },
    {
      key: "Creator",
      label: "Create by",
      filter: (
        <ChoiceList
          title="Create by"
          titleHidden
          choices={creatorOptions}
          selected={creatorValue}
          onChange={handleCreatorChange}
        />
      ),
      // shortcut: true,
    },
  ]; // required
  const appliedFilters = [];
  // null/undefined   ["",""]
  if (!!startDate && !!_.compact(startDate).length) {
    const key = "Start date";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, startDate),
      onRemove: handleStartDateRemove,
    });
  }
  if (!!endDate && !!_.compact(endDate).length) {
    const key = "End date";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, endDate),
      onRemove: handleEndDateRemove,
    });
  }
  if (creatorValue[0] !== "0") {
    const key = "Creator";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, creatorValue),
      onRemove: handleCreatorRemove,
    });
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case "Start date":
        return `Start date: ${value[0]} ~ ${value[1]}`;
      case "End date":
        return `End date: ${value[0]} ~ ${value[1]}`;
      case "Creator": {
        value = checkType(value) === "Array" ? value : [value];
        const arr = filterOptions(value, creatorOptions);
        return "Create by: " + arr.map((val) => `${val.label}`).join(", ");
      }
      default:
        return value;
    }
  }

  // select : 创建者筛选 , 排序方式
  const { selected: sortValue, handleSelectChange: handleSortChange } =
    useChoiceListLogic("updated_at|desc");

  // popoverActive : 排序筛选按钮弹框
  // const { popoverActive: creatorActive, togglePopoverActive: toggleCreatorActive } =
  //   usePopoverActive();
  const { popoverActive: sortActive, togglePopoverActive: toggleSortActive } = usePopoverActive();

  // activator : 排序筛选按钮触发器
  // const creatorActivator = <Button onClick={toggleCreatorActive}>Create by</Button>;
  const sortActivator = (
    <Button icon={SortMinor} onClick={toggleSortActive}>
      Sort
    </Button>
  );

  // 确认删除: 弹出modal
  const { popoverActive: deleteComfirmActive, togglePopoverActive: toggleDeleteComfirmActive } =
    usePopoverActive();

  // 确认删除按钮:确认删除处理函数:
  const handleConfirmDelete = async () => {
    try {
      toggleDeleteComfirmActive();
      // setIndexTableLoading(true);
      await deleteMultiCooperations(selectedResources);
      handleSelectionChange("all", false); // 清除indextable选中状态
      setToastMessage("Deleted successfully!");
      toggleCustomToastActive();
      await reqCurrentTable();
    } catch (error) {
      //
    }
  };
  // indexTable
  //#region
  const [coopList, setCoopList] = useState([]); // 合作渲染列表数据

  const resourceName = {
    singular: "cooperation",
    plural: "cooperations",
  };
  //   index Table 选中处理函数
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(coopList);
  // index Table 按钮
  const promotedBulkActions = [
    {
      content: "Delete cooperation",
      onAction: () => {
        toggleDeleteComfirmActive();
      },
    },
  ];

  //#region Columns
  const [local_campaign_columns, set_local_campaign_columns] = useLocalStorageState(
    "campaign_cooper_columns",
    { defaultValue: [] }
  );
  const [cooperListHeading, setCooperListHeading] = useState(cooperList_headings);
  const {
    selected: columns,
    setSelected: setcolumns,
    handleSelectChange: handleColumnsChange,
  } = useChoiceListLogic([
    "Cooperation partner",
    "Cooperation code",
    "Cooperation status",
    "Start date",
    "End date",
    "Note",
  ]);
  // 挂载时读取记录
  useMount(() => {
    if (local_campaign_columns?.length) {
      setcolumns(local_campaign_columns);
    }
  });
  // 变化时存储记录
  useUpdateEffect(() => {
    set_local_campaign_columns(columns);
  }, [columns]);

  const [columnsActive, { toggle: toggleColumnsActive }] = useToggle();
  const columnsActivator = useMemo(
    () => (
      <Button icon={Columns3Minor} onClick={toggleColumnsActive}>
        Columns
      </Button>
    ),
    [toggleColumnsActive]
  );

  // 动态加载列:
  useEffect(() => {
    const headings = cooperList_headings.filter(({ title }) => columns.includes(title));
    setCooperListHeading(headings);
    window.dispatchEvent(new Event("resize"));
  }, [columns]);
  //#endregion Columns

  const coopRowMarkup = coopList.map(
    (
      {
        id,
        code,
        partner,
        status: campaignStatus,
        start_time: startTime,
        end_time: endTime,
        note,
        cooperationBudgetApplications,
        deliverys,
        promotions,
        payment_applications,
        creator,
        created_at,
        updater,
        updated_at,
      },
      index
    ) => {
      // const { avatar, first_name } = partner;
      // const { name } = creator;
      const codeWrap = (
        <div
          onClick={(e) => {
            e.stopPropagation();
            history.push(`/campaign/Cooperations/cooperationDetail/${id}`);
          }}
        >
          {code}
        </div>
      );
      const titleWrap = (
        <div style={{ margin: "10px 0px 10px -4px", maxWidth: 380 }}>
          <Stack alignment="center" wrap={false}>
            <Avatar size="medium" source={partner?.avatar} />
            <div className="coopListItem" style={{ width: 300, display: "inline-block" }}>
              {compactParnterName(partner?.first_name, "")}
            </div>
          </Stack>
        </div>
      );
      const statusWrap = <div style={{}}>{progressStatusBadge(campaignStatus)}</div>;
      const CreatorWrap = <div>{creator?.name ? "@" + creator.name : ""}</div>;
      const UpdattorWrap = <div>{updater?.name ? "@" + updater.name : ""}</div>;
      // start_time: "2022-05-09 10:05:43"   跟其他地方的时间搓格式不同  需要注意
      const startTimeWrap = (
        <div style={{}}>{startTime ? moment2DateWithoutTime(moment(s_to_ms(startTime))) : ""}</div>
      );
      const endTimeWrap = (
        <div style={{}}>{endTime ? moment2DateWithoutTime(moment(s_to_ms(endTime))) : ""}</div>
      );
      function hanldeNote(note) {
        if (note?.length > 20) {
          return note.slice(0, 20) + "...";
        }
        return note;
      }
      const createTimeWrap = created_at ? moment2Date(s_to_ms(created_at)) : "";
      const updateTimeWrap = updated_at ? moment2Date(s_to_ms(updated_at)) : "";

      const props = [
        titleWrap,
        codeWrap,
        statusWrap,
        startTimeWrap,
        endTimeWrap,
        hanldeNote(note),
        <BudgetPopover key={v4()} Budgets={cooperationBudgetApplications} />,
        <DeliveryPopover key={v4()} delivery={deliverys} />,
        <PromotionPopover key={v4()} promotions={promotions} />,
        <PaymemntsPopover key={v4()} paymemnts={payment_applications} />,
        CreatorWrap,
        createTimeWrap,
        UpdattorWrap,
        updateTimeWrap,
      ];

      let SN = []; // 总的序列对应关系
      cooperListColumnsOptions.forEach(({ value }, index) => {
        SN.push({ column: value, prop: props[index] });
      });
      let showCell = SN.filter(({ column }) => columns.includes(column));

      return (
        <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
          {showCell.map(({ prop }) => (
            <IndexTable.Cell key={v4()}>{prop}</IndexTable.Cell>
          ))}
        </IndexTable.Row>
      );
    }
  );
  //#endregion
  // 首次加载请求的传参:
  const mountParams = useMemo(() => {
    return {
      campaign_id: campaignID,
      partner_name: queryValue,
      status: tabSelected ? tabSelected : "",
      start_date: startDate,
      end_date: endDate,
      created_by_current_user: creatorValue[0] === "0" ? "" : creatorValue[0],
      page: 1,
      per_page: 50,
      sort_by: sortState,
      sort_type: sortOrder,
    };
  }, [queryValue, creatorValue, tabSelected, sortState, sortOrder, campaignID, startDate, endDate]);
  // useEffect(() => {
  //   console.log("mountParams: ", mountParams);
  // }, [mountParams]);
  // 筛选参数
  useEffect(() => {
    const [update, sort] = splitChoiceValue(sortValue);
    setSortState(update);
    setSortOrder(sort);
  }, [sortValue]);

  //  分页器:
  const { currentPage, totalPages, setCurrentPage, setTotalPages } = useFiltersPagination();
  // 节流
  const { run } = useDebounceFn(
    async (params) => {
      setIndexTableLoading(true);
      const { data } = await getCampaignCooperations({ ...mountParams, ...params });
      // setBlackBoaodDataList([
      //   { label: "Cooperations", value: data.total },
      //   { label: "Partners reached out", value: data.sum_data?.partners_reached_out },
      //   { label: "Partners on board", value: data.sum_data?.partners_on_board },
      //   { label: "Response rate", value: data.sum_data?.respone_rate || "-" },
      // ]);
      setIndexTableLoading(false);
      setCoopList(data.list);
      setCurrentPage(data.meta.pagination.current_page);
      setTotalPages(data.meta.pagination.total_pages);
    },
    { wait: 600 }
  );
  const reqCurrentTable = useCallback(run, [mountParams, setCurrentPage, setTotalPages, run]);

  // 监听:
  // tabs 变化
  useUpdateEffect(() => {
    (async () => {
      await reqCurrentTable();
    })();
  }, [tabSelected, queryValue, creatorValue, sortValue, startDate, endDate]);
  // // 搜搜栏

  // 下一页
  const Next = useCallback(async () => {
    setCurrentPage((currentPage) => ++currentPage);
    await reqCurrentTable({ page: currentPage + 1 });
  }, [setCurrentPage, reqCurrentTable, currentPage]);
  // 上一页
  const Previous = useCallback(async () => {
    setCurrentPage((currentPage) => --currentPage);
    await reqCurrentTable({ page: currentPage - 1 });
  }, [setCurrentPage, reqCurrentTable, currentPage]);

  // 挂载完成
  useMount(() => {
    (async () => {
      showTopLoading();
      const { data } = await getCampaignCooperations({ ...mountParams });
      console.log("data list : ", data.list);

      // setBlackBoaodDataList([
      //   { label: "Cooperations", value: data.total },
      //   { label: "Partners reached out", value: data.sum_data?.partners_reached_out },
      //   { label: "Partners on board", value: data.sum_data?.partners_on_board },
      //   { label: "Response rate", value: data.sum_data?.respone_rate || "-" },
      // ]);
      setCoopList(data.list);
      setCurrentPage(data.meta.pagination.current_page);
      setTotalPages(data.meta.pagination.total_pages);

      hiddenTopLoading();
    })();
  });

  return (
    <>
      {TopLoadingJSX}
      <CustomToast
        customToastActive={customToastActive}
        toggleCustomToastActive={toggleCustomToastActive}
        toastMessage={toastMessage}
      />
      {/* {!!blackBoaodDataList.length && <BlackBoard dataList={blackBoaodDataList} />} */}
      <Card>
        <Tabs tabs={tabs} selected={tabSelected} onSelect={handleTabChange}></Tabs>

        <div style={filterDivStyle}>
          <div style={{ flex: 1 }}>
            <Filters
              queryValue={queryValue}
              queryPlaceholder="Search by partner name"
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={setQueryValue}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleClearAll}
            />
          </div>

          <div style={{ paddingLeft: "0.4rem" }}>
            <Popover active={sortActive} activator={sortActivator} onClose={toggleSortActive}>
              <Popover.Section>
                <TextContainer>
                  <Text variant="bodyMd" as="span" color="subdued">
                    Sort by
                  </Text>
                </TextContainer>
                <ChoiceList
                  choices={cooperSortOptions}
                  selected={sortValue}
                  onChange={handleSortChange}
                />
              </Popover.Section>
            </Popover>
          </div>
          <div style={{ paddingLeft: "0.4rem" }}>
            <Popover
              active={columnsActive}
              activator={columnsActivator}
              onClose={toggleColumnsActive}
            >
              <Popover.Section>
                <TextContainer>
                  <Text variant="bodyMd" as="span" color="subdued">
                    Columns
                  </Text>
                </TextContainer>
                <ChoiceList
                  allowMultiple
                  choices={cooperListColumnsOptions}
                  selected={columns}
                  onChange={handleColumnsChange}
                />
              </Popover.Section>
            </Popover>
          </div>
        </div>
        {
          <IndexTable
            loading={indexTableLoading}
            resourceName={resourceName}
            itemCount={coopList.length}
            selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
            onSelectionChange={handleSelectionChange}
            headings={cooperListHeading}
            promotedBulkActions={!access.WRITE ? undefined : promotedBulkActions}
            // bulkActions={bulkActions}
            emptyState={cooperListEmptyStateMarkup}
          >
            {coopRowMarkup}
          </IndexTable>
        }
        {coopList.length ? (
          <Stack distribution="center">
            <div style={{ padding: "2px 0 16px" }}>
              <Pagination
                label={
                  <>
                    {currentPage}/{totalPages}
                  </>
                }
                hasPrevious={currentPage < 2 ? false : true}
                onPrevious={Previous}
                hasNext={currentPage >= totalPages ? false : true}
                onNext={Next}
              />
            </div>
          </Stack>
        ) : null}
      </Card>
      <Modal
        open={deleteComfirmActive}
        onClose={toggleDeleteComfirmActive}
        title="Confirm delete"
        primaryAction={{ destructive: true, content: "Delete", onAction: handleConfirmDelete }}
        secondaryActions={[{ content: "Cancel", onAction: toggleDeleteComfirmActive }]}
      >
        <Modal.Section>Are you sure you want to delete the selected cooperation?</Modal.Section>
      </Modal>
    </>
  );
}
// table里面某些想有弹出框
function BudgetPopover({ Budgets }) {
  const ref = useRef(null);
  const isHovering = useHover(ref);
  const [active, { toggle }] = useToggle();
  const Activator = (
    <div
      ref={ref}
      style={
        isHovering ? { backgroundColor: "rgba(241, 242, 243, 1)", width: 110 } : { width: 110 }
      }
      onClick={(e) => {
        e.stopPropagation();

        toggle();
      }}
    >
      {Budgets.length > 0 ? Budgets.length + " budgets" : (Budgets.length ?? "0") + " budget"}
      <div style={{ display: "inline-block", verticalAlign: "bottom" }}>
        {isHovering ? Budgets.length && <Icon source={DropdownMinor} /> : null}
      </div>
    </div>
  );
  return (
    Budgets?.length && (
      <Popover active={active} activator={Activator} onClose={toggle}>
        {Budgets.map(({ code, approval, prices }) => (
          <Popover.Section key={v4()}>
            <Stack>
              <Stack.Item fill>
                <Text variant="bodyMd" as="span" fontWeight="bold">
                  {code}
                  <Text variant="bodySm" as="p">
                    {prices && Number(prices[0]?.value)}
                    {prices && prices[0]?.currency?.code}
                  </Text>
                </Text>
              </Stack.Item>
              <Stack.Item>{approvalStatusBadge(approval ? approval.status : 1)}</Stack.Item>
            </Stack>
          </Popover.Section>
        ))}
      </Popover>
    )
  );
}

function DeliveryPopover({ delivery }) {
  const ref = useRef(null);
  const isHovering = useHover(ref);
  const [active, { toggle }] = useToggle();
  const Activator = (
    <div
      ref={ref}
      style={
        isHovering ? { backgroundColor: "rgba(241, 242, 243, 1)", width: 110 } : { width: 110 }
      }
      onClick={(e) => {
        e.stopPropagation();
        toggle();
      }}
    >
      {delivery.length > 0
        ? delivery.length + " deliverires"
        : (delivery.length ?? "0") + " delivery"}
      <div style={{ display: "inline-block", verticalAlign: "bottom" }}>
        {isHovering ? delivery.length && <Icon source={DropdownMinor} /> : null}
      </div>
    </div>
  );
  return (
    delivery?.length && (
      <Popover active={active} activator={Activator} onClose={toggle}>
        {delivery.map(({ code, delivery_status, address }) => {
          const { state, street, city, postal_code } = address;
          return (
            <Popover.Section key={v4()}>
              <Stack>
                <Stack.Item fill>
                  <Text variant="bodyMd" as="span" fontWeight="bold">
                    {code}
                    <Text variant="bodySm" as="p">
                      {state ? `${state},` : null}
                      {street ? `${street},` : null}
                      {city ? `${city},` : null}
                      {postal_code ? `${postal_code},` : null}
                    </Text>
                  </Text>
                </Stack.Item>
                <Stack.Item>{deliveryStatusBadge(delivery_status)}</Stack.Item>
              </Stack>
            </Popover.Section>
          );
        })}
      </Popover>
    )
  );
}
function PromotionPopover({ promotions }) {
  const ref = useRef(null);
  const isHovering = useHover(ref);
  const [active, { toggle }] = useToggle();
  const Activator = (
    <div
      ref={ref}
      style={
        isHovering ? { backgroundColor: "rgba(241, 242, 243, 1)", width: 110 } : { width: 110 }
      }
      onClick={(e) => {
        e.stopPropagation();

        toggle();
      }}
    >
      {promotions.length > 0
        ? promotions.length + " promotions"
        : (promotions.length ?? "0") + " promotion"}
      <div style={{ display: "inline-block", verticalAlign: "bottom" }}>
        {isHovering ? promotions.length && <Icon source={DropdownMinor} /> : null}
      </div>
    </div>
  );
  return (
    promotions?.length && (
      <Popover active={active} activator={Activator} onClose={toggle}>
        {promotions.map(({ code, status, link }) => (
          <Popover.Section key={v4()}>
            <Stack>
              <Stack.Item fill>
                <Text variant="bodyMd" as="span" fontWeight="bold">
                  {code}{" "}
                  <Text variant="bodySm" as="p">
                    {link}
                  </Text>
                </Text>
              </Stack.Item>
              <Stack.Item>{promotionStatusBadge(status)}</Stack.Item>
            </Stack>
          </Popover.Section>
        ))}
      </Popover>
    )
  );
}
function PaymemntsPopover({ paymemnts }) {
  const ref = useRef(null);
  const isHovering = useHover(ref);
  const [active, { toggle }] = useToggle();
  const Activator = (
    <div
      ref={ref}
      style={
        isHovering ? { backgroundColor: "rgba(241, 242, 243, 1)", width: 110 } : { width: 110 }
      }
      onClick={(e) => {
        e.stopPropagation();

        toggle();
      }}
    >
      {paymemnts.length > 0
        ? paymemnts.length + " payments"
        : (paymemnts.length ?? "0") + " payment"}
      <div style={{ display: "inline-block", verticalAlign: "bottom" }}>
        {isHovering ? paymemnts.length && <Icon source={DropdownMinor} /> : null}
      </div>
    </div>
  );
  return (
    paymemnts?.length && (
      <Popover active={active} activator={Activator} onClose={toggle}>
        {paymemnts.map(({ code, currency, payment_status, total_amount }) => (
          <Popover.Section key={v4()}>
            <Stack>
              <Stack.Item fill>
                <Text variant="bodyMd" as="span" fontWeight="bold">
                  {code}{" "}
                  <Text variant="bodySm" as="p">
                    {total_amount && Number(total_amount)}
                    {currency && currency?.code}
                  </Text>
                </Text>
              </Stack.Item>
              <Stack.Item>{paymentStatusBadge(payment_status ? payment_status : 1)}</Stack.Item>
            </Stack>
          </Popover.Section>
        ))}
      </Popover>
    )
  );
}
