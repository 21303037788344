import { Avatar, Text } from "@shopify/polaris";

import { AvatarName } from "@/components/EnhanceShopify/AvatarName";
import { RowHeader } from "@/components/EnhanceShopify/RowHeader";
import { useBomLocation } from "@/hooks/useBomLocation";

export const PartnerNav = ({ name, avatar, partnerID, is_blacklisted }) => {
  const { origin } = useBomLocation();

  return (
    <RowHeader
      onClick={(e) => {
        e.stopPropagation();
        // history.push(`/home/${partnerID}/edit/report`);
        window.open(`${origin}/home/${partnerID}/edit/report`);
      }}
    >
      <AvatarName isBlackList={!!is_blacklisted}>
        <div className="avatar">
          <Avatar source={avatar} customer={!!is_blacklisted} />
        </div>
        {!is_blacklisted ? (
          <Text variant="bodyMd" as="span" fontWeight="bold">
            {name}
          </Text>
        ) : (
          <s>{name}</s>
        )}
      </AvatarName>
    </RowHeader>
  );
};
