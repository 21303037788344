import { atom, selector } from "recoil";

import { v4 } from "uuid";

export const contacts_atom = atom({
  key: v4(),
  default: [],
});
export const contactsEditing_atom = selector({
  key: v4(),
  get: ({ get }) => {
    const data = get(contacts_atom);
    return data.some((o) => o.isEdit);
  },
});
