import { atom } from "recoil";
import { v4 } from "uuid";

export const columns_atom = atom({
  key: v4(),
  default: ["Media contact name", "Role", "Email", "Operation"],
});

export const columnsActive_atom = atom({
  key: v4(),
  default: false,
});
