import styled from "styled-components";

// 因为设计给的原图 远比shopify自家的大, 所以进行了自定义的调整

export const CustomEmpty = styled.div`
  .Polaris-EmptyState .Polaris-EmptyState__Section .Polaris-EmptyState__ImageContainer img {
    height: 226px;
    width: 226px;
  }
`;
