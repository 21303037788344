import {
  getAnalyseCampaignMmebers,
  getCampaignMembers,
  postDeleteCampaignMembers,
} from "@/api/request";
import {
  Button,
  Card,
  ChoiceList,
  Filters,
  IndexTable,
  Pagination,
  Popover,
  Stack,
  Text,
  TextContainer,
  useIndexResourceState,
} from "@shopify/polaris";
import { Columns3Minor, SortMinor } from "@shopify/polaris-icons";
import { useDebounceFn, useLocalStorageState, useMount, useToggle, useUpdateEffect } from "ahooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  memberColumnsOptions,
  memberEmptyStateMarkup,
  memberIndexTableName,
  memberSortOptions,
  member_headings,
} from "./helper";

import { BlackBoard } from "@/components/BlackBoard";
import { useChoiceListLogic } from "@/customHooks/useChoiceListLogic";
import { useFiltersPagination } from "@/customHooks/useFiltersPagination";
import { useTabs } from "@/customHooks/useTabs";
import { useTopLoading } from "@/customHooks/useTopLoading";
import { loadableAuthAtom } from "@/global/authAtom";
import { ConfirmDeleteModal } from "@/utils/ConfirmDeleteModal";
import { CustomToast } from "@/utils/CustomToast";
import { splitChoiceValue } from "@/utils/checkType";
import { datepickToShopifyStyle } from "@/utils/datepickToShopifyStyle";
import { filterDivStyle } from "@/utils/filterDivStyle";
import { DatePicker as AntDatePicker } from "antd";
import { useAtom } from "jotai";
import _ from "lodash";
import moment from "moment";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";

const { RangePicker: AntDateRangePicker } = AntDatePicker;

export const CampaignMembers = ({ refreshMemberListFlag }) => {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      LOADING: permission.state === "loading",
      ERROR: permission.state === "hasError",
      READ: permission.data?.["pmp/workflows/campaigns/read"],
      WRITE: permission.data?.["pmp/workflows/campaigns/write"],
    }),
    [permission]
  );
  const { campaignID } = useParams();
  const { TopLoadingJSX, hiddenTopLoading, showTopLoading } = useTopLoading();
  const [indexTableLoading, setIndexTableLoading] = useState(false); // indexTable 加载状态显示
  // const { popoverActive: customToastActive, togglePopoverActive: toggleCustomToastActive } =
  //   usePopoverActive();
  const [customToastActive, { toggle: toggleCustomToastActive }] = useToggle();
  const [toastMessage, setToastMessage] = useState("All");

  const [blackBoaodDataList, setBlackBoaodDataList] = useState([]);
  const { tabJSX } = useTabs({ titles: ["All"] });

  // Filters
  const [queryValue, setQueryValue] = useState("");
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  // Start date
  const [dateRange, setDateRange] = useState();
  const onStartDateChange = useCallback((dates, dateStrings) => {
    setDateRange(dateStrings);
  }, []);
  const handleStartDateRemove = useCallback(() => setDateRange(null), []);

  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
    handleStartDateRemove();
  }, [handleQueryValueRemove, handleStartDateRemove]);

  // Sort
  const { selected: sortValue, handleSelectChange: handleSortChange } = useChoiceListLogic(
    "partners_reached_out|desc"
  );
  const [sortState, setSortState] = useState("partners_reached_out"); // 排序类型:最新时间
  const [sortOrder, setSortOrder] = useState("desc"); // 排序正序
  // 筛选参数
  useEffect(() => {
    const [update, sort] = splitChoiceValue(sortValue);
    setSortState(update);
    setSortOrder(sort);
  }, [sortValue]);
  const [sortActive, { toggle: toggleSortActive }] = useToggle();
  const sortActivator = (
    <Button icon={SortMinor} onClick={toggleSortActive}>
      Sort
    </Button>
  );

  const filters = [
    {
      key: "Start date",
      label: "Date",
      filter: (
        <>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <div onClick={(e) => e.stopPropagation()}>
            <AntDateRangePicker
              size="large"
              style={datepickToShopifyStyle}
              ranges={{
                Today: [moment(), moment()],
                "This Week": [moment().startOf("week"), moment().endOf("week")],
                "This Month": [moment().startOf("month"), moment().endOf("month")],
              }}
              allowClear={false}
              onChange={onStartDateChange}
              value={dateRange ? [moment(dateRange[0]), moment(dateRange[1])] : null}
            />
          </div>
        </>
      ),
      shortcut: true,
    },
  ];
  const appliedFilters = [];
  // null/undefined   ["",""]
  if (!!dateRange && !!_.compact(dateRange).length) {
    const key = "Start date";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, dateRange),
      onRemove: handleStartDateRemove,
    });
  }
  function disambiguateLabel(key, value) {
    switch (key) {
      case "Start date":
        return `Start date: ${value[0]} ~ ${value[1]}`;

      default:
        return value;
    }
  }

  //#region Columns
  const [local_campaign_columns, set_local_campaign_columns] = useLocalStorageState(
    "campaign_member_columns",
    { defaultValue: [] }
  );
  const [memberListHeadings, setMemberListHeadings] = useState(member_headings);
  const {
    selected: columns,
    setSelected: setcolumns,
    handleSelectChange: handleColumnsChange,
  } = useChoiceListLogic([
    "User name",
    "User code",
    "New partners",
    "Partners reached out",
    "Partners on board",
    "Response rate",
  ]);
  // 挂载时读取记录
  useMount(() => {
    if (local_campaign_columns?.length) {
      setcolumns(local_campaign_columns);
    }
  });
  // 变化时存储记录
  useUpdateEffect(() => {
    set_local_campaign_columns(columns);
  }, [columns]);

  const [columnsActive, { toggle: toggleColumnsActive }] = useToggle();
  const columnsActivator = useMemo(
    () => (
      <Button icon={Columns3Minor} onClick={toggleColumnsActive}>
        Columns
      </Button>
    ),
    [toggleColumnsActive]
  );

  // 动态加载列:
  useEffect(() => {
    const headings = member_headings.filter(({ title }) => columns.includes(title));
    setMemberListHeadings(headings);
    window.dispatchEvent(new Event("resize"));
  }, [columns]);
  //#endregion Columns
  const [memberList, setMemberList] = useState([{ id: "1" }]); // 合作渲染列表数据
  //   index Table 选中处理函数
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(memberList);
  // index Table 按钮
  const promotedBulkActions = [
    {
      content: "Remove member",
      onAction: () => {
        toggleDeleteComfirmActive();
      },
    },
  ];
  const membersRowMarkup = memberList.map(
    ({ id, code, name, partners_on_board, partners_reached_out, respone_rate }, index) => {
      // const { name } = creator;

      const props = [name, code, partners_reached_out, partners_on_board, respone_rate + "%"];

      let SN = []; // 总的序列对应关系

      memberColumnsOptions.forEach(({ value }, index) => {
        SN.push({ column: value, prop: props[index] });
      });
      let showCell = SN.filter(({ column }) => columns.includes(column));

      return (
        <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
          {showCell.map(({ prop }) => (
            <IndexTable.Cell key={v4()}>{prop}</IndexTable.Cell>
          ))}
        </IndexTable.Row>
      );
    }
  );

  // 首次加载请求的传参:
  const mountParams = useMemo(() => {
    return {
      page: 1,
      per_page: 50,
      sort_by: sortState,
      sort_type: sortOrder,
      name: queryValue,
      created_ats: dateRange,
      campaign_id: campaignID,
    };
  }, [queryValue, campaignID, sortState, sortOrder, dateRange]);

  // 筛选参数
  useEffect(() => {
    const [update, sort] = splitChoiceValue(sortValue);
    setSortState(update);
    setSortOrder(sort);
  }, [sortValue]);

  //  分页器:
  const { currentPage, totalPages, setCurrentPage, setTotalPages } = useFiltersPagination();
  // 节流
  const { run } = useDebounceFn(
    async (params) => {
      setIndexTableLoading(true);
      const { data } = await getCampaignMembers({ ...mountParams, ...params });
      setIndexTableLoading(false);
      setMemberList(data.list);
      setTotalPages(data.meta.pagination.total_pages);
      setCurrentPage(data.meta.pagination.current_page);
      const { campaign_id, created_ats } = mountParams;
      const { data: analyse } = await getAnalyseCampaignMmebers({ campaign_id, created_ats });
      setBlackBoaodDataList([
        { label: "Partners reached out", value: analyse.partners_reached_out_total },
        { label: "Partners on board", value: analyse.partners_on_board_total },
        {
          label: "Response rate",
          value: analyse.respone_rate_total !== "-" ? analyse.respone_rate_total + "%" : "-",
        },
      ]);
      window.dispatchEvent(new Event("resize")); // 解决加载动态列初始换header和列错位的问题
    },
    { wait: 600 }
  );
  const reqCurrentTable = useCallback(run, [mountParams, setCurrentPage, setTotalPages, run]);

  // 监听:
  // tabs 变化
  useUpdateEffect(() => {
    (async () => {
      await reqCurrentTable();
    })();
  }, [queryValue, sortValue, campaignID, dateRange, refreshMemberListFlag]);
  // // 搜搜栏

  // 下一页
  const Next = useCallback(async () => {
    setCurrentPage((currentPage) => ++currentPage);
    await reqCurrentTable({ page: currentPage + 1 });
  }, [setCurrentPage, reqCurrentTable, currentPage]);
  // 上一页
  const Previous = useCallback(async () => {
    setCurrentPage((currentPage) => --currentPage);
    await reqCurrentTable({ page: currentPage - 1 });
  }, [setCurrentPage, reqCurrentTable, currentPage]);

  // 确认删除: 弹出modal
  const [deleteComfirmActive, { toggle: toggleDeleteComfirmActive }] = useToggle();
  // 确认删除按钮:确认删除处理函数:
  const handleConfirmDelete = async () => {
    try {
      toggleDeleteComfirmActive();
      setIndexTableLoading(true);
      await postDeleteCampaignMembers({ campaign_id: campaignID, ids: selectedResources });
      handleSelectionChange("all", false); // 清除indextable选中状态
      setToastMessage("Removed successfully!");
      toggleCustomToastActive();
      await reqCurrentTable();
      setIndexTableLoading(false);
    } catch (error) {
      setIndexTableLoading(false);
    }
  };
  // 挂载完成
  useMount(() => {
    (async () => {
      showTopLoading();
      await reqCurrentTable();
      hiddenTopLoading();
    })();
  });

  return (
    <>
      <CustomToast
        customToastActive={customToastActive}
        toggleCustomToastActive={toggleCustomToastActive}
        toastMessage={toastMessage}
      />
      <ConfirmDeleteModal
        title="Confirm remove"
        deleteComfirmActive={deleteComfirmActive}
        toggleDeleteComfirmActive={toggleDeleteComfirmActive}
        content="Are you sure you want to remove the selected member?"
        buttonText="Remove"
        handleConfirmDelete={handleConfirmDelete}
      />
      {!!blackBoaodDataList.length && <BlackBoard dataList={blackBoaodDataList} />}
      <Card>
        {tabJSX}
        {TopLoadingJSX}

        <div style={filterDivStyle}>
          <div style={{ flex: 1 }}>
            <Filters
              queryValue={queryValue}
              queryPlaceholder="Search by user name"
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={setQueryValue}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleClearAll}
            />
          </div>

          <div style={{ paddingLeft: "0.4rem" }}>
            <Popover active={sortActive} activator={sortActivator} onClose={toggleSortActive}>
              <Popover.Section>
                <TextContainer>
                  <Text variant="bodyMd" as="span" color="subdued">
                    Sort by
                  </Text>
                </TextContainer>
                <ChoiceList
                  choices={memberSortOptions}
                  selected={sortValue}
                  onChange={handleSortChange}
                />
              </Popover.Section>
            </Popover>
          </div>
          <div style={{ paddingLeft: "0.4rem" }}>
            <Popover
              active={columnsActive}
              activator={columnsActivator}
              onClose={toggleColumnsActive}
            >
              <Popover.Section>
                <TextContainer>
                  <Text variant="bodyMd" as="span" color="subdued">
                    Columns
                  </Text>
                </TextContainer>
                <ChoiceList
                  allowMultiple
                  choices={memberColumnsOptions}
                  selected={columns}
                  onChange={handleColumnsChange}
                />
              </Popover.Section>
            </Popover>
          </div>
        </div>
        {
          <IndexTable
            loading={indexTableLoading}
            resourceName={memberIndexTableName}
            itemCount={memberList.length}
            selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
            onSelectionChange={handleSelectionChange}
            headings={memberListHeadings}
            promotedBulkActions={!access.WRITE ? undefined : promotedBulkActions}
            // bulkActions={bulkActions}
            emptyState={memberEmptyStateMarkup}
          >
            {membersRowMarkup}
          </IndexTable>
        }
        {memberList.length ? (
          <Stack distribution="center">
            <div style={{ padding: "2px 0 16px" }}>
              <Pagination
                label={
                  <>
                    {currentPage}/{totalPages}
                  </>
                }
                hasPrevious={currentPage < 2 ? false : true}
                onPrevious={Previous}
                hasNext={currentPage >= totalPages ? false : true}
                onNext={Next}
              />
            </div>
          </Stack>
        ) : null}
      </Card>
    </>
  );
};
