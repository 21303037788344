import { Card, EmptyState } from "@shopify/polaris";

import EmptyStatePng from "@/assets/svg/Group 182@2x.png";
import { CustomEmpty } from "@/components/EnhanceShopify/CustomEmpty";
import { TextClamp } from "@/components/EnhanceShopify/TextClamp";

export const NoReport = () => {
  return (
    <Card sectioned>
      <CustomEmpty>
        <EmptyState
          heading="This influencer's social link has not extracted data yet."
          image={EmptyStatePng}
          fullWidth
        >
          <p>
            You can check if the social link is entered in the correct format and wait for the
            system to extract the data.
          </p>
        </EmptyState>
      </CustomEmpty>
    </Card>
  );
};

export const NoReportUnderLink = ({ link }) => {
  return (
    <Card sectioned>
      <CustomEmpty>
        <EmptyState
          heading={
            <TextClamp
              clamp={10}
            >{`There are no reports for this link:  ${window.decodeURIComponent(
              link
            )}.`}</TextClamp>
          }
          image={EmptyStatePng}
          fullWidth
        >
          <p>
            You can check whether the platform link of this partner is added and wait for the system
            to automatically generate the relevant report.
          </p>
        </EmptyState>
      </CustomEmpty>
    </Card>
  );
};
