import "gantt-task-react/dist/index.css";

import { Checkbox, ChoiceList, EmptySearchResult, Filters } from "@shopify/polaris";
import { useDebounceFn, useMount, useUpdateEffect } from "ahooks";
import { Gantt, ViewMode } from "gantt-task-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { clacStatusColor } from "../helper";

import { campaignsSchedules } from "@/api/request";
import { useTopLoading } from "@/customHooks/useTopLoading";
import { creatorOptions } from "@/utils/frontEndData/common.option";
import { isEmpty } from "@/utils/isEmpty";
import { filterOptions } from "@/utils/updateTextRegFilter";
import { useHistory } from "react-router-dom";
import styles from "../Schedules.module.scss";

export const CampaignSchedule = () => {
  const history = useHistory();
  const { TopLoadingJSX, hiddenTopLoading, showTopLoading } = useTopLoading();
  // calender

  //#region gantt
  // view mode
  const [selectViewMode, setSelectViewMode] = useState(["Month"]);
  const handleViewModeOnSelect = useCallback((value) => setSelectViewMode(value), []);
  // const handleViewModeRemove = useCallback(() => setSelectViewMode(["Month"]), []);
  // show task list
  const [showTaskList, setShowTaskList] = useState(true);
  const handleChange = useCallback((newChecked) => setShowTaskList(newChecked), []);
  const [view, setView] = useState(ViewMode.Month);
  const [tasks, setTasks] = useState([]);

  let columnWidth = 65;
  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  const handleDblClick = () => {
    // alert("On Double Click event Id:" + task.id);
    // history.push(`/campaign/CampaignDetail/${task.id}`);
  };

  const handleSelect = (task) => {
    history.push(`/campaign/CampaignDetail/${task.id}`);

    // console.log("task id: ", task.id);
    // console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    // console.log("On expander click Id:" + task.id);
  };

  // 甘特图查看视图的模式
  useEffect(() => {
    if (!Array.isArray(selectViewMode)) return;

    if (selectViewMode[0] === "Month") {
      setView(ViewMode.Month);
    }
    if (selectViewMode[0] === "Week") {
      setView(ViewMode.Week);
    }
    if (selectViewMode[0] === "Day") {
      setView(ViewMode.Day);
    }
  }, [selectViewMode]);
  //#endregion

  // staus state
  const [Status, setStatus] = useState("");
  const handleStatusOnSelect = useCallback((value) => setStatus(value), []);
  const handleStatusRemove = useCallback(() => setStatus([]), []);

  // creator filter
  const [selectedCreatorOptions, setSelectedCreatorOptions] = useState(["0"]);
  const handleCreatorOnSelect = useCallback((value) => setSelectedCreatorOptions(value), []);
  const handleCreatorRemove = useCallback(() => setSelectedCreatorOptions(["0"]), []);

  //#region  request
  const initParams = useMemo(
    () => ({
      status: Status,
      created_by_current_user: selectedCreatorOptions[0],
    }),
    [Status, selectedCreatorOptions]
  );
  // useEffect(() => {
  //   console.log("initParams: ", initParams);
  // }, [initParams]);

  const { run } = useDebounceFn(
    async (params) => {
      showTopLoading();
      try {
        const {
          data: { list },
        } = await campaignsSchedules({ ...initParams, ...params });
        const campaignTasks = list.map(({ id, status, title, start_time, end_time }) => {
          return {
            id: String(id),
            name: title,
            start: new Date(start_time * 1000),
            end: new Date(end_time * 1000),
            progress: 0,
            type: "task",
            styles: clacStatusColor("Campaign/Cooperation")(status),
          };
        });
        setTasks(campaignTasks);
        hiddenTopLoading();
      } catch (error) {
        hiddenTopLoading();
      }
    },
    { wait: 600 }
  );
  // 渲染当前列表 - useMemo 中已经依赖项 - initParams
  const renderlist = useCallback(run, [run, initParams]);

  useUpdateEffect(() => {
    renderlist();
  }, [Status, selectedCreatorOptions]);
  //#endregion request

  // clear all filters
  const handleClearAll = useCallback(() => {
    handleStatusRemove();
    handleCreatorRemove();
  }, [handleStatusRemove, handleCreatorRemove]);

  const deselectedStatusOptions = [
    { value: "1", label: "Not start" },
    { value: "2", label: "In progress" },
    { value: "3", label: "Completed" },
    { value: "4", label: "Expired" },
  ];

  const filters = [
    {
      key: "Status",
      label: "Status",
      filter: (
        <ChoiceList
          title="Account status"
          titleHidden
          choices={deselectedStatusOptions}
          selected={Status || []}
          onChange={handleStatusOnSelect}
          allowMultiple
        />
      ),
      shortcut: true,
    },

    {
      key: "Creator",
      label: "Create by",
      filter: (
        <ChoiceList
          titleHidden
          choices={creatorOptions}
          selected={selectedCreatorOptions || []}
          onChange={handleCreatorOnSelect}
        />
      ),
      shortcut: true,
    },
    {
      key: "viewMode",
      label: "View mode",
      filter: (
        <>
          <Checkbox label="Show header" checked={showTaskList} onChange={handleChange} />
          <ChoiceList
            titleHidden
            choices={[
              { label: "Day", value: "Day" },
              { label: "Week", value: "Week" },
              { label: "Month", value: "Month" },
            ]}
            selected={selectViewMode || []}
            onChange={handleViewModeOnSelect}
          />
        </>
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];

  if (!isEmpty(Status)) {
    const key = "Status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, Status),
      onRemove: handleStatusRemove,
    });
  }

  if (selectedCreatorOptions["0"] !== "0") {
    const key = "Creator";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedCreatorOptions),
      onRemove: handleCreatorRemove,
    });
  }

  //  show the filter tags under the Search
  function disambiguateLabel(key, value) {
    switch (key) {
      case "Status": {
        const statusArr = filterOptions(value, deselectedStatusOptions);
        return "Status: " + statusArr.map((val) => `${val.label}`).join(", ");
      }

      case "Creator": {
        const checkedCreator = filterOptions(value, creatorOptions);
        return "Create by: " + checkedCreator.map((o) => `${o.label}`).join(", ");
      }

      default:
        return value;
    }
  }

  useMount(() => {
    renderlist();
  });

  return (
    <>
      {TopLoadingJSX}
      <div style={{ flex: 1 }}>
        <Filters
          filters={filters}
          appliedFilters={appliedFilters}
          onClearAll={handleClearAll}
          hideQueryField
        />
        {tasks.length ? (
          <div className={styles.ganttWraper}>
            <Gantt
              tasks={tasks}
              viewMode={view}
              // onDateChange={handleTaskChange}
              // onDelete={handleTaskDelete}
              // onProgressChange={handleProgressChange}
              onDoubleClick={handleDblClick}
              onSelect={handleSelect}
              onExpanderClick={handleExpanderClick}
              listCellWidth={showTaskList ? "160px" : ""}
              ganttHeight={600}
              columnWidth={columnWidth}
              todayColor={"rgba(228, 247, 250, 0.6)"}
            />
          </div>
        ) : (
          <EmptySearchResult
            title={"No campaigns found"}
            description={"Try changing the filters or search term"}
            withIllustration
          />
        )}
      </div>
    </>
  );
};
