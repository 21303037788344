import { Avatar, Card, Stack, Text } from "@shopify/polaris";

import { CollectCard } from "./CollectCard";
import { SiderInfoRow } from "./SiderInfoRow";

export const OtherInfo = ({
  title,
  creatorAvatar,
  creator,
  createTime,
  updaterAvatar,
  updater,
  updateTime,
}) => {
  return (
    <CollectCard title={title}>
      <Card.Section>
        {
          <>
            <SiderInfoRow label="Create by " value={AvatarNameBox(creatorAvatar, creator)} />
            <div style={{ height: 8 }} />
          </>
        }
        {
          <>
            <SiderInfoRow label="Create time " value={createTime} />
            <div style={{ height: 8 }} />
          </>
        }

        {
          <>
            <SiderInfoRow label="Update by " value={AvatarNameBox(updaterAvatar, updater)} />
            <div style={{ height: 8 }} />
          </>
        }
        {<SiderInfoRow label="Update time " value={updateTime} />}
      </Card.Section>
    </CollectCard>
  );
};

function AvatarNameBox(url, name) {
  return (
    <Stack spacing="tight">
      {url && <Avatar size="extraSmall" source={url} />}
      <Text>{name}</Text>
    </Stack>
  );
}
