import { atom } from "recoil";
import { v4 } from "uuid";

export const columns_atom = atom({
  key: v4(),
  default: ["Media outlet name", "Media website", "Monthly visits", "Operation"],
});

export const columnsActive_atom = atom({
  key: v4(),
  default: false,
});
