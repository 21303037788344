import {
  Avatar,
  Button,
  Card,
  Layout,
  Link,
  Stack,
  Text,
  TextContainer,
  Tooltip
} from "@shopify/polaris";
import { useEffect, useState } from "react";

import { AnchorMark } from "@/components/EnhanceShopify/AnchorMark";
import { InfoRow } from "@/components/EnhanceShopify/InfoRow";
import { LayoutStick } from "@/components/EnhanceShopify/LayoutStick";
import { RowStack } from "@/components/EnhanceShopify/RowStack";
import { TextClamp } from "@/components/EnhanceShopify/TextClamp";
import { IconFont } from "@/components/Icon/PMPicon";
import { useCopy } from "@/hooks/useCopy";
import { formatNumber } from "@/utils/formatNumber";
import { SafetyCertificateTwoTone } from "@ant-design/icons";
import { DuplicateMinor } from "@shopify/polaris-icons";
import { Anchor, Divider } from "antd";
import moment from "moment";
import { v4 } from "uuid";
import { Region } from "../../Platforms/Common/Region";
import { CustomerAvatar } from "../Common/CustomerAvatar";
import { EmailClamp } from "../Common/EmailClamp";
import { LongTextCollect } from "../Common/LongTextCollect";
import { NoReportUnderLink } from "../Common/NoReport";
import { PlatagList } from "../Common/Platags/PlatagList";

export const Twitter = ({ selectPlatform: platfrom, setUpdateTime, setUpdateBy }) => {
  const { copiedMark, handleCopy } = useCopy();

  const [region, setRegion] = useState({});

  const [cardInfo, setCardInfo] = useState({
    Join_date: 0,
    Posts: 0,
    Followers: 0,
    Following: 0,
    Likes: 0,
    Lists: 0,
  });
  const [email, setEmail] = useState([]);
  const [websiteUrl, setWebsiteUrl] = useState([]);

  const [ID, setID] = useState(null);
  const [platformLink, setPlatformLink] = useState("");

  useEffect(() => {
    setRegion(platfrom.region);

    setID(platfrom.id);
    setPlatformLink(platfrom.platform_url);
    setCardInfo({
      Join_date: platfrom?.options?.joinDate ?? 0,
      Posts: platfrom?.options?.totalPostCount ?? 0,
      Followers: platfrom?.options?.totalFollowerCount ?? 0,
      Following: platfrom?.options?.totalFollowingCount ?? 0,
      Likes: platfrom?.options?.totalLikeCount ?? 0,
      Lists: platfrom?.options?.totalListCount ?? 0,
    });
    setEmail(platfrom?.options?.email ?? []);
    setWebsiteUrl(platfrom?.options?.websiteUrl ?? []);
    setUpdateTime(platfrom?.updated_at);
    setUpdateBy(platfrom?.updater?.name);
  }, [platfrom, setUpdateTime, setUpdateBy]);

  return ID ? (
    <Layout>
      {copiedMark}
      <LayoutStick width={300}>
        <Card sectioned>
          <RowStack>
            <Region name={region?.name} image={region?.image} />
          </RowStack>
          <br />
          <CustomerAvatar
            avatar={<Avatar size="large" source={platfrom?.options?.profilePictureUrl} />}
            name={platfrom?.options?.username}
            subName={`${window.decodeURIComponent(platfrom?.options?.userId)}`}
            exta={
              <>
                {platfrom?.options?.isProfileVerified && (
                  <Tooltip content="is profile verified" preferredPosition="above">
                    <SafetyCertificateTwoTone twoToneColor="#2E72D2" />
                  </Tooltip>
                )}
              </>
            }
          />
          <br />
          <Stack spacing="tight">
            {PlatagList([
              // 为了统一成general组件中的平台列表tag为以后扩展准备
              {
                subscribers: platfrom?.options?.totalFollowerCount,
                link: platfrom?.options?.profileUrl,
                type: {
                  title: platfrom?.type,
                },
              },
            ])}
          </Stack>
          <br />
          <TextContainer>
            <Text variant="bodyMd" as="span" color="subdued">
              <LongTextCollect longText={platfrom?.options?.profileBioRaw} />
            </Text>
          </TextContainer>
          <br />
          <Stack wrap={false}>
            <div style={{ width: 50, marginTop: -4 }}>
              <IconFont code="&#xe75a;" title="Email" color="#008685" />
            </div>
            <Stack.Item fill>
              <EmailClamp>{email?.join("\n")}</EmailClamp>
            </Stack.Item>
            {!!email?.join("\n") && (
              <Button
                icon={DuplicateMinor}
                plain
                onClick={() => handleCopy({ copyText: email?.join("\n") })}
              />
            )}
          </Stack>
          <Divider />
          <Anchor offsetTop={60}>
            <Anchor.Link href="#Overview" title="Overview" />
            <Anchor.Link href="#Other_websites" title="Other websites" />
          </Anchor>
        </Card>
      </LayoutStick>
      <Layout.Section>
        <Card title="Overview" sectioned>
          <AnchorMark id="Overview" />
          <InfoRow label="Followers">
            {formatNumber("unit", platfrom?.options?.totalFollowerCount)}
          </InfoRow>
          <InfoRow label="Following">
            {formatNumber("unit", platfrom?.options?.totalFollowingCount)}
          </InfoRow>
          <InfoRow label="Likes">{formatNumber("unit", platfrom?.options?.totalLikeCount)}</InfoRow>
          <InfoRow label="Lists">{formatNumber("unit", platfrom?.options?.totalListCount)}</InfoRow>
          <InfoRow label="Posts">{formatNumber("unit", platfrom?.options?.totalPostCount)}</InfoRow>
          <InfoRow label="Join date" end>
            {moment(cardInfo.Join_date).format("YYYY-MM-DD")}
          </InfoRow>
        </Card>
        <Card title="Other websites" sectioned>
          <AnchorMark id="Other_websites" />
          {Array.isArray(websiteUrl) ? (
            websiteUrl?.map(({ websiteName, websiteUrl }, i) => (
              <div key={v4()}>
                <Link url={websiteUrl} removeUnderline>
                  <TextClamp>{websiteUrl}</TextClamp>
                </Link>
                <br />
                <Text variant="bodyMd" as="span" color="subdued">
                  {websiteName}
                </Text>
                <div style={websiteUrl.length - 1 !== i ? { height: 16 } : null} />
              </div>
            ))
          ) : (
            <Card.Section title="">
              <Link url={websiteUrl} removeUnderline>
                {websiteUrl}
              </Link>
            </Card.Section>
          )}
        </Card>
      </Layout.Section>
    </Layout>
  ) : (
    <NoReportUnderLink link={platformLink} />
  );
};
