const { atom } = require("recoil");

// 在尽量不改动原有组件的情况下 触发person里面的 tableToExcel 函数
export const pubExportActionAtom = atom({
  key: "pubExportActionAtom",
  default: true,
});

// 导入平台lead的行为
export const Craete_Lead_Atom = atom({
  key: "Craete_Lead_Atom",
  default: false,
});
