import {
  getFiltersCountrys,
  getMediaContact,
  getMediaOutletList,
  getPartnerPlatform,
  postAddMediaContact,
  putUpdateMediaContact
} from "@/api/request";
import { Button, ButtonGroup, Stack, TextField } from "@shopify/polaris";
import { useMount, useToggle, useUnmount } from "ahooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { contactID_atom, SociallinkTypes_atom } from "./atom";

import { AntDrawer } from "@/components/EnhanceShopify/AntDrawer";
import { DrawerSection } from "@/components/EnhanceShopify/DrawerSection";
import { DrawerSkeleton } from "@/components/EnhanceShopify/DrawerSkeleton";
import { SelectPlus } from "@/components/EnhanceShopify/SelectPlus";
import { useRefrehTable } from "@/customHooks/useRefrehTable";
import { wait } from "@/utils/wait";
import { useHistory } from "react-router-dom";
import { selectTagState } from "../../Common/InputTags/atom";
import { InputTags } from "../../Common/InputTags/InputTags";
import { contactsRefresh_atom } from "../atom";
import { AddOutlet } from "./AddOutlet/AddOutlet";
import { Columns } from "./AddOutlet/Column";
import { SelectOutlets } from "./AddOutlet/SelectOutlets";
import { selectedOutlets_atom } from "./AddOutlet/SelectOutlets/atom";
import { link_atom } from "./SocialLink/atom";
import { SocialLink } from "./SocialLink/SocialLink";

export const MediaContactEditor = ({ title, visible, setActive, toggle }) => {
  const history = useHistory();
  const [contactID, setContactID] = useRecoilState(contactID_atom);
  const [loading, setLoading] = useState(true); // 数据加载时
  const [saveLoading, setSaveLoading] = useState(false); // 保存请求时
  const [regionOps, setRegionOps] = useState([]);

  const { handleRefresh } = useRefrehTable(contactsRefresh_atom);

  const [contactNameInput, setContactNameInput] = useState("");
  const [roleInput, setRoleInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [NoteInput, setNoteInput] = useState("");

  const [selectRegion, setSelectRegion] = useState([]);
  const [regionStateInput, setRegionStateInput] = useState("");
  const [cityInput, setCityInput] = useState("");
  const [addressInput, setAddressInput] = useState("");
  const [, setsocailLinkTypes] = useRecoilState(SociallinkTypes_atom);

  const [selectedTags, setSelectedTags] = useRecoilState(selectTagState);
  const resetSelectedTags = useResetRecoilState(selectTagState);

  const [selectOutletsActive, { toggle: toggleSelectOutlets }] = useToggle();

  const [outlets, setOutlets] = useRecoilState(selectedOutlets_atom);
  const resetOutlets = useResetRecoilState(selectedOutlets_atom);

  const [socialLinks, setSocialLinks] = useRecoilState(link_atom);
  const resetSocialLinks = useResetRecoilState(link_atom);

  // 编辑侧边窗口未出现的属性值, 需要带上一起
  const [selectContactStatus, setSelectContactStatus] = useState("");
  const [selectBlackList, setSelectBlackList] = useState("");
  const [postalCodeInput, setPostalCodeInput] = useState("");
  const [selectedCollections, setSelectedCollections] = useState([]);

  // 更新数据收集
  // 新增 / 编辑
  const initParams = useMemo(() => {
    const platformsData = socialLinks
      .filter((o) => !o.isEdit)
      .map(({ platform, link, fans }) => ({
        type_id: platform,
        link,
        subscribers: fans,
      }));
    const outletIDs = outlets?.map(({ id }) => String(id));
    return {
      name: contactNameInput,
      role: roleInput,
      brief_description: NoteInput,
      contact_status: selectContactStatus,
      is_blacklisted: selectBlackList,
      mobile_phone: phoneInput,
      email: emailInput,
      address: {
        country_id: selectRegion.toString(),
        state: regionStateInput,
        city: cityInput,
        postal_code: postalCodeInput,
        street: addressInput,
      },
      platforms: platformsData,
      media_outlet_ids: outletIDs,
      groups_ids: selectedCollections,
      tags: selectedTags,
    };
  }, [
    contactNameInput,
    roleInput,
    NoteInput,
    selectContactStatus,
    selectBlackList,
    phoneInput,
    emailInput,
    selectRegion,
    regionStateInput,
    cityInput,
    postalCodeInput,
    addressInput,
    socialLinks,
    outlets,
    selectedCollections,
    selectedTags,
  ]);

  // 保存成功 / 关闭 Drawer 时候, 重置数据栏
  const handleClearAll = useCallback(() => {
    setContactID("");
    setContactNameInput("");
    setRoleInput("");
    setEmailInput("");
    setPhoneInput("");
    setSelectRegion([]);
    setRegionStateInput("");
    setCityInput("");
    setAddressInput("");
    resetSelectedTags();
    setNoteInput("");
    resetOutlets();
    resetSocialLinks();
  }, [setContactID, resetSelectedTags, resetOutlets, resetSocialLinks]);

  // Save 按钮
  const editorSave = useCallback(async () => {
    try {
      setSaveLoading(true);
      if (!contactID) {
        // 新增
        await postAddMediaContact(initParams);
        await wait(1000);
      } else {
        // 编辑
        await putUpdateMediaContact(contactID, initParams);
        await wait(1000);
      }
      handleClearAll();
      toggle();
      handleRefresh();
    } catch (error) {
      //
    } finally {
      setSaveLoading(false);
    }
  }, [contactID, initParams, toggle, handleClearAll, handleRefresh]);

  // Cancel 按钮
  const editorCancel = () => {
    toggle();
    handleClearAll();
  };

  const editorAdvaceSetting = () => {
    handleClearAll();
    toggle();
    history.push(`/contacts/detail/${contactID ? contactID : ""}`);
  };

  // 根据 id 请求快捷编辑的详情
  const request = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await getMediaContact(contactID);
      console.log("data: ", data);
      setContactNameInput(data.name);
      setRoleInput(data.role);
      setNoteInput(data.brief_description);
      setSelectContactStatus(String(data.contact_status));
      setSelectBlackList(String(data.is_blacklisted));
      setPhoneInput(data.mobile_phone);
      setEmailInput(data.email);
      setSelectRegion([String(data?.address?.country?.id || "")]);
      setRegionStateInput(data.address?.state || "");
      setCityInput(data.address?.city || "");
      setPostalCodeInput(data.address?.postal_code || "");
      setAddressInput(data.address?.street || "");
      setSocialLinks(
        data.platforms.map(({ type, link, subscribers }) => ({
          platform: String(type.id || ""),
          link: link || "",
          fans: String(subscribers ?? ""),
        }))
      );
      setSelectedTags(data.tags.map(({ title }) => title));

      setSelectedCollections(data.groups.map(({ id }) => String(id)));

      const outlets = await getMediaOutletList({ is_not_paged: 1, media_contact_ids: [contactID] });
      setOutlets(outlets.data.list);
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [contactID, setOutlets, setSelectedTags, setSocialLinks]);
  // outletID 变化, 且存在时候 -> 请求数据 -> outletID存在 -> 编辑模式
  useEffect(() => {
    if (contactID) {
      request();
    } else {
      setLoading(false); // 新增时候不需要加载状态
    }
  }, [contactID, request]);

  // 组件挂载时加载选项栏, 不会反复挂载
  useMount(async () => {
    const { data } = await getFiltersCountrys();

    const ops = data.list.map(({ id, name }) => ({ label: name, value: String(id) }));
    setRegionOps(ops);
    const {
      data: { list },
    } = await getPartnerPlatform({
      title: "",
      is_not_paged: 1,
      sort_by: "updated_at",
      sort_type: "desc",
    });
    const typeOptions = list.map(({ id, title }) => ({ label: title, value: String(id) }));
    setsocailLinkTypes(typeOptions);
  });

  useUnmount(() => {
    setActive(false);
  });

  return (
    <AntDrawer
      title={title}
      visible={visible}
      handleSave={editorSave}
      handleCancel={editorCancel}
      handleSetting={editorAdvaceSetting}
      saveLoading={saveLoading}
      disabledSave={!contactNameInput}
    >
      {loading ? (
        <DrawerSkeleton />
      ) : (
        <>
          <SelectOutlets active={selectOutletsActive} toggle={toggleSelectOutlets} />
          <DrawerSection title="Basic information">
            <Stack distribution="fillEvenly">
              <TextField
                autoComplete="off"
                label="Media contact name"
                value={contactNameInput}
                onChange={setContactNameInput}
                requiredIndicator
              />
              <TextField
                autoComplete="off"
                label="Role"
                value={roleInput}
                onChange={setRoleInput}
              />
            </Stack>
            <br />
            <Stack distribution="fillEvenly">
              <TextField
                autoComplete="off"
                label="Email"
                value={emailInput}
                onChange={setEmailInput}
              />
              <TextField
                autoComplete="off"
                label="Phone"
                value={phoneInput}
                onChange={setPhoneInput}
              />
            </Stack>
            <br />
            <Stack distribution="fillEvenly">
              <SelectPlus
                label="Country/region"
                select={selectRegion}
                onSelect={setSelectRegion}
                options={regionOps}
              />
              <TextField
                autoComplete="off"
                label="State/province"
                value={regionStateInput}
                onChange={setRegionStateInput}
              />
            </Stack>
            <br />
            <Stack distribution="fillEvenly">
              <TextField
                autoComplete="off"
                label="City"
                value={cityInput}
                onChange={setCityInput}
              />
              <TextField
                autoComplete="off"
                label="Address"
                value={addressInput}
                onChange={setAddressInput}
              />
            </Stack>
            <br />
            <InputTags label="Tag" />
            <br />
            <TextField
              autoComplete="off"
              label="Note"
              multiline={2}
              value={NoteInput}
              onChange={setNoteInput}
            />
          </DrawerSection>
          <DrawerSection
            title="Media outlet"
            action={
              <ButtonGroup>
                <Button size="slim" onClick={toggleSelectOutlets}>
                  Select media outlet
                </Button>
                <Columns />
              </ButtonGroup>
            }
          >
            <AddOutlet />
          </DrawerSection>
          <DrawerSection title="Social link">
            <SocialLink />
          </DrawerSection>
        </>
      )}
    </AntDrawer>
  );
};
