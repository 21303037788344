import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import ListSkeleton from "./container/skeletonContainer/ListSkeleton";
import { NotFound } from "./error/404";
import { AutoTools } from "./pages/AutoTools/AutoTools";
import { KeywordSettings } from "./pages/AutoTools/KeywordsList/KeywordSettings/KeywordSettings";
import { KeywordsList } from "./pages/AutoTools/KeywordsList/KeywordsList";
import { LaunchResults } from "./pages/AutoTools/KeywordsList/LaunchResults/LaunchResults";
import { CampaignBranches } from "./pages/Campaign/CampaignList/CampaignBranches/CampaignBranches";
import { Discovery } from "./pages/Discovery/Discovery";
import Home from "./pages/Home/Home";
import { TabEntry } from "./pages/Media/MediaContacts/ContactBoard/TabEntry";
import { Discoveries } from "./pages/Partner/Influencers/Influencers";
import { Partners } from "./pages/Partner/Partners";
import PlatformsListDetail from "./pages/Partner/Platforms/PlatformsListDetail/PlatformsListDetail";
import { ReidrectPage } from "./pages/ReidrectPage";
import { RootPage } from "./pages/RootPage";
import Schedules from "./pages/Schedules/Schedules";
import SystemModal from "./pages/System/SystemModal";
import TeamMembers from "./pages/Teams/TeamMembers";
import TeamSettings from "./pages/Teams/TeamSettings/TeamSettings.jsx";
import Teams from "./pages/Teams/Teams";
import { TestPage } from "./pages/alpha/TestPage";

const LastActiveList = React.lazy(() => import("./pages/AutoTools/LastActive/LastActive"));
const ActivityDetail = React.lazy(() =>
  import("./pages/AutoTools/LastActive/ActivityDetail/ActivityDetail")
);

const AccountSettings = React.lazy(() => import("./components/header/AcountSettings"));
const PartnerListDetail = React.lazy(() => import("./pages/Partner/PartnerList/PartnerListDetail"));
const PartnerReport = React.lazy(() => import("./pages/Partner/PartnerReport/PartnerReport.jsx"));
const PartnerGroup = React.lazy(() => import("./pages/Partner/Collections/Collections"));
const CollectionInfo = React.lazy(() =>
  import("./pages/Partner/Collections/CollectionInfo/CollectionInfo")
);
const LeadList = React.lazy(() => import("./pages/AutoTools/Leads/LeadList.jsx"));
const LeadDetail = React.lazy(() => import("./pages/AutoTools/Leads/LeadDetail/LeadDetail.jsx"));
const LeadReport = React.lazy(() => import("./pages/AutoTools/Leads/LeadReport/LeadReport.jsx"));

const CampaignList = React.lazy(() => import("./pages/Campaign/CampaignList/CampaignList"));
const CampaignDetail = React.lazy(() =>
  import("./pages/Campaign/CampaignList/CampaignDetail/CampaignDetail")
);

const CooperationDetail = React.lazy(() =>
  import("./pages/Campaign/CampaignList/CooperationList/CooperationDetail/CooperationDetail")
);
const CooperationAll = React.lazy(() => import("./pages/Campaign/Cooperations/Cooperations"));
const Promotions = React.lazy(() => import("./pages/Campaign/Promotions/Promotions.jsx"));
const PromotionDetail = React.lazy(() =>
  import("./pages/Campaign/Promotions/PromotionDetail/PromotionDetail.jsx")
);

const BudgetApps = React.lazy(() => import("./pages/Orders/BudgetApps/BudgetApps"));
const InfluencerDetail = React.lazy(() =>
  import("./pages/Orders/BudgetApps/Influencer/Detail/InfluencerDetail.jsx")
);
const MediaDetail = React.lazy(() =>
  import("./pages/Orders/BudgetApps/Media/Detail/MediaDetail.jsx")
);
const PaymentOrders = React.lazy(() => import("./pages/Orders/PaymentOrders/PaymentOrders"));
const PaymentDetail = React.lazy(() =>
  import("./pages/Orders/PaymentOrders/PaymentDetail/PaymentDetail")
);
const Deliveries = React.lazy(() => import("./pages/Orders/DeliveryOrders/Deliveries.jsx"));
const DeliveryDetails = React.lazy(() =>
  import("./pages/Orders/DeliveryOrders/DeliveryDetails/DeliveryDetails.jsx")
);

const AuxiliaryData = React.lazy(() => import("./pages/System/AuxiliaryData/AuxiliaryData"));
const AuxiliaryDetail = React.lazy(() =>
  import("./pages/System/AuxiliaryData/AuxiliaryDetail/AuxiliaryDetail")
);
const AuxiliaryItemDetail = React.lazy(() =>
  import("./pages/System/AuxiliaryData/AuxiliaryDetail/AuxiliaryItemDetail")
);
const EditCollection = React.lazy(() =>
  import("./pages/Partner/Collections/EditCollection/EditCollection")
);

// media 详情和报告
const OutletDetail = React.lazy(() =>
  import("./pages/Media/MediaOutlets/OutletDetail/OutletDetail")
);
const OutletBoard = React.lazy(() => import("./pages/Media/MediaOutlets/OutletBoard/OutletBoard"));

const ConcatDetail = React.lazy(() =>
  import("./pages/Media/MediaContacts/ConcatDetail/ConcatDetail")
);

const Routes = () => (
  <Switch>
    <Route path="/dashboard" exact>
      <Home />
    </Route>
    <Route path="/discovery" exact>
      <Discovery />
    </Route>
    <Route path="/tools" exact>
      <AutoTools />
    </Route>
    <Route path="/tools/lastactive" exact>
      <Suspense fallback={<ListSkeleton />}>
        <LastActiveList />
      </Suspense>
    </Route>
    <Route path="/tools/lastactive/detail/:id" exact>
      <Suspense fallback={<ListSkeleton />}>
        <ActivityDetail />
      </Suspense>
    </Route>
    {/* keywords 系列 */}
    <Route path="/tools/keywords" exact>
      <KeywordsList />
    </Route>
    <Route path="/tools/keywords/results/:id">
      <LaunchResults />
    </Route>
    <Route path="/tools/keywords/settings/:id?">
      <Suspense fallback={<ListSkeleton />}>
        <KeywordSettings />
      </Suspense>
    </Route>
    <Route path="/AccountSettings" exact>
      <Suspense fallback={<ListSkeleton />}>
        <AccountSettings />
      </Suspense>
    </Route>
    <Route path="/influencers" exact>
      <Discoveries />
    </Route>
    <Route path="/influencers/leads" exact>
      <Suspense fallback={<ListSkeleton />}>
        <LeadList />
      </Suspense>
    </Route>

    <Route path="/home" exact>
      <Suspense fallback={<ListSkeleton />}>
        {/* <PartnersTabs /> */}
        <Partners />
      </Suspense>
    </Route>
    <Route path="/home/group" exact>
      <Suspense fallback={<ListSkeleton />}>
        <PartnerGroup />
      </Suspense>
    </Route>
    <Route path="/home/group/:CollectionID/info" exact>
      <Suspense fallback={<ListSkeleton />}>
        <EditCollection />
      </Suspense>
    </Route>
    <Route path="/home/group/:CollectionID">
      <Suspense fallback={<ListSkeleton />}>
        <CollectionInfo />
      </Suspense>
    </Route>
    <Route path="/home/:partnerId/:operation" exact>
      <Suspense fallback={<ListSkeleton />}>
        {/* <PartnerDetail /> */}
        <PartnerListDetail />
      </Suspense>
    </Route>
    <Route path="/home/:partnerId/:operation/report" exact>
      <Suspense fallback={<ListSkeleton />}>
        <PartnerReport />
      </Suspense>
    </Route>
    <Route path="/home/platforms/:platformID/report/:partnerID" exact>
      <Suspense fallback={<ListSkeleton />}>
        <PlatformsListDetail />
      </Suspense>
    </Route>
    {/* 侧边栏的leads列表 */}
    <Route path="/tools/leads" exact>
      <Suspense fallback={<ListSkeleton />}>
        <LeadList />
      </Suspense>
    </Route>
    <Route path="/tools/leads/lead_detail/:leadID?" exact>
      <Suspense fallback={<ListSkeleton />}>
        <LeadDetail />
      </Suspense>
    </Route>
    <Route path="/tools/leads/:partnerID/lead_report" exact>
      <Suspense fallback={<ListSkeleton />}>
        <LeadReport />
      </Suspense>
    </Route>
    <Route path="/campaign" exact>
      <Suspense fallback={<ListSkeleton />}>
        <CampaignList />
      </Suspense>
    </Route>
    <Route path="/campaign/CampaignDetail/:campaignID?" exact>
      <Suspense fallback={<ListSkeleton />}>
        <CampaignDetail />
      </Suspense>
    </Route>
    {/* 在活动列表点进去是依据该活动id进入的合作列表 */}
    <Route path="/campaign/cooperation/:campaignID?" exact>
      <Suspense fallback={<ListSkeleton />}>
        <CampaignBranches />
      </Suspense>
    </Route>
    <Route path="/campaign/Cooperations" exact>
      <Suspense fallback={<ListSkeleton />}>
        <CooperationAll />
      </Suspense>
    </Route>
    <Route path="/campaign/Cooperations/cooperationDetail/:cooperID?" exact>
      <Suspense fallback={<ListSkeleton />}>
        <CooperationDetail />
      </Suspense>
    </Route>
    <Route path="/campaign/Promotions" exact>
      <Suspense fallback={<ListSkeleton />}>
        <Promotions />
      </Suspense>
    </Route>
    <Route path="/campaign/Promotions/PromotionDetail/:PromotionID?" exact>
      <Suspense fallback={<ListSkeleton />}>
        <PromotionDetail />
      </Suspense>
    </Route>

    {/* Orders 系列 */}
    <Route path="/orders/BudgetApps" exact>
      <Suspense fallback={<ListSkeleton />}>
        <BudgetApps />
      </Suspense>
    </Route>
    <Route path="/orders/BudgetApps/InfluencerDetail/:budgetID?" exact>
      <Suspense fallback={<ListSkeleton />}>
        <InfluencerDetail />
      </Suspense>
    </Route>
    <Route path="/orders/BudgetApps/MediaDetail/:budgetID?" exact>
      <Suspense fallback={<ListSkeleton />}>
        <MediaDetail />
      </Suspense>
    </Route>
    <Route path="/orders/PaymentOrders" exact>
      <Suspense fallback={<ListSkeleton />}>
        <PaymentOrders />
      </Suspense>
    </Route>
    <Route path="/orders/PaymentOrders/PaymentDetail/:OrderID?" exact>
      <Suspense fallback={<ListSkeleton />}>
        <PaymentDetail />
      </Suspense>
    </Route>
    <Route path="/orders/Deliveries" exact>
      <Suspense fallback={<ListSkeleton />}>
        <Deliveries />
      </Suspense>
    </Route>
    <Route path="/orders/Deliveries/DeliveryDetails/:DeliveryID?" exact>
      <Suspense fallback={<ListSkeleton />}>
        <DeliveryDetails />
      </Suspense>
    </Route>

    <Route path="/Schedules" exact>
      <Schedules />
    </Route>

    <Route path="/Teams" exact>
      <Teams />
    </Route>
    <Route path="/Teams/:teamID/TeamMembers" exact>
      <Suspense fallback={<ListSkeleton />}>
        <TeamMembers />
      </Suspense>
    </Route>
    <Route path="/Teams/:teamID?/TeamSettings" exact>
      <Suspense fallback={<ListSkeleton />}>
        <TeamSettings />
      </Suspense>
    </Route>

    <Route path="/system" exact>
      <Suspense fallback={<ListSkeleton />}>
        <AuxiliaryData />
      </Suspense>
    </Route>
    <Route path="/system/detail/:type/:ID" exact>
      <Suspense fallback={<ListSkeleton />}>
        <AuxiliaryItemDetail />
      </Suspense>
    </Route>
    <Route path="/system/detail/:type" exact>
      <Suspense fallback={<ListSkeleton />}>
        <AuxiliaryDetail />
      </Suspense>
    </Route>
    <Route path="/account">
      <Suspense fallback={<ListSkeleton />}>
        <SystemModal />
      </Suspense>
    </Route>

    {/* Media */}
    <Route path="/media/detail/:id?" exact>
      <Suspense fallback={<ListSkeleton />}>
        <OutletDetail />
      </Suspense>
    </Route>

    <Route path="/media/board/:id" exact>
      <Suspense fallback={<ListSkeleton />}>
        <OutletBoard />
      </Suspense>
    </Route>

    <Route path="/contacts/detail/:id?" exact>
      <Suspense fallback={<ListSkeleton />}>
        <ConcatDetail />
      </Suspense>
    </Route>

    <Route path="/contacts/board/:id" exact>
      <Suspense fallback={<ListSkeleton />}>
        <TabEntry />
      </Suspense>
    </Route>

    <Route path="/test" exact>
      <TestPage />
    </Route>

    <Route path="/redirect" exact>
      <ReidrectPage />
    </Route>

    <Route path="/" exact>
      <RootPage />
    </Route>

    <Route path="*" exact>
      <NotFound />
    </Route>

    <Redirect to="/dashboard" />
  </Switch>
);

export default Routes;
