import styled from "styled-components";

export const AntPagination = styled.div`
  padding: 8px 16px 16px 0;
  .ant-pagination {
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link,
    .ant-pagination-item,
    .ant-pagination-options .ant-select-selector {
      border: 0.1rem solid rgba(186, 191, 195, 1);
      border-radius: 0.4rem;
    }
  }
`;
