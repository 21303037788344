import {
  filterOptions,
  showOptionLabelsInTextFeild,
  updateTextRegFilter,
} from "@/utils/updateTextRegFilter";
import {
  Autocomplete,
  Avatar,
  Button,
  ChoiceList,
  Filters,
  IndexTable,
  Popover,
  Select,
  Stack,
  Text,
  TextContainer,
  TextField,
  useIndexResourceState,
} from "@shopify/polaris";
import { Columns3Minor, SortMinor } from "@shopify/polaris-icons";
import { useDebounceFn, useMount, useToggle, useUpdateEffect } from "ahooks";
import { DatePicker as AntDatePicker, Pagination } from "antd";
import { useEffect, useState } from "react";
import {
  current_page_atom,
  outletsExport_atom,
  outletsRefresh_atom,
  outlets_filter_countryDesOps,
  outlets_filter_countryOps,
  outlets_filter_countrySelect,
  outlets_filter_creatimeSelect,
  outlets_filter_creatorDesOps,
  outlets_filter_creatorOps,
  outlets_filter_creatorSelect,
  outlets_filter_subsMax,
  outlets_filter_subsMin,
  outlets_search_type,
  per_page_atom,
  sort_atom,
} from "./atom";
import { columnOps, init_headings } from "./column";

import {
  deleteMediaOutlets,
  deleteOutletsToCollections,
  getExportFile,
  getMediaOutletConfig,
  getMediaOutletList,
} from "@/api/request";
import { AntPagination } from "@/components/AntToShopify/AntPagination";
import { ButtonLink } from "@/components/EnhanceShopify/ButtonLink";
import { CountryEmoji } from "@/components/EnhanceShopify/CountryEmoji";
import { FilterBox } from "@/components/EnhanceShopify/FilterBox";
import { RowHeader } from "@/components/EnhanceShopify/RowHeader";
import { StopPropagation } from "@/components/EnhanceShopify/StopPropagation";
import { CountryRegionListAtom } from "@/components/InitCountryRegions";
import { useGlobalToast } from "@/customHooks/useGlobalToast";
import { useRefrehTable } from "@/customHooks/useRefrehTable";
import { useTopLoading } from "@/customHooks/useTopLoading";
import { loadableAuthAtom } from "@/global/authAtom";
import { useBomLocation } from "@/hooks/useBomLocation";
import { useDispatchResize } from "@/hooks/useDispatchResize";
import { useDynamicHeadings } from "@/hooks/useDynamicHeadings";
import { useRecoilToggle } from "@/hooks/useRecoilToggle";
import { useStoreLocalState } from "@/hooks/useStoreLocalState";
import { Region } from "@/pages/Partner/Platforms/Common/Region";
import { matchSearchType } from "@/pages/Partner/Platforms/Common/matchSearchType";
import { TagPopover } from "@/pages/Partner/Profiles/Common/TagPopover";
import { ConfirmDeleteModal } from "@/utils/ConfirmDeleteModal";
import { s_to_ms } from "@/utils/accurateTimeTrans";
import { splitChoiceValue } from "@/utils/checkType";
import { datepickToShopifyStyle } from "@/utils/datepickToShopifyStyle";
import { searchOps } from "@/utils/frontEndData/searchBy";
import { isEmpty } from "@/utils/isEmpty";
import { moment2Date } from "@/utils/moment2Date";
import { openNewWindow } from "@/utils/openNewWindow";
import { transformNumber } from "@/utils/tools";
import { wait } from "@/utils/wait";
import { useAtom } from "jotai";
import { compact, omit } from "lodash";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import { v4 } from "uuid";
import { AddToCampaign } from "./Common/AddToCampaign";
import { AddToCollection } from "./Common/AddToCollection";
import { ImportMediaOutlets } from "./Import/ImportMediaOutlets";
import { importOutletsUrl_atom, toggleOutletsDrawer_atom } from "./Import/atom";
import { MediaOutletsEditor } from "./MediaOutletsEditor";
import { Operation } from "./Operation";

const { RangePicker: AntDateRangePicker } = AntDatePicker;

const sortOptions = [
  {
    label: "Monthly visits (Min-Max)",
    value: "subscriber|asc",
  },
  {
    label: "Monthly visits (Max-Min)",
    value: "subscriber|desc",
  },
  { label: "Creation time (oldest first)", value: "created_at|asc" },
  { label: "Creation time (newest first)", value: "created_at|desc" },
  { label: "Update time (oldest first)", value: "updated_at|asc" },
  {
    label: "Update time (newest first)",
    value: "updated_at|desc",
  },
];
const resourceName = {
  singular: "media outlet",
  plural: "media outlets",
};

export const isUpdateOutletsDataAtom = atom({ default: false, key: "isUpdateOutletsDataAtom" });

export const MediaOutlets = ({ CollectionID, contactID }) => {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      READ: permission.data?.["pmp/resources/discovery/read"],
      WRITE: permission.data?.["pmp/resources/discovery/write"],
      IMPORT: permission.data?.["pmp/resources/discovery/import"],
      EXPORT: permission.data?.["pmp/resources/discovery/export"],
    }),
    [permission]
  );
  const regions = useRecoilValue(CountryRegionListAtom);

  const { origin } = useBomLocation();
  const [tableLoading, setTableLoading] = useState(false);
  const { TopLoadingJSX, showTopLoading, hiddenTopLoading } = useTopLoading();
  const { refreshMark } = useRefrehTable(outletsRefresh_atom);
  const [, setIsUpdateOutlets] = useRecoilState(isUpdateOutletsDataAtom);

  const [, setTemplateFileUrl] = useRecoilState(importOutletsUrl_atom); // 下载模版连接初始化

  const {
    active: drawerVisible,
    setActive: setDrawerVisible,
    toggle: toggleDrawerVisible,
  } = useRecoilToggle(toggleOutletsDrawer_atom);
  const { handleGlobalToast } = useGlobalToast();
  const [add2CampaignModalActive, { toggle: toggleadd2CampaignModal }] = useToggle();
  const [add2CollectionModalActive, { toggle: toggleadd2CollectionModal }] = useToggle();
  const [deleteActive, { toggle: toggleDeleteModal }] = useToggle();
  //#region  ---------------------- ↓ Drawer ↓ ----------------------
  // const [drawerVisible, { toggle: toggleDrawerVisible }] = useToggle();
  //#endregion -------------------- ↑ Drawer ↑ ----------------------

  // search type
  const [searchType, setSearchType] = useRecoilState(outlets_search_type);
  //#region  ---------------------- ↓ Filters ↓ ----------------------
  const [queryValue, setQueryValue] = useState("");

  // Sort
  const [popoverSortActive, { toggle: togglePopoverSortActive }] = useToggle();
  const [sortState, setSortState] = useState("updated_at"); // 排序类型:最新时间
  const [sortOrder, setSortOrder] = useState("desc"); // 排序倒序

  const [sortValue, setSortValue] = useRecoilState(sort_atom);
  const handleSortChange = useCallback((value) => setSortValue(value), [setSortValue]);

  // Columns
  const [columnsActive, { toggle: toggleColumnsActive }] = useToggle();

  // 筛选参数
  useEffect(() => {
    const [update, sort] = splitChoiceValue(sortValue);
    setSortState(update);
    setSortOrder(sort);
  }, [sortValue]);

  //#region Country filter
  const [deselectedCountryOptions, setDeselectedCountryOptions] = useRecoilState(
    outlets_filter_countryDesOps
  );
  const [countryOptions, setCountryOptions] = useRecoilState(outlets_filter_countryOps);
  const [selectedCountry, setSelectedCountry] = useRecoilState(outlets_filter_countrySelect);
  const [countryInputValue, setCountryInputValue] = useState("");
  const handleCountryOnSelect = useCallback(
    (selectList) => {
      setSelectedCountry(selectList);
    },
    [setSelectedCountry]
  );
  const updateCountryText = useCallback(
    (value) => {
      setCountryInputValue(value);
      if (value === "") {
        setCountryOptions(deselectedCountryOptions);
        return;
      }
      const resultOptions = updateTextRegFilter(value, deselectedCountryOptions);
      setCountryOptions(resultOptions);
    },
    [deselectedCountryOptions, setCountryOptions]
  );
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(selectedCountry, deselectedCountryOptions);
    setCountryInputValue(showtext);
  }, [selectedCountry, deselectedCountryOptions]);

  const handleCountryRemove = useCallback(() => setSelectedCountry([]), [setSelectedCountry]);
  //#endregion

  //#region Platform subscribers
  const [minnum, setminnumValue] = useRecoilState(outlets_filter_subsMin);
  const [maxnum, setmaxnumValue] = useRecoilState(outlets_filter_subsMax);
  const noSubscribers = useMemo(() => !(minnum || maxnum), [minnum, maxnum]);

  const handleSubscribersRemove = useCallback(() => {
    setminnumValue("");
    setmaxnumValue("");
  }, [setminnumValue, setmaxnumValue]);
  //#endregion

  //#region Create by
  const [deselectedCreatorOptions, setDeselectedCreatorOptions] = useRecoilState(
    outlets_filter_creatorDesOps
  );
  const [creatorOptions, setCreatorOptions] = useRecoilState(outlets_filter_creatorOps);
  const [selectedCreator, setSelectedCreator] = useRecoilState(outlets_filter_creatorSelect);
  const [creatorInputValue, setCreatorInputValue] = useState("");
  const handleCreatorOnSelect = useCallback(
    (selectList) => {
      setSelectedCreator(selectList);
    },
    [setSelectedCreator]
  );
  const updateCreatorText = useCallback(
    (value) => {
      setCreatorInputValue(value);
      if (value === "") {
        setCreatorOptions(deselectedCreatorOptions);
        return;
      }
      const resultOptions = updateTextRegFilter(value, deselectedCreatorOptions);
      setCreatorOptions(resultOptions);
    },
    [deselectedCreatorOptions, setCreatorOptions]
  );
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(selectedCreator, deselectedCreatorOptions);
    setCreatorInputValue(showtext);
  }, [selectedCreator, deselectedCreatorOptions]);

  const handleCreatorRemove = useCallback(() => setSelectedCreator([]), [setSelectedCreator]);
  //#endregion

  //#region Creation time
  const [creationTime, setCreationTime] = useRecoilState(outlets_filter_creatimeSelect);
  const onCreationTimeChange = useCallback(
    (dates, dateStrings) => {
      setCreationTime(dateStrings);
    },
    [setCreationTime]
  );

  const handleCreationTimeRemove = useCallback(() => setCreationTime(null), [setCreationTime]);
  //#endregion

  // Clear filter
  const handleClearAll = useCallback(() => {
    setQueryValue("");
    handleCountryRemove();
    handleSubscribersRemove();
    handleCreatorRemove();
    handleCreationTimeRemove();
  }, [handleCountryRemove, handleSubscribersRemove, handleCreatorRemove, handleCreationTimeRemove]);

  const filters = [
    // Country/region
    {
      key: "Country",
      label: "Country/region",
      filter: (
        <StopPropagation>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            options={countryOptions}
            selected={selectedCountry}
            textField={
              <Autocomplete.TextField
                autoComplete="off"
                onChange={updateCountryText}
                // label="Tags"
                value={countryInputValue}
              />
            }
            onSelect={handleCountryOnSelect}
            listTitle="Country/region"
          />
        </StopPropagation>
      ),
      shortcut: true,
    },
    // Platform subscriber
    {
      key: "subscribers",
      label: "Monthly visits",
      filter: (
        <>
          <TextField
            label="Minimum"
            type="number"
            value={minnum}
            onChange={setminnumValue}
            placeholder="Minimum"
            min={1}
            autoComplete="off"
          />
          <br />
          <TextField
            label="Maximum"
            type="number"
            value={maxnum}
            onChange={setmaxnumValue}
            placeholder="Maximum"
            autoComplete="off"
          />
        </>
      ),
      shortcut: true,
    },
    // Create by
    {
      key: "Creator",
      label: "Create by",
      filter: (
        <div>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            options={creatorOptions}
            selected={selectedCreator}
            textField={
              <Autocomplete.TextField
                autoComplete="off"
                onChange={updateCreatorText}
                // label="Tags"
                value={creatorInputValue}
              />
            }
            onSelect={handleCreatorOnSelect}
            listTitle="Create by"
          />
        </div>
      ),
      shortcut: true,
    },
    // Creation time
    {
      key: "Creation_time",
      label: "Creation time",
      filter: (
        <>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <AntDateRangePicker
            style={datepickToShopifyStyle}
            // showTime
            ranges={{
              Today: [moment(), moment()],
              "This Week": [moment().startOf("week"), moment().endOf("week")],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
            }}
            allowClear={false}
            onChange={onCreationTimeChange}
            value={creationTime ? [moment(creationTime[0]), moment(creationTime[1])] : null}
          />
        </>
      ),
    },
  ];

  const appliedFilters = [];
  // Country/region
  if (!isEmpty(selectedCountry)) {
    const key = "Country";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedCountry),
      onRemove: handleCountryRemove,
    });
  }
  // Platform subscriber
  if (!!minnum || !!maxnum) {
    const key = "subscribers";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, { minnum, maxnum }),
      onRemove: handleSubscribersRemove,
    });
  }
  // Create by
  if (!isEmpty(selectedCreator)) {
    const key = "Creator";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedCreator),
      onRemove: handleCreatorRemove,
    });
  }
  if (!!creationTime && !!compact(creationTime).length) {
    const key = "Creation_time";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, creationTime),
      onRemove: handleCreationTimeRemove,
    });
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case "Country": {
        const checkedCountry = filterOptions(value, deselectedCountryOptions);
        return "Country/region: " + checkedCountry.map((o) => `${o.label}`).join(", ");
      }
      case "subscribers":
        return `Monthly visits:  ${value.minnum || "0"}${value.maxnum ? "-" + value.maxnum : ""}`;
      case "Creator": {
        const checkedCreator = filterOptions(value, deselectedCreatorOptions);
        return "Create by: " + checkedCreator.map((o) => `${o.label}`).join(", ");
      }
      case "Creation_time":
        return `Creation time: ${value[0]} ~ ${value[1]}`;
      default:
        return value;
    }
  }

  //#endregion -------------------- ↑ Filters ↑ ----------------------

  //#region  ---------------------- ↓ Request ↓ ----------------------

  const [total, setTotal] = useState(1);
  const [currentPage, setCurrentPage] = useRecoilState(current_page_atom);
  const [perPage, setPerPage] = useRecoilState(per_page_atom);

  const initParams = useMemo(() => {
    return {
      search_by: searchType,
      search: queryValue ?? "",
      page: currentPage,
      per_page: perPage,
      total_visits: noSubscribers ? [] : [minnum, maxnum],
      country_ids: selectedCountry,
      creator_ids: selectedCreator,
      created_ats: creationTime,
      sort_by: sortState,
      sort_type: sortOrder,
      group_ids: CollectionID ? [CollectionID] : "",
      media_contact_ids: !contactID ? [] : [contactID],
    };
  }, [
    contactID,
    searchType,
    currentPage,
    perPage,
    queryValue,
    minnum,
    maxnum,
    selectedCountry,
    selectedCreator,
    creationTime,
    sortState,
    sortOrder,
    CollectionID,
    noSubscribers,
  ]);
  //TODO - 导出 outlets
  const { active: exportMark } = useRecoilToggle(outletsExport_atom);
  const startExport = useCallback(async () => {
    try {
      showTopLoading();
      // const params = pick(initParams, ["search", "search_by"]);
      const params = omit(initParams, ["page", "per_page"]);
      // const { data } = await postExportOutlets({ ...params, type: "influencer" });
      // downLoadFile(data.file_link, "PartnerList", false);
      await getExportFile({ ...params, type: "media_outlet" });

      // history.push(`/account/export`);
      openNewWindow("/account/export");
    } catch (error) {
      //
    } finally {
      hiddenTopLoading();
    }
  }, [initParams, showTopLoading, hiddenTopLoading]);

  useUpdateEffect(() => {
    startExport();
  }, [exportMark]);

  const { run } = useDebounceFn(
    async (params) => {
      setTableLoading(true);
      const { data } = await getMediaOutletList({ ...initParams, ...params });

      setTableList(data.list);
      setTotal(data.meta.pagination.total);
      setPerPage(data.meta.pagination.per_page);
      setCurrentPage(data.meta.pagination.current_page);
      window.dispatchEvent(new Event("resize"));
      setIsUpdateOutlets((b) => !b);
      setTableLoading(false);
    },
    { wait: 600 }
  );
  const reqCurrentTable = useCallback(run, [initParams, run]);

  // 筛选项的变化 应该重新请求至第一页, 因为如果保留页码,请求到最大页面<当前页码会导致页面为空
  useEffect(() => {
    reqCurrentTable({ page: 1 });
  }, [
    reqCurrentTable,
    queryValue,
    queryValue,
    minnum,
    maxnum,
    selectedCountry,
    selectedCreator,
    creationTime,
    refreshMark,
  ]); // 刷新列表在这里

  useEffect(() => {
    reqCurrentTable();
  }, [
    // 不能监听页码和每页数, 会造成挂载执行两次
    reqCurrentTable,
    sortValue,
  ]);

  //#endregion -------------------- ↑ Request ↑ ----------------------

  //#region  ---------------------- ↓ Index Table ↓ ----------------------
  const [tableList, setTableList] = useState([]);
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(tableList);

  const promotedBulkActions = [
    {
      content: "Add to collection",
      onAction: toggleadd2CollectionModal,
    },
    {
      content: "Add to campaign",
      onAction: toggleadd2CampaignModal,
    },
    {
      content: `${CollectionID ? "Remove" : "Delete"} media outlet`,
      onAction: toggleDeleteModal,
    },
  ];
  // handle delete influencer
  const handleDeletePartner = useCallback(async () => {
    try {
      if (!CollectionID) {
        await deleteMediaOutlets(selectedResources);
        await wait(1000);
        toggleDeleteModal();
        handleSelectionChange("all", false); // 清除indextable选中状态
        reqCurrentTable();
        handleGlobalToast({ msg: "Delete successfully!" });
      } else {
        await deleteOutletsToCollections([CollectionID], selectedResources);
        await wait(1000);

        toggleDeleteModal();
        handleSelectionChange("all", false); // 清除indextable选中状态
        reqCurrentTable();
        handleGlobalToast({ msg: "Remove successfully!" });
      }
    } catch (error) {
      handleGlobalToast({ msg: "Delete unsuccessfully!", isErr: true });
    }
  }, [
    toggleDeleteModal,
    selectedResources,
    reqCurrentTable,
    handleSelectionChange,
    handleGlobalToast,
    CollectionID,
  ]);

  const [headings, setHeadings] = useState(init_headings);

  const [columns, setColumns] = useState([
    "Media outlet name",
    "Country/region",
    // "Tag",
    "Media website",
    // "Monthly visits",
    // "Traffic in the main country",
    "Operation",
  ]);

  useStoreLocalState("media_outlets_column", { state: columns, setState: setColumns });
  useDispatchResize(columns);
  useDynamicHeadings({ columns, staticHeadings: init_headings, setTableHeadings: setHeadings });

  const rowMarkup = tableList.map(
    (
      { id, avatar, address, created_at, creator, name, media_website, tags, updated_at, updater },
      index
    ) => {
      // 总的序列对应关系
      let SN = [];
      // 实际渲染的每列
      let Cell = [];
      const trafficArr = [];

      const code = address?.country?.code;
      const regionObj = regions.filter(({ iso2 }) => iso2 === code)?.[0];
      const renderRegion = (
        <div style={{ display: "flex", alignItems: "center" }}>
          <CountryEmoji>{regionObj?.emoji ?? ""}</CountryEmoji>
          <span>{regionObj?.name ?? ""}</span>
        </div>
      );

      media_website?.traffic_in_the_main_country_region?.forEach((r, index) => {
        trafficArr.push({
          ...r,
          ...media_website?.traffic_in_the_main_country_percentage?.[index],
        });
      });
      const trafficList = trafficArr.map(({ name, code, value }, i) => {
        const img = regions.filter(({ iso2 }) => iso2 === code)?.[0];
        return (
          <Region
            key={i}
            name={
              <>
                <CountryEmoji compact>{img?.emoji}</CountryEmoji> {name} {value}
              </>
            }
          />
        );
      });
      // 每一列的属性展示
      const props = [
        // "Media outlet name",
        <RowHeader
          key={v4()}
          onClick={(e) => {
            e.stopPropagation();
            window.open(`${origin}/media/board/${id}`);
          }}
        >
          <Stack wrap={false} alignment="center">
            <Avatar source={avatar} size="extraSmall" />
            <Text variant="bodyMd" as="span" fontWeight="bold">
              {name}
            </Text>
          </Stack>
        </RowHeader>,
        // "Country/region",
        // <Region key={v4()} name={address?.country?.name} image={address?.country?.image} />,
        renderRegion,
        // "Tag",
        <TagPopover key={v4()} tags={tags} />,
        // "Media website",
        <ButtonLink link={media_website?.url} />,
        // "Monthly visits",
        <Text>{transformNumber(media_website?.total_visits)}</Text>,

        // "Traffic in the main country",
        trafficList,

        // "Create by",
        creator?.name,
        // "Creation time",
        moment2Date(s_to_ms(created_at)),
        // "Update by",
        updater?.name,
        // "Update time",
        moment2Date(s_to_ms(updated_at)),
        // "Operation",
        <Operation key={v4()} handleEdit={toggleDrawerVisible} id={id} />,
      ];

      columnOps.forEach(({ value }, index) => {
        SN.push({ column: value, prop: props[index] });
      });

      Cell = SN.filter(({ column }) => columns.includes(column));

      return (
        <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
          {Cell.map(({ prop }) => (
            <IndexTable.Cell key={v4()}>{prop}</IndexTable.Cell>
          ))}
        </IndexTable.Row>
      );
    }
  );

  //#endregion -------------------- ↑ Index Table ↑ ----------------------

  useMount(async () => {
    const options = await getMediaOutletConfig();
    // import template
    setTemplateFileUrl(options.data.media_outlet_template);
    // 国家
    const countryList = options?.data?.countrys?.map(({ id, name }) => ({
      label: name,
      value: `${id}`,
    }));
    setDeselectedCountryOptions(countryList);
    setCountryOptions(countryList);
    const Creator = options?.data?.users?.map(({ id, name }) => ({
      label: name,
      value: String(id),
    }));
    setDeselectedCreatorOptions(Creator);
    setCreatorOptions(Creator);
  });

  return (
    <>
      <ImportMediaOutlets />
      {TopLoadingJSX}
      <ConfirmDeleteModal
        title={`Confirm ${CollectionID ? "remove" : "delete"}`}
        content={`Are you sure you want to ${
          CollectionID ? "remove" : "delete"
        } the selected media outlet?`}
        buttonText={CollectionID ? "Remove" : "Delete"}
        deleteComfirmActive={deleteActive}
        toggleDeleteComfirmActive={toggleDeleteModal}
        handleConfirmDelete={handleDeletePartner}
      />
      <AddToCampaign
        active={add2CampaignModalActive}
        handleChange={toggleadd2CampaignModal}
        handleSave={reqCurrentTable}
        outletIDS={selectedResources}
      />
      <AddToCollection
        active={add2CollectionModalActive}
        handleChange={toggleadd2CollectionModal}
        handleSave={reqCurrentTable}
        outletIDS={selectedResources}
      />
      <MediaOutletsEditor
        title={"Edit media outlet basic profile"}
        visible={drawerVisible}
        toggle={toggleDrawerVisible}
        setActive={setDrawerVisible}
      />

      <FilterBox>
        <Select
          options={searchOps}
          value={searchType}
          onChange={(value) => {
            setSearchType(value);
            setQueryValue("");
          }}
        />

        <Filters
          queryValue={queryValue}
          filters={filters}
          appliedFilters={appliedFilters}
          onQueryChange={setQueryValue}
          onQueryClear={() => setQueryValue("")}
          onClearAll={handleClearAll}
          queryPlaceholder={`Search ${matchSearchType(searchOps, searchType)}`}
        />

        <div style={{ paddingLeft: "0.4rem" }}>
          <Popover
            active={popoverSortActive}
            activator={
              <Button onClick={togglePopoverSortActive} icon={SortMinor}>
                Sort
              </Button>
            }
            onClose={togglePopoverSortActive}
            preferredAlignment="right"
          >
            <Popover.Pane>
              <Popover.Section>
                <TextContainer>
                  <Text variant="bodyMd" as="span" color="subdued">
                    Sort by
                  </Text>
                </TextContainer>

                <ChoiceList
                  choices={sortOptions}
                  selected={sortValue}
                  onChange={handleSortChange}
                />
              </Popover.Section>
            </Popover.Pane>
          </Popover>
        </div>
        <div style={{ paddingLeft: "0.4rem" }}>
          <Popover
            active={columnsActive}
            activator={
              <Button icon={Columns3Minor} onClick={toggleColumnsActive}>
                Columns
              </Button>
            }
            onClose={toggleColumnsActive}
          >
            <Popover.Section>
              <TextContainer>
                <Text variant="bodyMd" as="span" color="subdued">
                  Columns
                </Text>
              </TextContainer>
              <ChoiceList
                allowMultiple
                choices={columnOps}
                selected={columns}
                onChange={setColumns}
              />
            </Popover.Section>
          </Popover>
        </div>
      </FilterBox>
      <IndexTable
        loading={tableLoading}
        resourceName={resourceName}
        itemCount={tableList.length}
        selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
        onSelectionChange={handleSelectionChange}
        promotedBulkActions={!access.WRITE ? undefined : promotedBulkActions}
        headings={headings}
        lastColumnSticky={!contactID}
      >
        {rowMarkup}
      </IndexTable>
      {tableList.length ? (
        <Stack distribution="trailing">
          <AntPagination>
            <Pagination
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              current={currentPage}
              pageSize={perPage}
              pageSizeOptions={[10, 20, 50, 100]}
              showSizeChanger
              total={total}
              onChange={(page, pageSize) => {
                reqCurrentTable({ ...initParams, page: page, per_page: pageSize });
              }}
            />
          </AntPagination>
        </Stack>
      ) : null}
    </>
  );
};
