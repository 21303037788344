import { Avatar, Stack, Text } from "@shopify/polaris";
import { TextClamp } from "./TextClamp";

export const UpdateInfo = ({
  creatorAvatar,
  creator,
  createTime,
  updaterAvatar,
  updater,
  updateTime,
}) => {
  return (
    <>
      <Row label="Create by " value={AvatarNameBox(creatorAvatar, creator)} />
      <div style={{ height: 8 }} />
      <Row label="Create time " value={createTime} />
      <div style={{ height: 8 }} />
      <Row label="Update by " value={AvatarNameBox(updaterAvatar, updater)} />
      <div style={{ height: 8 }} />
      <Row label="Update time " value={updateTime} />
    </>
  );
};

function AvatarNameBox(url, name) {
  return (
    <Stack spacing="tight">
      {url && <Avatar size="extraSmall" source={url} />}
      <Text>{name}</Text>
    </Stack>
  );
}

function Row({ label, value }) {
  return (
    <Stack distribution="" wrap={false} alignment="center">
      <Stack.Item>
        <div style={{ width: 120 }}>
          <Text variant="bodyMd" as="span" color="subdued">
            {label}
          </Text>
        </div>
      </Stack.Item>
      <Stack.Item fill>
        <TextClamp>{value}</TextClamp>
      </Stack.Item>
    </Stack>
  );
}
