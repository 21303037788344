import {
  Button,
  Icon,
  OptionList,
  Popover,
  Scrollable,
  Stack,
  Tag,
  TextField
} from "@shopify/polaris";
import { CirclePlusMinor, SearchMinor } from "@shopify/polaris-icons";
import { useDebounceFn, useToggle, useUnmount } from "ahooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";

import { getPartnerTags } from "@/api/request";
import { StopPropagation } from "@/components/EnhanceShopify/StopPropagation";
import { useBottomSpinner } from "@/customHooks/useBottomSpinner";
import styled from "styled-components";
import { selectTagState } from "./atom";

export const InputTags = ({ label }) => {
  // 选中的状态 []
  const [selected, setSelected] = useRecoilState(selectTagState);
  // 重置状态
  const resetSelected = useResetRecoilState(selectTagState);

  const [popoverActive, { toggle: togglePopoverActive }] = useToggle();
  const { BottomSpinner, setIsLoading } = useBottomSpinner();

  // 本第新增的列表
  const [newlyAddOptions, setNewlyAddOptions] = useState([]);
  // 后端返回的列表
  const [availableOptions, setAvailableOptions] = useState([]);

  const removeTag = useCallback(
    (tag) => () => {
      setSelected((previousTags) => previousTags.filter((previousTag) => previousTag !== tag));
      setNewlyAddOptions((previousTagsObj) => previousTagsObj.filter(({ value }) => value !== tag));
    },
    [setSelected]
  );

  const tagMarkup = selected.map((option) => (
    <Tag key={option} onRemove={removeTag(option)}>
      {option}
    </Tag>
  ));

  const activator = (
    <Wall>
      <div style={{ margin: "0 0 4px" }}>{label}</div>
      <TagBox>
        <Stack spacing="extraTight">
          {tagMarkup}
          <Tag onClick={togglePopoverActive}>+ New</Tag>
        </Stack>
      </TagBox>
    </Wall>
  );

  const [query, setQuery] = useState("");
  const handleClearSearch = () => setQuery(""); // 输入的文案

  // 点击添加 tags 操作
  const handleAdd = useCallback(() => {
    setNewlyAddOptions((oldArr) => [{ label: query, value: query }, ...oldArr]);
    setSelected((selected) => [...selected, query]);

    handleClearSearch();
  }, [query, setSelected]);

  const handleSelectChange = useCallback(
    (select) => {
      setSelected(select);
      setNewlyAddOptions((oldOptions) => {
        const res = oldOptions.filter(({ value }) => select.includes(value));
        return res;
      });
    },
    [setSelected]
  );

  // 清除选中状态, 清空新增选项列表 (新增选项列表未提交都是无效的列表, 仅供新增时用户查看, 不作为保留列表)
  // eslint-disable-next-line
  const handleClearSelect = useCallback(() => {
    setSelected([]);
    setNewlyAddOptions([]);
  }, [setSelected]);

  // 校验输入结果
  // 需要查询新增的 tags 和 后端存储的 tags, 输入的是否重复, 重复的则不显示 add 按钮
  const showAddButton = useMemo(() => {
    if (!query?.trim()) {
      return false;
    } else {
      const newlyDuplicate = newlyAddOptions.map(({ value }) => value).includes(query);
      const avaliableDuplicate = availableOptions.map(({ value }) => value).includes(query);
      return !(newlyDuplicate || avaliableDuplicate);
    }
  }, [query, newlyAddOptions, availableOptions]);

  //#region request

  // 首次加载请求的传参:
  const initParams = useMemo(() => {
    return {
      title: query,
      is_not_paged: 1,
    };
  }, [query]);

  // 在请求展示所有条目的基础上, 筛选当前页条目:
  const { run } = useDebounceFn(
    async (params) => {
      setIsLoading(true);
      const { data } = await getPartnerTags({ ...initParams, ...params });

      const optionList = data.list.map(({ title }) => ({ label: title, value: title }));
      setAvailableOptions(optionList);

      setIsLoading(false);
    },
    { wait: 600 }
  );
  const reqCurrentTable = useCallback(run, [initParams, run]);

  // tabs / 搜搜栏 / creator/sort/project 变化
  useEffect(() => {
    reqCurrentTable();
  }, [
    // 不能监听页码和每页数, 会造成挂载执行两次
    reqCurrentTable,
    query,
  ]);

  //#endregion

  useUnmount(() => {
    resetSelected();
  });

  return (
    <Popover
      active={popoverActive}
      activator={activator}
      // autofocusTarget="first-node"
      onClose={togglePopoverActive}
      preferredAlignment="left"
    >
      <Popover.Pane fixed>
        <div style={{ padding: "12px", minWidth: 320 }}>
          <StopPropagation>
            <TextField
              clearButton
              labelHidden
              placeholder="Search"
              autoComplete="off"
              prefix={<Icon source={SearchMinor} />}
              value={query}
              onChange={setQuery}
              onClearButtonClick={() => setQuery("")}
            />
            {showAddButton && (
              // 样式外壳
              <AddBox>
                <Button
                  icon={CirclePlusMinor}
                  plain
                  fullWidth
                  removeUnderline
                  textAlign="left"
                  onClick={handleAdd}
                >
                  {/* 模仿官方后台的效果: 输入内容会作为按钮的文案的一部分 */}
                  Add {query.trim() && `"` + query + `"`}
                </Button>
              </AddBox>
            )}
          </StopPropagation>
        </div>
      </Popover.Pane>
      <Popover.Pane>
        <div style={{ position: "relative" }}>
          {BottomSpinner}
          <Scrollable
            shadow
            style={{ height: 300 }}
            onScrolledToBottom={() => {
              console.log("onScrolledToBottom");
            }}
          >
            <OptionList
              onChange={handleSelectChange}
              sections={[
                newlyAddOptions.length && { title: "newly added", options: newlyAddOptions },
                availableOptions.length && { title: "available", options: availableOptions },
              ]}
              selected={selected}
              allowMultiple
            />
          </Scrollable>
        </div>
      </Popover.Pane>
      {/* <Popover.Pane fixed>
        <div style={{ padding: "12px" }}>
          <Stack distribution="trailing">
            <ButtonGroup>
              <Button primary onClick={handleAdd}>
                Add
              </Button>
            </ButtonGroup>
          </Stack>
        </div>
      </Popover.Pane> */}
    </Popover>
  );
};

const Wall = styled.div``;

const TagBox = styled.div`
  border: 1px solid var(--p-border-subdued);
  border-radius: 0.25rem;
  padding: 6px;
`;

const AddBox = styled.div`
  border-radius: 4px;
  padding-top: 6px;
  margin-top: 4px;
  /* margin: 0 8px; */
  &:hover {
    background-color: rgba(241, 242, 243, 1);
  }
`;
