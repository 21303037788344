import { Button, Stack, Tag } from "@shopify/polaris";
import { useEffect, useState } from "react";

export const ShowTags = ({ tags, num = 9, showAll = false, isProfile = false }) => {
  const [list, setList] = useState([]);
  const [renderList, setRenderList] = useState([]);

  useEffect(() => {
    let arr;
    if (isProfile) {
      arr = tags?.map(({ title }) => title);
    } else {
      arr = tags;
    }
    setList(arr);
    const shortCut = arr?.slice(0, num);
    // 加载时是否展示所有
    if (showAll) {
      setRenderList(arr);
    } else {
      setRenderList(shortCut);
    }
  }, [tags, num, showAll, isProfile]);

  return (
    <>
      <Stack spacing="tight">
        {renderList?.map((item, index) => (
          <Tag key={index}>{item}</Tag>
        ))}
      </Stack>
      <div style={{ height: 8 }}></div>

      {list?.length !== renderList?.length && list?.length > num && (
        <Stack distribution="center">
          <Button
            plain
            removeUnderline
            disclosure="down"
            onClick={() => {
              setRenderList([...list]);
            }}
          >
            Show all
          </Button>
        </Stack>
      )}
      {list?.length === renderList?.length && list?.length > num && (
        <Stack distribution="center">
          <Button
            plain
            removeUnderline
            disclosure="up"
            onClick={() => {
              setRenderList([...list.slice(0, num)]);
            }}
          >
            Show less
          </Button>
        </Stack>
      )}
    </>
  );
};
