import { useState, useCallback } from "react";

// 参考 choiceList 文档
// 值得注意的是: 如果初始值是一个字符串数组,报警告,因为select组件要开mutip模式,所以初始值判断一下就行字符串还是数组就行了
export function useChoiceListLogic(initValue) {
  const [selected, setSelected] = useState(initValue);

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  return {
    selected,
    setSelected,
    handleSelectChange,
  };
}
// 其实选择逻辑跟select都是一样的, 我换了一种初始化方式,就不用传递整个optionList进来了
