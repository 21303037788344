import { getPartnerPrincipalOptions, postAddCampaignMembers } from "@/api/request";
import { Filters, Loading, Modal, OptionList, Page, Pagination, Stack } from "@shopify/polaris";
import { useLocalStorageState, useToggle, useUpdateEffect } from "ahooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { useFiltersPagination } from "@/customHooks/useFiltersPagination";
import { useTabs } from "@/customHooks/useTabs";
import { NoPermission } from "@/error/403";
import { ResponseError } from "@/error/500";
import { loadableAuthAtom } from "@/global/authAtom";
import { useMount } from "ahooks";
import { useAtom } from "jotai";
import _ from "lodash";
import { CampaignMembers } from "../CampaignMembers/CampaignMembers";
import CampaignCooperations from "../CooperationList/CampaignCooperations";

export const CampaignBranches = () => {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      LOADING: permission.state === "loading",
      ERROR: permission.state === "hasError",
      READ: permission.data?.["pmp/workflows/campaigns/read"],
      WRITE: permission.data?.["pmp/workflows/campaigns/write"],
    }),
    [permission]
  );
  const history = useHistory();
  const { campaignID } = useParams();
  const { state: routeStates } = useLocation();
  const [refreshMemberListFlag, { toggle: refreshMemberList }] = useToggle();

  const [tabIndex_in_campaign, setTabIndex_in_campaign] = useLocalStorageState(
    "tabIndex_in_campaign",
    {
      defaultValue: 0,
    }
  );
  const {
    tabJSX: BranchTabJSX,
    selected: tabIndex,
    setSelected: setTabIndex,
  } = useTabs({
    titles: ["Cooperations", "Members"],
  });
  useMount(() => {
    setTabIndex(tabIndex_in_campaign);
  });
  useUpdateEffect(() => {
    setTabIndex_in_campaign(tabIndex);
  }, [tabIndex]);
  // #region ****************** Add Member ***************************************************************
  // Add product modal state
  const [addMemberActive, { toggle: toggleAddMemberActive }] = useToggle();
  const [memberOptions, setMemberOptions] = useState([]); // 总选项表 [ [obj] ]
  const [chunkMembers, setChunkMembers] = useState([]); // 前端分页选项列表 [ obj ]
  const [selectedMembers, setSelectedMembers] = useState([]); // 选中的列表 [ id ]
  const [, setMemberIDs] = useState([]); // 需要渲染的memberIDs [ id  ]
  // add members searh
  const [queryValue, setQueryValue] = useState("");
  const handleQueryChange = useCallback((newValue) => {
    setQueryValue(newValue);
  }, []);
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue("");
  }, []);
  const { currentPage, setCurrentPage, totalPages, setTotalPages } = useFiltersPagination();

  // 下一页
  const Next = useCallback(() => {
    setCurrentPage((currentPage) => ++currentPage);
    const chunkmember = _.chunk(memberOptions, 10);
    const g = [...chunkmember][currentPage]?.map(({ id, name }) => ({
      label: name,
      value: String(id),
    }));
    setChunkMembers(g);
  }, [setCurrentPage, memberOptions, currentPage]);
  // 上一页
  const Previous = useCallback(() => {
    setCurrentPage((currentPage) => --currentPage);
    const chunkmember = _.chunk(memberOptions, 10);
    const g = chunkmember[currentPage - 2]?.map(({ id, name }) => ({
      label: name,
      value: String(id),
    }));
    setChunkMembers(g);
  }, [setCurrentPage, memberOptions, currentPage]);
  // 前端分页 初始化
  useEffect(() => {
    const chunkmember = _.chunk(memberOptions, 10);
    const g = chunkmember[0]?.map(({ id, name }) => ({
      label: name,
      value: String(id),
    }));
    setChunkMembers(g);
    setTotalPages(chunkmember?.length ? chunkmember.length : 1);
  }, [memberOptions, setTotalPages]);

  // 监听搜索输入:
  useEffect(() => {
    if (queryValue) {
      if (queryValue.trim()) {
        const filterRegex = new RegExp(queryValue, "i");

        const resultOptions = memberOptions.filter((option) => option.name.match(filterRegex));
        const chunkmember = _.chunk(resultOptions, 10);
        const g = chunkmember[0]?.map(({ id, name }) => ({
          label: name,
          value: String(id),
        }));
        setChunkMembers(g);

        setCurrentPage(1);
        setTotalPages(1);
      }
    } else {
      const chunkmember = _.chunk(memberOptions, 10);
      const g = chunkmember[0]?.map(({ id, name }) => ({
        label: name,
        value: String(id),
      }));
      setChunkMembers(g);
      setTotalPages(chunkmember?.length ? chunkmember.length : 1);
    }
  }, [queryValue, memberOptions, setCurrentPage, setTotalPages]);

  // Menmber List IndexTable

  const AddMemberModal = (
    <Modal
      title="Select member"
      small
      open={addMemberActive}
      onClose={toggleAddMemberActive}
      primaryAction={{
        content: "Save",
        onAction: async () => {
          //  改变memberIDs 就行了, 来源是 选择的ids
          setMemberIDs([...selectedMembers]);
          setCurrentPage(1);
          const chunkmember = _.chunk(memberOptions, 10);
          const g = [...chunkmember][0]?.map(({ id, name }) => ({
            label: name,
            value: String(id),
          }));
          setChunkMembers(g);
          // 添加member请求
          await postAddCampaignMembers({ campaign_id: campaignID, member_ids: selectedMembers });
          refreshMemberList();
          setSelectedMembers([]);
          toggleAddMemberActive();
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction() {
            setCurrentPage(1);
            const chunkmember = _.chunk(memberOptions, 10);
            const g = [...chunkmember][0]?.map(({ id, name }) => ({
              label: name,
              value: String(id),
            }));
            setChunkMembers(g);
            toggleAddMemberActive();
          },
        },
      ]}
    >
      <Modal.Section>
        <div style={{ flex: 1 }}>
          <Filters
            queryValue={queryValue}
            filters={[]}
            appliedFilters={[]}
            onQueryChange={handleQueryChange}
            onQueryClear={handleQueryValueRemove}
            queryPlaceholder="Search by user name"
          />
        </div>
      </Modal.Section>
      <Modal.Section>
        <OptionList
          title=""
          onChange={setSelectedMembers}
          options={chunkMembers}
          selected={selectedMembers}
          allowMultiple
        />
        <Stack distribution="center">
          <Pagination
            label={
              <>
                {currentPage} / {totalPages}
              </>
            }
            hasPrevious={currentPage < 2 ? false : true}
            onPrevious={Previous}
            hasNext={currentPage >= totalPages ? false : true}
            onNext={Next}
          />
        </Stack>
      </Modal.Section>
    </Modal>
  );
  //#endregion ****************** Add Member end ***************************************************************

  // page titles
  const PageTitle = useCallback(() => {
    if (tabIndex === 0) return "Campaign cooperations";
    if (tabIndex === 1) return "Campaign members";
  }, [tabIndex]);
  // 动态的主按钮
  const AddButton = useCallback(() => {
    if (tabIndex === 0) return "Create cooperation";
    if (tabIndex === 1) return "Add member";
  }, [tabIndex]);

  // 动态的主按钮-处理函数
  const handleAdd = useCallback(() => {
    if (tabIndex === 0)
      return history.push(`/campaign/Cooperations/cooperationDetail/`, { routeStates });
    if (tabIndex === 1) {
      toggleAddMemberActive();
    }
  }, [tabIndex, history, routeStates, toggleAddMemberActive]);

  // 动态的次按钮
  const Details = useCallback(() => {
    if (tabIndex === 0 && access.WRITE) return "Campaign settings";
    return "";
  }, [tabIndex, access]);
  // 动态的次按钮-处理函数
  const handleDetails = useCallback(() => {
    if (tabIndex === 0) return history.push(`/campaign/CampaignDetail/${campaignID}`);
  }, [tabIndex, history, campaignID]);

  useMount(async () => {
    const memberRes = await getPartnerPrincipalOptions();
    setMemberOptions(memberRes.data.list); // 存一份member总表
  });

  if (access.LOADING) return <Loading />;
  if (access.ERROR) return <ResponseError />;
  if (!access.READ) return <NoPermission />;

  return (
    <Page
      fullWidth
      breadcrumbs={[
        {
          content: "goback",
          onAction() {
            history.goBack();
          },
        },
      ]}
      title={PageTitle()}
      primaryAction={
        !access.WRITE
          ? undefined
          : {
              content: AddButton(),
              onAction: handleAdd,
              // primary: false,
            }
      }
      secondaryActions={
        !access.WRITE
          ? undefined
          : [
              {
                content: Details(),
                onAction: handleDetails,
              },
            ]
      }
    >
      {BranchTabJSX}
      {tabIndex === 0 && <CampaignCooperations />}
      {tabIndex === 1 && <CampaignMembers refreshMemberListFlag={refreshMemberListFlag} />}
      {AddMemberModal}
    </Page>
  );
};
