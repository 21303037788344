import { Badge } from "@shopify/polaris";

export function paymentStatusBadge(status) {
  status = Number(status);
  const colors = new Map([
    [1, ""],
    [2, "success"],
  ]);
  const currentStatus = new Map([
    [1, "Unpaid"],
    [2, "Paid"],
  ]);

  const content = (status) => currentStatus.get(status);
  const badgeColor = (status) => colors.get(status);

  return (
    <Badge status={badgeColor(status)}>
      <span style={{ whiteSpace: "nowrap" }}>{content(status)}</span>
    </Badge>
  );
}
