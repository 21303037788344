import { Icon } from "@shopify/polaris";
import { PlusMinor } from "@shopify/polaris-icons";
import styled from "styled-components";

const KitCard = styled.div`
  width: 256px;
  height: 132px;
  padding: 16px;
  margin: 0px 8px 8px 0px;
  border-radius: 8px;
  box-sizing: border-box;
  background: transparent;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  transition: box-shadow 0.2s ease-in, background-color 0.2s ease-in;
  display: grid;
  place-items: center;
  cursor: pointer;
  &:hover {
    box-shadow: 2px 2px 8px #00000020, -2px -2px 8px #00000020;
    background-color: #f8f8f9;
    > :first-of-type {
      background-color: #ffff;
    }
  }
`;

const CenterCircle = styled.div`
  display: grid;
  place-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #f8f8f9;
`;

// 二次封装的 styled 组件不能直接绑定 onclick 事件, 需要传递到对应的 styled 组件直接绑定
export const EmptyKittyCard = ({ title, onClick }) => {
  return (
    <KitCard onClick={onClick}>
      <CenterCircle>
        <Icon source={PlusMinor} color="subdued" />
      </CenterCircle>
      <span style={{ backgroundColor: "transparent", color: "#b1b6bb" }}>{title}</span>
    </KitCard>
  );
};
