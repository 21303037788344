import { cookieScope } from "@/utils/cookieScope";
import { hotToast } from "@/utils/hotToast";
import { isDev } from "@/utils/isDev";

export const requsetHandler = (config) => {
  // 在这里可以添加你想要的请求拦截逻辑，例如添加 token 到请求头
  config.headers["Authorization"] = cookieScope.getToken();
  config.headers["x-fstln-tenant-id"] = cookieScope.getTenantId();

  return config;
};

export const requsetErrorHandler = (error) => {
  // 错误处理
  return Promise.reject(error);
};

export const responseHandler = (response) => {
  if (response.config.responseType === "blob") {
    return response;
  }
  return response.data;
};

export const responseErrorHandler = (error) => {
  // 错误处理
  if (error?.response?.status === 401) {
    if (!isDev) {
      cookieScope.removeToken();
      cookieScope.removeTenantId();
      window.location.href = `${process.env.REACT_APP_PRODUCT_URL}`;
    } else {
      hotToast("Token has expired", { error: true });
    }
  }

  return Promise.reject(error);
};
