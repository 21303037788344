
export const StopPropagation = ({ children }) => {
  const stopEventPropagation = (event) => {
    event.stopPropagation();
  };

  return (
    <div onClick={stopEventPropagation} onTouchStart={stopEventPropagation}>
      {children}
    </div>
  );
};
