import { getFileList, getPartnerPrincipalOptions } from "@/api/request";
import { AntPagination } from "@/components/AntToShopify/AntPagination";
import { StopPropagation } from "@/components/EnhanceShopify/StopPropagation";
import { useTabs } from "@/customHooks/useTabs";
import { NoPermission } from "@/error/403";
import { ResponseError } from "@/error/500";
import { loadableAuthAtom } from "@/global/authAtom";
import { useDispatchResize } from "@/hooks/useDispatchResize";
import { useDynamicHeadings } from "@/hooks/useDynamicHeadings";
import { s_to_ms } from "@/utils/accurateTimeTrans";
import { splitChoiceValue } from "@/utils/checkType";
import { datepickToShopifyStyle } from "@/utils/datepickToShopifyStyle";
import { downLoadFile } from "@/utils/downLoadFile";
import { isEmpty } from "@/utils/isEmpty";
import { moment2Date } from "@/utils/moment2Date";
import {
  filterOptions,
  showOptionLabelsInTextFeild,
  updateTextRegFilter,
} from "@/utils/updateTextRegFilter";
import {
  Autocomplete,
  Badge,
  Button,
  Card,
  ChoiceList,
  Filters,
  IndexTable,
  Loading,
  Page,
  Popover,
  Stack,
  Text,
  TextContainer,
  useIndexResourceState,
} from "@shopify/polaris";
import { Columns3Minor, SortMinor } from "@shopify/polaris-icons";
import { useDebounceFn, useMount, useToggle, useUpdateEffect } from "ahooks";
import { DatePicker as AntDatePicker, Pagination } from "antd";
import { useAtom } from "jotai";
import { compact } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";

const { RangePicker: AntDateRangePicker } = AntDatePicker;

const tabsName = ["All", "Not start", "In progress", "Partial fail", "Successful", "Failed"];

const businessTypeOptions = [
  { label: "Influencer basic profile", value: "influencer" },
  { label: "Media outlet basic profile", value: "media_outlet" },
  { label: "Media contact basic profile", value: "media_contact" },
];

const sortOptions = [
  { label: "Creation time (oldest first)", value: "created_at|asc" },
  { label: "Creation time (newest first)", value: "created_at|desc" },
  { label: "Update time (oldest first)", value: "updated_at|asc" },
  {
    label: "Update time (newest first)",
    value: "updated_at|desc",
  },
];

const init_table_headings = ["Status", "Successes", "Failures", "Create by", "Creation time"];
const columnOps = init_table_headings.map((str) => ({
  label: str,
  value: str,
}));
columnOps.unshift({
  label: "Type",
  value: "Type",
  disabled: true,
});
columnOps.push({
  label: "Operation",
  value: "Operation",
  disabled: true,
});

const init_headings = columnOps.map(({ value }) => ({ title: value }));

//TODO - 导入列表
export const ImportList = () => {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      LOADING: permission.state === "loading",
      ERROR: permission.state === "hasError",
      READ: permission.data?.["pmp/settings/import_export/read"],
      DOWNLOAD: permission.data?.["pmp/settings/import_export/download"],
    }),
    [permission]
  );

  const { tabJSX, selected: tabIndex } = useTabs({ titles: tabsName });
  const statusValue = useMemo(() => {
    switch (tabIndex) {
      case 0: {
        return "";
      }
      case 1: {
        return "not_start";
      }
      case 2: {
        return "in_progress";
      }
      case 3: {
        return "partial_fail";
      }
      case 4: {
        return "success";
      }
      case 5: {
        return "fail";
      }
      default:
        return "";
    }
  }, [tabIndex]);

  const [queryValue, setQueryValue] = useState("");

  // business
  const [businessType, setBusinessType] = useState([]);
  const handleBusinessTypeRemove = () => {
    setBusinessType([]);
  };

  //#region Create by
  const [deselectedCreatorOptions, setDeselectedCreatorOptions] = useState([]);
  const [creatorOptions, setCreatorOptions] = useState([]);
  const [selectedCreator, setSelectedCreator] = useState([]);
  const [creatorInputValue, setCreatorInputValue] = useState("");
  const handleCreatorOnSelect = useCallback(
    (selectList) => {
      setSelectedCreator(selectList);
    },
    [setSelectedCreator]
  );
  const updateCreatorText = useCallback(
    (value) => {
      setCreatorInputValue(value);
      if (value === "") {
        setCreatorOptions(deselectedCreatorOptions);
        return;
      }
      const resultOptions = updateTextRegFilter(value, deselectedCreatorOptions);
      setCreatorOptions(resultOptions);
    },
    [deselectedCreatorOptions, setCreatorOptions]
  );
  useUpdateEffect(() => {
    const showtext = showOptionLabelsInTextFeild(selectedCreator, deselectedCreatorOptions);
    setCreatorInputValue(showtext);
  }, [selectedCreator, deselectedCreatorOptions]);

  const handleCreatorRemove = () => setSelectedCreator([]);
  //#endregion

  //#region Creation time
  const [creationTime, setCreationTime] = useState(null);
  const onCreationTimeChange = (dates, dateStrings) => {
    setCreationTime(dateStrings);
  };

  const handleCreationTimeRemove = () => setCreationTime(null);
  //#endregion

  const handleClearAll = () => {
    setQueryValue("");
    handleBusinessTypeRemove();
    handleCreatorRemove();
    handleCreationTimeRemove();
  };

  //ANCHOR - Sort
  const [popoverSortActive, { toggle: togglePopoverSortActive }] = useToggle();
  const [sortValue, setSortValue] = useState("updated_at|desc");

  const [columnsActive, { toggle: toggleColumnsActive }] = useToggle();
  const [columns, setColumns] = useState([
    "Type",
    "Status",
    "Successes",
    "Failures",
    "Create by",
    "Creation time",
    "Operation",
  ]);

  const filters = [
    // Business type
    {
      key: "Business_type",
      label: "Type",
      shortcut: true,
      filter: (
        <ChoiceList
          allowMultiple
          choices={businessTypeOptions}
          selected={businessType}
          onChange={setBusinessType}
        />
      ),
    },
    // Create by
    {
      key: "Creator",
      label: "Create by",
      shortcut: true,
      filter: (
        <div>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <Autocomplete
            allowMultiple
            titleHidden
            options={creatorOptions}
            selected={selectedCreator}
            textField={
              <Autocomplete.TextField
                autoComplete="off"
                onChange={updateCreatorText}
                // label="Tags"
                value={creatorInputValue}
              />
            }
            onSelect={handleCreatorOnSelect}
            listTitle="Create by"
          />
        </div>
      ),
    },
    // Creation time
    {
      key: "Creation_time",
      label: "Creation time",
      shortcut: true,
      filter: (
        <>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <StopPropagation>
            <AntDateRangePicker
              size="large"
              style={datepickToShopifyStyle}
              // showTime
              ranges={{
                Today: [moment(), moment()],
                "This Week": [moment().startOf("week"), moment().endOf("week")],
                "This Month": [moment().startOf("month"), moment().endOf("month")],
              }}
              allowClear={false}
              onChange={onCreationTimeChange}
              value={creationTime ? [moment(creationTime[0]), moment(creationTime[1])] : null}
            />
          </StopPropagation>
        </>
      ),
    },
  ];
  const appliedFilters = [];

  if (!isEmpty(businessType)) {
    const key = "Business_type";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, businessType),
      onRemove: handleBusinessTypeRemove,
    });
  }
  // Create by
  if (!isEmpty(selectedCreator)) {
    const key = "Creator";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedCreator),
      onRemove: handleCreatorRemove,
    });
  }
  // Creation time
  if (!!creationTime && !!compact(creationTime).length) {
    const key = "Creation_time";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, creationTime),
      onRemove: handleCreationTimeRemove,
    });
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case "Business_type": {
        const checkedBusinessType = filterOptions(value, businessTypeOptions);
        return "Business type: " + checkedBusinessType.map((o) => `${o.label}`).join(", ");
      }
      case "Creator": {
        const checkedCreator = filterOptions(value, deselectedCreatorOptions);
        return "Create by: " + checkedCreator.map((o) => `${o.label}`).join(", ");
      }
      case "Creation_time":
        return `Creation time: ${value[0]} ~ ${value[1]}`;
      default:
        return value;
    }
  }

  //ANCHOR - index table
  const resourceName = {
    singular: "item",
    plural: "items",
  };
  const [headings, setHeadings] = useState(init_headings);
  useDynamicHeadings({
    columns: columns,
    staticHeadings: init_headings,
    setTableHeadings: setHeadings,
  });
  useDispatchResize([columns]);

  const [tableLoading, setTableLoading] = useState(false); // indexTable 加载状态显示

  const [tableList, setTableList] = useState([]);
  // index Table 选中处理函数
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(tableList);

  const rowMarkup = tableList?.map(
    (
      {
        id,
        business_type,
        created_at,
        creator,
        failed_qty,
        succeeded_qty,
        name,
        status,
        updated_at,
        file_url,
      },
      index
    ) => {
      // Type
      const typeMark = (type) => {
        switch (type) {
          case "media_outlet":
            return "Media outlet profile";
          case "influencer":
            return "Influencer basic profile";
          case "media_contact":
            return "Media contact profile";
          default:
            break;
        }
      };

      const sumItem = succeeded_qty + failed_qty;

      const StatusBadge = (status) => {
        switch (status) {
          case "fail":
            return <Badge status="critical">Failed</Badge>;
          case "success":
            return <Badge status="success">Successful</Badge>;
          case "in_progress":
            return <Badge status="info">In progress</Badge>;
          case "partial_fail":
            return <Badge status="warning">Partial fail</Badge>;
          case "not_start":
          default:
            return <Badge>Not start</Badge>;
        }
      };
      const Download = (link) => {
        return !access.DOWNLOAD ? undefined : (
          <StopPropagation>
            <Button plain disabled={!link} onClick={() => downLoadFile(link, link)}>
              Download
            </Button>
          </StopPropagation>
        );
      };
      // 字段展示
      const props = [
        // "Type",
        typeMark(business_type),
        // "Status",
        StatusBadge(status),
        // "Successes",
        <>
          <Text variant="bodyMd" as="span">
            {succeeded_qty}
          </Text>

          <Text variant="bodyMd" as="span" color="subdued">
            /{sumItem}
          </Text>
        </>,
        // "Failures",
        <>
          <Text variant="bodyMd" as="span">
            {failed_qty}
          </Text>

          <Text variant="bodyMd" as="span" color="subdued">
            /{sumItem}
          </Text>
        </>,
        // "Create by",
        creator.name,
        // "Creation time",
        moment2Date(s_to_ms(created_at)),
        // "Operation",
        Download(file_url),
      ];
      let SN = []; // 总的序列对应关系
      columnOps.forEach(({ value }, index) => {
        SN.push({ column: value, prop: props[index] });
      });
      let showCell = SN.filter(({ column }) => columns.includes(column));

      return (
        <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
          {showCell.map(({ prop }) => (
            <IndexTable.Cell key={v4()}>{prop}</IndexTable.Cell>
          ))}
        </IndexTable.Row>
      );
    }
  );

  //ANCHOR - 分页
  const [total, setTotal] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  //ANCHOR - 请求函数
  const initParams = useMemo(() => {
    return {
      file_type: "import",
      status: statusValue,
      name: queryValue,
      page: currentPage,
      per_page: perPage,
      sort_by: splitChoiceValue(sortValue)[0],
      sort_type: splitChoiceValue(sortValue)[1],
      business_types: businessType,
      creator_ids: selectedCreator,
      created_at: creationTime,
    };
  }, [
    statusValue,
    selectedCreator,
    creationTime,
    queryValue,
    currentPage,
    perPage,
    sortValue,
    businessType,
  ]);

  const renderTable = useCallback(
    async (params) => {
      setTableLoading(true);

      const { data } = await getFileList({ ...initParams, ...params });
      console.log("data: ", data);

      setTableList(data?.list ?? []);
      setTotal(data.meta?.pagination.total);
      setPerPage(data.meta?.pagination.per_page);

      setCurrentPage(data.meta?.pagination.current_page);
      setTableLoading(false);
    },
    [initParams]
  );

  const { run } = useDebounceFn(renderTable, { wait: 600 });

  const req = useCallback(run, [initParams, run]);

  useMount(async () => {
    // renderTable();
    const { data: createBylist } = await getPartnerPrincipalOptions();
    const creators = createBylist.list.map((o) => ({ label: o.name, value: String(o.id) }));
    setDeselectedCreatorOptions(creators);
    setCreatorOptions(creators);
  });

  useEffect(() => {
    req({ page: 1 });
  }, [queryValue, selectedCreator, creationTime, creationTime, businessType, tabIndex, req]);

  useEffect(() => {
    req();
  }, [
    // 不能监听页码和每页数, 会造成挂载执行两次
    req,
    sortValue,
  ]);

  if (access.LOADING) return <Loading />;
  if (access.ERROR) return <ResponseError />;
  if (!access.READ) return <NoPermission />;

  return (
    <Page title="Import activities">
      <Card>
        {tabJSX}
        <Card.Section>
          <Stack spacing="extraTight" distribution="trailing">
            <Stack.Item fill>
              <Filters
                queryValue={queryValue}
                filters={filters}
                appliedFilters={appliedFilters}
                onQueryChange={setQueryValue}
                onQueryClear={() => setQueryValue("")}
                onClearAll={handleClearAll}
                queryPlaceholder={`Search by type`}
                hideQueryField
              />
            </Stack.Item>

            <Popover
              active={popoverSortActive}
              activator={
                <Button onClick={togglePopoverSortActive} icon={SortMinor}>
                  Sort
                </Button>
              }
              onClose={togglePopoverSortActive}
              preferredAlignment="right"
            >
              <Popover.Pane>
                <Popover.Section>
                  <TextContainer>
                    <Text variant="bodyMd" as="span" color="subdued">
                      Sort by
                    </Text>
                  </TextContainer>

                  <ChoiceList choices={sortOptions} selected={sortValue} onChange={setSortValue} />
                </Popover.Section>
              </Popover.Pane>
            </Popover>
            <Popover
              active={columnsActive}
              activator={
                <Button icon={Columns3Minor} onClick={toggleColumnsActive}>
                  Columns
                </Button>
              }
              onClose={toggleColumnsActive}
            >
              <Popover.Section>
                <TextContainer>
                  <Text variant="bodyMd" as="span" color="subdued">
                    Columns
                  </Text>
                </TextContainer>
                <ChoiceList
                  allowMultiple
                  choices={columnOps}
                  selected={columns}
                  onChange={setColumns}
                />
              </Popover.Section>
            </Popover>
          </Stack>
        </Card.Section>
        <IndexTable
          loading={tableLoading}
          resourceName={resourceName}
          itemCount={tableList.length}
          selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
          onSelectionChange={handleSelectionChange}
          headings={headings}
          selectable={false}
          // emptyState={emptyStateMarkup}
          lastColumnSticky
        >
          {rowMarkup}
        </IndexTable>
        {tableList.length ? (
          <Stack distribution="trailing">
            <AntPagination>
              <Pagination
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                current={currentPage}
                pageSize={perPage}
                pageSizeOptions={[10, 20, 50, 100]}
                showSizeChanger
                total={total}
                onChange={(page, pageSize) => {
                  req({ ...initParams, page: page, per_page: pageSize });
                }}
              />
            </AntPagination>
          </Stack>
        ) : null}
      </Card>
    </Page>
  );
};
