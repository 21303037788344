import { atom } from "recoil";
import { v4 } from "uuid";

export const exportTwitter_atom = atom({
  key: v4(),
  default: false,
});

const twiter_search_type = atom({
  key: v4(),
  default: "all",
});

const twiter_current_page = atom({
  key: v4(),
  default: 1,
});
const twiter_per_page = atom({
  key: v4(),
  default: 10,
});

const twiter_filter_sort = atom({
  key: v4(),
  default: "updated_at|desc",
});

const twiter_filter_search = atom({
  key: v4(),
  default: "",
});

const twiter_filter_platsrDesOps = atom({
  key: v4(),
  default: [],
});

const twiter_filter_platsrcOps = atom({
  key: v4(),
  default: [],
});

const twiter_filter_platsrcSelect = atom({
  key: v4(),
  default: [],
});

const twiter_filter_keywordDesOps = atom({
  key: v4(),
  default: [],
});

const twiter_filter_keywordOps = atom({
  key: v4(),
  default: [],
});

const twiter_filter_keywordSelect = atom({
  key: v4(),
  default: [],
});

const twiter_filter_relatedPtnsDesOps = atom({
  key: v4(),
  default: [],
});

const twiter_filter_relatedPtnsOps = atom({
  key: v4(),
  default: [],
});

const twiter_filter_relatedPtnsSelect = atom({
  key: v4(),
  default: [],
});

const twiter_filter_locationDesOps = atom({
  key: v4(),
  default: [],
});

const twiter_filter_locationOps = atom({
  key: v4(),
  default: [],
});

const twiter_filter_locationSelect = atom({
  key: v4(),
  default: [],
});

const twiter_filter_postMin = atom({
  key: v4(),
  default: "",
});
const twiter_filter_postMax = atom({
  key: v4(),
  default: "",
});

const twiter_filter_followerMin = atom({
  key: v4(),
  default: "",
});
const twiter_filter_followerMax = atom({
  key: v4(),
  default: "",
});

const twiter_filter_likesMin = atom({
  key: v4(),
  default: "",
});
const twiter_filter_likesMax = atom({
  key: v4(),
  default: "",
});

const twiter_filter_listMin = atom({
  key: v4(),
  default: "",
});
const twiter_filter_listMax = atom({
  key: v4(),
  default: "",
});

// const twiter_filter_xxDesOps = atom({
//   key: v4(),
//   default: [],
// });

// const twiter_filter_xxOps = atom({
//   key: v4(),
//   default: [],
// });

// const twiter_filter_xxSelect = atom({
//   key: v4(),
//   default: [],
// });

const twiter_filter_creatorDesOps = atom({
  key: v4(),
  default: [],
});

const twiter_filter_creatorOps = atom({
  key: v4(),
  default: [],
});

const twiter_filter_creatorSelect = atom({
  key: v4(),
  default: [],
});

const twiter_filter_creatime = atom({
  key: v4(),
  default: null,
});

export {
  twiter_search_type,
  twiter_current_page,
  twiter_per_page,
  twiter_filter_sort,
  twiter_filter_search,
  twiter_filter_platsrDesOps,
  twiter_filter_platsrcOps,
  twiter_filter_platsrcSelect,
  twiter_filter_keywordDesOps,
  twiter_filter_keywordOps,
  twiter_filter_keywordSelect,
  twiter_filter_relatedPtnsDesOps,
  twiter_filter_relatedPtnsOps,
  twiter_filter_relatedPtnsSelect,
  twiter_filter_locationDesOps,
  twiter_filter_locationOps,
  twiter_filter_locationSelect,
  twiter_filter_postMin,
  twiter_filter_postMax,
  twiter_filter_followerMin,
  twiter_filter_followerMax,
  twiter_filter_likesMin,
  twiter_filter_likesMax,
  twiter_filter_listMin,
  twiter_filter_listMax,
  twiter_filter_creatorDesOps,
  twiter_filter_creatorOps,
  twiter_filter_creatorSelect,
  twiter_filter_creatime,
};
