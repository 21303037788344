import { Loading } from "@shopify/polaris";
import { useMemo } from "react";
import { useToggle } from "ahooks";

export const useTopLoading = () => {
  const [isTopLoading, { setLeft: hiddenTopLoading, setRight: showTopLoading }] = useToggle();

  const TopLoadingJSX = useMemo(() => isTopLoading ? <Loading /> : null, [isTopLoading]);

  return {
    // isTopLoading,
    TopLoadingJSX,
    hiddenTopLoading,
    showTopLoading,
  };
};
