import { CaretDownMinor, CaretUpMinor } from "@shopify/polaris-icons";
import { useEffect, useState } from "react";

import { Button } from "@shopify/polaris";
import { v4 } from "uuid";

export const RowTextList = ({ textList, len = 3 }) => {
  const [list, setList] = useState([]);
  const [renderList, setRenderList] = useState([]);

  useEffect(() => {
    setList(textList ?? []);

    const shortCut = textList?.slice(0, len);
    setRenderList(shortCut);
  }, [textList, len]);

  return (
    <div
      style={{ cursor: "text" }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {renderList?.map((s) => (
        <p key={v4()}>{s}</p>
      ))}
      {list?.length !== renderList?.length && list?.length > len && (
        <Button
          plain
          removeUnderline
          icon={CaretDownMinor}
          onClick={(e) => {
            e.stopPropagation();
            setRenderList([...list]);
          }}
        >
          Show all
        </Button>
      )}
      {list?.length === renderList?.length && list?.length > len && (
        <Button
          plain
          removeUnderline
          icon={CaretUpMinor}
          onClick={(e) => {
            e.stopPropagation();
            setRenderList([...list.slice(0, len)]);
          }}
        >
          Show less
        </Button>
      )}
    </div>
  );
};
