import "./style.scss";

import { Icon, Link, Text } from "@shopify/polaris";
import {
  ChevronLeftMinor,
  ChevronRightMinor,
  ExportMinor,
  ImportMinor,
  MobileCancelMajor,
  SettingsMajor,
  ShareMinor,
  StoreMinor
} from "@shopify/polaris-icons";
import { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";

import PageContainerAuxiliaryItemDetail from "@/container/PageContainer/AuxiliaryItemDetail";
import useWindowSize from "@/customHooks/useWindowSize";
import { useLocation } from "react-router-dom";
import CompanyDetail from "../AuxiliaryData/AuxiliaryDetail/CompanyDetail";
import ContactDetail from "../AuxiliaryData/AuxiliaryDetail/ContactDetail";
import CompanyItemDetail from "./../AuxiliaryData/AuxiliaryDetail/AuxiliaryItemDetail/CompanyItemDetail";
import ContactItemDetail from "./../AuxiliaryData/AuxiliaryDetail/AuxiliaryItemDetail/ContactItemDetail";
import PlatformItemDetail from "./../AuxiliaryData/AuxiliaryDetail/AuxiliaryItemDetail/PlatformItemDetail";
import { ExportList } from "./exportList/ExportList";
import { ImportList } from "./importList/ImportList";

//TODO - 0.9.4 系统设置新增的两个列表
const routesURL = {
  import: "/account/import",
  export: "/account/export",
};

export default function SystemModal() {
  const { width } = useWindowSize(); // 768是小屏的
  const history = useHistory();
  const location = useLocation();
  const [isBig, setIsBig] = useState(true);
  const [specificPage, setSpecificPage] = useState(location.pathname.length > 8);

  useEffect(() => {
    if (width <= 768) setIsBig(false);
    else setIsBig(true);
    if (location.pathname.length > 8) setSpecificPage(true);
    else setSpecificPage(false);
  }, [width, location]);

  let ScrenNav = (
    <nav>
      <div>
        <Text variant="headingLg" as="p">
          PMP
        </Text>
        <p>
          <Link url="/" external>
            pmp.fastgrowth.app
          </Link>
        </p>
      </div>
      <ul>
        <li>
          <div className={[location.pathname === "/account/company" ? "action" : ""].join(" ")}>
            <Link url="/account/company">
              <div>
                <div
                  className={[location.pathname === "/account/company" ? "action" : ""].join(" ")}
                ></div>
                <div>
                  <div>
                    <Icon
                      source={StoreMinor}
                      color={location.pathname === "/account/company" ? "success" : "base"}
                    />
                  </div>
                  <span>Companies</span>
                  <div>
                    <Icon
                      source={ChevronRightMinor}
                      color={location.pathname === "/account/company" ? "success" : "base"}
                    />
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </li>
        <li>
          <div className={[location.pathname === "/account/contact" ? "action" : ""].join(" ")}>
            <Link url="/account/contact">
              <div>
                <div
                  className={[location.pathname === "/account/contact" ? "action" : ""].join(" ")}
                ></div>
                <div>
                  <div>
                    <Icon
                      source={ShareMinor}
                      color={location.pathname === "/account/contact" ? "success" : "base"}
                    />
                  </div>
                  <span>Contact types</span>
                  <div>
                    <Icon
                      source={ChevronRightMinor}
                      color={location.pathname === "/account/contact" ? "success" : "base"}
                    />
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </li>
        <li>
          <div className={[location.pathname === routesURL.import ? "action" : ""].join(" ")}>
            <Link url={routesURL.import}>
              <div>
                <div
                  className={[location.pathname === routesURL.import ? "action" : ""].join(" ")}
                ></div>
                <div>
                  <div>
                    <Icon
                      source={ImportMinor}
                      color={location.pathname === routesURL.import ? "success" : "base"}
                    />
                  </div>
                  <span>Import activities</span>
                  <div>
                    <Icon
                      source={ChevronRightMinor}
                      color={location.pathname === routesURL.import ? "success" : "base"}
                    />
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </li>
        <li>
          <div className={[location.pathname === routesURL.export ? "action" : ""].join(" ")}>
            <Link url={routesURL.export}>
              <div>
                <div
                  className={[location.pathname === routesURL.export ? "action" : ""].join(" ")}
                ></div>
                <div>
                  <div>
                    <Icon
                      source={ExportMinor}
                      color={location.pathname === routesURL.export ? "success" : "base"}
                    />
                  </div>
                  <span>Export activities</span>
                  <div>
                    <Icon
                      source={ChevronRightMinor}
                      color={location.pathname === routesURL.export ? "success" : "base"}
                    />
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </li>
      </ul>
    </nav>
  );

  let topLeftShow = (
    <div className="system__modal__header__settings--inside">
      <div className="system__modal__header__settings--inside__icon">
        <Icon source={SettingsMajor} color="base" />
      </div>
      <p className="system__modal__header__settings--inside__text">System settings</p>
    </div>
  );
  if (specificPage && !isBig) {
    topLeftShow = (
      <Link url="/account" removeUnderline>
        <div className="system__modal__header__settings--inside">
          <div className="system__modal__header__settings--inside__icon">
            <Icon source={ChevronLeftMinor} color="success" />
          </div>
          <p
            className="system__modal__header__settings--inside__text"
            style={{ color: "var(--p-text-success)" }}
          >
            System settings
          </p>
        </div>
      </Link>
    );
  }

  return (
    <div className="system__modal">
      <div className="system__modal__header">
        <div className="system__modal__header__settings">{topLeftShow}</div>
        <div className="system__modal__header__close">
          {/* //ANCHOR - 系统设置整体右上角的叉叉图标 */}
          <Link onClick={() => history.replace('/dashboard')} removeUnderline>
            <Icon source={MobileCancelMajor} color="base" />
          </Link>
        </div>
      </div>
      <div className="system__modal__content">
        <div className="system__modal__content__body">
          <div
            className="system__modal__content__body__nav"
            style={{ display: specificPage && !isBig ? "none" : "inline-block" }}
          >
            {ScrenNav}
          </div>
          <div
            className="system__modal__content__body__content"
            style={{ display: specificPage || isBig ? "inline-block" : "none" }}
          >
            <Switch>
              <Route path="/account/detail/company/:ID" exact>
                <PageContainerAuxiliaryItemDetail>
                  <CompanyItemDetail />
                </PageContainerAuxiliaryItemDetail>
              </Route>
              <Route path="/account/detail/contact/:ID" exact>
                <PageContainerAuxiliaryItemDetail>
                  <ContactItemDetail />
                </PageContainerAuxiliaryItemDetail>
              </Route>
              <Route path="/account/detail/platform/:ID" exact>
                <PageContainerAuxiliaryItemDetail>
                  <PlatformItemDetail />
                </PageContainerAuxiliaryItemDetail>
              </Route>
              <Route path="/account/company" exact>
                <CompanyDetail />
              </Route>
              <Route path="/account/contact" exact>
                <ContactDetail />
              </Route>
              <Route path={routesURL.import} exact>
                <ImportList />
              </Route>
              <Route path={routesURL.export} exact>
                <ExportList />
              </Route>
              <Route path="/account">
                <CompanyDetail />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
