import { getKeyword, postCreateKeyword, putUpdateKeyword } from "@/api/request";
import { OtherInfo } from "@/components/EnhanceShopify/OtherInfo";
import { SelectPlus } from "@/components/EnhanceShopify/SelectPlus";
import { useTopLoading } from "@/customHooks/useTopLoading";
import { useBanner } from "@/hooks/useBanner";
import { BeforeUnload } from "@/hooks/useBeforeUnload";
import { s_to_ms } from "@/utils/accurateTimeTrans";
import { moment2Date } from "@/utils/moment2Date";
import { TopSaveBar } from "@/utils/TopSaveBar";
import { toJson } from "@/utils/toValue";
import { Card, Layout, Page, Select, TextField } from "@shopify/polaris";
import { useMount, useUpdateEffect } from "ahooks";
import { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

const statusOps = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

const platformOps = [{ label: "YouTube", value: "youtube" }];

export const KeywordSettings = () => {
  const history = useHistory();
  const { id } = useParams();
  const [isDirty, setIsDirty] = useState(false);

  const { TopLoadingJSX, showTopLoading, hiddenTopLoading } = useTopLoading();
  const { BannerJSX, showBanner, hiddenBanner } = useBanner();

  const [keywordInput, setKeywordInput] = useState("");
  const [selectStatus, setSelectStatus] = useState("active");
  const [selectPlatform, setSelectPlatform] = useState(["youtube"]);
  // Other information
  const [otherInfo, setOtherInfo] = useState({
    creatorAvatar: "",
    creator: "",
    createTime: "",
    updaterAvatar: "",
    updater: "",
    updateTime: "",
  });

  const cannotSave = useMemo(() => !keywordInput?.trim(), [keywordInput]);

  const [Data, setData] = useState({});

  const update = useMemo(() => {
    return {
      title: keywordInput.trim(),
      status: selectStatus,
      platform: selectPlatform,
    };
  }, [keywordInput, selectStatus, selectPlatform]);

  const backup = useMemo(() => {
    return {
      title: Data.title,
      status: Data.status,
      platform: Data.platform,
    };
  }, [Data]);

  useUpdateEffect(() => {
    // console.log("toJson(backup): ", toJson(backup));
    // console.log("toJson(update): ", toJson(update));

    if (toJson(backup) !== toJson(update)) {
      setIsDirty(true);
      hiddenBanner();
    } else {
      setIsDirty(false);
    }
  }, [update, backup]);

  const handleSetState = useCallback((data) => {
    setKeywordInput(data.title);
    setSelectStatus(data.status);
    setSelectPlatform(data.platform);

    setOtherInfo({
      creatorAvatar: data.creator.avatar,
      creator: data.creator.name,
      createTime: moment2Date(s_to_ms(data.created_at)),
      updaterAvatar: data.updater.avatar,
      updater: data.updater.name,
      updateTime: moment2Date(s_to_ms(data.updated_at)),
    });
  }, []);

  // 取消保存
  const handleDiscard = useCallback(() => {
    if (id) {
      handleSetState(Data);
    } else {
      history.goBack();
    }
  }, [id, history, Data, handleSetState]);
  // 保存
  const handleSave = useCallback(async () => {
    try {
      showTopLoading();
      if (!id) {
        await postCreateKeyword(update);
        history.goBack();
      } else {
        const { data } = await putUpdateKeyword(id, update);

        setData(data);
        handleSetState(data);
        showBanner({ title: "Changes Saved", status: "success" });
      }
    } catch (error) {
      showBanner({ title: "Changes Save failed", status: "critical" });
    } finally {
      hiddenTopLoading();
    }
  }, [id, history, update, handleSetState, showTopLoading, hiddenTopLoading, showBanner]);

  useMount(async () => {
    if (id) {
      showTopLoading();
      const { data } = await getKeyword(id);
      setData(data);
      handleSetState(data);

      setOtherInfo({
        creatorAvatar: data.creator.avatar,
        creator: data.creator.name,
        createTime: moment2Date(s_to_ms(data.created_at)),
        updaterAvatar: data.updater.avatar,
        updater: data.updater.name,
        updateTime: moment2Date(s_to_ms(data.updated_at)),
      });

      hiddenTopLoading();
    } else {
      setIsDirty(true);
    }
  });

  return (
    <BeforeUnload blockRoute={isDirty}>
      <Page
        title="Keyword settings"
        breadcrumbs={[
          {
            content: "keywords",
            onAction() {
              history.goBack();
            },
          },
        ]}
      >
        {TopLoadingJSX}
        {BannerJSX}
        <TopSaveBar
          isDirty={isDirty}
          handleSave={handleSave}
          handleDiscard={handleDiscard}
          disabled={cannotSave}
        />
        <Layout>
          <Layout.Section>
            <Card sectioned title="Basic information">
              <TextField
                label="Keyword"
                autoComplete="off"
                value={keywordInput}
                onChange={setKeywordInput}
                disabled={!!id}
              />
              <br />
              <Select
                label="Status"
                value={selectStatus}
                onChange={setSelectStatus}
                options={statusOps}
              />
              <br />
              <SelectPlus
                label="Platform"
                allowMultiple
                disabled
                options={platformOps}
                select={selectPlatform}
                onSelect={setSelectPlatform}
              />
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <OtherInfo
              title="Other information"
              creatorAvatar={otherInfo.creatorAvatar}
              creator={otherInfo.creator}
              createTime={otherInfo.createTime}
              updaterAvatar={otherInfo.updaterAvatar}
              updater={otherInfo.updater}
              updateTime={otherInfo.updateTime}
            />
          </Layout.Section>
        </Layout>
      </Page>
    </BeforeUnload>
  );
};
