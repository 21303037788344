import { useEffect } from "react";

/**
 * 根据 columns 映射 IndexTable 的 headings, 需要一个静态的参照数组 staticHeadings (由总 columns 映射生成的)
 * @param {Array} staticHeadings
 * @param {React State} columns
 * @param {React setState} setTableHeadings
 *
 */

export const useDynamicHeadings = ({ columns, staticHeadings, setTableHeadings }) => {
  useEffect(() => {
    const headings = staticHeadings.filter(({ title }) => columns.includes(title));
    setTableHeadings(headings);
  }, [columns, staticHeadings, setTableHeadings]);
};
