import styled from "styled-components";

export const ImgBox = styled.div`
  width: 174px;
  height: 130px;
`;

export const TitlleIcon = styled.div`
  display: inline-block;
  width: 28px;
  height: 28px;
`;
