import { getTenantPermissionCode } from "@/api/api_tenant";
import { cookieScope } from "@/utils/cookieScope";
import { atom } from "jotai";
import { loadable } from "jotai/utils";

const authCodes = [
  "pmp/home/home/all",

  "pmp/resources/discovery/export",
  "pmp/resources/discovery/import",
  "pmp/resources/discovery/read",
  "pmp/resources/discovery/write",

  "pmp/resources/collections/read",
  "pmp/resources/collections/write",

  "pmp/apps/keyword_extractor/all",
  "pmp/apps/media_contact_extractor/all",
  "pmp/apps/social_link_extractor/all",

  "pmp/workflows/campaigns/read",
  "pmp/workflows/campaigns/write",

  "pmp/workflows/cooperations/export",
  "pmp/workflows/cooperations/read",
  "pmp/workflows/cooperations/write",

  "pmp/workflows/budgets/read",
  "pmp/workflows/budgets/submit",
  "pmp/workflows/budgets/write",

  "pmp/workflows/deliverys/export",
  "pmp/workflows/deliverys/read",
  "pmp/workflows/deliverys/write",

  "pmp/workflows/promotions/export",
  "pmp/workflows/promotions/read",
  "pmp/workflows/promotions/write",

  "pmp/workflows/calendar/all",

  "pmp/workflows/teams/read",
  "pmp/workflows/teams/write",

  "pmp/settings/companys/all",
  "pmp/settings/contact_types/all",
  "pmp/settings/import_export/read",
  "pmp/settings/import_export/download",

  "pmp/settings/admin/all",
];

export const authAtom = atom(async () => {
  const id = cookieScope.getTenantId();
  const { codes } = await getTenantPermissionCode({
    tenantId: id,
    codes: authCodes.toString(),
  });

  return codes;
});

export const loadableAuthAtom = loadable(authAtom);
