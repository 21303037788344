import { Banner } from "@shopify/polaris";
import { useToggle } from "ahooks";

export const useBanner = ({
  content = "successfully",
  status = "success", // success,warning,critical
}) => {
  const [active, { toggle: toggleBanner, setRight: setBannerShow, setLeft: setBannerHidden }] =
    useToggle();

  const BannerJSX = active ? 
    <>
      <Banner
        title={`The current changes were saved ${content}.`}
        status={status}
        onDismiss={toggleBanner}
      />
      <br />
    </>
   : null;

  return {
    BannerJSX,
    setBannerShow,
    setBannerHidden,
  };
};
