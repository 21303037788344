export const genderOptions = [
  // { label: "", value: "0" },
  { label: "Male", value: "1" },
  { label: "Female", value: "2" },
];

export const TierOptions = [
  // { label: "", value: "" },
  { label: "A", value: "1" },
  { label: "B", value: "2" },
  { label: "C", value: "3" },
  { label: "D", value: "4" },
  { label: "E", value: "5" },
];

export const blacklistOptions = [
  { label: "Unblock", value: "0" },
  { label: "Block", value: "1" },
];

export const ContactStatusOptions = [
  { label: "Not contacted", value: "0" },
  { label: "In contact", value: "1" },
  { label: "Rejected", value: "2" },
  { label: "Accepted", value: "3" },
];
