import { Button, ButtonGroup, Stack, Text, TextField } from "@shopify/polaris";
import { useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { linkEditing_atom, link_atom } from "./atom";

import { ButtonLink } from "@/components/EnhanceShopify/ButtonLink";
import { SpaceHeight } from "@/components/EnhanceShopify/SpaceHeight";
import styled from "styled-components";
import { v4 } from "uuid";

const titles = [
  // "Platform",
  "Social link",
  "Followers",
  "Operation",
];

export const SocialLink = () => {
  const [socialLinks, setSocialLinks] = useRecoilState(link_atom);
  const isAddOrEdit = useRecoilValue(linkEditing_atom);

  const handleAdd = () => {
    setSocialLinks((arr) => {
      return [
        ...arr,
        {
          // platform: "",
          link: "",
          fans: "",
          isEdit: true,
          isNew: true,
        },
      ];
    });
  };

  return (
    <>
      <Table>
        <Header>
          <Row className="header">
            {titles.map((head) => {
              return (
                <Cell key={v4()}>
                  <Text variant="bodyMd" as="span" fontWeight="bold">
                    {head}
                  </Text>
                </Cell>
              );
            })}
          </Row>
        </Header>
        <Body>
          {socialLinks.map(({ platform, link, fans, isEdit }, index) => {
            if (isEdit)
              return (
                // <EditMode key={v4()} platform={platform} link={link} fans={fans} index={index} />
                <EditMode key={v4()} link={link} fans={fans} index={index} />
              );
            else
              return (
                // <TextMode key={v4()} platform={platform} link={link} fans={fans} index={index} />
                <TextMode key={v4()} link={link} fans={fans} index={index} />
              );
          })}
        </Body>
      </Table>
      <div style={{ height: 8 }} />
      <Stack distribution="">
        <Button plain disabled={isAddOrEdit} onClick={handleAdd}>
          Add social link
        </Button>
      </Stack>
    </>
  );
};

function TextMode({ platform, link, fans, index }) {
  // const [socailLinkTypes] = useRecoilState(SociallinkTypes_atom);
  const [, setSocialLinks] = useRecoilState(link_atom);

  const isAddOrEdit = useRecoilValue(linkEditing_atom);

  // const matchPlatform = (platform) => {
  //   const hasValue = socailLinkTypes.map(({ value }) => value).includes(platform);
  //   if (hasValue) {
  //     return socailLinkTypes.filter(({ value }) => value === platform)[0].label;
  //   } else {
  //     return "";
  //   }
  // };

  const handleEdit = (index) => () => {
    setSocialLinks((arr) => {
      const newArr = [...arr].map((o, i) => {
        if (i === index) {
          return {
            ...o,
            isEdit: true,
          };
        }
        return o;
      });
      return newArr;
    });
  };
  const handleDelete = (index) => () => {
    setSocialLinks((arr) => {
      const newArr = [...arr].filter((o, i) => {
        if (i !== index) {
          return true;
        }
        return false;
      });
      return newArr;
    });
  };
  return (
    <Row>
      {/* <Cell className="col-1">{matchPlatform(platform)}</Cell> */}
      <Cell className="col-2">
        <ButtonLink link={link} len={48}>
          {link}
        </ButtonLink>
      </Cell>
      <Cell className="col-3">{fans}</Cell>
      <Cell>
        <ButtonGroup segmented>
          <Button plain disabled={isAddOrEdit} onClick={handleEdit(index)}>
            Edit
          </Button>
          <Button plain disabled={isAddOrEdit} onClick={handleDelete(index)}>
            delete
          </Button>
        </ButtonGroup>
      </Cell>
    </Row>
  );
}

function EditMode({ platform, link, fans, index }) {
  // const [socailLinkTypes] = useRecoilState(SociallinkTypes_atom);
  const [, setSocialLinks] = useRecoilState(link_atom);
  // const [selectPlatform, setSelectPlatform] = useState(platform ? [platform] : []);

  const [linkInput, setLinkInput] = useState(link ? link : "");
  const [fansInput, setFansInput] = useState(fans ? fans : "");

  // const [typeError, setTypeError] = useState("");
  const [linkError, setLinkError] = useState("");
  const [fansError, setFansError] = useState("");

  // 是否存在校验错误信息
  const hasErr = useMemo(
    () =>
      // !!typeError ||
      !!linkError || !!fansError,
    [linkError, fansError]
  );

  // 撑开每个 td 的间隔
  const SpaceH = <SpaceHeight height={28} />;

  // 取消
  const handleCancel = () => {
    setSocialLinks((arr) => {
      const newArr = arr.filter((o) => !o.isNew);

      return newArr.map((o) => ({
        ...o,
        isEdit: false,
      }));
    });
  };

  // 保存
  const handleSave = (index) => () => {
    // if (!selectPlatform.toString()) {
    //   setTypeError("Required!");
    // }
    if (!linkInput?.trim()) {
      setLinkError("Required!");
    }
    if (!fansInput?.trim()) {
      setFansError("Required!");
    }

    if (
      // !selectPlatform.toString() ||
      !linkInput?.trim() ||
      !fansInput?.trim()
    )
      return;

    const updateobj = {
      // platform: selectPlatform.toString(),
      link: linkInput,
      fans: fansInput,
      isEdit: false,
      isNew: false,
    };
    setSocialLinks((arr) => {
      const newArr = [...arr].map((o, i) => {
        if (i === index) {
          return updateobj;
        }
        return o;
      });
      return newArr;
    });
  };

  return (
    <Row>
      {/* <Cell className="col-1">
        <SelectPlus
          options={socailLinkTypes}
          select={selectPlatform}
          onSelect={setSelectPlatform}
          error={typeError}
          onBlur={() => {
            if (selectPlatform.toString()) {
              setTypeError("");
            }
          }}
        />
        {hasErr && SpaceH}
      </Cell> */}
      <Cell className="col-2">
        <TextField
          autoComplete="off"
          value={linkInput}
          onChange={setLinkInput}
          error={linkError}
          onBlur={() => {
            if (linkInput?.trim()) {
              setLinkError("");
            }
          }}
        />
        {hasErr && SpaceH}
      </Cell>
      <Cell className="col-3">
        <TextField
          autoComplete="off"
          value={fansInput}
          onChange={setFansInput}
          error={fansError}
          onBlur={() => {
            if (fansInput?.trim()) {
              setFansError("");
            }
          }}
        />
        {hasErr && SpaceH}
      </Cell>
      <Cell>
        <ButtonGroup segmented>
          <Button plain onClick={handleSave(index)}>
            Save
          </Button>
          <Button plain onClick={handleCancel}>
            Cancel
          </Button>
        </ButtonGroup>
      </Cell>
    </Row>
  );
}

const Table = styled.table`
  width: 100%;
`;

const Header = styled.thead`
  border-bottom: 1px solid #f0f0f0;
`;

const Body = styled.tbody``;

const Row = styled.tr`
  border-bottom: 1px solid #f0f0f0;
  &:not(.header):hover {
    background-color: rgba(241, 242, 243, 1);
  }
  transition: all 0.2s;
`;

const Cell = styled.td`
  padding: 8px 4px;
  position: relative;
  .Polaris-Labelled__Error {
    position: absolute;
  }
  /* &.col-1 {
    width: 30%;
  } */
  &.col-2 {
    width: 70%;
  }
  &.col-3 {
    width: 20%;
  }
`;
