import { Stack, Text } from "@shopify/polaris";

import { Avatar } from "antd";

export function TabProp({ avatar, type, name }) {
  return (
    <Stack spacing="tight" alignment="center" wrap={false}>
      <Stack.Item>
        <Avatar src={avatar} />
      </Stack.Item>
      <Stack.Item>
        <Text>
          {type}
          <Text variant="bodySm" as="p">
            {name}
          </Text>
        </Text>
      </Stack.Item>
    </Stack>
  );
}
