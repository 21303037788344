import { getTenantInfo } from '@/api/api_tenant'
import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

export const userAtom = atom(async () => {
  // 用户信息请求
  try {
    const data = await getTenantInfo()
    return data
  } catch (error) {
    return error
  }
})

export const loadableUserAtom = loadable(userAtom)
