import { Button, Stack, TextContainer } from "@shopify/polaris";
import { useState } from "react";

import { TextClamp } from "@/components/EnhanceShopify/TextClamp";
import { useEffect } from "react";

export const LongTextCollect = ({ longText = "" }) => {
  const [text, setText] = useState("");
  const [flag, setFlag] = useState(true);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setText(longText);
    // 三行文字 length 大概是 110
  }, [longText]);

  useEffect(() => {
    if (text?.length > 110) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [text]);

  return (
    <>
      {!!text && (
        <>
          <TextContainer>
            <span style={{ color: "rgba(109, 113, 117, 1)", wordBreak: "break-word" }}>
              <TextClamp clamp={flag ? 3 : null}>{text}</TextClamp>
            </span>
          </TextContainer>
          <div style={{ height: 8 }}></div>
        </>
      )}
      <Stack distribution="center">
        {show && (
          <Button plain disclosure={flag ? "down" : "up"} onClick={() => setFlag((b) => !b)}>
            {flag ? "Show all" : "Show less"}
          </Button>
        )}
      </Stack>
    </>
  );
};
