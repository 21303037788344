const CheckBox = ["Role", "Email", "Phone", "Country/region", "Media outlet", "Social link", "Tag"];
const columsOptions = CheckBox.map((str) => ({
  label: str,
  value: str,
}));
columsOptions.unshift({
  label: "Media contact name",
  value: "Media contact name",
  disabled: true,
});
columsOptions.push({
  label: "Operation",
  value: "Operation",
  disabled: true,
});
const table_headings = columsOptions.map(({ value }) => ({ title: value }));

export { columsOptions, table_headings };
