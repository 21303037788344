import { useState, useCallback } from "react";

// 用于select 组件选择逻辑
export function useSelectLogic(options) {
  // shopify文档里面的select配置项中, options必须是[ {label:'xx',value:'xxx'}, ... ]
  // shopify 内部做了判断, value属性值需是 String 类型

  const [selected, setSelected] = useState(options[0]?.value ?? ""); //

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  return {
    selected,
    setSelected,
    handleSelectChange,
  };
}
