import { EmptySearchResult } from "@shopify/polaris";
const memberIndexTableName = {
  singular: "member",
  plural: "members",
};
// Sort
const memberSortOptions = [
  // { label: "New partners (Min-Max)", value: "partners|asc" },
  // { label: "New partners (Min-Max)", value: "partners|desc" },
  { label: "Partners reached out (Min-Max)", value: "partners_reached_out|asc" },
  { label: "Partners reached out (Max-Min)", value: "partners_reached_out|desc" },
  { label: "Partners on board (Min-Max)", value: "partners_on_board|asc" },
  { label: "Partners on board (Max-Min)", value: "partners_on_board|desc" },
  { label: "Response rate (Min-Max)", value: "respone_rate|asc" },
  { label: "Response rate (Max-Min)", value: "respone_rate|desc" },
];
// index Table Searh_emptyStatus
const memberEmptyStateMarkup = 
  <EmptySearchResult
    title={"No member found"}
    description={"Try changing the filters or search term"}
    withIllustration
  />
;
const allowCheckOptions = [
  // "New partners",
  "User code",
  "Partners reached out",
  "Partners on board",
  "Response rate",
];
const memberColumnsOptions = allowCheckOptions.map((str) => ({
  label: str,
  value: str,
}));
memberColumnsOptions.unshift({
  label: "User name",
  value: "User name",
  disabled: true,
});

const member_headings = memberColumnsOptions.map(({ value }) => ({ title: value }));

export {
  memberIndexTableName,
  memberSortOptions,
  memberEmptyStateMarkup,
  memberColumnsOptions,
  member_headings,
};
