import { getMediaContact, getPlatformReport } from "@/api/request";
import profiles_icon from "@/assets/svg/Profile.svg";
import ins_icon from "@/assets/svg/platform/PMP-Instagram.svg";
import titok_icon from "@/assets/svg/platform/PMP-TikTok.svg";
import twitch_icon from "@/assets/svg/platform/PMP-Twitch.svg";
import twitter_icon from "@/assets/svg/platform/PMP-Twitter.svg";
import youtube_icon from "@/assets/svg/platform/PMP-YouTube.svg";
import { AntTabs } from "@/components/AntToShopify/AntTabs";
import { TabProp } from "@/components/AntToShopify/TabProp";
import { SketelonLoading } from "@/components/EnhanceShopify/SketelonLoading";
import { loadableAuthAtom } from "@/global/authAtom";
import { Instagram } from "@/pages/Partner/PartnerReport/Instagram";
import { TikTok } from "@/pages/Partner/PartnerReport/TikTok/TikTok";
import { Twitch } from "@/pages/Partner/PartnerReport/Twitch/Twitch";
import { Twitter } from "@/pages/Partner/PartnerReport/Twitter";
import { YouTuBe } from "@/pages/Partner/PartnerReport/Youtube/YouTuBe";
import { BlackListBadge } from "@/pages/Partner/Profiles/Common/BlackListBadge";
import { ContactStatus } from "@/pages/Partner/Profiles/Common/ContactStatus";
import { s_to_ms } from "@/utils/accurateTimeTrans";
import { filterPlatformList } from "@/utils/filterPlatformList";
import { moment2Date } from "@/utils/moment2Date";
import { Page } from "@shopify/polaris";
import { EditMinor } from "@shopify/polaris-icons";
import { useMount, useToggle } from "ahooks";
import { Tabs } from "antd";
import { useAtom } from "jotai";
import { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AddToCampaign } from "../Common/AddToCampaign";
import { AddToCollection } from "../Common/AddToCollection";
import ContactBoard from "./ContactBoard";

function MatchTabIcon(type) {
  switch (type) {
    case "TikTok":
      return titok_icon;
    case "YouTube":
      return youtube_icon;
    case "Twitch":
      return twitch_icon;
    case "Instagram":
      return ins_icon;
    case "Twitter":
      return twitter_icon;
    default:
      return null;
  }
}

export const TabEntry = () => {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      READ: permission.data?.["pmp/resources/discovery/read"],
      WRITE: permission.data?.["pmp/resources/discovery/write"],
      IMPORT: permission.data?.["pmp/resources/discovery/import"],
      EXPORT: permission.data?.["pmp/resources/discovery/export"],
    }),
    [permission]
  );
  const { id } = useParams();
  const history = useHistory();

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [contactInfo, setContactInfo] = useState({});
  const [platformList, setPlatformList] = useState([]); //总表包含[ { TikTok } , { YouTube } ]
  const [updateTime, setUpdateTime] = useState("");
  const [updateBy, setUpdateBy] = useState("");

  const [contactStatus, setContactStatus] = useState(0);
  const [isBlack, setIsBlack] = useState(0);

  function MatchPlatform(platform) {
    switch (platform.type) {
      case "YouTube":
        return (
          <YouTuBe
            selectPlatform={platform}
            setUpdateTime={setUpdateTime}
            setUpdateBy={setUpdateBy}
          />
        );
      case "Instagram":
        return (
          <Instagram
            selectPlatform={platform}
            setUpdateTime={setUpdateTime}
            setUpdateBy={setUpdateBy}
          />
        );
      case "TikTok":
        return (
          <TikTok
            selectPlatform={platform}
            setUpdateTime={setUpdateTime}
            setUpdateBy={setUpdateBy}
          />
        );
      case "Twitch":
        return (
          <Twitch
            selectPlatform={platform}
            setUpdateTime={setUpdateTime}
            setUpdateBy={setUpdateBy}
          />
        );
      case "Twitter":
        return (
          <Twitter
            selectPlatform={platform}
            setUpdateTime={setUpdateTime}
            setUpdateBy={setUpdateBy}
          />
        );
      default:
        return null;
    }
  }

  const [add2CampaignModalActive, { toggle: toggleadd2CampaignModal }] = useToggle();
  const [add2CollectionModalActive, { toggle: toggleadd2CollectionModal }] = useToggle();

  useMount(async () => {
    setIsPageLoading(true);

    const { data } = await getMediaContact(id);
    setContactInfo(data);
    // 平台页签对应不同的页面
    const platform = await getPlatformReport(id);

    const paltformAllList = filterPlatformList(platform);
    setPlatformList(paltformAllList);
    setIsPageLoading(false);
  });

  return (
    <SketelonLoading loading={isPageLoading}>
      <Page
        fullWidth
        title="Media contact details"
        subtitle={updateTime ? `${moment2Date(s_to_ms(updateTime))} update by ${updateBy}` : null}
        secondaryActions={
          !access.WRITE
            ? undefined
            : [
                {
                  content: "Edit basic profile",
                  icon: EditMinor,
                  onAction: () => history.push(`/contacts/detail/${id}`),
                },
                {
                  content: "Add to collection",
                  onAction: toggleadd2CollectionModal,
                },
                {
                  content: "Add to campaign",
                  onAction: toggleadd2CampaignModal,
                },
              ]
        }
        titleMetadata={
          <>
            <ContactStatus contact_status={contactStatus} />
            &nbsp;
            <BlackListBadge isBlack={isBlack} />
          </>
        }
      >
        <AddToCampaign
          active={add2CampaignModalActive}
          handleChange={toggleadd2CampaignModal}
          handleSave={() => {}}
          contactIDs={[id]}
        />
        <AddToCollection
          active={add2CollectionModalActive}
          handleChange={toggleadd2CollectionModal}
          handleSave={() => {}}
          contactIDs={[id]}
        />
        <AntTabs>
          <Tabs size="middle" type="card" destroyInactiveTabPane={true}>
            <Tabs.TabPane
              tab={
                <TabProp
                  type="Basic profile"
                  name={contactInfo?.first_name ?? ""}
                  avatar={profiles_icon}
                />
              }
              key="general"
            >
              {!!contactInfo && (
                <ContactBoard
                  data={contactInfo}
                  setUpdateTime={setUpdateTime}
                  setUpdateBy={setUpdateBy}
                  setContactStatus={setContactStatus}
                  setIsBlack={setIsBlack}
                />
              )}
            </Tabs.TabPane>
            {platformList.map((platform) => {
              return (
                <Tabs.TabPane
                  tab={
                    <TabProp
                      type={platform.type}
                      name={platform?.options?.username ?? ""}
                      avatar={MatchTabIcon(platform.type)}
                    />
                  }
                  key={String(platform.platform_id)}
                >
                  {MatchPlatform(platform)}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </AntTabs>
      </Page>
    </SketelonLoading>
  );
};
