export const filterPlatformList = (platform, filter = false) => {
  if (filter) {
    return platform.data.list.filter(
      ({ id, type }) =>
        (type === "YouTube" ||
          type === "Instagram" ||
          type === "TikTok" ||
          type === "Twitch" ||
          type === "Twitter") &&
        id
    );
  } else {
    return platform.data.list.filter(
      // eslint-disable-next-line
      ({ id, type }) =>
        type === "YouTube" ||
        type === "Instagram" ||
        type === "TikTok" ||
        type === "Twitch" ||
        type === "Twitter"
    );
  }
};
