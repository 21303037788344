import { fetchDel, fetchGet, fetchGetBlob, fetchPost, fetchPut } from "../commons/require";
// 本地测试的url
const LocalURL = false ? `http://192.168.9.150:59179/api/` : "";

// 用户登出
export const postLogOut = () => fetchPost("v1/logout");

// 获取伙伴过滤条件
export const getPartnersFilterInit = () => fetchGet("v1/partners/init");
// 导出筛选的伙伴
export const exportPartnerFile = (data) => fetchPost("v1/partners/export", data);
// 导入伙伴
export const postImportPartners = (data) =>
  fetchPost("v1/partners/import", { formData: data, need: true });

// 获取价格类型列表
export const getPartnerPriceList = () => fetchGet("v1/price-types");

// 获取货币列表信息
export const getFiltersCurrency = () => fetchGet("v1/currencys");

// 获取国家列表信息
export const getFiltersCountrys = () => fetchGet("v1/countrys");

// 获取公司列表信息
export const getPartnerCompanys = (data = {}) => fetchGet("v1/companys", data);

// 获取单个公司详情
export const getPartnerCompanysById = (id) => fetchGet(`v1/companys/${id}`);

// 获取平台类型信息
export const getPartnerPlatform = (data) => fetchGet("v1/platform-types", data);

// 获取平台类型详情信息
export const getPartnerPlatformByID = (ID) => fetchGet(`v1/platform-types/${ID}`);

export const getPartnerTagsByType = (type) => fetchGet(`v1/tags?type=${type}`);
// 获取 principle 列表
export const getPartnerPrincipalOptions = () => fetchGet("v1/me/list");
// 获取分组列表
export const getPartnerGroups = (param) => fetchGet("v1/groups", param);
// 获取伙伴列表
export const getPartners = (data) => fetchGet(`${LocalURL}v1/partners`, { ...data });

// 创建伙伴
export const postAddNewPartner = (data) => fetchPost("v1/partners", data);

// 获取分组详情
export const getPartnerGroupDetail = (groupId) => fetchGet(`v1/groups/${groupId}`);

// 创建伙伴分组
export const postPartnerGroup = (data) => fetchPost("v1/groups", data);

// 编辑伙伴分组
export const putPartnerGroup = (id, data) => fetchPut(`v1/groups/${id}`, data);

export const getPartnerUserInfo = (userId) => fetchGet(`v1/partners/${userId}`);

export const getPartnerLanguagesOptions = () => fetchGet("v1/languages");

// 获取联系渠道类型列表
export const getPartnerContactTypes = (data) => fetchGet("v1/contact-types", { ...data });

// 获取联系渠道类型详情
export const getPartnerContactTypesByID = (id) => fetchGet(`v1/contact-types/${id}`);

export const deletePartnerUser = (userId) => fetchDel(`v1/partners/${userId}`);

export const deletePartnerAttachments = (userId, attachmentId) =>
  fetchDel(`v1/partners/${userId}/attachments/${attachmentId}`);

// 批量删除附件
export const deletePartnerManyAttachments = (ids) =>
  fetchDel("v1/attachments/destroy_many", { ids });

export const deletePartnerContact = (userId, contactId) =>
  fetchDel(`v1/partners/${userId}/contacts/${contactId}`);

export const deletePartnerUserTags = (userId, tags) =>
  fetchDel(`v1/partners/${userId}/tags`, { tag_ids: [tags] });

export const deletePartnerUserPlatform = (userId, platformId) =>
  fetchDel(`v1/partners/${userId}/platforms/${platformId}`);

export const postUserAttachment = (userId, data) =>
  fetchPost(`v1/partners/${userId}/attachments`, { formData: data, need: true });

export const postCommonAttachment = (data) =>
  fetchPost("v1/attachments", { formData: data, need: true });

export const postPartnerUserTag = (userId, tags) =>
  fetchPost(`v1/partners/${userId}/tags`, { tags: [tags] });

export const postPartnerUserNewTag = (userId, tags) =>
  fetchPost("v1/partners-tags", {
    tags: [tags],
    partner_ids: [userId],
  });

export const postPartnerUserNewManyTag = (userId, tags) =>
  fetchPost("v1/partners-tags", {
    tags: tags,
    partner_ids: [userId],
  });

// 创建伙伴分组关联
export const postPartnerToGroup = (groupIds, partner_ids) =>
  fetchPost("v1/partners-groups", { group_ids: [groupIds], partner_ids });
// 分离伙伴与分组
export const deletePartnerToGroup = (group_ids, partner_ids) =>
  fetchDel("v1/partners-groups", { group_ids, partner_ids });

// 创建联系渠道类型
export const postPartnerContactType = (title) => fetchPost("v1/contact-types", { title });

// 修改联系渠道类型
export const putPartnerContactType = (ID, data) => fetchPut(`v1/contact-types/${ID}`, data);

// 批量删除联系渠道类型
export const deletePartnerContactType = (ids) => fetchDel("v1/contact-types/destroy_many", { ids });

export const postPartnerUserContact = (userId, typeId, account) =>
  fetchPost(`v1/partners/${userId}/contacts`, {
    type_id: typeId,
    account,
  });

// 创建公司
export const postPartnerUserCompany = (data) => fetchPost("v1/companys", data);

// 添加一个平台
export const postPartnerUserPlatform = (data) => fetchPost("v1/platform-types", data);

// 删除平台类型
export const deletePartnerUserPlatformMany = (ids) =>
  fetchDel("v1/platform-types/destroy_many", { ids });

// 修改平台类型信息
export const putPartnerUserPlatformTitle = (ID, data) => fetchPut(`v1/platform-types/${ID}`, data);

// 添加一个用户所属平台
export const postPartnerAddUserPlatform = (userId, data) =>
  fetchPost(`v1/partners/${userId}/platforms`, data);

export const postCompanyInfo = (companyName) => fetchPost("v1/companys", { name: companyName });

// 编辑上传用户头像
export const postPartnerUserAvatar = (userId, data) =>
  fetchPost(`v1/partners/${userId}/avatar`, { formData: data, need: true });

// 公共接口: 新增 -> 上传用户头像 -> 文件服务器 -> 头像
export const postCommonAvatar = (data) => {
  return fetchPost(`v1/avatar`, { formData: data, need: true });
};

// 删除伙伴头像
export const deletePartnerUserAvatar = (userId) => fetchDel(`v1/partners/${userId}/avatar`);

export const postPartnerRemark = (userId, remark) => {
  return fetchPost("v1/partners/" + userId + "/events", { content: remark });
};
// 编辑伙伴
export const putPartnerUserInfo = (userId, data) => {
  return fetchPut(`v1/partners/${userId}`, data);
};

export const putPartnerUserContactsInfo = (userId, contactId, typeId, account) =>
  fetchPut(`v1/partners/${userId}/contacts/${contactId}`, {
    type_id: typeId,
    account,
  });

// 修改公司信息
export const putPartnerCompanyInfo = (companyId, data) =>
  fetchPut(`v1/companys/${companyId}`, data);

export const putPartnerUserToCompany = (userId, companyId) =>
  fetchPut(`v1/partners/${userId}/company`, { company_id: companyId });

// 修改公司地址信息
export const putCompanyInfoAddress = (companyId, data) =>
  fetchPut(`v1/companys/${companyId}/address`, data);

export const putPartnerUserGroup = (userId, groups) =>
  fetchPut(`v1/partners/${userId}/groups`, { group_ids: groups });

// AcuntSettings

// 用户详情
export const getAccountDetails = () => fetchGet("v1/me");
// 修改用户详情
export const putAccountDetails = (data) => fetchPut("v1/me", data);
// department 列表
export const getDepartmentList = (params) => fetchGet("v1/departments", params);
// 上传用户头像
export const postAcountAvatar = (data) => fetchPost("v1/me/avatar", { formData: data, need: true });
// 删除用户头像
export const deleteAccountAvatar = () => fetchDel("v1/me/avatar");
export const putPartnerUserPlatform = (userId, platformId, data) =>
  fetchPut(`v1/partners/${userId}/platforms/${platformId}`, data);

export const putPartnerUserTagType = (userId, tags) =>
  fetchPut(`v1/partners/${userId}/tags`, { tag_ids: tags });

// Campagin

// 活动列表
export const getCampaignList = (data) => fetchGet("v1/campaigns", { ...data });
// 活动详情
export const getCampaignDetail = (id) => fetchGet(`v1/campaigns/${id}`);
// 创建活动
export const postCampaignCreate = (data) => fetchPost("v1/campaigns", data);
// 修改活动
export const putCampaignUpdate = (id, data) => fetchPut(`v1/campaigns/${id}`, data);
// 单个删除活动
export const deleteCampaignSingle = (id) => fetchDel(`v1/campaigns/${id}`);
// 批量删除活动
export const deleteCampaignMultiple = (ids) => fetchDel("v1/campaigns/destroy_many", { ids });
// image
// 上传图片: 批量 直接在 formData 实例添加一个文件对象数组
export const postUploadImages = (data) => fetchPost("v1/images", { formData: data, need: true });
// 删除已上传的单个图片
export const deleteSingleImage = (imageID) => fetchDel(`v1/images/${imageID}`);
// 删除已上传的多个图片
export const deleteMultipleImages = (ids) => fetchDel("v1/images/destroy_many", { ids });
// 编辑模式上传图片
export const postEditUploadImages = (campaignID, data) =>
  fetchPost(`v1/products/${campaignID}/images`, { formData: data, need: true });
// 当前活动下合作重复性校验
export const getSameCooperationIDs = (campaignID) =>
  fetchGet(`v1/campaigns/${campaignID}/partners`, { cooperation_status: [1, 2] });
// Cooperations

// 活动下的合作人列表
export const getCurrentCooperations = (params) => fetchGet("v1/cooperations", { ...params });
// 批量删除合作
export const deleteCooperations = (ids) => fetchDel("v1/cooperations/destroy_many", { ids });
// 合作人详情
export const getCooperationDetail = (cooperID) => fetchGet(`v1/cooperations/${cooperID}`);
// 创建合作
export const postCooperationCreate = (data) => fetchPost("v1/cooperations", data);
// 价格类型
export const getPriceTypeOptions = () => fetchGet("v1/price-types");
// 修改合作
export const putCooperationUpdate = (cooperID, data) =>
  fetchPut(`v1/cooperations/${cooperID}`, data);
// 批量删除合作
export const deleteMultiCooperations = (ids) => fetchDel("v1/cooperations/destroy_many", { ids });
// 新增模式 -- 上传附件
export const postUpLoadAttachments = (data) =>
  fetchPost("v1/attachments", { formData: data, need: true });
// 编辑模式 -- 上传附件
export const postEditUpLoadAttachments = (cooperID, data) =>
  fetchPost(`v1/cooperations/${cooperID}/attachments`, { formData: data, need: true });
// 批量删除附件
export const deleteMultiAttachments = (ids) => fetchDel("v1/attachments/destroy_many", { ids });

// 批量删除公司
export const deletePartnerCompanyMany = (ids) => fetchDel("v1/companys/destroy_many", { ids });

// Payment

// get 支付申请列表
export const getPaymentOrdersList = (params) => fetchGet("v1/payment_applications", { ...params });
// delete 多个支付申请
export const deletePaymentOrder = (ids) =>
  fetchDel("v1/payment_applications/destroy_many", { ids });
// import 导入支付申请列表
export const psotImportPayment = (data) =>
  fetchPost("v1/payment_applications/import", { formData: data, need: true });
// export 导出选中的支付申请
export const postExportPayments = (payment_application_ids) =>
  fetchPost("v1/payment_applications/export-pdf?file_type=pdf&package_type=zip", {
    payment_application_ids,
  });
// submit 批量提交到企业微信审核
export const postSubmitApproval = (application_ids) =>
  fetchPost("v1/payment_applications/approvals", { application_ids });

// payment detail

// get 支付申请详情
export const getPaymentOrderDetail = (OrderID) => fetchGet(`v1/payment_applications/${OrderID}`);
// create 创建支付申请
export const postCreatePaymentOrder = (data) => fetchPost("v1/payment_applications", data);
// update 修改支付申请
export const putUpdatePaymentOrder = (OrderID, data) =>
  fetchPut(`v1/payment_applications/${OrderID}`, data);
// 支付申请详情中的选项列表
export const getPaymentOrdersConfigs = () => fetchGet("v1/payment_applications/configs");
// timeline
export const postPaymentDetailEvents = (OrderID, string) =>
  fetchPost(`v1/payment_applications/${OrderID}/events`, { content: string });
// 上传支付详情中的文件
export const postEditUploadPaymentAttachments = (OrderID, data) =>
  fetchPost(`v1/payment_applications/${OrderID}/attachments`, {
    formData: data,
    need: true,
  });
// 删除订单详情的附件
export const deletePaymentAttachments = (OrderID, ids) =>
  fetchDel(`v1/payment_applications/${OrderID}/attachments/destroy_many`, { ids });

//  辅助资料管理
export const getAuxiliaryDate = (data = {}) => fetchGet("v1/auxiliary_data", data);

// *************** 0.4.0 promotion ***************
export const postCreatePromotion = (data) => fetchPost("v1/promotions", data);
export const putUpdatePromotion = (PromotionID, data) =>
  fetchPut(`v1/promotions/${PromotionID}`, data);
export const deletePromotions = (ids) => fetchDel("v1/promotions/destroy_many", { ids });
export const getPromotionDetails = (PromotionID) => fetchGet(`v1/promotions/${PromotionID}`);
export const getPromotionConfigs = () => fetchGet("v1/promotions-configs");
export const getPromotions = (params) => fetchGet("v1/promotions", params);
export const postUploadPromotionImages = (PromotionID, data) =>
  fetchPost(`v1/promotions/${PromotionID}/images`, {
    formData: data,
    need: true,
  });
// *************** 0.4.0 calenders ***************
export const getCalender = (params) => fetchGet("v1/promotions-calendar", { ...params });
// *************** 0.4.0 Delivery ***************
export const postCreateDelivery = (data) => {
  fetchPost("v1/deliverys", data);
};
export const putUpdateDelivery = (DeliveryID, data) => fetchPut(`v1/deliverys/${DeliveryID}`, data);
export const deleteDeliveries = (ids) => fetchDel("v1/deliverys/destroy_many", { ids });
export const getDeliveryDetails = (DeliveryID) => fetchGet(`v1/deliverys/${DeliveryID}`);
export const getDeliveryConfigs = () => fetchGet("v1/deliverys-configs");
export const getDeliveries = (params) => fetchGet("v1/deliverys", params);
// *************** 0.4.0 Export ***************
export const postExportCooperations = (data) => fetchPost("v1/cooperations/export", data);
export const postExportPromotions = (data) => fetchPost("v1/promotions/export", data);
export const postExportPayment = (data) => fetchPost("v1/payment_applications/export", data);
export const postExportDeliverys = (data) => fetchPost("v1/deliverys/export", data);

// *************** 0.5.1 ***************
// 伙伴报告 general
export const getPartnerReportGeneral = (partnerID) => fetchGet(`v1/partners/${partnerID}`);
// 第三方平台列表在 PartnerReport.support.js 里面. 因为有特殊的 token

// 线索列表
export const getLeadList = (params) => fetchGet("v1/lead/list", params);
// 列表页面删除选中的线索
export const postDelteLeads = (ids) =>
  fetchPost("v1/lead/destroyMany", { data: { ids }, isRaw: true });
// 列表页导入线索
export const postImportLeads = (data) => fetchPost("v1/lead/import", { data, isRaw: true });
// 线索列表 convert 按钮
export const postLeadsConvertPartner = (ids) =>
  fetchPost("v1/lead/convert", { data: { ids }, isRaw: true });
// 线索详情
export const getLeadDetail = (leadID) => fetchGet(`v1/lead/${leadID}/show`);
// 新增线索
export const postAddLead = (data) => fetchPost("v1/lead/store", { data, isRaw: true });
// 更新线索
export const postUpdateLead = (leadID, data) =>
  fetchPost(`v1/lead/${leadID}/update`, { data, isRaw: true });

// *************** 0.5.2 ***************
// payment 导出合并后的 pdf
export const getExportPaymentsMerge = (payment_application_ids) =>
  fetchGetBlob("v1/payment/export-merge", { payment_application_ids });
// 合作预算列表
export const getBudgetAppsList = (params) => fetchGet("v1/cooperation_budget_applications", params);
// 合作预算详情
export const getBudgetAppDetail = (id) => fetchGet(`v1/cooperation_budget_applications/${id}`);
// 新增合作预算
export const postAddBudgetApp = (data) =>
  fetchPost("v1/cooperation_budget_applications", { data, isRaw: true });
// 编辑合作预算
export const putEditBudgetApp = (id, data) =>
  fetchPut(`v1/cooperation_budget_applications/${id}`, { data, isRaw: true });
// 合作预算删除
export const deleteBudgetApps = (ids) =>
  fetchDel("v1/cooperation_budget_applications/destroy_many", { ids });
// 提交合作预算审批
export const postBudgetAppApproval = (application_id, type) =>
  fetchPost("v1/cooperation_budget_applications/approvals", { application_id, type });
// 提交合作撤销审批
export const getBudgetAppRevoking = (application_id, approval_status) =>
  fetchGet("v1/budget_applications/cancel", { application_id, approval_status });
// 合作预算配置项
export const getBudgetConfigs = (budgetID) =>
  fetchGet(`v1/cooperation_budget_applications/${budgetID}/configs`);
// 合作预算详情上传文件
export const postEditBudgetAttachments = (budgetID, data) =>
  fetchPost(`v1/cooperation_budget_applications/${budgetID}/attachments`, {
    formData: data,
    need: true,
  });
// 合作预算详情删除文件
export const deleteBudgetAttachments = (budgetID, ids) =>
  fetchDel(`v1/cooperation_budget_applications/${budgetID}/attachments/destroy_many`, {
    ids,
  });
// 合作预算详情添加时间线接口
export const postBudgetDetailEvents = (budgetID, string) =>
  fetchPost(`v1/cooperation_budget_applications/${budgetID}/events`, { content: string });
// **************************** 0.6.0 ****************************
// 活动合作列表 Campaign Cooperations
export const getCampaignCooperations = (params) =>
  fetchGet("v1/campaigns_cooperation/list", params);
// 活动成员列表
export const getCampaignMembers = (params) => fetchGet("v1/campaigns_member/list", params);
// 活动成员统计
export const getAnalyseCampaignMmebers = (params) => fetchGet("v1/campaigns_member/total", params);
// 删除活动成员
export const postDeleteCampaignMembers = (data) =>
  fetchPost("v1/campaigns_member/destroyMany", { data, isRaw: true });
export const postAddCampaignMembers = (data) =>
  fetchPost("v1/campaigns_member/store", { data, isRaw: true });
// 团队
// 新增团队
export const postAddTeam = (data) => fetchPost("v1/team/store", { data, isRaw: true });
// 团队列表
export const getTeamList = (params) => fetchGet("v1/team/list", params);
// 删除团队
export const postDeleteTeams = (data) => fetchPost("v1/team/destroyMany", { data, isRaw: true });
// 修改团队
export const postUpdateTeam = (id, data) =>
  fetchPost(`v1/team/${id}/update`, { data, isRaw: true });
// 团队详情
export const getTeamDetail = (id) => fetchGet(`v1/team/${id}/show`);
// 新增团队成员
export const postAddTeamMembers = (data) => fetchPost("v1/team_user/store", { data, isRaw: true });
// 团队成员列表
export const getTeamMembers = (params) => fetchGet("v1/team_user/list", params);
// 删除团队成员
export const postDeleteTeamMembers = (data) =>
  fetchPost("v1/team_user/destroyMany", { data, isRaw: true });
// 甘特图 Schedules
export const campaignsSchedules = (params) => fetchGet("v1/campaigns-calendar", params);
export const cooperationsSchedules = (params) => fetchGet("v1/cooperations-calendar", params);
export const promotionsSchedules = (params) => fetchGet("v1/promotions-calendar", params);
// 团队成员统计
export const getAnalyseTeamMembers = (params) => fetchGet("v1/team_user/total", params);
// *************************** 0.6.1 ***************************
// 使用用户 token 请求伙伴报告页面
// export const getPlatformReport = (id) => {
//   return fetchGet(`v1/partners/${id}/platform-profiles`);
// };
// *************************** 0.7.0 ***************************
// Home --------------------
// 伙伴平台搜索
export const getHomeSearchPartners = (params) => fetchGet("v1/partners-platforms", params);
// 伙伴平台总数
export const getHomeTotal = (params) => {
  return fetchGet(`v1/partners-platforms-total`, params);
};
export const getHomeData = () => {
  return fetchGet(`v1/home`);
};

// Partners --------------------
// 向多个伙伴添加多个 tags
export const postAddTags2Partners = (partnerIDs, tags) =>
  fetchPost("v1/partners-tags", {
    tags: tags,
    partner_ids: partnerIDs,
  });
// 向多个分组关联多个伙伴
export const postAddPartners2Collections = (partnerIDs, collectionIDs) =>
  fetchPost("v1/partners-groups", { partner_ids: partnerIDs, group_ids: collectionIDs });
// 向过个活动关联多个伙伴
export const postAddPartners2Campaigns = (partnerIDs, CampaignIDs) =>
  fetchPost("v1/partners-campaigns", { partner_ids: partnerIDs, campaign_ids: CampaignIDs });
// 批量删除伙伴
export const deletePartners = (partnerIDs) =>
  fetchDel("v1/partners/destroy_many", { ids: partnerIDs });
// 获取 tags
export const getPartnerTags = (params) => fetchGet("v1/tags", params);
// Collection --------------------
// 批量删除 collection
export const deleteCollections = (ids) => fetchDel("v1/groups/destroy_many", { ids });

export const deletePlatformsInCollections = (group_ids, platform_ids) =>
  fetchDel("v1/platforms-groups", { group_ids, platform_ids });
// Leads --------------------
// 先关 action 就是平台的接口

// Platforms --------------------
// 有关联伙伴的平台 请求这个
export const getPlatformReport = (id) => fetchGet(`v1/partners/${id}/platform-profiles`);
// 没有关联的伙伴 平台 请求这个
export const getNoRelatedPlatformReport = (id) => fetchGet(`v1/platforms/${id}/report`);
// 关联平台 / lead 到伙伴
export const postAddPlatforms2Partners = (platform_ids, partner_id) =>
  fetchPost("v1/platforms-partner", { platform_ids, partner_id });
// 关联平台 / lead 到分组
export const postAddPlatforms2Collections = (platform_ids, group_ids) =>
  fetchPost("v1/platforms-groups", { platform_ids, group_ids });
// lead options
export const getLeadOptions = () => fetchGet("v1/lead/config");
// 平台筛选项 options
export const getYoutubeListOptions = () => fetchGet("v1/platforms/youtube-config");
export const getInstagramListOptions = () => fetchGet("v1/platforms/instagram-config");
export const getTikTokListOptions = () => fetchGet("v1/platforms/tiktok-config");
export const getTwitchListOptions = () => fetchGet("v1/platforms/twitch-config");
export const getTwitterListOptions = () => fetchGet("v1/platforms/twitter-config");

// 平台列表
//TODO - 095-移除
export const getAllPlatforms = (params) => fetchGet("v1/platforms", params);
export const getYoutubeList = (params) => fetchGet("v1/platforms/youtube", params);
export const getInstagramList = (params) => fetchGet("v1/platforms/instagram", params);
export const getTikTokList = (params) => fetchGet("v1/platforms/tiktok", params);
export const getTwitchList = (params) => fetchGet("v1/platforms/twitch", params);
export const getTwitterList = (params) => fetchGet("v1/platforms/twitter", params);

// 删除平台
export const deletePlatforms = (ids) => fetchDel("v1/platforms/destroy_many", { ids });

// 0.8.1 Media Outlets
// 创建媒体机构
export const postAddMediaOutlet = (data) => fetchPost(`v1/media_outlets`, { data, isRaw: true });
// 修改媒体机构
export const putUpdateMediaOutlet = (id, data) =>
  fetchPut(`v1/media_outlets/${id}`, { data, isRaw: true });
// 媒体机构详情
export const getMediaOutlet = (id) => fetchGet(`v1/media_outlets/${id}`);
// 媒体机构列表
export const getMediaOutletList = (params) => fetchGet(`${LocalURL}v1/media_outlets`, params);
// 媒体机构列表筛选项
export const getMediaOutletConfig = () => fetchGet(`v1/media_outlets/configs`);
// 批量删除媒体机构
export const deleteMediaOutlets = (ids) => fetchDel(`v1/media_outlets/destroy_many`, { ids });
// 创建媒体机构标签 /api/v1/media_outlets_tags
// 删除媒体机构标签 /api/v1/media_outlets/{id}/tags

// 关联媒体机构分组
export const postOutletsToCollections = (group_ids, media_outlet_ids) =>
  fetchPost(`v1/media_outlets_groups`, { data: { group_ids, media_outlet_ids }, isRaw: true });
// 删除媒体机构分组
export const deleteOutletsToCollections = (group_ids, media_outlet_ids) =>
  fetchDel(`v1/media_outlets_groups`, { group_ids, media_outlet_ids });
// 上传媒体机构附件
export const postUploadOutletFiles = (id, data) =>
  fetchPost(`v1/media_outlets/${id}/attachments`, {
    formData: data,
    need: true,
  });
// 创建媒体机构动态
export const postAddOutletEvents = (id, content) =>
  fetchPost(`v1/media_outlets/${id}/events`, { data: { content }, isRaw: true });
// 媒体机构导入
export const postImportOutlets = (data) =>
  fetchPost(`v1/media_outlets/import`, {
    formData: data,
    need: true,
  });
// 媒体机构导出
export const postExportOutlets = (data) =>
  fetchPost(`v1/media_outlets/export`, { data, isRaw: true });
// 关联媒体机构和活动
export const postOutletsToCampaigns = (campaign_ids, media_outlet_ids) =>
  fetchPost(`v1/media_outlets_campaigns`, {
    data: { campaign_ids, media_outlet_ids },
    isRaw: true,
  });

// 0.8.1 导出平台
export const postExportPlatforms = (data) =>
  fetchPost(`v1/platforms/export`, { data, isRaw: true });
// 0.8.1 Media Contacts
// 创建媒体联系人
export const postAddMediaContact = (data) => fetchPost(`v1/media_contacts`, { data, isRaw: true });
// 修改媒体联系人
export const putUpdateMediaContact = (id, data) =>
  fetchPut(`v1/media_contacts/${id}`, { data, isRaw: true });
// 媒体联系人详情
export const getMediaContact = (id) => fetchGet(`v1/media_contacts/${id}`);
// 媒体联系人列表
export const getMediaContactList = (params) => fetchGet(`${LocalURL}v1/media_contacts`, params);
// 媒体联系人列表筛选项
export const getMediaContactConfig = () => fetchGet(`v1/media_contacts/configs`);
// 批量删除媒体联系人
export const deleteMediaContacts = (ids) => fetchDel(`v1/media_contacts/destroy_many`, { ids });
// 上传媒体联系人头像
export const postUploadContactAvatar = (id, data) =>
  fetchPost(`v1/media_contacts/${id}/avatar`, { formData: data, need: true });
// 删除媒体联系人头像
export const deleteContactAvatar = (id) => fetchDel(`v1/media_contacts/${id}/avatar`);
// 关联媒体联系人分组
export const postContactsToCollections = (group_ids, media_contact_ids) =>
  fetchPost(`v1/media_contacts_groups`, { data: { group_ids, media_contact_ids }, isRaw: true });
// 删除媒体联系人分组
export const deleteContactsToCollections = (group_ids, media_contact_ids) =>
  fetchDel(`v1/media_contacts_groups`, { group_ids, media_contact_ids });
// 上传媒体联系人附件
export const postUploadContactFiles = (id, data) =>
  fetchPost(`v1/media_contacts/${id}/attachments`, {
    formData: data,
    need: true,
  });
// 创建媒体联系人动态
export const postAddContactEvents = (id, content) =>
  fetchPost(`v1/media_contacts/${id}/events`, { data: { content }, isRaw: true });
// 媒体联系人导入
export const postImportContacts = (data) =>
  fetchPost(`v1/media_contacts/import`, {
    formData: data,
    need: true,
  });
// 关联媒体联系人和活动
export const postContactsToCampaigns = (campaign_ids, media_contact_ids) =>
  fetchPost(`v1/media_contacts_campaigns`, {
    data: { campaign_ids, media_contact_ids },
    isRaw: true,
  });

// 090 版本 -------------------------------------------------------

// 创建 keyword
export const postCreateKeyword = (data) => fetchPost(`v1/keywords/import`, { data, isRaw: true });
// 修改 keyword
export const putUpdateKeyword = (id, data) => fetchPut(`v1/keywords/${id}`, data);
// 详情 keyword
export const getKeyword = (id) => fetchGet(`v1/keywords/${id}`);
// 列表 Keyword
export const getKeywordList = (params) => fetchGet(`v1/keywords`, params);
// 列表筛选项 Keyword config
export const getKeywordConfig = () => fetchGet(`v1/keywords/configs`);
// 批量删除 Keyword
export const deleteKeywords = (ids) => fetchDel(`v1/keywords/destroy_many`, { ids });
// keyword result 列表
export const getKeywordResultList = (params) => fetchGet(`v1/keyword_results`, params);
// keyword result 筛选项
export const getKeywordResultConfig = () => fetchGet(`v1/keyword_results/configs`);
// 批量删除 keyword result
export const deleteKeywordResults = (ids) => fetchDel(`v1/keyword_results/destroy_many`, { ids });

// 092 版本 -------------------------------------------------------
// 获取文章列表
export const getMediaArticles = (params) => fetchGet(`v1/media_contact_articles`, params);
// Contact Extract Activitys 列表
export const getMediaContactExtractActivitys = (params) =>
  fetchGet(`v1/media_contact_extract_activitys`, params);

// Contact Extract Activitys 筛选项
export const getMediaContactExtractActivitysConfigs = () =>
  fetchGet(`v1/media_contact_extract_activitys/configs`);

// Contact Extract Activity 详情
export const getMediaContactExtractActivityDetail = (id) =>
  fetchGet(`v1/media_contact_extract_activitys/${id}`);

// 创建 Contact Extract Activity
export const postMediaContactExtractActivity = (data) =>
  fetchPost(`v1/media_contact_extract_activitys`, { data, isRaw: true });

// 接收抓取结果
export const postMediaContactExtractActivityOutput = () =>
  fetchPost(`v1/media_contact_extract_output`);

// 批量删除
export const deleteMediaContactExtractActivitys = (ids) =>
  fetchDel(`v1/media_contact_extract_activitys/destroy_many`, { ids });

// 094 版本 -------------------------------------------------------

export const getFileList = (params) => fetchGet(`${LocalURL}v1/file/list`, params);

export const getFile = (id) => fetchGet(`${LocalURL}v1/file/${id}`);

export const postImportFile = (data) =>
  fetchPost(`${LocalURL}v1/file/import`, {
    formData: data,
    need: true,
  });

export const getExportFile = (params) => fetchGet(`${LocalURL}v1/file/export`, params);
