import styled from "styled-components";

// css display
const KeepBox = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
`;
// component : 子组件 , 是否显示, 被隐藏时是否销毁 DOM 结构
const KeepAlive = ({ children, show, destroy = false }) => {
  if (destroy) {
    return show && <KeepBox show={true}>{children}</KeepBox>;
  } else {
    return <KeepBox show={show}>{children}</KeepBox>;
  }
};
// hook
const useKeepAlive = (funcArray = []) => {
  if (!Array.isArray(funcArray)) {
    throw new Error("The arguments passed to useKeepAlive must be an array of functions.");
  }

  const keepShow = (targetFunc, boolean) => {
    funcArray.forEach((fn) => {
      if (fn !== targetFunc) {
        fn(!boolean);
      } else {
        targetFunc(boolean);
      }
    });
  };

  return { keepShow };
};

export { KeepAlive, useKeepAlive };
