import { Banner } from "@shopify/polaris";
import { useToggle } from "ahooks";
import { useCallback, useState } from "react";

export const useBanner = () => {
  const [titleString, setTitleString] = useState(""); // 简要提示用titile就行
  const [contentString, setContentString] = useState(""); // 额外内容区
  const [status, setStatus] = useState("success"); // success,warning,critical

  const [active, { toggle: toggleBanner, setRight, setLeft: hiddenBanner }] = useToggle();

  const showBanner = useCallback(
    (o) => {
      o?.title && setTitleString(o.title);
      o?.content && setContentString(o.content);
      o?.status && setStatus(o.status);
      setRight();
    },
    [setRight]
  );

  const BannerJSX = active ? (
    <>
      <Banner title={titleString} status={status} onDismiss={toggleBanner}>
        {contentString && <p>{contentString}</p>}
      </Banner>
      <br />
    </>
  ) : null;

  return {
    BannerJSX,
    showBanner,
    hiddenBanner,
  };
};
