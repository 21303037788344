// eslint-disable-next-line
import { cookieScope } from "@/utils/cookieScope";
import { hotToast } from "@/utils/hotToast";
import { isDev } from "@/utils/isDev";

import axios from "axios";
import qs from "qs";

/**
 * @Author: lijunwei
 * @description: Added reading api origin from env config file.
 */
const { REACT_APP_API_ORIGIN, REACT_APP_PRODUCT_URL } = process.env;

const instance = axios.create({
  baseURL: `${REACT_APP_API_ORIGIN}/api/`,
  timeout: 2000000,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});
// // instance.headers.post['Content-Type'] = "application/x-www-form-urlencoded"
// let params = new URLSearchParams(document.location.search.substring(1));
// let locationUrl = params.get("token");
// if (!getCookie("Authorization")) {
//   setCookie("Authorization", locationUrl);
// } else if (getCookie("Authorization") === "undefined") {
//   setCookie("Authorization", locationUrl);
// }

// 传参序列化(添加请求拦截器)
instance.interceptors.request.use(
  (config) => {
    // 在发送请求之前做某件事
    // config.headers.Accept="appliaction/json,text/plan";

    config.headers.Authorization = cookieScope.getToken(); // 设置请求头
    config.headers["x-fstln-tenant-id"] = cookieScope.getTenantId();

    if (config.method === "post") {
      if (config?.data?.need) {
        config.headers.post["Content-Type"] = "multipart/form-data";
        config.data = config.data.formData;
      } else if (config?.data?.isRaw) {
        // 0.5.1后续用的raw的 json格式
        config.data = config.data.data;
      } else {
        config.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
        config.data = qs.stringify(config.data);
      }
    }
    if (config.method === "get") {
    }
    if (config.method === "put") {
      if (config?.data?.isRaw) {
        // 0.5.1后续用的raw的 json格式
        config.data = config.data.data;
      } else {
        config.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
        config.data = qs.stringify(config.data);
      }
    }
    return config;
  },
  (error) => {
    console.log("错误的传参");
    return Promise.reject(error);
  }
);

// 返回状态判断(添加响应拦截器)
instance.interceptors.response.use(
  (res) => {
    // 对响应数据做些事
    // console.log(res)
    if (res.data.status === "success") {
      return Promise.resolve(res.data);
    } else {
      // blob对象的状态: 200, 没有 "success"
      return Promise.resolve(res);
      // alert("操作失败");
    }
  },
  (error) => {
    const errorData = error?.response?.data;
    const httpStatus = error?.response?.status;

    // if (errorData?.code === 403) {
    //   hotToast(errorData?.message, { error: true });
    // } else if (httpStatus === 403) {
    //   hotToast(msg.error403, { error: true });
    // }

    if (errorData?.code === 401 || httpStatus === 401) {
      if (!isDev) {
        cookieScope.removeToken();
        cookieScope.removeTenantId();
        window.location.href = `${REACT_APP_PRODUCT_URL}`;
      } else {
        hotToast("Token has expired", { error: true });
      }
    }
    // else if (errorData.code === 500) {
    //   pubsub.emit("AxiosError", "PMP server error");
    // } else if (errorData.code === 403) {
    //   pubsub.emit("AxiosError", "You do not have permission!");
    // } else if (errorData.code === 404) {
    //   pubsub.emit("AxiosError", "Record not found!");
    // } else if (errorData.code === 422) {
    //   pubsub.emit("AxiosError", errorData.message);
    // } else {
    //   pubsub.emit("AxiosError", errorData.message);
    // }

    return Promise.reject(error.response); // 后端校验是否存在相同的title需要从响应体拿错误对象 + error.response
  }
);

// 返回一个Promise(发送post请求)
export function fetchPost(url, param) {
  return new Promise((resolve, reject) => {
    instance
      .post(url, param)
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
}
// 返回一个Promise(发送get请求)
export function fetchGet(url, param) {
  return new Promise((resolve, reject) => {
    instance
      .get(url, { params: param })
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
}

export function fetchGetBlob(url, param) {
  return new Promise((resolve, reject) => {
    instance
      .get(url, { responseType: "blob", params: param })
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
}

export function fetchPut(url, param) {
  return new Promise((resolve, reject) => {
    instance
      .put(url, { ...param })
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((err) => {
        reject(err);
      });
  });
}

export function fetchDel(url, param) {
  return new Promise((resolve, reject) => {
    instance
      .delete(url, { data: param })
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((err) => {
        reject(err);
      });
  });
}

const exportVariable = {
  fetchPost,
  fetchGet,
  fetchPut,
  fetchDel,
};

export default exportVariable;
