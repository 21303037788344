/* eslint-disable */
var toFirstLetterUp = (val) => {
  return val.slice(0, 1).toLocaleUpperCase() + val.slice(1);
};

/**
 * 节流函数-频率控制 用于将高频率连续调用某函数控制为低频率
 * 抄 underscore.js 的，具体用法请看 _.throttle
 */
var throttle = function (func, wait, options) {
  var context, args, result;
  var timeout = null;
  var previous = 0;
  options = options || {};
  var later = function () {
    previous = options.leading === false ? 0 : new Date();
    timeout = null;
    result = func.apply(context, args);
  };
  return function () {
    var now = new Date();
    if (!previous && options.leading === false) {
      previous = now;
    }
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0) {
      clearTimeout(timeout);
      timeout = null;
      previous = now;
      result = func.apply(context, args);
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
};

/**
 * 防抖函数-空闲控制 当高频率连续调用某函数停下来时，才会真正地去执行它
 * 抄 underscore.js 的，具体用法请看 _.debounce
 */
var debounce = function (func, wait, immediate) {
  var timeout, args, context, timestamp, result;
  return function () {
    context = this;
    args = arguments;
    timestamp = new Date();
    var later = function () {
      var last = new Date() - timestamp;
      if (last < wait) {
        timeout = setTimeout(later, wait - last);
      } else {
        timeout = null;
        if (!immediate) {
          result = func.apply(context, args);
        }
      }
    };
    var callNow = immediate && !timeout;
    if (!timeout) {
      timeout = setTimeout(later, wait);
    }
    if (callNow) {
      result = func.apply(context, args);
    }
    return result;
  };
};

/**
 * create by clf on 2018-6-12
 * 进行深拷贝的函数
 * @param 需要进行拷贝的对象
 * @return 新的拷贝对象
 */

function deepClone(data) {
  let type = Object.prototype.toString.call(data);
  let obj;

  if (type === "[object Array]") {
    obj = [];
    for (let i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]));
    }
  } else if (type === "[object Object]") {
    obj = {};
    for (let prop in data) {
      if (data.hasOwnProperty(prop)) {
        obj[prop] = deepClone(data[prop]);
      }
    }
  } else {
    //不再具有下一层次
    return data;
  }

  return obj;
}

function timestampToLocalString(totalS) {
  return new Date(totalS * 1000)
    .toLocaleString("zh", { hour12: false })
    .replaceAll("/", "-")
    .replaceAll(",", "");
}

function customStringNumber(number) {
  return number < 10 ? "0" + number : number;
}

function PartnerInfoCompare(origin, prop, value) {
  switch (prop) {
    case "language_ids":
    case "groups":
    case "tags":
      return JSON.stringify(origin[prop]) === JSON.stringify(value);
    case "platforms":
      if (origin[prop].length === value.length) {
        for (let i = 0; i < value.length; i++) {
          if (
            value[i].addressValue !== origin[prop][i].addressValue ||
            value[i].numberValue != origin[prop][i].numberValue ||
            value[i].selectPlatformValue !== origin[prop][i].selectPlatformValue
          ) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    case "prices":
      if (origin[prop].length === value.length) {
        for (let i = 0; i < value.length; i++) {
          if (
            value[i].type_id != origin[prop][i].type_id ||
            value[i].value != origin[prop][i].value ||
            value[i].currency != origin[prop][i].currency
          ) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    case "contacts":
      if (origin[prop].length === value.length) {
        for (let i = 0; i < value.length; i++) {
          if (
            value[i].contactInfo != origin[prop][i].contactInfo ||
            value[i].selectitem != origin[prop][i].selectitem
          ) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    default:
      return origin[prop] === value;
  }
}

const initOriginInfo = (res) => {
  let temp = {
    first_name: res?.data?.first_name ?? "",
    last_name: res?.data?.last_name ?? "",
    gender: (res?.data?.gender ?? "") + "",
    birthday: res?.data?.birthday ?? "",
    // language_ids: [+languageSelected],
    language_ids: res?.data?.languages?.map((item) => item.id + "") ?? [],
    type: (res?.data?.type ?? "") + "",
    role: res?.data?.role ?? "",
    brief_description: res?.data?.brief_description ?? "",
    currency: (res?.data?.price?.currency?.id ?? "") + "",
    price: (res?.data?.price?.value ?? "") + "",
    contact_status: (res?.data?.contact_status ?? "") + "",
    is_blacklisted: (res?.data?.is_blacklisted ?? "") + "",
    mobile_phone: res?.data?.mobile_phone ?? "",
    email: res?.data?.email ?? "",
    telephone: res?.data?.telephone ?? "",
    fax: res?.data?.fax ?? "",
    account_level: (res?.data?.account_level?.id ?? "") + "",
    person_country: res?.data?.address?.country ?? "",
    person_state: res?.data?.address?.state ?? "",
    person_city: res?.data?.address?.city ?? "",
    person_postalCode: res?.data?.address?.postal_code ?? "",
    person_address: res?.data?.address?.street ?? "",
    company_id: (res?.data?.company?.id ?? "") + "",
    company_name: res?.data?.company?.name ?? "",
    company_contactPerson: res?.data?.company?.contact_person ?? "",
    company_mobilePhone: res?.data?.company?.mobile_phone ?? "",
    company_Email: res?.data?.company?.email ?? "",
    company_telePhone: res?.data?.company?.telephone ?? "",
    company_fax: res?.data?.company?.fax ?? "",
    company_country: (res?.data?.company?.address?.country?.id ?? "") + "",
    company_state: res?.data?.company?.address?.state ?? "",
    company_city: res?.data?.company?.address?.city ?? "",
    company_postalCode: res?.data?.company?.address?.postal_code ?? "",
    company_address: res?.data?.company?.address?.street ?? "",
    company_officialWebsite: res?.data?.company?.official_website ?? "",
    platforms:
      res?.data?.platforms?.map((item) => ({
        selectPlatformValue: item?.type?.id + "",
        addressValue: item?.link ?? "",
        numberValue: item?.subscribers ?? "" + "",
      })) ?? [],
    prices:
      res?.data?.prices?.map((item) => ({
        type_id: item?.type?.id ?? "" + "",
        value: item?.value ?? "",
        currency: item?.currency?.id ?? "" + "",
      })) ?? [],
    contacts:
      res?.data?.contacts?.map((item) => ({
        contactInfo: item?.account ?? "",
        selectitem: (item?.type?.id ?? "") + "",
      })) ?? [],
    groups: res?.data?.groups?.map((item) => item.id + ""),
    tags:
      res?.data?.tags?.map((item) => ({
        // id: item.id,
        title: item.title,
        type: item.type,
      })) ?? [],
  };
  return temp;
};

const transformNumber = (num, digits = 1) => {
  if (num === null) return "";
  if (isNaN(num)) return "";
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};
const getTransformedValue = (val) => {
  if (val.trim() === "") return "";
  if (Number(val.trim())) return val;
  let value = val.trim().split("").pop().toLowerCase();

  if (value === "k") return parseFloat(val) * 1000;
  else if (value === "m") return parseFloat(val) * 1000000;
  else if (value === "b") return parseFloat(val) * 1000000000;
};
// 秒 转换成时分秒
const formatSeconds = (value) => {
  var secondTime = parseInt(value); // 秒
  var minuteTime = 0; // 分
  var hourTime = 0; // 小时
  if (secondTime >= 60) {
    minuteTime = parseInt(secondTime / 60);
    secondTime = parseInt(secondTime % 60);
    if (minuteTime >= 60) {
      hourTime = parseInt(minuteTime / 60);
      minuteTime = parseInt(minuteTime % 60);
    }
  }
  var result = "" + (parseInt(secondTime) < 10 ? "0" + parseInt(secondTime) : parseInt(secondTime));

  if (minuteTime > 0) {
    result =
      "" +
      (parseInt(minuteTime) < 10 ? "0" + parseInt(minuteTime) : parseInt(minuteTime)) +
      ":" +
      result;
  }
  if (hourTime > 0) {
    result =
      "" + (parseInt(hourTime) < 10 ? "0" + parseInt(hourTime) : parseInt(hourTime)) + ":" + result;
  }
  return result;
};

export {
  transformNumber,
  getTransformedValue,
  initOriginInfo,
  throttle,
  debounce,
  deepClone,
  toFirstLetterUp,
  timestampToLocalString,
  customStringNumber,
  PartnerInfoCompare,
  formatSeconds,
};
