import { Badge } from "@shopify/polaris";

export function approvalStatusBadge(status) {
  status = Number(status);
  const colors = new Map([
    [1, ""],
    [2, "info"],
    [3, "success"],
    [4, "critical"],
  ]);
  const currentStatus = new Map([
    [1, "Unsubmitted"],
    [2, "In progress"],
    [3, "Passed"],
    [4, "Unpassed"],
  ]);

  const content = (status) => currentStatus.get(status);
  const badgeColor = (status) => colors.get(status);

  return (
    <Badge status={badgeColor(status)}>
      <span style={{ whiteSpace: "nowrap" }}>{content(status)}</span>
    </Badge>
  );
}
