import { Button, ButtonGroup, TextField } from "@shopify/polaris";
import { useMount, useToggle, useUnmount } from "ahooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";

import {
  getFiltersCountrys,
  getMediaContactList,
  getMediaOutlet,
  postAddMediaOutlet,
  putUpdateMediaOutlet
} from "@/api/request";
import { AntDrawer } from "@/components/EnhanceShopify/AntDrawer";
import { DrawerSection } from "@/components/EnhanceShopify/DrawerSection";
import { DrawerSkeleton } from "@/components/EnhanceShopify/DrawerSkeleton";
import { SelectPlus } from "@/components/EnhanceShopify/SelectPlus";
import { useRefrehTable } from "@/customHooks/useRefrehTable";
import { useHistory } from "react-router-dom";
import { selectTagState } from "../../Common/InputTags/atom";
import { InputTags } from "../../Common/InputTags/InputTags";
import { outletsRefresh_atom } from "../atom";
import { id_atom } from "./atom";
import { Contacts } from "./Contacts";
import { Columns } from "./Contacts/Column";
import { SelectContacts } from "./Contacts/SelectContacts";
import { selectedContacts_atom } from "./Contacts/SelectContacts/atom";

export const MediaOutletsEditor = ({ title, visible, setActive, toggle }) => {
  const history = useHistory();
  const [outletID] = useRecoilState(id_atom); // 跨组件传递的id
  const resetOutletID = useResetRecoilState(id_atom);
  const [loading, setLoading] = useState(true); // 数据加载时
  const [saveLoading, setSaveLoading] = useState(false); // 保存请求时
  const [websitelink, setWebsitelink] = useState("");
  const { handleRefresh } = useRefrehTable(outletsRefresh_atom);

  const [name, setName] = useState("");
  const [selectRegion, setSelectRegion] = useState([]);
  const [regionOps, setRegionOps] = useState([]);

  const [selectedTags, setSelectedTags] = useRecoilState(selectTagState);
  const resetSelectedTags = useResetRecoilState(selectTagState);

  const [note, setNote] = useState("");

  const [contacts, setContacts] = useRecoilState(selectedContacts_atom);
  const resetContacts = useResetRecoilState(selectedContacts_atom);

  const [selectContactsActive, { toggle: toggleSelectContacts }] = useToggle();

  // 保存成功 / 关闭 Drawer 时候, 重置数据栏
  const handleClearAll = useCallback(() => {
    setName("");
    setSelectRegion([]);
    setNote("");
    resetOutletID();
    resetSelectedTags();
    resetContacts();
  }, [resetOutletID, resetSelectedTags, resetContacts]);

  // 剩余部分数据收集 -> 因为没有局部更新, 需要收集侧边栏未做更新的数据
  // 更新数据收集
  // 新增 / 编辑
  const initParams = useMemo(() => {
    console.log("contacts: ", contacts);
    const contactIDs = contacts?.map(({ id }) => String(id));

    return {
      name,
      address: {
        country_id: selectRegion?.toString(),
      },
      brief_description: note,
      media_website: { url: websitelink },
      media_contact_ids: contactIDs,
      tags: selectedTags,
    };
  }, [name, selectRegion, note, contacts, websitelink, selectedTags]);

  // Save 按钮
  const editorSave = useCallback(async () => {
    try {
      setSaveLoading(true);
      if (!outletID) {
        // 新增
        await postAddMediaOutlet(initParams);
      } else {
        // 修改
        await putUpdateMediaOutlet(outletID, initParams);
      }
      handleRefresh();
      toggle();
      handleClearAll();
    } catch (error) {
      //
    } finally {
      setSaveLoading(false);
    }
  }, [outletID, toggle, handleClearAll, initParams, handleRefresh]);

  // Cancel 按钮
  const editorCancel = () => {
    toggle();
    handleClearAll();
  };

  const editorAdvaceSetting = () => {
    handleClearAll();
    toggle();
    history.push(`/media/detail/${outletID ? outletID : ""}`);
  };

  // 根据 id 请求快捷编辑的详情
  const request = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await getMediaOutlet(outletID);
      console.log("data: ", data);
      setName(data.name);
      setSelectRegion([String(data?.address?.country?.id || "")]);
      setSelectedTags(data.tags.map(({ title }) => title));
      setNote(data?.brief_description ?? "");
      setWebsitelink(data.media_website?.url);
      const contacts = await getMediaContactList({ is_not_paged: 1, media_outlet_ids: [outletID] });
      setContacts(contacts.data.list);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [setSelectedTags, setContacts, outletID]);

  // outletID 变化, 且存在时候 -> 请求数据 -> outletID存在 -> 编辑模式
  useEffect(() => {
    if (outletID) {
      request();
    } else {
      setLoading(false); // 新增时候不需要加载状态
    }
  }, [outletID, request]);

  // 组件挂载时加载选项栏, 不会反复挂载
  useMount(async () => {
    const { data } = await getFiltersCountrys();

    const ops = data.list.map(({ id, name }) => ({ label: name, value: String(id) }));
    setRegionOps(ops);
  });

  useUnmount(() => {
    setActive(false);
    resetSelectedTags();
  });

  const canSave = useMemo(() => {
    return !name || selectRegion?.length === 0 || !websitelink;
  }, [name, selectRegion, websitelink]);

  return (
    <AntDrawer
      title={title}
      visible={visible}
      handleSave={editorSave}
      handleCancel={editorCancel}
      handleSetting={editorAdvaceSetting}
      saveLoading={saveLoading}
      disabledSave={canSave}
    >
      {loading ? (
        <DrawerSkeleton />
      ) : (
        <>
          <SelectContacts active={selectContactsActive} toggle={toggleSelectContacts} />
          <DrawerSection title="Basic information">
            <TextField
              autoComplete="off"
              label="Media outlet name"
              value={name}
              onChange={setName}
              requiredIndicator
            />
            <br />

            <TextField
              label="Media wesite URL"
              value={websitelink}
              onChange={setWebsitelink}
              requiredIndicator
            />
            <br />
            <SelectPlus
              label="Country/region"
              select={selectRegion}
              onSelect={setSelectRegion}
              options={regionOps}
              requiredIndicator
              // allowMultiple
            />
            <br />

            <InputTags label="Tag" />
            <br />

            <TextField
              autoComplete="off"
              label="Note"
              multiline={2}
              value={note}
              onChange={setNote}
            />
          </DrawerSection>

          <DrawerSection
            title="Media contacts"
            action={
              <ButtonGroup>
                <Button size="slim" onClick={toggleSelectContacts}>
                  Select media contact
                </Button>
                <Columns />
              </ButtonGroup>
            }
          >
            <Contacts />
          </DrawerSection>
        </>
      )}
    </AntDrawer>
  );
};
