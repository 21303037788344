import Cookies from "js-cookie";

// CookiesDomain -> 配置环境变量 测试环境: .bbxlk.cc
const GROWTH_ACCESS_TOKEN = "growth-access-token";
const GROWTH_TENANT_ID = "growth-tenant-id";

const jsCookieConfig = {
  domain: process.env.REACT_APP_COOKIES_DOMAIN,
};

/**
 * 获取指定主域名下的 cookie
 */

export const cookieScope = {
  // 读取 token
  getToken: () => Cookies.get(GROWTH_ACCESS_TOKEN),
  // 设置 token
  setToken: (token) => Cookies.set(GROWTH_ACCESS_TOKEN, token, jsCookieConfig),
  // 移除 token
  removeToken: () => Cookies.remove(GROWTH_ACCESS_TOKEN, jsCookieConfig),
  // 读取 租户id
  getTenantId: () => Cookies.get(GROWTH_TENANT_ID),
  // 设置 租户id
  setTenantId: (tenantId) => Cookies.set(GROWTH_TENANT_ID, tenantId, jsCookieConfig),
  // 移除 租户id
  removeTenantId: () => Cookies.remove(GROWTH_TENANT_ID, jsCookieConfig),
};
