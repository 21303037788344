import {
  getPartnerContactTypesByID, postPartnerContactType, putPartnerContactType
} from "@/api/request";
import { LoadingContext } from "@/context/loading-context";
import { Card, ContextualSaveBar, FormLayout, TextField } from "@shopify/polaris";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";

export default function ContactItemDetail() {
  const { ID } = useParams();
  const loadingContext = useContext(LoadingContext);
  const [addDisable, setAddDisable] = useState(false);
  const history = useHistory();

  const originInfo = useRef("");

  useEffect(() => {
    if (ID !== "add") {
      getPartnerContactTypesByID(ID).then((res) => {
        setContactName(res?.data?.title);
        originInfo.current = res?.data?.title;
      });
    } else {
      setIsShowContextSaveBar(true);
      setAddDisable(true);
      setContactName("");
      originInfo.current = "";
    }
  }, [ID]);

  const handleSaveClick = () => {
    if (ID !== "add") {
      loadingContext.loading(true);
      putPartnerContactType(ID, { title: contactName }).then((res) => {
        loadingContext.loading(false, "Saved successfully !");
        setIsShowContextSaveBar(false);
        setContactName(res?.data?.title);
        originInfo.current = res?.data?.title;
      });
    } else {
      loadingContext.loading(true);
      postPartnerContactType(contactName).then(() => {
        loadingContext.loading(false, "Saved successfully !");
        history.goBack();
      });
    }
  };

  const [isShowContextSaveBar, setIsShowContextSaveBar] = useState(false);
  let contextSaveBar = isShowContextSaveBar ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        onAction: handleSaveClick,
        loading: false,
        disabled: addDisable,
      }}
      discardAction={{
        onAction: () => {
          if (ID !== "add") {
            setContactName(originInfo.current);
            setIsShowContextSaveBar(false);
          } else {
            history.goBack();
          }
        },
      }}
    />
  ) : null;

  // contact name
  const [contactName, setContactName] = useState("");
  const handleContactNameChange = useCallback(
    (newValue) => {
      setContactName(newValue);
      if (ID !== "add") {
        if (newValue !== originInfo.current) {
          setIsShowContextSaveBar(true);
        } else {
          setIsShowContextSaveBar(false);
        }
      } else {
        if (newValue !== originInfo.current) {
          setAddDisable(false);
        } else {
          setAddDisable(true);
        }
      }
    },
    [ID]
  );
  return (
    <Card title="">
      {contextSaveBar}
      <Card.Section>
        <FormLayout>
          <TextField
            label="Contact type"
            value={contactName}
            maxLength={50}
            onChange={handleContactNameChange}
          />
        </FormLayout>
      </Card.Section>
    </Card>
  );
}
