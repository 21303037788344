import { Card, Collapsible, Icon } from "@shopify/polaris";
import { ChevronDownMinor, ChevronUpMinor } from "@shopify/polaris-icons";
import { useCallback, useEffect, useState } from "react";

// import { KeepAlive } from "./KeepAlive";
import styled from "styled-components";

const SvgColor = styled.div`
  > span > svg {
    fill: rgba(92, 95, 98, 1);
  }
  & {
    margin-left: 0.5rem;
    transition: all 0.5s;
    &.active {
      transition: rotate(-90deg);
    }
  }
`;
const Bottom = styled.div`
  height: 16px;
`;

//  title: Card的title , mountStatus: 挂载时是否收缩
export const CollectCard = ({ children, title, mountStatus, action = [] }) => {
  const [isUnfold, setIsUnfold] = useState(true);
  const toggle = useCallback(() => setIsUnfold((boo) => !boo), []);

  useEffect(() => {
    if (typeof mountStatus !== "undefined") {
      setIsUnfold(mountStatus);
    }
  }, [mountStatus]);

  return (
    <Card
      title={title}
      actions={[
        ...action,
        {
          content: (
            <SvgColor className={isUnfold ? "active" : ""}>
              <Icon source={isUnfold ? ChevronDownMinor : ChevronUpMinor} />
            </SvgColor>
          ),
          onAction: toggle,
        },
      ]}
    >
      {/* <KeepAlive show={isUnfold}>{children}</KeepAlive> */}
      <Collapsible
        open={isUnfold}
        id="basic-collapsible"
        transition={{
          duration: "var(--p-duration-150)",
          timingFunction: "var(--p-ease-in-out)",
        }}
        expandOnPrint
      >
        {children}
      </Collapsible>
      {!isUnfold ? <Bottom /> : null}
    </Card>
  );
};
