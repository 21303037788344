import styled from "styled-components";

const Section = styled.div`
  padding: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  margin-top: -1px;
  .Polaris-IndexTable {
    margin: -2px;
  }
`;

const Head = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  align-items: center;
`;
const Action = styled.div``;

const Title = styled.div`
  color: #202223;
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: 16px;
  flex-grow: 1;
`;

export const DrawerSection = ({ title = "", action, children }) => {
  return (
    <Section>
      <Head>
        <Title> {title ?? ""}</Title>
        <Action>{action}</Action>
      </Head>
      {children}
    </Section>
  );
};
