import "antd/dist/antd.css";
// import "@shopify/polaris/dist/styles.css";
import "@shopify/polaris/build/esm/styles.css";
import { BrowserRouter, Link as ReactRouterLink } from "react-router-dom";
import "./assets/iconfont/iconfont.css";

import { AppProvider } from "@shopify/polaris";
import en from "@shopify/polaris/locales/en.json";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import { App } from "./App";

import { Toaster } from "react-hot-toast";
import { Route, Switch } from "react-router-dom";
import { ReidrectPage } from "./pages/ReidrectPage";

function Main() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <AppProvider i18n={en} linkComponent={Link}>
          <Toaster position="bottom-center" />

          <Switch>
            <Route path="/redirect" exact>
              <ReidrectPage />
            </Route>
            <App />
          </Switch>
        </AppProvider>
      </BrowserRouter>
    </RecoilRoot>
  );
}

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;
// eslint-disable-next-line
function Link({ children, url = "", external, ref, ...rest }) {
  // react-router only supports links to pages it can handle itself. It does not
  // support arbirary links, so anything that is not a path-based link should
  // use a reglar old `a` tag
  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    rest.target = "_blank";
    rest.rel = "noopener noreferrer";
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <ReactRouterLink to={url} {...rest}>
      {children}
    </ReactRouterLink>
  );
}

ReactDOM.render(<Main />, document.getElementById("root"));
