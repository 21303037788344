import {
  getFiltersCountrys,
  getFiltersCurrency,
  getPartnerContactTypes,
  getPartnerLanguagesOptions,
  getPartnerPlatform,
  getPartnerPriceList,
} from "@/api/request";
import { useTabs } from "@/customHooks/useTabs";
import { NoPermission } from "@/error/403";
import { ResponseError } from "@/error/500";
import { loadableAuthAtom } from "@/global/authAtom";
import { useDispatchResize } from "@/hooks/useDispatchResize";
import { useRecoilToggle } from "@/hooks/useRecoilToggle";
import { BarsOutlined } from "@ant-design/icons";
import { Card, Loading, Page } from "@shopify/polaris";
import { ExportMinor, ImportMinor } from "@shopify/polaris-icons";
import { useMount } from "ahooks";
import { Segmented } from "antd";
import { useAtom } from "jotai";
import { useCallback, useMemo } from "react";
import { atom, useRecoilState } from "recoil";
import { MediaContacts } from "../Media/MediaContacts";
import {
  toggleContactsDrawer_atom,
  toggleImportContacts_atom,
} from "../Media/MediaContacts/Import/atom";
import { SociallinkTypes_atom } from "../Media/MediaContacts/MediaContactEditor/atom";
import { exportContactAtom } from "../Media/MediaContacts/atom";
import { MediaOutlets } from "../Media/MediaOutlets";
import {
  toggleImportOutlets_atom,
  toggleOutletsDrawer_atom,
} from "../Media/MediaOutlets/Import/atom";
import { outletsExport_atom } from "../Media/MediaOutlets/atom";
import {
  contactTypeOps_atom,
  currecyOps_atom,
  editorActive_atom,
  languageOps_atom,
  priceTypeOps_atom,
  regionOps_atom,
} from "../Partner/Influencers/Eidtor/atom";
import { pubExportActionAtom } from "../Partner/PartnersTabs/atom";
import { Platforms } from "../Partner/Platforms";
import { epxortINS_atom } from "../Partner/Platforms/InstagramTable/atom";
import { exportTikTok_atom } from "../Partner/Platforms/TikTokTable/atom";
import { exportTwitch_atom } from "../Partner/Platforms/TwitchTable/atom";
import { exportTwitter_atom } from "../Partner/Platforms/TwitterTable/atom";
import { exportYTB_atom } from "../Partner/Platforms/YoutubeTable/atom";
import { Plat_tab_init } from "../Partner/Platforms/atom/commonAtom";
import { toggleImportAtom } from "../Partner/Platforms/import/atom";

const tableTabs = [
  {
    label: "Influencers",
    value: "influencers",
    icon: <BarsOutlined />,
  },
  {
    label: "Media outlets",
    value: "outlets",
    icon: <BarsOutlined />,
  },
  {
    label: "Media contacts",
    value: "contacts",
    icon: <BarsOutlined />,
  },
];
const discoveryTabAtom = atom({
  key: "discoveryTabAtom",
  default: "influencers",
});

export const Discovery = () => {
  const [permission] = useAtom(loadableAuthAtom);
  const access = useMemo(
    () => ({
      LOADING: permission.state === "loading",
      ERROR: permission.state === "hasError",
      WRITE: permission.data?.["pmp/resources/discovery/write"],
      IMPORT: permission.data?.["pmp/resources/discovery/import"],
      EXPORT: permission.data?.["pmp/resources/discovery/export"],
      READ: permission.data?.["pmp/resources/discovery/read"],
    }),
    [permission]
  );

  const [tableTab, setTableTab] = useRecoilState(discoveryTabAtom);
  useDispatchResize(tableTab);

  const [influencerTab] = useRecoilState(Plat_tab_init);
  const { toggle: toggleImportInfluencers } = useRecoilToggle(toggleImportAtom);
  const { toggle: toggleEditor } = useRecoilToggle(editorActive_atom);

  const { toggle: exportPartners } = useRecoilToggle(pubExportActionAtom);
  const { toggle: exportYouTuBe } = useRecoilToggle(exportYTB_atom);
  const { toggle: exportINS } = useRecoilToggle(epxortINS_atom);
  const { toggle: exportTikTok } = useRecoilToggle(exportTikTok_atom);
  const { toggle: exportTwitch } = useRecoilToggle(exportTwitch_atom);
  const { toggle: exportTwitter } = useRecoilToggle(exportTwitter_atom);

  const { toggle: exportMediaContact } = useRecoilToggle(exportContactAtom);
  const { toggle: exportMediaOutlets } = useRecoilToggle(outletsExport_atom);
  const { toggle: toggleImportOutletsActive } = useRecoilToggle(toggleImportOutlets_atom);
  const { toggle: addMediaOutletts } = useRecoilToggle(toggleOutletsDrawer_atom);

  const { toggle: toggleImportActive } = useRecoilToggle(toggleImportContacts_atom);
  const { toggle: addMediaContacts } = useRecoilToggle(toggleContactsDrawer_atom);

  //#region  ---------------------- ↓ Editor ↓ ----------------------
  const [, setSocailLinkTypes] = useRecoilState(SociallinkTypes_atom);
  const [, setRegionOps] = useRecoilState(regionOps_atom);
  const [, setLanguageOps] = useRecoilState(languageOps_atom);
  const [, setContactTypeOps] = useRecoilState(contactTypeOps_atom);
  const [, setPriceTypeOps] = useRecoilState(priceTypeOps_atom);
  const [, setCurrecyOps] = useRecoilState(currecyOps_atom);

  useMount(async () => {
    const countryRes = await getFiltersCountrys();
    const countryOps = countryRes.data.list.map(({ id, name }) => ({
      label: name,
      value: String(id),
    }));
    setRegionOps(countryOps);

    const langRes = await getPartnerLanguagesOptions();
    const langOps = langRes.data.list.map(({ id, name }) => ({ label: name, value: String(id) }));
    setLanguageOps(langOps);

    const contactRes = await getPartnerContactTypes();
    const contactOps = contactRes.data.list.map(({ id, title }) => ({
      label: title,
      value: String(id),
    }));
    setContactTypeOps(contactOps);

    const currencyRes = await getFiltersCurrency();
    const currencyOps = currencyRes.data.list.map(({ id, code }) => ({
      label: code,
      value: String(id),
    }));
    setCurrecyOps(currencyOps);

    const priceTypeRes = await getPartnerPriceList();
    const priceTypeOps = priceTypeRes.data.list.map(({ id, title }) => ({
      label: title,
      value: String(id),
    }));
    setPriceTypeOps(priceTypeOps);

    const platformRes = await getPartnerPlatform({
      title: "",
      is_not_paged: 1,
      sort_by: "updated_at",
      sort_type: "desc",
    });
    const typeOptions = platformRes.data.list.map(({ id, title }) => ({
      label: title,
      value: String(id),
    }));
    setSocailLinkTypes(typeOptions);
  });

  //#endregion -------------------- ↑ Editor ↑ ----------------------

  const matchExportTab = useCallback(
    (tabIndex) => {
      switch (tabIndex) {
        case 0:
          return exportPartners;
        case 1:
          return exportYouTuBe;
        case 2:
          return exportINS;
        case 3:
          return exportTikTok;
        case 4:
          return exportTwitch;
        case 5:
          return exportTwitter;
        default:
          return exportPartners;
      }
    },
    [exportPartners, exportYouTuBe, exportINS, exportTikTok, exportTwitch, exportTwitter]
  );

  const PagePrimaryAction = (tab) => {
    if (!access.WRITE) {
      return null;
    }
    switch (tab) {
      case "contacts":
        return {
          content: "Add media contact",
          onAction: addMediaContacts,
        };
      case "outlets":
        return {
          content: "Add media outlet",
          onAction: addMediaOutletts,
        };
      case "influencers":
      default:
        return {
          content: "Add influencer",
          onAction: toggleEditor,
        };
    }
  };

  const PageSecondActions = (tab) => {
    const filterAuthActions = (actions) => {
      return actions.filter((o) => {
        if (o.content === "Import" && access.IMPORT) {
          return true;
        }
        if (o.content === "Export" && access.EXPORT) {
          return true;
        }
        return false;
      });
    };
    const influencersActions = [
      {
        content: "Export",
        icon: ExportMinor,
        onAction: matchExportTab(influencerTab),
      },
      {
        content: "Import",
        icon: ImportMinor,
        onAction: toggleImportInfluencers,
      },
    ];
    const outletsActions = [
      {
        content: "Export",
        icon: ExportMinor,
        onAction: exportMediaOutlets,
      },
      {
        content: "Import",
        icon: ImportMinor,
        onAction: toggleImportOutletsActive,
      },
    ];
    const contactsActions = [
      {
        content: "Export",
        icon: ExportMinor,
        onAction: exportMediaContact,
      },
      {
        content: "Import",
        icon: ImportMinor,
        onAction: toggleImportActive,
      },
    ];

    switch (tab) {
      case "contacts":
        return filterAuthActions(contactsActions);
      case "outlets":
        return filterAuthActions(outletsActions);
      case "influencers":
      default:
        return filterAuthActions(influencersActions);
    }
  };

  const { tabJSX } = useTabs({ titles: ["All"] });

  const matchTable = useMemo(() => {
    switch (tableTab) {
      case "contacts":
        return (
          <Card>
            {tabJSX}
            <MediaContacts />
          </Card>
        );
      case "outlets":
        return (
          <Card>
            {tabJSX}
            <MediaOutlets />
          </Card>
        );
      case "influencers":
      default:
        return <Platforms />;
    }
  }, [tableTab, tabJSX]);

  if (access.LOADING) return <Loading />;
  if (access.ERROR) return <ResponseError />;
  if (!access.READ) return <NoPermission />;

  return (
    <Page
      fullWidth
      title="Discovery"
      primaryAction={PagePrimaryAction(tableTab)}
      secondaryActions={PageSecondActions(tableTab)}
    >
      <div style={{ paddingBottom: 16 }}>
        <Segmented options={tableTabs} value={tableTab} onChange={setTableTab} />
      </div>
      {matchTable}
    </Page>
  );
};
