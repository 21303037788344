import { SkeletonBodyText, SkeletonDisplayText } from "@shopify/polaris";

import { DrawerSection } from "./DrawerSection";

export const DrawerSkeleton = () => {
  return (
    <DrawerSection>
      <SkeletonDisplayText size="large" />
      <br />
      <SkeletonBodyText />
      <br />
      <SkeletonDisplayText size="large" />
      <br />
      <SkeletonBodyText />
      <br />
      <SkeletonBodyText />
      <br />
      <SkeletonDisplayText size="large" />
      <br />
      <SkeletonBodyText />
      <br />
      <SkeletonBodyText />
      <br />
    </DrawerSection>
  );
};
