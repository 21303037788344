import { getMediaArticles } from "@/api/request";
import none_face from "@/assets/none.png";
import notFound from "@/assets/svg/notFound.svg";
import { AntPagination } from "@/components/AntToShopify/AntPagination";
import { FilterNarrow } from "@/components/EnhanceShopify/FilterBox";
import { IconText } from "@/components/EnhanceShopify/IconText";
import { NotFound } from "@/components/EnhanceShopify/NotFound";
import { TextClamp } from "@/components/EnhanceShopify/TextClamp";
import { splitChoiceValue } from "@/utils/checkType";
import { datepickToShopifyStyle } from "@/utils/datepickToShopifyStyle";
import {
  Button,
  ChoiceList,
  Filters,
  Popover,
  ResourceList,
  Stack,
  Text,
  TextContainer
} from "@shopify/polaris";
import { ClockMinor, ConversationMinor, SortMinor, ThumbsUpMinor } from "@shopify/polaris-icons";
import { useDebounceFn, useMount, useToggle, useUpdateEffect } from "ahooks";
import { DatePicker, Pagination } from "antd";
import _ from "lodash";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { atom, useRecoilState } from "recoil";
const { RangePicker: AntDateRangePicker } = DatePicker;

const resourceName = {
  singular: "article",
  plural: "articles",
};
const sortOptions = [
  { label: "Article date (oldest first)", value: "article_date|asc" },
  { label: "Article date (newest first)", value: "article_date|desc" },
];

export const isUpdateArticlesAtom = atom({
  key: "isUpdateArticlesAtom",
  default: false,
});

export const Articles = ({ outletID = [], contactID = [] }) => {
  const [indexTableLoading, setIndexTableLoading] = useState(false); // indexTable 加载状态显示
  const [, setIsUpdateArticles] = useRecoilState(isUpdateArticlesAtom);

  const [articles, setArticles] = useState([]);

  const [queryValue, setQueryValue] = useState("");
  const clearQueryValue = () => setQueryValue("");

  // #region --------- ↓ creation time ↓ ----------------------
  const [date, setDate] = useState(null);
  const onDateChange = useCallback(
    (dates, dateStrings) => {
      setDate(dateStrings);
    },
    [setDate]
  );
  const clearDate = () => setDate(null);
  // #endregion ------ ↑ creation time ↑ ----------------------

  const clearAll = () => {
    clearQueryValue();
    clearDate();
  };

  const [sortActive, { toggle: toggleSortActive }] = useToggle();
  const [sortValue, setSortValue] = useState("article_date|desc");
  const sortBy = useMemo(() => splitChoiceValue(sortValue)[0], [sortValue]);
  const orderBy = useMemo(() => splitChoiceValue(sortValue)[1], [sortValue]);

  const filters = [
    {
      key: "Creation_time",
      label: "Creation time",
      filter: (
        <div onClick={(e) => e.stopPropagation()}>
          <Text visuallyHidden variant="headingMd" as="h2">
            <Button> Intercepting autofocus!</Button>
          </Text>
          <AntDateRangePicker
            size="large"
            style={datepickToShopifyStyle}
            ranges={{
              Today: [moment(), moment()],
              "This Week": [moment().startOf("week"), moment().endOf("week")],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
            }}
            allowClear={false}
            onChange={onDateChange}
            value={date ? [moment(date[0]), moment(date[1])] : null}
          />
        </div>
      ),
      shortcut: true,
    },
  ];
  const appliedFilters = [];
  if (!!date && !!_.compact(date).length) {
    const key = "Creation_time";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, date),
      onRemove: clearDate,
    });
  }
  //  show the filter tags under the Search
  function disambiguateLabel(key, value) {
    switch (key) {
      case "Creation_time":
        return `Creation time: ${value[0]} ~ ${value[1]}`;
      default:
        return value;
    }
  }
  const renderItem = ({
    id,
    article_url,
    article_title,
    article_date,
    article_brief,
    article_category,
    article_profile_picture_url,
    article_comments,
    article_likes,
    author,
    media_outlet,
  }) => {
    //TODO - 230118002 文章封面tag显示不全的问题
    const articleFace = (
      <>
        <div
          style={{
            height: 102,
            width: 222,
            position: "relative",
            borderRadius: 8,
          }}
        >
          {article_category ? (
            <div
              style={{
                position: "absolute",
                height: 20,
                width: 222,
                padding: "0 4px",
                backgroundColor: "rgba(0,0,0,.4)",
                borderTopLeftRadius: 8,
                borderBottomRightRadius: 4,
                color: "#eee",
                fontSize: 12,
              }}
            >
              <TextClamp clamp={1}>{article_category}</TextClamp>
            </div>
          ) : null}
          <img
            style={{ height: "100%", width: "100%" }}
            src={article_profile_picture_url ? article_profile_picture_url : none_face}
            alt="article"
          />
        </div>
      </>
    );
    const articleTitle = (
      <Text variant="bodyMd" as="p" fontWeight="bold">
        <TextClamp clamp={1}>{article_title}</TextClamp>
      </Text>
    );
    const artileBref = (
      <Text variant="bodyMd" as="p" color="subdued">
        <TextClamp clamp={2}>{article_brief}</TextClamp>
      </Text>
    );
    const articleAuthor = (
      <>
        <div
          style={{
            width: 16,
            height: 16,
            display: "inline-block",
            overflow: "hidden",
          }}
        >
          <img
            src={author?.avatar}
            alt="article"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 2,
              border: "1px solid #E5E5E5",
            }}
          />
        </div>
        <Text variant="bodySm" as="span" color="subdued">
          &nbsp;
          {author?.name}
        </Text>
        <Text variant="bodySm" as="span" color="subdued">
          &nbsp;·&nbsp;{media_outlet?.name}
        </Text>
      </>
    );
    const articleMeta = (
      <Stack>
        <Stack.Item>
          <IconText icon={ClockMinor} text={article_date} />
        </Stack.Item>
        <Stack.Item>
          <IconText icon={ThumbsUpMinor} text={article_likes} />
        </Stack.Item>
        <Stack.Item>
          <IconText icon={ConversationMinor} text={article_comments} />
        </Stack.Item>
      </Stack>
    );

    return (
      <ResourceList.Item id={id} url={article_url} media={articleFace}>
        {articleTitle}
        {artileBref}
        <div style={{ marginTop: 4, alignContent: "center" }}>
          <Stack>
            <Stack.Item fill>{articleAuthor}</Stack.Item>
            <Stack.Item>{articleMeta}</Stack.Item>
          </Stack>
        </div>
      </ResourceList.Item>
    );
  };

  const emptyStateMarkup =
    !appliedFilters.length && !articles.length ? (
      <NotFound>
        <Stack vertical alignment="center">
          <img src={notFound} alt="notFound" srcset="" />
          <Text variant="headingLg" as="h3">
            No media contact articles found
          </Text>
          <p style={{ paddingBottom: 16 }}>
            <Text color="subdued">Try changing the filters or search term</Text>
          </p>
        </Stack>
      </NotFound>
    ) : null;

  const [total, setTotal] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const initParams = useMemo(() => {
    const contact_ids = contactID?.length ? { media_contact_ids: [contactID] } : {};
    const outlet_ids = outletID?.length ? { media_outlet_ids: [outletID] } : {};

    return {
      search: queryValue,
      article_date: date,
      page: currentPage,
      per_page: perPage,
      sort_by: sortBy,
      order_by: orderBy,
      ...outlet_ids,
      ...contact_ids,
    };
  }, [queryValue, date, sortBy, orderBy, currentPage, perPage, outletID, contactID]);

  // 在请求展示所有条目的基础上,筛选当前页条目:
  const { run } = useDebounceFn(
    async (params) => {
      setIndexTableLoading(true);

      const { data } = await getMediaArticles({ ...initParams, ...params });

      console.log("data: ", data);
      setArticles(data.list);
      setTotal(data.meta.pagination.total);
      setPerPage(data.meta.pagination.per_page);
      setCurrentPage(data.meta.pagination.current_page);
      window.dispatchEvent(new Event("resize"));
      setIsUpdateArticles((b) => !b);
      setIndexTableLoading(false);
    },
    { wait: 600 }
  );
  const req = useCallback(run, [initParams, run]);

  useMount(() => {
    req();
  }, []);

  useUpdateEffect(() => {
    req({ page: 1 });
  }, [queryValue, date]);

  useUpdateEffect(() => {
    req();
  }, [sortValue]);

  return (
    <>
      <FilterNarrow>
        <Filters
          queryValue={queryValue}
          filters={filters}
          appliedFilters={appliedFilters}
          onQueryChange={setQueryValue}
          onQueryClear={clearQueryValue}
          onClearAll={clearAll}
          queryPlaceholder={`Search`}
        />
        <div style={{ paddingLeft: "0.4rem" }}>
          <Popover
            active={sortActive}
            activator={
              <Button onClick={toggleSortActive} icon={SortMinor}>
                Sort
              </Button>
            }
            onClose={toggleSortActive}
            preferredAlignment="right"
          >
            <Popover.Pane>
              <Popover.Section>
                <TextContainer>
                  <Text variant="bodyMd" as="span" color="subdued">
                    Sort by
                  </Text>
                  <ChoiceList choices={sortOptions} selected={sortValue} onChange={setSortValue} />
                </TextContainer>
              </Popover.Section>
            </Popover.Pane>
          </Popover>
        </div>
      </FilterNarrow>
      <ResourceList
        resourceName={resourceName}
        items={articles}
        renderItem={renderItem}
        loading={indexTableLoading}
        emptyState={emptyStateMarkup}
      />

      {!articles.length ? null : (
        <Stack distribution="trailing">
          <AntPagination>
            <Pagination
              //TODO - 230117003 页码文案调整
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              current={currentPage}
              pageSize={perPage}
              pageSizeOptions={[10, 20, 50, 100]}
              showSizeChanger
              total={total}
              onChange={(page, pageSize) => {
                req({ ...initParams, page: page, per_page: pageSize });
              }}
            />
          </AntPagination>
        </Stack>
      )}
    </>
  );
};
