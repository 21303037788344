import { Button, ChoiceList, Popover, Text, TextContainer } from "@shopify/polaris";
import { columnsActive_atom, columns_atom } from "./atom";

import { ColumnStyled } from "@/components/EnhanceShopify/ColumnStyled";
import { useRecoilToggle } from "@/hooks/useRecoilToggle";
import { Columns3Minor } from "@shopify/polaris-icons";
import { useRecoilState } from "recoil";
import { columsOptions } from "./columnOps";

// 这是侧边栏的选择 机构 用的
export const Columns = () => {
  const { active: columnsActive, toggle: toggleColumnsActive } =
    useRecoilToggle(columnsActive_atom);
  const [columns, setColumns] = useRecoilState(columns_atom);

  return (
    <Popover
      active={columnsActive}
      activator={
        <ColumnStyled>
          <Button icon={Columns3Minor} size="slim" onClick={toggleColumnsActive}>
            Columns
          </Button>
        </ColumnStyled>
      }
      onClose={toggleColumnsActive}
    >
      <Popover.Section>
        <TextContainer>
          <Text variant="bodyMd" as="span" color="subdued">
            Columns
          </Text>
        </TextContainer>
        <ChoiceList
          allowMultiple
          choices={columsOptions}
          selected={columns}
          onChange={setColumns}
        />
      </Popover.Section>
    </Popover>
  );
};
